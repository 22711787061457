import { createRoutine } from "redux-saga-routines";
import { createAction }  from "@reduxjs/toolkit";

// --- CRUD ---
export const getReportRoutine         = createRoutine( "DATASTUDIO/getReport" );
export const removeReportRoutine      = createRoutine( "DATASTUDIO/removeReport" );
export const updateReportRoutine      = createRoutine( "DATASTUDIO/updateReport" );
export const addReportRoutine         = createRoutine( "DATASTUDIO/addReport" );

// --- FORM ---
export const changeUrlFormValueAction = createAction( "DATASTUDIO/changeUrlFormValue" );
export const toggleShowUpdateURLReport = createAction( "DATASTUDIO/toggleShowUpdateURLReport" );
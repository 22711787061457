import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getUniqueUsers } from "clean-archi/core/use-cases/statistics/unique-users/getUniqueUsersThunk";
import ChartSkeleton from "../ChartSkeleton";
import useStatsData from "../hooks/graphsHook";
import { getUniqueUsersSeries, getUniqueUsersOptions } from "../config/chartsConfig";
import { UniqueUsersApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";

const UniqueUsers = () => {

    const { storeData, loading, statsDates, selectedConfiguration, dispatch } =
        useStatsData({
            dataSelector: ( state ) => state.stats.uniqueUsers,
            loadingSelector: ( state ) => state.stats.uniqueUsersLoading,
        });

    useEffect(() => {
        dispatch(
            getUniqueUsers({
                startDate: statsDates.startDate,
                endDate: statsDates.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: selectedConfiguration.branchName,

            })
        );
    }, [
        dispatch,
        selectedConfiguration.instanceId,
        selectedConfiguration.branchName,
        statsDates.startDate,
        statsDates.endDate,
    ]);

    const { series, labels } = getUniqueUsersSeries(
        storeData as UniqueUsersApiResponse[]
    );
    const options = getUniqueUsersOptions( labels, series );

    if ( loading ) return <ChartSkeleton />;

    return (
        <div className="p-3">
            <span className="chart-title">
                {TranslationManager.getText( textCodes.UNIQUE_USERS )}
            </span>
            <Chart height={200} options={options} series={series} type="donut" />
        </div>
    );

};
export default UniqueUsers;
import React from "react";
import { ResponsivePie } from "@nivo/pie";


const ChartPie = ({
    data,
    margin,
    borderColor,
    ...rest
}) => (

    <ResponsivePie
        data={data}
        margin={{ top: 50, right: 50, bottom: 50, left: 50, ...margin }}
        sortByValue={true}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={2}
        colors={{ scheme: "dark2" }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [ [ "darker", 0.2 ] ], ...borderColor }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        defs={[
            {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        legends={[
            {
                anchor: "bottom",
                direction: "row",
                translateY: 56,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                    {
                        on: "hover",
                        style: {
                            itemTextColor: "#000"
                        }
                    }
                ]
            }
        ]}
        {...rest}
    />
);

ChartPie.propTypes = {
    ...ResponsivePie.propTypes
};

export default ChartPie;

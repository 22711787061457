import { DefaultAction, FormSchema, RequestState } from "legacy/context/contextTypes";
import { defaultApiRequestReducer } from "legacy/context/contextHelpers";
import { set } from "lodash";

export type FormActionType = 
    "UPDATE_VALUE" |
    "SAVE" |
    "SUBMIT" |
    "UPDATE_ERRORS" |
    "MARK_FIELDS_AS_TOUCHED" |
    "RESET" |
    "SUCCESS"
;
export type FormAction = DefaultAction<FormActionType>;
export type FormDispatch = ( action: FormAction ) => void
export type FormState = {
    initialValue: any;
    value: any;
    errors: any;
    touchedFields: any;
    request: RequestState<any>,
    validator: any;
    success: boolean;
}

export function initFormState ( formSchema: FormSchema ): FormState {
    const initialValue: any = {};
    Object.entries( formSchema ).forEach(([key, value]: Array<string | any> ) => {
        initialValue[key] = value.defaultValue;
    });

    return {
        initialValue: initialValue,
        value: initialValue,
        errors: undefined,
        touchedFields: undefined,
        request: {
            loading: false,
            data: undefined,
        },
        validator: formSchema,
        success: false,
    };
}

export function formReducer ( state: FormState, action: FormAction ) {
    state.request = defaultApiRequestReducer<FormActionType, FormState["request"]>( "SAVE", state.request, action );

    switch ( action.type ) {
        case "MARK_FIELDS_AS_TOUCHED": {
            const touchedFields: any = {};
            Object.entries( state.validator ).map(([key, value]) => {
                return touchedFields[key] === true;
            });
            state = {
                ...state,
                touchedFields: touchedFields,
            };
            break;
        }
        case "UPDATE_VALUE": 
            state = {
                ...state,
                touchedFields: {...set( state.value, action.payload.valuePath, true )},
                value: {...set( state.value, action.payload.valuePath, action.payload.value )},
            };
            break;
        case "UPDATE_ERRORS": 
            state = {
                ...state,
                errors: {...action.payload},
            };
            break;
        case "SUCCESS": 
            state = {
                ...state,
                success: true,
            };
            break;
        case "RESET": 
            state = {
                ...action.payload,
            };
            break;
    }

    return state;
}


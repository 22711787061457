import React, { useRef }          from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes                  from "prop-types";
import classnames                 from "classnames";
import { FormControl, FormGroup } from "react-bootstrap";
import Button                     from "legacy/components/Button/Button";
import textCodes                  from "legacy/constants/translations/textCodes";
import { TranslationManager } from "translation-manager";
import Input                  from "legacy/components/form/Input/Input";
import { useInputControlled } from "legacy/hooks/useInput";
import { functionDelaying }       from "legacy/tools/performance";
import "./SearchInput.scss";

const SearchInput = ({
    className,
    style,
    onSearch,
    onChange: controlledOnChange,
    value: controlledValue = null,
    searchOnChange = false,
    searchOnChangeDelay = 300,
    bsRole = "feedback",
    ...rest
}) => {
    const refId = useRef( uuidv4());

    function onChange ( value ) {
        if ( typeof controlledOnChange === "function" )
            controlledOnChange( value );

        if ( searchOnChange && typeof onSearch === "function" ) {
            if ( !searchOnChangeDelay ) {
                onSearch( value );
            } else {
                functionDelaying(() => {
                    onSearch( value );
                }, searchOnChangeDelay, refId.current );
            }
        }
    }

    function handleSearchKeyDown ( event ) {
        const key = event.which || event.keyCode;
        if ( key === 13 && typeof onSearch === "function" )
            onSearch( value );
    }

    const [value, setValue, bindInput] = useInputControlled( controlledValue || "", controlledValue, onChange );

    return (
        <FormGroup
            className={classnames( "SearchInput__container", "search", className, { "has-value": !!value })}
            style={style}
        >
            <Input
                {...bindInput}
                type="search"
                placeholder={TranslationManager.getText( textCodes.SEARCH )}
                onKeyDown={handleSearchKeyDown}
                {...rest}
                className={"SearchInput__input"}
            />
            <span className={"SearchInput__buttonInlineContainer"}>
                {
                    value
                        ? (
                            <Button
                                simple
                                icon
                                tabIndex="-1"
                                onClick={() => setValue( "" )}>
                                <i className="fal fa-times"/>
                            </Button>
                        )
                        : (
                            <Button
                                // disabled={!value}
                                icon
                                simple
                                tabIndex="-1"
                                onClick={() => {
                                    if ( onSearch && typeof onSearch === "function" ) onSearch( value );
                                    return;
                                }}
                            >
                                <i className="fal fa-search"/>
                            </Button>
                        )
                }
            </span>
        </FormGroup>
    );
};

SearchInput.propTypes = {
    ...FormControl.propTypes,
    onSearch: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.string,
    searchOnChange: PropTypes.bool,
    searchOnChangeDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    bsRole: PropTypes.string,
};

export default SearchInput;

import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { MisunderstandingApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import { getMisunderstanding } from "clean-archi/core/use-cases/statistics/misunderstanding/getMisunderstandingThunk";
import ChartSkeleton from "../ChartSkeleton";
import useStatsData from "../hooks/graphsHook";
import { getMisunderstandingChartOptions, getMisunderstandingSeriesData } from "../config/chartsConfig";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";

const Misunderstanding = () => {

    const { storeData, loading, statsDates, selectedConfiguration, dispatch } =
        useStatsData({
            dataSelector: ( state ) => state.stats.misunderstanding,
            loadingSelector: ( state ) => state.stats.misunderstandingLoading,
        });


    useEffect(() => {
        dispatch(
            getMisunderstanding({
                startDate: statsDates.startDate,
                endDate: statsDates.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: selectedConfiguration.branchName,
            })
        );
    }, [
        dispatch,
        selectedConfiguration.instanceId,
        selectedConfiguration.branchName,
        statsDates.startDate,
        statsDates.endDate,
    ]);

    const options = getMisunderstandingChartOptions( storeData as MisunderstandingApiResponse[]);
    const series = getMisunderstandingSeriesData(statsDates.startDate, statsDates.endDate, storeData as MisunderstandingApiResponse[]);

    if ( loading ) {
        return <ChartSkeleton />;
    }
    return ( 
        <div className="p-3">
            <span className="chart-title">
                {TranslationManager.getText( textCodes.MISUNDERSTANDINGS )}
            </span>
            <Chart options={options} series={series} type="bar" height={300} />
        </div> 
    );
};

export default Misunderstanding;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NodeFlow, ApiError } from "clean-archi/core/entities/flowbuilder";
import { MainReducerState } from "../../../../../legacy/store/reducers";
import { InputMoveCard } from "../../../interfaces/gateways/flowbuilder/CardGateway";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export const moveCard = createAsyncThunk<Partial<NodeFlow>, InputMoveCard, {
        extra: Partial<Dependencies>, rejectValue: ApiError, state: MainReducerState} >(
            "flowbuilder/moveCard",
            async ( input: InputMoveCard, {extra, rejectWithValue, getState}) => {
                const { branchName } = getState().app.selectedConfiguration;
                const response = await extra.cardGateway?.moveCard( input, branchName );
                if ( !response?.data || response?.error ) {
                    return rejectWithValue({
                        message: response?.message as string,
                        error: response?.error as string,
                    });
                }
                return response?.data as Partial<NodeFlow>;
            }
        );

import { SerializedError } from "@reduxjs/toolkit";
import { BotFlow, Form } from "clean-archi/core/entities/flowbuilder";
import { HighlightedElement } from "clean-archi/core/entities/flowbuilder/HighlightedElement";
import { createSelector } from "reselect";
import { MainReducerState } from "legacy/store/reducers";
import { Bot, BotEnvironment } from "clean-archi/core/entities/bot/Bot";

export interface SelectedConfigParams {
    branchName: string;
    botId: string;
    botInstanceId: string;
    language: string;
}

const BRANCH_NAME_PROD = "prod";

export const getAppConfigParams: ( state: MainReducerState ) => SelectedConfigParams =
    createSelector(
        ( state: MainReducerState ) => state.app.selectedConfiguration,
        ( config ) => {
            return config
                ? {
                    branchName: config.branchName,
                    botId: config.botId,
                    botInstanceId: config.instanceId,
                    language: config.language
                }
                : {
                    branchName: "",
                    botId: "",
                    botInstanceId: "",
                    language: ""
                };
        }
    );

export const getBotLanguages: ( state: MainReducerState ) => string[] | undefined =
    createSelector(
        ( state: MainReducerState ) => state.user.botsList.find(( b: Bot ) => b._id === state.app.selectedConfiguration.botId )?.languages ?? [],
        ( languages ) => {
            return languages;
        }
    );   

export const getBotBranches: ( state: MainReducerState ) => BotEnvironment[] | undefined =
    createSelector(
        ( state: MainReducerState ) => state.user.botsList.find(( b: Bot ) => b._id === state.app.selectedConfiguration.botId )?.environments ?? [],
        ( environments ) => {
            return environments;
        }
    );   

export const getBotFlow: ( state: MainReducerState ) => BotFlow | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow,
        ( botFlow ) => {
            return botFlow;
        }
    );

export const getSelectedFunction: ( state: MainReducerState ) => string | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.selectedFunctionId,
        ( codeFunction ) => codeFunction
    );

export const getSelectedFlow: ( state: MainReducerState ) => string | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.selectedFlowId,
        ( flow ) => flow
    );

export const getSelectedNode: ( state: MainReducerState ) => string | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.selectedNodeId,
        ( node ) => node
    );

export const getShouldRerenderNodes: ( state: MainReducerState ) => boolean =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.shouldRerenderNodes,
        ( shouldRerenderNodes ) => shouldRerenderNodes
    );

export const getShouldRerenderEdges: ( state: MainReducerState ) => boolean =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.shouldRerenderEdges,
        ( shouldRerenderEdges ) => shouldRerenderEdges
    );

export const getSelectedItemForm: ( state: MainReducerState ) => Form =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.itemForm,
        ( form ) => form
    );

export const getIsProd: ( state: MainReducerState ) => boolean =
    createSelector(
        ( state: MainReducerState ) => state.app.selectedConfiguration,
        ( config ) => {
            return config ? config.branchName === BRANCH_NAME_PROD : true;
        }
    );

export const getErrors: ( state: MainReducerState ) => SerializedError | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.error || state.training.error,
        ( error ) => error
    );

export const getHighlightedElement: ( state: MainReducerState ) => HighlightedElement | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.highlightedElement,
        ( highlightedElement ) => highlightedElement
    );

import { useAppDispatch, useAppSelector } from "../../../../../legacy/store/typedHooks";
import { getAppConfigParams } from "../../view-models-generators/config/configParamsViewModels";
import { useEffect } from "react";
import { setFilters } from "../../../../store/training";

export const useInitLanguageFilter = () => {
    const dispatch = useAppDispatch();
    const { language } = useAppSelector( getAppConfigParams );

    useEffect(() => {
        dispatch( setFilters({ language }));
    }
    , [dispatch, language]);

};

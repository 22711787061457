import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getConversationCount } from "clean-archi/core/use-cases/statistics/conversation-count/getConversationCountThunk";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import ChartSkeleton from "../ChartSkeleton";
import { ConversationsCountWithPreviousApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import { getConversationsChartOptions } from "../config/chartsConfig";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";

const Conversations = () => {

    const conversationCountLoading: boolean = useAppSelector(
        ( state ) => state.stats.conversationCountLoading
    );
    const dispatch = useAppDispatch();
    const selectedConfiguration = useAppSelector(
        ( state ) => state.app.selectedConfiguration
    );
    const statsDates = useAppSelector(( state ) => state.stats.filters );
    const ConversationsStoreData = useAppSelector(
        ( state ) => state.stats.conversationsCount
    );

    useEffect(() => {
        dispatch(
            getConversationCount({
                startDate: statsDates.startDate,
                endDate: statsDates.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: selectedConfiguration.branchName,
                perDay: true,
                isCurrentAndPrevious: true,
            })
        );
    }, [
        dispatch,
        selectedConfiguration.instanceId,
        selectedConfiguration.branchName,
        statsDates.startDate,
        statsDates.endDate,
    ]);
    // TODO: change options type 'any'
    const options = getConversationsChartOptions(
      ConversationsStoreData as ConversationsCountWithPreviousApiResponse,
      statsDates.startDate,
      statsDates.endDate,
    );
    if ( conversationCountLoading ) return <ChartSkeleton />;

    return (
        <div className="p-3">
            <span className="chart-title">
                {TranslationManager.getText( textCodes.CONVERSATIONS )}
            </span>
            <Chart
                options={options}
                series={options.series}
                type="line"
                height={300}
            />
        </div>
    );
};

export default Conversations;
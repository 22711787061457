import React, { FC } from "react";
import moment from "moment";
import {TranslationManager} from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import DateTime from "react-datetime";
import { setFilters } from "clean-archi/store/statistic";
const StatisticsFilters: FC = () => {
    const stats = useAppSelector(( state ) => state.stats );
    const dispatch = useAppDispatch();  
    return (
        <div className="header">
            <div className="d-flex flex-row justify-content-between">
                {/* <div className="filters-group d-flex flex-row align-items-center gap-2">
                    <Select
                        isClearable={false}
                        options={[
                            { label: "Par jour", value: "perDar" },
                            { label: "Par mois", value: "perMonth" },
                            { label: "Par Année", value: "perYear" },
                        ]}
                        // placeholder={<Text textCode={textCodes.MESSAGE_OR_FALLBACK} />}
                        placeholder={"Par jour, Par mois, ..."}
                        onChange={( option ) =>
                            dispatch(
                                setFilters({
                                    perDay: option?.value === "perDay",
                                    perMonth: option?.value === "perMonth",
                                    perYear: option?.value === "perYear",
                                })
                            )
                        }
                    />
                </div> */}
                <div>
                    <div className="d-flex flex-row dates-range align-items-center gap-2  ">
                        <i className="fa fa-calendar-alt"></i>
                        <DateTime
                            inputProps={{
                                className: "form-control",
                                placeholder: TranslationManager.getText( textCodes.FROM_DATE ), 
                            }}
                            onChange={( date ) =>
                                dispatch(
                                    setFilters({
                                        startDate: moment( date ).toISOString(),
                                    })
                                )
                            }
                            value={new Date( stats.filters.startDate )}
                        />
                        <DateTime
                            inputProps={{
                                className: "form-control",
                                placeholder: TranslationManager.getText( textCodes.TO_DATE ),
                            }}
                            onChange={( date ) =>
                                dispatch(
                                    setFilters({
                                        endDate: moment( date ).toISOString(),
                                    })
                                )
                            }
                            value={new Date( stats.filters.endDate )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StatisticsFilters;
import {
    CardFlow,
    CARDS,
    CARD_STYLE,
    FLOW_ELEMENT_TYPE,
    FLOW_SPECIAL_ELEMENT,
    NodeFlow,
    NODES,
} from "clean-archi/core/entities/flowbuilder";

interface FlowAccessControlConfig {
  input: FLOW_ELEMENT_TYPE[];
  output: FLOW_ELEMENT_TYPE[];
  maxCards?: number;
  isLastCard?: boolean;
  isContent?: boolean;
  isAction?: boolean;
  isEvent?: boolean;
  isFunction?: boolean;
  isDraggable?: boolean;
  cardStyle?: CARD_STYLE;
}

const config: {
  [T in FLOW_ELEMENT_TYPE]: FlowAccessControlConfig;
} = {
    [NODES.BotResponse]: {
        input: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Payload,
            CARDS.Event,
            CARDS.FlowEntrypoint,
            CARDS.Intent,
        ], // same as CARDS.ContentText, CARDS.ContentQuickReply
        output: [],
        isContent: true,
    },
    [NODES.Trigger]: {
        input: [], // no input possible
        output: [],
        maxCards: 1,
        cardStyle: CARD_STYLE.Borderless,
    },
    [NODES.Action]: {
        input: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Payload,
            CARDS.Event,
            CARDS.FlowEntrypoint,
            CARDS.Intent,
        ],
        output: [],
        isAction: true,
    },
    [NODES.Condition]: {
        input: [],
        output: [],
    },
    [NODES.FlowExit]: {
        input: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            FLOW_SPECIAL_ELEMENT.ContentQuickReplySingleReply,
        ],
        output: [],
        cardStyle: CARD_STYLE.Borderless,
        maxCards: 1,
    },
    [NODES.FlowEntry]: {
        input: [],
        output: [],
        cardStyle: CARD_STYLE.Borderless,
        maxCards: 1,
    },
    [NODES.Switch]: {
        input: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Payload,
            CARDS.Event,
            CARDS.FlowEntrypoint,
            CARDS.Intent,
        ],
        output: [],
    },
    [CARDS.ContentText]: {
        input: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Payload,
            CARDS.Event,
            CARDS.Intent,
            CARDS.Output,
        ],
        output: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Function,
            NODES.BotResponse,
            NODES.FlowExit,
            NODES.Action,
        ],
        isDraggable: true,
        isContent: true,
    },
    [CARDS.Case]: {
        input: [],
        output: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Function,
            NODES.BotResponse,
            NODES.FlowExit,
            NODES.Action,
        ],
        isDraggable: false,
        isContent: false,
        isEvent: true,
    },
    [CARDS.ContentQuickReply]: {
        input: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Payload,
            CARDS.Event,
            CARDS.Intent,
        ],
        output: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Function,
            NODES.BotResponse,
            NODES.Action,
        ],
        isDraggable: true,
        isEvent: true,
    },
    [CARDS.Event]: {
        input: [],
        output: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Function,
            NODES.BotResponse,
            NODES.Action,
        ],
    },
    [CARDS.Function]: {
        input: [CARDS.Payload],
        output: [NODES.BotResponse],
        isDraggable: true,
        isFunction: true,
    },
    [CARDS.Tag]: {
        input: [],
        output: [],
        isDraggable: true,
        isFunction: true,
    },
    [CARDS.Intent]: {
        input: [],
        output: [NODES.BotResponse, CARDS.ContentText, CARDS.ContentQuickReply],
        isDraggable: true,
        isEvent: true,
    },
    [CARDS.Default]: {
        input: [],
        output: [NODES.BotResponse, CARDS.ContentText, CARDS.ContentQuickReply],
        isDraggable: true,
        isEvent: true,
    },
    [CARDS.FlowExitpoint]: {
        input: [],
        output: [],
    },
    [CARDS.FlowEntrypoint]: {
        input: [],
        output: [NODES.BotResponse],
    },
    [CARDS.Payload]: {
        input: [],
        output: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Function,
            NODES.BotResponse,
            NODES.Action,
        ],
        isEvent: true,
        isDraggable: true,
    },
    [CARDS.Output]: {
        input: [],
        output: [
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Function,
            NODES.BotResponse,
            NODES.FlowExit,
        ],
        isEvent: true,
    },
    [FLOW_SPECIAL_ELEMENT.ContentQuickReplySingleReply]: {
        input: [],
        output: [NODES.BotResponse, CARDS.ContentText, CARDS.ContentQuickReply],
    },
};

// --------------------------------------
// Get the config of a flow element (or the full config if no type is specified)

export const getConfig = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type ? config[type] : config;
};

// --------------------------------------
// Check if the element type can have an input

export const hasInput = ( type: FLOW_ELEMENT_TYPE ) => {
    return config[type].input.length > 0;
};

// --------------------------------------
// Get all the types that can be connected to the input

export const getInputTypes = ( type: FLOW_ELEMENT_TYPE ) => {
    return config[type].input;
};

// --------------------------------------
// Check if the element type can have an output

export const hasOutput = ( type: FLOW_ELEMENT_TYPE, isLastCard = true ) => {
    return config[type].output.length > 0 ? isLastCard : false;
};

// --------------------------------------
// Get all the types that can be connected to the output

export const getOutputTypes = ( type: FLOW_ELEMENT_TYPE ) => {
    return config[type].output;
};

// --------------------------------------
// Check if the card type is draggable

export const isDraggable = ( type?: FLOW_ELEMENT_TYPE, nodeType?: NODES ) => {
    if ( nodeType && config[nodeType].maxCards === 1 ) return false;
    return type && config[type].isDraggable;
};

// --------------------------------------
// Check if the card should be the last of the node

export const isLastCard = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type && config[type].isLastCard;
};

// --------------------------------------
// Check if the node is a content

export const isContentNode = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type && config[type].isContent;
};

// --------------------------------------
// Check if the node is a action

export const isActionNode = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type && config[type].isAction;
};

// --------------------------------------
// Check if the node is a switch

export const isSwitchNode = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type === NODES.Switch;
};

// --------------------------------------
// Check if the card is a content

export const isContentCard = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type && config[type].isContent;
};

// --------------------------------------
// Check if the card is an event

export const isEventCard = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type && config[type].isEvent;
};

// --------------------------------------
// Check if the card is a function

export const isFunctionCard = ( type?: FLOW_ELEMENT_TYPE ) => {
    return type && config[type].isFunction;
};

// --------------------------------------
// Get the cards style by type

export const getCardStyle = ( type?: FLOW_ELEMENT_TYPE ) => {
    return ( type && config[type].cardStyle ) || CARD_STYLE.Bordered;
};

// --------------------------------------
// Test if we can add another card to the node

export const canAddCardTo = ( node: NodeFlow | undefined ) => {
    if ( !node ) return false;
    const nodeMaxCards = config[node.type].maxCards;
    return nodeMaxCards ? node.cards.size < nodeMaxCards : true;
};

export const canAddCardOfTypeTo = (
    cardType: CardFlow["type"],
    nodeType: NodeFlow["type"],
    cardsTypes: CardFlow["type"][] = []
): boolean => {
    if ( cardType === CARDS.ContentQuickReply && nodeType === NODES.BotResponse ) {
        return cardsTypes.every(( type ) => type !== cardType );
    }
    return true;
};

export const canAppendCardTo = (
    node: NodeFlow | undefined,
    hasLastCardAnOutput = false
) => {
    if ( !node ) return false;
    if ( hasLastCardAnOutput ) return false;
    const hasLastCard = Array.from( node.cards.values()).find(( card ) =>
        isLastCard( card?.type )
    );
    return !hasLastCard && canAddCardTo( node );
};

// --------------------------------------
// Check if two elements can be connected

export const isConnectionValid = (
    from: FLOW_ELEMENT_TYPE,
    to: FLOW_ELEMENT_TYPE
) => {
    return (
        config[from] &&
    config[from].output.includes( to ) &&
    config[to] &&
    config[to].input.includes( from )
    );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {isEmpty } from "lodash";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { BotFlow } from "../../../entities/flowbuilder";
import { initBotFlow } from "../init-bot-flow/initBotflowThunk";

export interface InputFetchBotFlow {
    botId: string;
    branchName: string;
}

export type ResponseApi<T> = {
    data?: Partial<T>,
    message?: string,
    error?: string
}

export interface FlowbuilderApiError {
    message: string;
    error: string;
}

export const fetchBotFlow = createAsyncThunk<Partial<BotFlow>, InputFetchBotFlow, { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: FlowbuilderApiError} >(
    "flowbuilder/fetchBotFlow",
    async ( input: InputFetchBotFlow, {dispatch, extra, getState, rejectWithValue}) => {
        const response = await extra.botFlowGateway?.fetchBotFlow( input );
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }

        if ( response?.data && isEmpty( response?.data )) {
            const { botId, branchName, instanceId: botInstanceId } = getState().app.selectedConfiguration;
            await dispatch( initBotFlow({botId, branchName, botInstanceId}));
        }
        return response?.data as Partial<BotFlow>;
    }
);
import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { addCardReducer } from "../../core/use-cases/flowbuilder/add-card/addCardReducer";
import { addFlowReducer, addStressTestFlowReducer } from "../../core/use-cases/flowbuilder/add-flow/addFlowReducer";
import { preselectFlowReducer } from "../../core/use-cases/flowbuilder/preselect-flow/preselectFlowReducer";
import { addNodeReducer } from "../../core/use-cases/flowbuilder/add-node/addNodeReducer";
import { applyConnectionReducer } from "../../core/use-cases/flowbuilder/apply-connection/applyConnectionReducer";
import { BotFlow, Flow, FlowListEntry, FlowBuilderState, Connection, ExitpointCardFlow, NodeFlow, Position, CardFlow, IOFlowRef, Form, FORMS_SPECS, CARDS, ConnectionItem, RemovedItem, FunctionItem } from "../../core/entities/flowbuilder";
import { initItemFormReducer } from "../../core/use-cases/flowbuilder/edit-item/initItemFormReducer";
import { switchFormSpecReducer } from "../../core/use-cases/flowbuilder/edit-item/switchFormSpecReducer";
import { resetItemFormReducer } from "../../core/use-cases/flowbuilder/edit-item/resetItemFormReducer";
import { updateFormContentReducer } from "../../core/use-cases/flowbuilder/edit-item/updateFormContentReducer";
import { updateCardFlowReducer, UpdateCardFlowAction } from "../../core/use-cases/flowbuilder/edit-item/updateCardFlowReducer";
import { addFlowEntryElementsReducer } from "../../core/use-cases/flowbuilder/add-flow-entry-elements/addFlowEntryElementsReducer";
import { fetchBotFlow } from "../../core/use-cases/flowbuilder/fetch-bot-flow/fetchBotFlowThunk";
import { initBotFlow } from "../../core/use-cases/flowbuilder/init-bot-flow/initBotflowThunk";
import { addFlow } from "../../core/use-cases/flowbuilder/add-flow/addFlowThunk";
import { fetchFlowsListReducer } from "../../core/use-cases/flowbuilder/fetch-flows-list/fetchFlowsListReducer";
import { fetchFlowsList as fetchFlowsListThunk } from "../../core/use-cases/flowbuilder/fetch-flows-list/fetchFlowsListThunk";
import { fetchFunctionsListReducer } from "../../core/use-cases/flowbuilder/fetch-functions-list/fetchFunctionsListReducer";
import { fetchFunctionsList as fetchFunctionsListThunk } from "../../core/use-cases/flowbuilder/fetch-functions-list/fetchFunctionsListThunk";
import { updateNodeReducer } from "../../core/use-cases/flowbuilder/update-node/updateNodeReducer";
import { updateNode as updateNodeThunk } from "../../core/use-cases/flowbuilder/update-node/updateNodeThunk";
import { moveCardReducer } from "../../core/use-cases/flowbuilder/move-card/moveCardReducer";
import { selectFlowReducer } from "../../core/use-cases/flowbuilder/select-flow/selectFlowReducer";
import { disconnectReducer } from "../../core/use-cases/flowbuilder/disconnect/disconnectReducer";
import { addCard } from "../../core/use-cases/flowbuilder/add-card/addCardThunk";
import { updateCard } from "../../core/use-cases/flowbuilder/update-card/updateCardThunk";
import { updateCardReducer } from "../../core/use-cases/flowbuilder/update-card/updateCardReducer";
import { addNode as addNodeThunk } from "../../core/use-cases/flowbuilder/add-node/addNodeThunk";
import { addConnection as addConnectionThunk } from "../../core/use-cases/flowbuilder/add-connection/addConnectionThunk";
import { removeConnection as removeConnectionThunk } from "../../core/use-cases/flowbuilder/remove-connection/removeConnectionThunk";
import { updateItemConnectionsReducer } from "../../core/use-cases/flowbuilder/update-item-connections/updateItemConnectionsReducer";
import { removeItemReducer } from "../../core/use-cases/flowbuilder/remove-item/removeItemReducer";
import { removeNode as removeNodeThunk } from "../../core/use-cases/flowbuilder/remove-node/removeNodeThunk";
import { removeCard as removeCardThunk } from "../../core/use-cases/flowbuilder/remove-card/removeCardThunk";
import { removeFlowLinkNode as removeFlowLinkNodeThunk } from "../../core/use-cases/flowbuilder/remove-flow-link-node/removeFlowLinkNodeThunk";
import { toggleCardLock } from "../../core/use-cases/flowbuilder/toggle-card-lock/toggleCardLockThunk";
import { removeFlow } from "../../core/use-cases/flowbuilder/remove-flow/removeFlowThunk";
import { removeFlowReducer } from "../../core/use-cases/flowbuilder/remove-flow/removeFlowReducer";
import { editFlow } from "../../core/use-cases/flowbuilder/edit-flow/editFlowThunk";
import { editFlowReducer } from "../../core/use-cases/flowbuilder/edit-flow/editFlowReducer";
import { UpdateFlowResult } from "../../core/interfaces/gateways/flowbuilder/FlowGateway";
import { moveCard } from "../../core/use-cases/flowbuilder/move-card/moveCardThunk";
import { HighlightedElement } from "../../core/entities/flowbuilder/HighlightedElement";

export const initialState: FlowBuilderState = {
    botFlow: {
        id: undefined,
        botId: undefined,
        branchName: undefined,
        loading: false,
        error: undefined,
        systemError: undefined,
        selectedFlowId: undefined,
        numberOfNodes: 0,
        flows: new Map<Flow["id"], Flow>(),
        flowsList: [],
        selectedNodeId: undefined,
        shouldRerenderNodes: true,
        shouldRerenderEdges: true,
        selectedFunctionId: undefined,
        functionsList: [],
    },
    itemForm: {
        cardId: undefined,
        nodeId: undefined,
        spec: undefined,
        type: undefined,
    },
    highlightedElement: undefined,
};

export const flowbuilderSlice = createSlice({
    name: "flowbuilder",
    initialState,
    reducers: {
        addStressTestFlow: {
            reducer: ( state, action: PayloadAction<Flow> ) => addStressTestFlowReducer( state, action ),
            prepare: () => {
                const id = nanoid();
                const nodes = new Map<NodeFlow["id"], NodeFlow>();
                return { payload: {id, index: 0, name: "Stress Test", nodes}};
            }
        },
        addNode ( state, action: PayloadAction<NodeFlow> ) {
            addNodeReducer( state, action );
        },
        applyConnection ( state, action: PayloadAction<Partial<Connection>> ) {
            applyConnectionReducer( state, action );
        },
        disconnect ( state, action: PayloadAction<{from: IOFlowRef, to: IOFlowRef}> ) {
            disconnectReducer( state, action );
        },
        updateNode ( state, action: PayloadAction<{id: string, position?: Position}> ) {
            updateNodeReducer( state, action );
        },
        preselectFlow ( state ) {
            preselectFlowReducer( state );
        },
        selectFlow ( state, action: PayloadAction<{flowId: string, nodeId?: string}> ) {
            selectFlowReducer( state, action );
        },
        selectNode ( state, action: PayloadAction<string> ) {
            state.botFlow.selectedNodeId = action.payload;
        },
        selectFunction ( state, action: PayloadAction<string | undefined> ) {
            state.botFlow.selectedFunctionId = action.payload;
        },
        initItemForm ( state, action: PayloadAction<Form> ) {
            initItemFormReducer( state, action );
        },
        switchFormSpec ( state, action: PayloadAction<FORMS_SPECS> ) {
            switchFormSpecReducer( state, action );
        },
        resetItemForm ( state ) {
            resetItemFormReducer( state );
        },
        updateFormContent ( state, action: PayloadAction<CardFlow> ) {
            updateFormContentReducer( state, action );
        },
        updateCardFlow ( state, action: PayloadAction<UpdateCardFlowAction> ) {
            updateCardFlowReducer( state, action );
        },
        addFlowEntryElements ( state, action: PayloadAction<ExitpointCardFlow> ) {
            addFlowEntryElementsReducer( state, action );
        },
        disableNodesRerender ( state ) {
            state.botFlow.shouldRerenderNodes = false;
        },
        enableNodesRerender ( state ) {
            state.botFlow.shouldRerenderNodes = true;
        },
        disableEdgesRerender ( state ) {
            state.botFlow.shouldRerenderEdges = false;
        },
        enableEdgesRerender ( state ) {
            state.botFlow.shouldRerenderEdges = true;
        },
        clearError ( state ) {
            state.botFlow.error = undefined;
            state.botFlow.systemError = undefined;
        },
        fetchFlowsList ( state, action: PayloadAction<FlowListEntry[]> ) {
            fetchFlowsListReducer( state, action );
        },
        updateItemConnections ( state, action: PayloadAction<ConnectionItem[]> ) {
            updateItemConnectionsReducer( state, action );
        },
        removeItem ( state, action: PayloadAction<RemovedItem> ) {
            removeItemReducer( state, action );
        },
        resetFunctionsList ( state ) {
            state.botFlow.functionsList = [];
        },
        highlightElement ( state, action: PayloadAction<HighlightedElement | undefined> ) {
            state.highlightedElement = action.payload;
        }
    },
    extraReducers: ( builder ) => {
        /**
         * FETCH BOT FLOW
         */
        builder.addCase( fetchBotFlow.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( fetchBotFlow.fulfilled, ( state, action: PayloadAction<Partial<BotFlow>> ) => {
            state.botFlow = {
                ...state.botFlow,
                ...action.payload,
                loading: false
            };
        });
        builder.addCase( fetchBotFlow.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.error = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * INIT BOT FLOW
         */
        builder.addCase( initBotFlow.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( initBotFlow.fulfilled, ( state, action: PayloadAction<Partial<BotFlow>> ) => {
            state.botFlow = {
                ...state.botFlow,
                ...action.payload,
                shouldRerenderNodes: true,
                shouldRerenderEdges: true,
                loading: false
            };
        });
        builder.addCase( initBotFlow.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.error = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * ADD FLOW
         */
        builder.addCase( addFlow.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( addFlow.fulfilled, ( state, action: PayloadAction<Partial<Flow>> ) => {
            addFlowReducer( state, action );
        });
        builder.addCase( addFlow.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.error = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * EDIT FLOW
         */
        builder.addCase( editFlow.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( editFlow.fulfilled, ( state, action: PayloadAction<UpdateFlowResult> ) => {
            editFlowReducer( state, action );
        });
        builder.addCase( editFlow.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.error = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * REMOVE FLOW
         */
        builder.addCase( removeFlow.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( removeFlow.fulfilled, ( state, action ) => {
            removeFlowReducer( state, action );
        });
        builder.addCase( removeFlow.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.error = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * UPDATE NODE
         */
        builder.addCase( updateNodeThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( updateNodeThunk.fulfilled, ( state, action: PayloadAction<Partial<NodeFlow>> ) => {
            updateNodeReducer( state, action );
        });
        builder.addCase( updateNodeThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.error = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * ADD CARD
         */
        builder.addCase( addCard.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( addCard.fulfilled, ( state, action: PayloadAction<Partial<CardFlow | CardFlow[]>, string,
            {nodeId: string, flowId: string, cardId: string } > ) => {
            addCardReducer( state, action );
            const newCard = Array.isArray( action.payload ) ? action.payload.find( c => c?.id === action.meta.cardId ) : action.payload;
            initItemFormReducer( state, {
                ...action,
                payload: {
                    cardId: newCard?.id,
                    content: {
                        id: newCard?.id,
                        index: newCard?.index,
                        type: newCard?.type,
                        connections: newCard?.connections,
                        acm: newCard?.acm,
                        payload: newCard?.payload,
                        intent: newCard?.intent,
                        tag: newCard?.tag,
                    } as CardFlow,
                    nodeId: action.meta.nodeId,
                    spec: FORMS_SPECS.Card,
                    type: newCard?.type,
                }
            });
        });
        builder.addCase( addCard.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.error = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * UPDATE CARD
         */
        builder.addCase( updateCard.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( updateCard.fulfilled, ( state, action: PayloadAction<Partial<CardFlow | CardFlow[]>, string,
            {nodeId: string, flowId: string, cardId: string, removedItems: RemovedItem[]}> ) => {
            if ( !Array.isArray( action.payload ) && action.payload.type === CARDS.FlowExitpoint ) {
                addFlowEntryElementsReducer( state, {
                    ...action,
                    payload: action.payload as ExitpointCardFlow
                });
            }
            updateCardReducer( state, action );
            if ( action.meta.removedItems.length ) {
                // Remove connections if QR buttons have been deleted
                updateItemConnectionsReducer( state, {
                    ...action,
                    payload: action.meta.removedItems.flatMap( item => item.flowRefs )
                });
            }
            updateFormContentReducer( state, {
                ...action,
                payload: Array.isArray( action.payload )
                    ? action.payload.find( card => card?.id === action.meta.cardId ) as CardFlow
                    : action.payload as CardFlow
            });
        });
        builder.addCase( updateCard.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * MOVE CARD
         */
        builder.addCase( moveCard.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( moveCard.fulfilled, ( state, action: PayloadAction<any> ) => {
            moveCardReducer( state, action );
        });
        builder.addCase( moveCard.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * ADD NODE
         */
        builder.addCase( addNodeThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( addNodeThunk.fulfilled, ( state, action: PayloadAction<NodeFlow> ) => {
            addNodeReducer( state, action );
        });
        builder.addCase( addNodeThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * ADD CONNECTION
         */
        builder.addCase( addConnectionThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( addConnectionThunk.fulfilled, ( state, action: PayloadAction<Partial<Connection>> ) => {
            applyConnectionReducer( state, action );
        });
        builder.addCase( addConnectionThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * REMOVE CONNECTION
         */
        builder.addCase( removeConnectionThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( removeConnectionThunk.fulfilled, ( state, action: PayloadAction<Partial<Connection>> ) => {
            applyConnectionReducer( state, action );
        });
        builder.addCase( removeConnectionThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * REMOVE NODE
         */
        builder.addCase( removeNodeThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( removeNodeThunk.fulfilled, ( state, action: PayloadAction<Partial<RemovedItem>> ) => {
            removeItemReducer( state, action );
            updateItemConnectionsReducer( state, {
                ...action,
                payload: action.payload.flowRefs as ConnectionItem[]
            });
        });
        builder.addCase( removeNodeThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * REMOVE FLOW LINK NODE
         */
        builder.addCase( removeFlowLinkNodeThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( removeFlowLinkNodeThunk.fulfilled, ( state, action: PayloadAction<Partial<RemovedItem[]>> ) => {
            action.payload.forEach( entry => {
                if ( !entry ) return;
                removeItemReducer( state, {
                    ...action,
                    payload: entry
                });
                updateItemConnectionsReducer( state, {
                    ...action,
                    payload: entry.flowRefs as ConnectionItem[]
                });
            });
        });
        builder.addCase( removeFlowLinkNodeThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * REMOVE CARD
         */
        builder.addCase( removeCardThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( removeCardThunk.fulfilled, (
            state, action: PayloadAction<Partial<RemovedItem>, string, {nodeId: string, flowId: string, cardId: string}>
        ) => {
            removeItemReducer( state, action );
            updateCardReducer( state, {
                ...action,
                payload: action.payload.cards as CardFlow[]
            });
            updateItemConnectionsReducer( state, {
                ...action,
                payload: action.payload.flowRefs as ConnectionItem[]
            });
        });
        builder.addCase( removeCardThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * FETCH FLOWS LIST
         */
        builder.addCase( fetchFlowsListThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( fetchFlowsListThunk.fulfilled, ( state, action: PayloadAction<FlowListEntry[]> ) => {
            fetchFlowsListReducer( state, action );
        });
        builder.addCase( fetchFlowsListThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * LOCK CARD (for editing)
         */
        builder.addCase( toggleCardLock.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( toggleCardLock.fulfilled, ( state, action: PayloadAction<Partial<CardFlow>, string,
            {nodeId: string, flowId: string, cardId: string }> ) => {
            updateCardReducer( state, action );
        });
        builder.addCase( toggleCardLock.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
        /**
         * FETCH FUNCTIONS LIST
         */
        builder.addCase( fetchFunctionsListThunk.pending, ( state ) => {
            state.botFlow.loading = true;
        });
        builder.addCase( fetchFunctionsListThunk.fulfilled, ( state, action: PayloadAction<FunctionItem[]> ) => {
            fetchFunctionsListReducer( state, action );
        });
        builder.addCase( fetchFunctionsListThunk.rejected, ( state, action ) => {
            if ( action.payload ) {
                state.botFlow.error = action.payload;
            } else {
                state.botFlow.systemError = action.error;
            }
            state.botFlow.loading = false;
        });
    }
});

export const { 
    addStressTestFlow,
    preselectFlow,
    selectFlow,
    selectNode,
    selectFunction,
    addNode,
    applyConnection,
    disconnect,
    initItemForm,
    switchFormSpec,
    resetItemForm,
    updateFormContent,
    updateCardFlow,
    addFlowEntryElements,
    updateNode,
    disableNodesRerender,
    enableNodesRerender,
    disableEdgesRerender,
    enableEdgesRerender,
    clearError,
    fetchFlowsList,
    updateItemConnections,
    removeItem,
    resetFunctionsList,
    highlightElement,
} = flowbuilderSlice.actions;
export default flowbuilderSlice.reducer;

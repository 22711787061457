import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
    Suspense,
} from "react";
import { debounce, isEqual } from "lodash";
import { updateCard } from "clean-archi/core/use-cases/flowbuilder/update-card/updateCardThunk";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { getBotFlowId, getFlowsList } from "clean-archi/adapters/primary/view-models-generators/bot-elements/botElementsViewModels";
import { getAppConfigParams, getSelectedFlow, getSelectedItemForm } from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";
import { HttpCardStorelessGateway } from "clean-archi/adapters/secondary/gateways/flowbuilder/card/HttpCardStorelessGateway";
import { IFetchCardInput } from "clean-archi/core/interfaces/gateways/flowbuilder/CardStorelessGateway";
import { CARDS, FORMS_SPECS, CardFlow, FlowListEntry, Form } from "clean-archi/core/entities/flowbuilder";
import { FormContract } from "./form-manager-entities";
import useFormContract from "./useFormContract";

export interface FormManagerProps {
    id: string;
    isProd: boolean;
    nodeId: string;
    onClose?: Function;
    onRemove: Function;
    spec?: FORMS_SPECS;
    type: CARDS;
}

const FormManager: FC<FormManagerProps> = ({
    id,
    isProd,
    nodeId,
    onClose,
    onRemove,
    spec = FORMS_SPECS.Panel,
    type,
}) => {
    const storeFlows: FlowListEntry[] = useAppSelector( getFlowsList );
    const [flows, setFlows] = useState<FlowListEntry[]>(() => storeFlows );

    useEffect(
        () => {
            if ( !isEqual( flows, storeFlows )) {
                setFlows( storeFlows );
            }
        },
        [flows, storeFlows]
    );

    const dispatch = useAppDispatch();
    const { content: cardFlow }: Form = useAppSelector( getSelectedItemForm );
    const fetchCardGateway = useMemo(() => new HttpCardStorelessGateway(), []);
    const [inputToFetch, setInputToFetch] = useState<IFetchCardInput | undefined>( undefined );
    const [fetchedData, setFetchedData] = useState({});
    const [formDefaultValues, setFormDefaultValues] = useState({});
    const [updatedData, setUpdatedData] = useState({});
    const [status, setStatus] = useState( "idle" );
    const appConfigParams = useAppSelector( getAppConfigParams );
    const flowId = useAppSelector( getSelectedFlow ) as string;
    const botFlowId = useAppSelector( getBotFlowId ) as string;
    const contractConfig = useMemo(() => ({ ...appConfigParams, botFlowId, flowId, flows, nodeId }), [appConfigParams, botFlowId, flowId, flows, nodeId]);
    const formContract: FormContract | undefined = useFormContract( contractConfig, type, spec );

    useEffect(() => setStatus( "idle" ), [formContract]);

    useEffect(() => {
        if ( !id || !nodeId ) {
            setInputToFetch( undefined );
            setStatus( "resolved" );
            return;
        }
        setStatus( "idle" );
        setInputToFetch({
            botId: contractConfig.botId,
            branchName: contractConfig.branchName,
            id,
            language: contractConfig.language,
            nodeId
        });
    }, [contractConfig.botId, contractConfig.branchName, contractConfig.language, id, nodeId]);

    const resetDefaultFormProps = useCallback(
        ( cardFlow: CardFlow ) => {
            if ( !formContract?.resetDefaultFormProps ) return;
            const nextFormDefaultValues = formContract.resetDefaultFormProps(
                formDefaultValues,
                cardFlow
            );
            if ( !isEqual( formDefaultValues, nextFormDefaultValues )) {
                setFormDefaultValues( nextFormDefaultValues );
            }
        },
        [formContract, formDefaultValues]
    );

    useEffect(
        () => {
            if ( cardFlow && status === "resolved" ) {
                resetDefaultFormProps( cardFlow );
            }
        },
        [cardFlow, resetDefaultFormProps, status]
    );

    useEffect(() => {
        if ( status !== "idle" ) return;
        if ( formContract && inputToFetch ) {
            setStatus( "pending" );
            fetchCardGateway
                .fetch( inputToFetch, contractConfig.branchName )
                .then(( response: any ) => {
                    if ( !response.data ) {
                        setStatus( "rejected" );
                        return;
                    }
                    setFetchedData( response.data );
                    const defaultProps = formContract.initForm( response.data );
                    setFormDefaultValues( defaultProps );
                    setUpdatedData( defaultProps );
                    setStatus( "resolved" );
                })
                .catch(() => setStatus( "rejected" ));
        }
    }, [
        inputToFetch,
        fetchCardGateway,
        formContract,
        status,
        setFormDefaultValues,
        setUpdatedData,
        setStatus,
        contractConfig.branchName
    ]);

    const FormComponent: Function | null = useMemo(() => {
        if ( status !== "resolved" ) return null;
        return formContract?.component || null;
    }, [formContract, status]);

    const debounceMemo = useMemo(
        () => debounce(( func: Function, value: any ) => func( value ), 2000 ),
        []
    );

    const onFormSubmit = useCallback(
        ( formData?: any ) => {
            if ( isProd || !formContract ) return;
            const inputData = formContract?.prepareSubmit({ id, nodeId }, fetchedData, formData || updatedData );
            dispatch ( updateCard( inputData ));
        },
        [formContract, id, nodeId, updatedData, fetchedData, dispatch, isProd]
    );

    const onFieldChange = useCallback(
        ( partialNextData: any, debounced = true ) => {
            if ( !isProd ) {
                const nextUpdatedData = formContract?.onFieldUpdate(
                    updatedData,
                    partialNextData
                );
                setUpdatedData( nextUpdatedData );
                if ( debounced ) {
                    debounceMemo( onFormSubmit, nextUpdatedData );
                } else {
                    onFormSubmit( nextUpdatedData );
                }
            }
        },
        [
            updatedData,
            formContract,
            debounceMemo,
            onFormSubmit,
            setUpdatedData,
            isProd
        ]
    );

    const onCardFormClose = useCallback(() => {
        if ( spec === FORMS_SPECS.Card ) {
            debounceMemo.cancel();
            onFormSubmit( updatedData );
        }
        onClose?.();
    }, [updatedData, spec, onClose, debounceMemo, onFormSubmit]);

    const formProps = useMemo(
        () => ({
            defaultValues: formDefaultValues,
            onChange: onFieldChange,
            extra: formContract?.extra?.({ ...fetchedData, nodeId }) || undefined,
            onCardRemove: onRemove,
            ...( spec === FORMS_SPECS.Card && { onBlur: onCardFormClose }),
        }),
        [formDefaultValues, onFieldChange, formContract, fetchedData, nodeId, onRemove, spec, onCardFormClose]
    );

    return (
        <Suspense fallback={<div />}>
            {FormComponent && <FormComponent {...formProps} />}
        </Suspense>
    );
};

export default FormManager;

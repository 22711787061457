import { createAsyncThunk } from "@reduxjs/toolkit";
import { NodeFlow, ApiError } from "clean-archi/core/entities/flowbuilder";
import { InputUpdateNode } from "clean-archi/core/interfaces/gateways/flowbuilder/NodeGateway";
import { MainReducerState } from "../../../../../legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export const updateNode = createAsyncThunk<Partial<NodeFlow>, InputUpdateNode, {
        extra: Partial<Dependencies>, rejectValue: ApiError, state: MainReducerState} >(
            "flowbuilder/updateNode",
            async ( input: InputUpdateNode, {extra, rejectWithValue, getState}) => {
                const { branchName } = getState().app.selectedConfiguration;
                const response = await extra.nodeGateway?.updateNode( input, branchName );
                if ( !response?.data || response?.error ) {
                    return rejectWithValue({
                        message: response?.message as string,
                        error: response?.error as string,
                    });
                }
                return response?.data as Partial<NodeFlow>;
            }
        );

import { CardFlow } from "./Card";
import { IO } from "./Connection";

/* eslint-disable no-unused-vars */

export enum NODES {
    Action = "Action",
    BotResponse = "BotResponse",
    Condition = "Condition",
    FlowExit = "FlowExit",
	FlowEntry = "FlowEntry",
    Trigger = "Trigger",
    Switch = "Switch",
}

export interface Position {
    x: number;
    y: number;
}

export type NodeFlow = {
    id: string;
    name: string; // auto-généré et modifiable
    position: Position;
    type: NODES;
    cards: Map<CardFlow["id"], CardFlow>;
    connections: IO;
    flowId?: string;
}

export type BotNodeFlow = Omit<NodeFlow, "cards"> & {
    cards: BotCardFlow[],
}

export type BotCardFlow = CardFlow & {
    
}

import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState, FocusEventHandler } from "react";
import { Text } from "translation-manager-react";
import cx from "classnames";
import { debounce } from "lodash";
import {
    useCombobox,
    UseComboboxProps,
    UseComboboxState,
    UseComboboxStateChange,
    UseComboboxStateChangeOptions
// @ts-ignore 
} from "downshift";
import InfoTooltip from "legacy/components/InfoTooltip/InfoTooltip";
import useKeyPress from "legacy/hooks/useKeyPress";

import "./AutocompleteIntent.scss";
import { AssignedIntent } from "../../../../../core/entities/training/AssignedIntent";
import { useIntentSuggestions } from "../useIntentSuggestions";

export interface DisplayConditionInput {
    hasResults: boolean;
    inputValue: string;
    isOpen: boolean;
}

export interface SpecialItemProps<AssignedIntent> {
    translationKey?: string;
    prefix?: string;
    prepareSelectedItem: ( inputValue: string ) => AssignedIntent;
    shouldShowOption: ( conditionInput: DisplayConditionInput ) => boolean;
}

export interface AutocompleteIntentProps extends Pick<UseComboboxProps<AssignedIntent>, "initialSelectedItem"> {
    autoFocus?: boolean;
    className?: string;
    disabled?: boolean;
    onFocus?: FocusEventHandler<AssignedIntent> | undefined;
    placeholder?: string;
    readOnlyReason?: string;
    specialItems?: SpecialItemProps<AssignedIntent>[];
    wait?: number;
    onChange?: ( intent: AssignedIntent | null | undefined ) => void;
}

export function AutocompleteIntent ({
    autoFocus = false,
    className = "",
    disabled,
    initialSelectedItem,
    onFocus,
    placeholder,
    readOnlyReason = "",
    wait,
    onChange,
} : AutocompleteIntentProps ): ReactElement {

    const [searchValue, setSearchValue] = useState( "" );
    
    const [ items ] = useIntentSuggestions( searchValue );

    const inputRef = useRef( "" );
    const filteredSpecialItemsRef = useRef<AssignedIntent[]>([]);
    const debounceMemo = useMemo(
        () => debounce(( func: Function, value: any ) => func( value ), wait || 0 ),
        [wait]
    );
    const [registerKeyPressEnter] = useKeyPress([[ "Enter" ]]);

    const onInputValueChange = useCallback(( changes: UseComboboxStateChange<AssignedIntent> ) => {
        const value = ( changes.selectedItem && changes.selectedItem.name === changes.inputValue ) ? changes.selectedItem : {id: "", name: changes.inputValue ?? ""};

        if ( value.id === "" ) {
            const searchIntent = items?.find(( i ) => i.name === value.name );
            if ( searchIntent ) value.id = searchIntent.id ?? "";
        }

        setSearchValue( value.name );
        onChange?.( value );
    }, [items, onChange]);
    
    const itemToId = ( item: AssignedIntent ) => item.id as string;
    const itemToString = useCallback(( item: AssignedIntent | null | undefined ) => item?.name || "", []);

    const stateReducer = useCallback(
        ( state: UseComboboxState<AssignedIntent>, actionAndChanges: UseComboboxStateChangeOptions<AssignedIntent> ) => {
            const { type, changes } = actionAndChanges;
            switch ( type ) {
                case useCombobox.stateChangeTypes.InputKeyDownEscape:
                    return { isOpen: false };
                case useCombobox.stateChangeTypes.InputBlur:
                    if ( itemToString( changes.selectedItem )) {
                        return {
                            inputValue: changes.inputValue,
                        };
                    } else if ( filteredSpecialItemsRef.current.length === 1 ) {
                        return { 
                            inputValue: changes.inputValue,
                            selectedItem: filteredSpecialItemsRef.current[0],
                        };
                    } else if ( items.length > 0 ) {
                        const item = items.find(( item: any ) => item?.name?.toLowerCase() === changes.inputValue?.toLowerCase());
                        return { 
                            inputValue: changes.inputValue, 
                            selectedItem: item,
                        };
                    } else {
                        return { inputValue: ""};
                    }
                default:
                    return changes;
            }
        },
        [itemToString, items]
    );

    const {
        inputValue,
        isOpen,
        getComboboxProps,
        getMenuProps,
        getInputProps,
        getItemProps,
        highlightedIndex,
    } = useCombobox({
        initialSelectedItem,
        items: ([] as AssignedIntent[]).concat( items, filteredSpecialItemsRef.current ),
        itemToString,
        onInputValueChange: ( changes: UseComboboxStateChange<AssignedIntent> ) => onInputValueChange( changes ),
        stateReducer
    });

    inputRef.current = inputValue;

    const onEnter = useCallback(() => {
        let returnValue: Partial<UseComboboxState<AssignedIntent>> = { inputValue: ""};
        if ( filteredSpecialItemsRef.current.length === 1 ) {
            returnValue = { 
                inputValue: inputValue,
                selectedItem: filteredSpecialItemsRef.current[0],
            };
        } else if ( items.length > 0 ) {
            const item = items.find(( item: any ) => item?.name?.toLowerCase() === inputValue?.toLowerCase());
            returnValue = { 
                inputValue: inputValue, 
                selectedItem: item,
            };
        }

        // onSelectedItemChange( returnValue );
    }, [inputValue, items]);

    return (
        <div className={cx( "autocomplete", { [`autocomplete-${className}`]: className })}>
            <div {...getComboboxProps()} className="autocomplete__input-wrapper">
                {readOnlyReason && (
                    <InfoTooltip
                        className="autocomplete__tooltip-wrapper"
                        text={readOnlyReason}
                    />
                ) || ""}
                <input
                    {...getInputProps()}
                    autoFocus={autoFocus}
                    className={cx(
                        "form-control autocomplete__input",
                        { [`autocomplete-${className}__input`]: className }
                    )}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    readOnly={Boolean( readOnlyReason )}
                    {...registerKeyPressEnter( onEnter, { preventDefault: true })}
                    disabled={disabled}
                />
            </div>
            <ul
                {...getMenuProps()}
                className={cx( "autocomplete__list", { "autocomplete__list--collapsed": !isOpen })}
            >
                {Boolean( isOpen && items?.length ) && items.map(( item, index ) => (
                    <li
                        key={`${itemToId( item )}${index}`}
                        {...getItemProps({item, index})}
                        className={cx(
                            "autocomplete__list-option",
                            { "autocomplete__list-option--highlighted": highlightedIndex === index }
                        )}
                    >
                        <strong>{itemToString( item )}</strong>
                        {item.firstSentence && (
                            <div>
                                <small>
                                    <i className="fa fa-comment-alt-lines"></i>
                                    {item.firstSentence}
                                </small>
                            </div>
                        )}
                    </li>
                )) || ""}
            </ul>
        </div>
    );
}

export default AutocompleteIntent;

import { CARDS, CardFlow, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import { addCard } from "clean-archi/core/use-cases/flowbuilder/add-card/addCardThunk";
import React, { FC } from "react";
import { useAppDispatch } from "legacy/store/typedHooks";

import "./AddSwitchCardMenu.scss";

interface props {
    nodeId: NodeFlow["id"];
    nodeType: NodeFlow["type"];
    index?: number;
    cardsTypes: CardFlow["type"][];
    isLastCardConnected?: boolean;
}

const AddSwitchCardMenu: FC<props> = ({nodeId, nodeType, index, cardsTypes, isLastCardConnected, ...props}) => {

    const dispatch = useAppDispatch();

    const actionAddCard = ( type: CARDS ) => {
        dispatch( addCard({ nodeId, type, index }));
    };

    const addCaseCard = () => {
        actionAddCard( CARDS.Case );
    };
    

    return <div className="btn-add-card">
        <button className="dropdown-toggle" onClick={addCaseCard}>
            <i className="fa fa-plus"></i>
        </button>
    </div>;
};

export default AddSwitchCardMenu;

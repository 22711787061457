import dayjs from "dayjs";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TrainingState } from "../../core/entities/training/TrainingState";
import { TRAINING_STATUS_UNTRAINED } from "../../core/entities/training/TrainingData";
import { loadMessages } from "../../core/use-cases/training/load-messages/loadMessagesThunk";
import { Message } from "../../core/entities/training/Message";
import { TrainingFilters } from "../../core/entities/training/TrainingFilters";
import { ignoreMessages } from "../../core/use-cases/training/ignore-messages/ignoreMessagesThunk";
import { trainMessage } from "../../core/use-cases/training/train-message/trainMessageThunk";
import { cancelIgnoredMessages } from "../../core/use-cases/training/cancel-ignored-messages/cancelIgnoredMessagesThunk";
import { setMessagesToPending } from "../../core/use-cases/training/set-messages-to-pending/setMessagesToPendingThunk";

const defaultFilters: Omit<TrainingFilters, "botId"> = {
    branchName: "prod",
    startDate: dayjs().subtract( 5, "day" ).toISOString(),
    endDate: dayjs().toISOString(),
    fallback: true,
    language: "",
    page: "1",
    perPage: "25",
    status: TRAINING_STATUS_UNTRAINED
};

const initialState: TrainingState = {
    checkedMessages: [],
    conversationId: undefined,
    error: undefined,
    filters: defaultFilters,
    loading: false,
    messages: [],
    nbOfPages: 0,
    shouldReload: false,
};

export const trainingSlice = createSlice({
    name: "training",
    initialState,
    reducers: {
        checkAllMessages ( state, action: PayloadAction<string[]> ) {
            state.checkedMessages = action.payload;
        },
        checkToggleMessage ( state, action: PayloadAction<string> ) {
            state.checkedMessages = state.checkedMessages.includes( action.payload )
                ? state.checkedMessages.filter(( i: string ) => i !== action.payload )
                : [ ...state.checkedMessages, action.payload ];
        },
        displayConversation ( state, action: PayloadAction<string> ) {
            state.conversationId = action.payload;
        },
        initTestingState ( state, action: PayloadAction<TrainingState> ) {
            for ( const [key, value] of Object.entries( action.payload )) {
                if ( key === "filters" ) {
                    state.filters = {
                        ...state.filters,
                        ...value as Omit<TrainingFilters, "botId">
                    };
                }
                if ( key === "checkedMessages" ) {
                    state.checkedMessages = value as string[];
                }
                if ( key === "shouldReload" ) {
                    state.shouldReload = value as boolean;
                }
                if ( key === "conversationId" ) {
                    state.conversationId = value as string;
                }
                if ( key === "messages" ) {
                    state.messages = value as Message[];
                }
                if ( key === "loading" ) {
                    state.loading = value as boolean;
                }
                if ( key === "error" ) {
                    state.error = value as {};
                }
            }
        },
        refreshMessages ( state ) {
            state.filters.endDate = dayjs().toISOString();
            state.shouldReload = true;
        },
        setFilters ( state, action: PayloadAction<Partial<Omit<TrainingFilters, "botId">>> ) {
            state.filters = {
                ...state.filters,
                ...action.payload,
            };
            state.shouldReload = true;
        },
        resetFilters ( state ) {
            const { language, ...filters } = defaultFilters;
            state.filters = {
                ...state.filters,
                ...filters,
                startDate: dayjs().subtract( 5, "day" ).toISOString(),
                endDate: dayjs().toISOString()
            };
            state.shouldReload = true;
        },
        uncheckAllMessages ( state ) {
            state.checkedMessages = [];
        },
    },
    extraReducers: ( builder ) => {
        /**
         * IGNORE MESSAGES
         */
        builder.addCase( ignoreMessages.pending, ( state ) => {
            state.loading = true;
        });
        builder.addCase( ignoreMessages.fulfilled, ( state, action: PayloadAction<Message[]> ) => {
            state.loading = false;
            state.shouldReload = true;
        });
        builder.addCase( ignoreMessages.rejected, ( state, action ) => {
            state.loading = false;
            state.error = action.payload;
        });
        /**
         * CANCEL IGNORED MESSAGES
         */
        builder.addCase( cancelIgnoredMessages.pending, ( state ) => {
            state.loading = true;
        });
        builder.addCase( cancelIgnoredMessages.fulfilled, ( state, action: PayloadAction<Message[]> ) => {
            state.loading = false;
            state.shouldReload = true;
        });
        builder.addCase( cancelIgnoredMessages.rejected, ( state, action ) => {
            state.loading = false;
            state.error = action.payload;
        });
        /**
         * LOAD MESSAGES
         */
        builder.addCase( loadMessages.pending, ( state ) => {
            state.loading = true;
        });
        builder.addCase( loadMessages.fulfilled, ( state, action: PayloadAction<{messages: Message[], nbOfPages: number}> ) => {
            state.loading = false;
            state.messages = action.payload.messages;
            state.nbOfPages = action.payload.nbOfPages;
            state.shouldReload = false;
        });
        builder.addCase( loadMessages.rejected, ( state, action ) => {
            state.loading = false;
            state.error = action.payload;
        });
        /**
         * SET MESSAGES TO PENDING
         */
        builder.addCase( setMessagesToPending.pending, ( state ) => {
            state.loading = true;
        });
        builder.addCase( setMessagesToPending.fulfilled, ( state, action: PayloadAction<Message[]> ) => {
            state.loading = false;
            state.shouldReload = true;
        });
        builder.addCase( setMessagesToPending.rejected, ( state, action ) => {
            state.loading = false;
            state.error = action.payload;
        });
        /**
         * TRAIN MESSAGE
         */
        builder.addCase( trainMessage.pending, ( state ) => {
            state.loading = true;
        });
        builder.addCase( trainMessage.fulfilled, ( state, action: PayloadAction<Message> ) => {
            state.loading = false;
            state.shouldReload = true;
        });
        builder.addCase( trainMessage.rejected, ( state, action ) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const {
    checkAllMessages,
    checkToggleMessage,
    displayConversation,
    initTestingState,
    refreshMessages,
    setFilters,
    resetFilters,
    uncheckAllMessages
} = trainingSlice.actions;

export default trainingSlice.reducer;

import React, { FC, ReactNode } from "react";
import cx from "classnames";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import FormManager from "../../forms/FormManager/FormManager";

import "./CardFunction.scss";

interface Props extends CardFlow {
    form?: any;
    index: number;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
}

const CardFunction: FC<Props> = ({form, isEditing, onClick, handle, function: func}) => {
    return (
        <div className="bot-card-function-container">
            <div className="bot-card bot-card-function">
                <div className={cx( "bot-card-type", { "bot-card-type--editing": isEditing })}>
                    <i className="card-icon fas fa-code"></i>
                    {handle}
                </div>
                <div className="content" onClick={( e: React.MouseEvent ) => onClick?.( e )}>
                    {form && !form.isProd && isEditing ? <FormManager {...form} /> : (
                        <div>
                            {func && func.name || <em>No function selected</em>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardFunction;

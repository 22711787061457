import { PayloadAction } from "@reduxjs/toolkit";
import {
    CARDS,
    AcmStaticQuickReplyValue,
    AcmStaticQuickReplyResponseItemResult,
    CardFlow,
    Connection,
    ConnectionItem,
    FlowBuilderState,
    NodeFlow
} from "../../../entities/flowbuilder";

function getConnectedItem ( nodes: Map<NodeFlow["id"], NodeFlow> | undefined, item: ConnectionItem ): CardFlow | NodeFlow | AcmStaticQuickReplyValue | undefined {
    if ( !nodes || !item ) return;

    const node = nodes.get( item.nodeId || item.id );
    if ( !node ) return;
    if ( !item.nodeId ) return node;

    const card = node.cards.get( item.cardId || item.id );
    if ( !card ) return;
    if ( !item.cardId ) return card;

    switch ( card.type ) {
        case CARDS.ContentQuickReply: {
            return ( card?.acm?.responseItem as AcmStaticQuickReplyResponseItemResult ).values.find( value => value.id === item.id );
        }
        default:
            return;
    }    
}

export function applyConnectionReducer ( state: FlowBuilderState, action: PayloadAction<Partial<Connection>> ) {
    const selectedFlowId = state.botFlow.selectedFlowId;
    if ( !selectedFlowId ) return;

    const nodes = state.botFlow.flows.get( selectedFlowId )?.nodes;
    if ( !nodes ) return;

    if ( action.payload.from?.connections ) {
        const from = getConnectedItem( nodes, action.payload.from );
        if ( from ) {
            from.connections = action.payload.from.connections;
        }
    }
    if ( action.payload.to?.connections ) {
        const to = getConnectedItem( nodes, action.payload.to );
        if ( to ) {
            to.connections = action.payload.to.connections;
        }
    }

    // Request a rerender
    state.botFlow.shouldRerenderEdges = true;
}
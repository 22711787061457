import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NodeProps, Position, useUpdateNodeInternals } from "react-flow-renderer";
import NodeHandle from "../../handles/NodeHandle/NodeHandle";
import { canAppendCardTo, getCardStyle, hasInput, hasOutput, isContentCard, isContentNode, isActionNode, isEventCard, isFunctionCard, isSwitchNode } from "legacy/context/flowBuilder/flowBuilderConfig";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { BotNodeFlow, CardFlow } from "clean-archi/core/entities/flowbuilder";
import { getBotFlowNodeById } from "clean-archi/adapters/primary/view-models-generators/bot-elements/botElementsViewModels";
import BotNodeCards, { MoveCardProps } from "./BotNodeCards";
import AddActionCardMenu from "../../menus/AddActionCardMenu/AddActionCardMenu";
import AddFunctionCardMenu from "../../menus/AddFunctionCardMenu/AddFunctionCardMenu";
import AddEventCardMenu from "../../menus/AddEventCardMenu/AddEventCardMenu";
import { useFlowBuilder } from "legacy/context/flowBuilder/FlowBuilderContext";
import { getHighlightedElement, getIsProd } from "../../../../view-models-generators/config/configParamsViewModels";
import AddSwitchCardMenu from "../../menus/AddSwitchCardMenu/AddSwitchCardMenu";
import { moveCard } from "clean-archi/core/use-cases/flowbuilder/move-card/moveCardThunk";

import "./BotNode.scss";

interface Props extends Omit<NodeProps, "data"> {
    data: BotNodeFlow,
}

const BotNode: FC<Props> = ({ data }) => {
    const {centerOnNode, contextMenu} = useFlowBuilder();
    const ref = useRef<HTMLDivElement>( null );
    const node = useAppSelector(( state ) => getBotFlowNodeById( state, data.id ));
    const isProd = useAppSelector( getIsProd );
    const updateNodeInternals = useUpdateNodeInternals();
    const dispatch = useAppDispatch();
    const [cards, setCards] = useState<CardFlow[]>([]);
    const contentCards =  useMemo(() => cards.filter( card => isContentCard( card.type )), [cards]);
    const functionCards = useMemo(() => cards.filter( card => isFunctionCard( card.type )), [cards]);
    const eventCards =  useMemo(() => cards.filter( card => isEventCard( card.type ) && !card.isDefault ).sort(( a, b ) => a.index - b.index ), [cards]);
    const defaultCards = useMemo(() => cards.filter( card => card.isDefault ), [cards]);
    const lastContentCardIsConnected = useMemo(() => contentCards[contentCards.length - 1]?.connections.output.length > 0, [contentCards]);
    const canAppendCard = useMemo(
        () => canAppendCardTo(
            node,
            lastContentCardIsConnected
        ), [lastContentCardIsConnected, node]
    );
    const cardStyle = useMemo(() => getCardStyle( node?.type ), [node]);
    const highlightedElement = useAppSelector( getHighlightedElement );
    const isHighlighted = useMemo(() => highlightedElement?.nodeId === node?.id && !highlightedElement?.cardId, [highlightedElement?.cardId, highlightedElement?.nodeId, node?.id]);

    const onNoteMouseEnter = useCallback(() => {
        if ( ref.current?.parentElement ) {
            ref.current.parentElement.style.zIndex = "1000";
        }
    }, []);

    const onNoteMouseLeave = useCallback(() => {
        if ( ref.current?.parentElement ) {
            ref.current.parentElement.style.zIndex = "0";
        }
    }, []);

    const onMoveCard = ( moveProps: MoveCardProps ) => {
        
        const fromIndex = moveProps.fromIndex;
        const toIndex = moveProps.toIndex;
        const cardId = moveProps.id;

        dispatch( moveCard({ cardId: cardId, nodeId: data.id, newIndex: toIndex }));       

    };

    useEffect(() => {
        if ( node ) {
            setCards(
                Array.from( node.cards.values()).map(( card ) => ({...card})).sort(( a, b ) => a.index - b.index )
            );
            setTimeout(() => {
                updateNodeInternals( node.id );
            });
        }
    }, [node, updateNodeInternals]);

    const cardsTypes = useMemo(
        () => cards.map( c => c.type ),
        [cards]
    );

    const center = useCallback(() => {
        if ( node?.id ) {
            centerOnNode( node.id, 200 );
        }
    }, [centerOnNode, node?.id]);

    const setNewCardIndex = ( blockType: "content" | "function" | "event" | "default" ) => {
        let previousCards: CardFlow[] | undefined;
        let currentBlock: CardFlow[];
        switch ( blockType ) {
            case "content":
                currentBlock = contentCards;
                break;
            case "function":
                previousCards = [...contentCards];
                currentBlock = functionCards;
                break;
            case "event":
                previousCards = [...contentCards, ...functionCards];
                currentBlock = eventCards;
                break;
            case "default":
                previousCards = [...contentCards, ...functionCards, ...eventCards];
                currentBlock = defaultCards;
                break;
            default:
                throw new Error( "Block type not supported yet" );
        }
        return currentBlock.length ? currentBlock[currentBlock.length - 1].index + 1 : previousCards?.length ?
            previousCards[previousCards.length - 1].index + 1 : 0;
    };

    const renderNodeCards = () => {
        if ( !node ) return;
        if ( isContentNode( node.type )) {
            return <>
                <div className={`cards-group ${contentCards.length === 0 ? "empty" : ""}`}>
                    <div className="title">Content</div>
                    <div className="content">
                        <BotNodeCards id="content-cards" node={node} cards={contentCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddActionCardMenu nodeId={node.id} nodeType={node.type} />} />
                        {canAppendCard && (
                            <div className="add-card-container">
                                {!isProd && <AddActionCardMenu nodeId={node.id} nodeType={node.type} index={setNewCardIndex( "content" )}/>}
                            </div>
                        )}
                    </div>
                </div>

                <div className={`cards-group functions ${functionCards.length === 0 ? "empty" : ""}`}>
                    <div className="title">Functions</div>
                    <div className="content">
                        <BotNodeCards id="content-cards" node={node} cards={functionCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddFunctionCardMenu nodeId={node.id} nodeType={node.type} />} />
                        <div className="add-card-container">
                            {!isProd && <AddFunctionCardMenu nodeId={node.id} nodeType={node.type} index={setNewCardIndex( "function" )}/>}
                        </div>
                    </div>
                </div>

                {!lastContentCardIsConnected && (
                    <div className={`cards-group events ${eventCards.length === 0 ? "empty" : ""}`}>
                        <div className="title">Events</div>
                        <div className="content">
                            <BotNodeCards id="event-cards" node={node} cards={eventCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddEventCardMenu nodeId={node.id} nodeType={node.type} cardsTypes={cardsTypes} />} />
                            
                            <div className="add-card-container">
                                {!isProd && <AddEventCardMenu
                                    nodeId={node.id}
                                    nodeType={node.type}
                                    cardsTypes={cardsTypes}
                                    isLastCardConnected={lastContentCardIsConnected}
                                    index={setNewCardIndex( "event" )}/>}
                            </div>
                        </div>
                    </div>
                )}
            </>;
        } else if ( isActionNode( node.type )) {
            return <>
                <div className={`${functionCards.length === 0 ? "empty" : ""}`}>
                    <BotNodeCards id="content-cards" node={node} cards={functionCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddFunctionCardMenu nodeId={node.id} nodeType={node.type} />} />
                    <div className="add-card-container">
                        {!isProd && <AddFunctionCardMenu nodeId={node.id} nodeType={node.type} index={setNewCardIndex( "function" )}/>}
                    </div>
                </div>

                <div>
                    <div className="content">
                        <BotNodeCards id="event-cards" node={node} cards={eventCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddEventCardMenu nodeId={node.id} nodeType={node.type} cardsTypes={cardsTypes} />} />
                    </div>
                </div>
            </>;
        } else if ( isSwitchNode( node.type )) {
            return <>
                <div className={`${functionCards.length === 0 ? "empty" : ""}`}>
                    <BotNodeCards id="content-cards" node={node} cards={functionCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddFunctionCardMenu nodeId={node.id} nodeType={node.type} />} />
                    {functionCards.length === 0 && (
                        <div className="add-card-container">
                            {!isProd && <AddFunctionCardMenu nodeId={node.id} nodeType={node.type} index={setNewCardIndex( "function" )}/>}
                        </div>
                    )}
                </div>

                <div className={`cards-group mt-2 events ${eventCards.length === 0 ? "empty" : ""}`}>
                    <div className="title">Cases</div>
                    <div className="content">
                        <BotNodeCards id="event-cards" node={node} cards={eventCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddEventCardMenu nodeId={node.id} nodeType={node.type} cardsTypes={cardsTypes} />} />
                        
                        <div className="add-card-container">
                            {!isProd && <AddSwitchCardMenu
                                nodeId={node.id}
                                nodeType={node.type}
                                cardsTypes={cardsTypes}
                                isLastCardConnected={lastContentCardIsConnected}
                                index={setNewCardIndex( "event" )}/>}
                        </div>
                    </div>
                </div>

                <div className={`events ${defaultCards.length === 0 ? "empty" : ""}`}>
                    <BotNodeCards id="event-cards" node={node} cards={defaultCards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<></>} />
                </div>
            </>;
        } else {
            return (
                <>
                    <BotNodeCards id="content-cards" node={node} cards={cards} nodeCardsTypes={cardsTypes} onMoveCard={onMoveCard} menu={<AddEventCardMenu nodeId={node.id} nodeType={node.type} cardsTypes={cardsTypes} isLastCardConnected={lastContentCardIsConnected} />} />
                    {canAppendCard && (
                        <div className="add-card-container">
                            { !isProd && <AddEventCardMenu nodeId={node.id} nodeType={node.type} cardsTypes={cardsTypes} isLastCardConnected={lastContentCardIsConnected} />}
                        </div>
                    )}
                </>
            );
        }
    };

    return node ? (
        <div 
            className={`bot-node bot-node-${node.type.toLowerCase()} bot-node-cards-${cardStyle.toLowerCase()} ${( contextMenu.nodeId === node.id && !contextMenu.cardId ) ? "node-context-open" : ""} ${isHighlighted ? "bot-node-highlighted" : ""}`} 
            onMouseEnter={onNoteMouseEnter}
            onMouseLeave={onNoteMouseLeave}
            ref={ref}
        >
            {hasInput( node.type ) && (
                <NodeHandle isConnectable type="target" position={Position.Left} />
            )}
            <div className="node-header">
                <span onClick={center} className="node-header-name">{node.name}</span></div>
            <div className="node-body">

                {renderNodeCards()}

            </div>
            
            {hasOutput( node.type ) && (
                <NodeHandle isConnectable={node.connections.output.length === 0} type="source" position={Position.Right} />
            )}
        </div>
    ) : <></>;
};

export default BotNode;

import React, {FC, useCallback, useEffect} from "react";
import { NodeType } from "legacy/context/flowBuilder/flowBuilderTypes";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { Flow, FunctionSuggestion, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import { getAppConfigParams, getSelectedFunction } from "../../../../view-models-generators/config/configParamsViewModels";
import { HttpFunctionStorelessGateway } from "clean-archi/adapters/secondary/gateways/flowbuilder/function/HttpFunctionStorelessGateway";
import { fetchFunctionsList } from "clean-archi/core/use-cases/flowbuilder/fetch-functions-list/fetchFunctionsListThunk";
import routes from "legacy/constants/routes";
import { useHistory } from "react-router";
import FunctionMenuItem from "./FunctionMenuItem";
import { getFunctionsList } from "../../../../view-models-generators/bot-elements/botElementsViewModels";
import { useCodeSiderbar } from "../../../code-editor/CodeSidebar";

import "./FunctionsMenu.scss";
import { resetFunctionsList, selectFunction } from "../../../../../../store/flowbuilder";

const fetchFunctionGateway = new HttpFunctionStorelessGateway();

interface FunctionsMenuProps {
    selectedItem?: string;
    onSelect: ( id: string ) => void;
    isSortable?: boolean;
    onSort?: ( source: number, destination: number ) => void;
    disabledNodes?: Array<Flow["id"] | NodeFlow["id"]>;
    disabledTypes?: NodeType[];
    isSystem?: boolean;
}

const FunctionsMenu: FC<FunctionsMenuProps> = ({selectedItem, onSelect, onSort, isSystem}) => {

    const history = useHistory();
    const {rerenderList, setRerenderList} = useCodeSiderbar();
    const selectedFunctionId = useAppSelector( getSelectedFunction );
    const dispatch = useAppDispatch();
    const items = useAppSelector( getFunctionsList );

    const appConfigParams = useAppSelector( getAppConfigParams );

    const deleteFunction = useCallback( async ( id: string ) => {
        const response = await fetchFunctionGateway.deleteFunction({
            id,
            branchName: appConfigParams.branchName,
            botId: appConfigParams.botId,
        });

        if ( id === selectedFunctionId ) {
            dispatch( selectFunction( items[0].id ));
        }

        return ( response.data || []) as FunctionSuggestion[];
    }, [appConfigParams.botId, appConfigParams.branchName, dispatch, items, selectedFunctionId]);

    const executeFunction = useCallback( async ( id: string ) => {
        const response = await fetchFunctionGateway.executeFunction({
            id,
            branchName: appConfigParams.branchName,
            botId: appConfigParams.botId,
        });
        return response.data;
    }, [appConfigParams.botId, appConfigParams.branchName]);

    useEffect(() => {
        if ( !rerenderList ) {
            if ( isSystem ) {
                history.push( routes.systemFunctionsList.buildPath());
            } else {
                history.push( routes.code.buildPath());
            }
            dispatch( resetFunctionsList());
            dispatch( selectFunction( undefined ));
            setRerenderList( true );
        }
    }, [appConfigParams.botId, appConfigParams.branchName]);

    useEffect(() => {
        if ( rerenderList ) {
            setRerenderList( false );
            dispatch( fetchFunctionsList({isSystem}));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerenderList, setRerenderList]);

    const onOpen = useCallback(( id: string ) => {
        onSelect( id );
    }, [onSelect]);

    const onExecute = useCallback(( id: string ) => {
        executeFunction( id );
    }, [executeFunction, setRerenderList]);

    const onDelete = useCallback(( id: string ) => {
        deleteFunction( id ).then(( values ) => {
            setRerenderList( true );
        });
    }, [deleteFunction, setRerenderList]);

    useEffect(() => {
        if ( items.length > 0 && items[0].id && !selectedFunctionId ) {
            
            const matches = global.window.location.pathname.match( /^\/.*\/\w+\/code\/(.*)\?*$/ );

            if ( matches?.length ) {
                const [, routeSelectedFunctionId] = matches;
                const selectedFunctionId =
                        items.filter(( codeFunction ) => codeFunction.id === routeSelectedFunctionId ).length > 0
                            ? routeSelectedFunctionId
                            : items[0].id;
                dispatch( selectFunction( selectedFunctionId ));
                return;
            } else {
                dispatch( selectFunction( items[0].id ));
            }

        }
    }, [dispatch, items, selectedFunctionId]);

    return (
        <div className="FunctionsMenu">
            <div className="Code__Sidebar_title"><i className="fal fa-chevron-down"></i> Functions</div>
            <div className="FunctionsMenu_list">
                {items.map(( codeFunction: FunctionSuggestion, index: number ) => (
                    <FunctionMenuItem
                        key={`fonction-${ codeFunction.id}`}
                        codeFunction={codeFunction}
                        isActive={codeFunction.id === selectedFunctionId}
                        isCron={codeFunction.isCron}
                        isSystem={isSystem}
                        onOpen={onOpen}
                        onDelete={onDelete}
                        onExecute={onExecute}
                    />
                ))}
            </div>

        </div>
    );
};

export default FunctionsMenu;

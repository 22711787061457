import { CardFlowApiReturnType, FlowApiReturnType, NodeFlowApiReturnType } from "./gateways-entities";
import { CardFlow, CARDS, Flow, NodeFlow, NODES, RemovedItem } from "clean-archi/core/entities/flowbuilder";
import { isEmpty } from "lodash";

export function transformToNodeFlow ( data: NodeFlowApiReturnType ): Partial<NodeFlow> {
    const cards = new Map<CardFlow["id"], CardFlow>();
    for ( const card of data.cards ) {
        cards.set( card.id, card.type === CARDS.Tag ? transformToSystemFnCard( card ) : card );
    }
    return {
        id: data.id,
        name: data.name,
        flowId: data.flowId,
        position: data.position,
        type: data.type as NODES,
        connections: data.connections,
        cards: cards
    };
}

export function transformToFlow ( flow: FlowApiReturnType ): Partial<Flow> {
    const nodes = new Map<NodeFlow["id"], NodeFlow>();
    for ( const node of flow.nodes ) {
        const cards = new Map<CardFlow["id"], CardFlow>();
        if ( node.cards ) {
            for ( const card of node.cards ) {
                cards.set( card.id, card.type === CARDS.Tag ? transformToSystemFnCard( card ) : card );
            }
        }
        nodes.set( node.id, {
            id: node.id,
            name: node.name,
            flowId: flow.id,
            position: node.position,
            type: node.type as NODES,
            connections: node.connections,
            cards: cards
        });
    }

    return {
        id: flow.id,
        name: flow.name,
        nodes
    };
}

export function transformToSystemFnCard ( card: CardFlowApiReturnType ): CardFlow {
    switch ( card.type ) {
        case CARDS.Tag:
            return {
                ...extractCardFlowCoreData( card ),
                tag: {
                    name: card.systemFnMetadata?.tagName || "",
                    functionId: card.function?.id
                }
            };
        default:
            throw new Error( `Card type ${card.type} is not supported yet` );
    }
}

const extractCardFlowCoreData = ( card: CardFlowApiReturnType ): CardFlow => {
    return {
        id: card.id,
        index: card.index,
        type: card.type,
        connections: card.connections,
    };
};

export const transformDataToCardFlow = (
    data: Partial<CardFlowApiReturnType | CardFlowApiReturnType[]> | undefined
): CardFlow | CardFlow[] | undefined => {
    if ( !data || isEmpty( data )) return undefined;
    return Array.isArray( data )
        ? ( data.map(( c ) =>
            c?.type === CARDS.Tag ? transformToSystemFnCard( c ) : c
        ) as CardFlow[])
        : ( data as CardFlow );
};

export const transformDataToRemovedItem = (
    data: Partial<RemovedItem> | undefined
): RemovedItem | undefined => {
    if ( !data || isEmpty( data )) return undefined;
    return {
        ...data,
        cards: transformDataToCardFlow(
      data.cards as CardFlowApiReturnType[]
        ) as CardFlow[] || [],
    } as RemovedItem;
};

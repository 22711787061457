import { createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { AcmTextType, AcmStaticQuickReplyType, CardFlow, CARDS, ApiError } from "../../../entities/flowbuilder";
import { ResponseApi } from "../fetch-bot-flow";
import { selectNodeFromFlow } from "../utils";

export interface InputAddCardThunk {
    nodeId: string;
    index?: number;
    type: CARDS;
    isEditing?: boolean;
}

const RESPONSE_ITEM_ACM_TEXT_EMPTY = {
    _type: "text" as AcmTextType,
    text: {
        en: [],
        fr: [],
        es: [],
    },
};

const RESPONSE_ITEM_ACM_QUICK_REPLY_EMPTY = {
    _type: "static_quick_reply" as AcmStaticQuickReplyType,
    values: [{
        index: 0,
        text: {
            en: [],
            fr: [],
            es: [],
        }
    }]
};

export const addCard = createAsyncThunk<Partial<CardFlow | CardFlow[]>, InputAddCardThunk,
    { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError,
        fulfilledMeta: {nodeId: string, flowId: string, cardId: string } } >(
            "flowbuilder/addCard",
            async ( input: InputAddCardThunk, {getState, extra, rejectWithValue, fulfillWithValue}) => {
                let response: ResponseApi<CardFlow | CardFlow[]> | undefined = undefined;
                const { botId, branchName, instanceId: botInstanceId } = getState().app.selectedConfiguration;
                const flowId = getState().flowbuilder.botFlow.selectedFlowId as string;
                const targetedNode = selectNodeFromFlow({id: input.nodeId, flowId, state: getState().flowbuilder });
                const cardId = `card_${nanoid()}`;
                switch ( input.type ) {
                    case CARDS.Default:
                    case CARDS.Payload:
                    case CARDS.Intent:
                    case CARDS.Function:
                    case CARDS.Case:
                    case CARDS.Tag:
                        response = await extra.cardGateway?.addCard({
                            ...input,
                            index: input.index ?? targetedNode?.cards.size as number,
                            id: cardId,
                        }, branchName );
                        break;
                    case CARDS.ContentText:
                        response = await extra.cardGateway?.addCardWithAcm({
                            ...input,
                            index: input.index ?? targetedNode?.cards.size as number,
                            id: cardId,
                            acmData: {
                                botId,
                                branchName,
                                botInstanceId,
                                context: `acm_context_${nanoid()}`,
                                responseItem: RESPONSE_ITEM_ACM_TEXT_EMPTY
                            }
                        }, branchName );
                        break;
                    case CARDS.ContentQuickReply:
                        response = await extra.cardGateway?.addCardWithAcm({
                            ...input,
                            index: input.index ?? targetedNode?.cards.size as number,
                            id: cardId,
                            acmData: {
                                botId,
                                branchName,
                                botInstanceId,
                                context: `acm_context_${nanoid()}`,
                                responseItem: RESPONSE_ITEM_ACM_QUICK_REPLY_EMPTY
                            }
                        }, branchName );
                        break;
                    default:
                        throw new Error( "Card type not supported yet" );
                }

                if ( !response?.data || response?.error ) {
                    return rejectWithValue({
                        message: response?.message as string,
                        error: response?.error as string,
                    });
                }

                return fulfillWithValue( response?.data, { nodeId: input.nodeId, flowId, cardId });
            }
        );




import React, { FC, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { CARDS, Form, FORMS_SPECS, NODES } from "clean-archi/core/entities/flowbuilder";
import FormManager from "../forms/FormManager/FormManager";
import { getBotFlowNodeById } from "../../../view-models-generators/bot-elements/botElementsViewModels";
import { getAppConfigParams, getIsProd, getSelectedItemForm } from "../../../view-models-generators/config/configParamsViewModels";
import { removeCard } from "clean-archi/core/use-cases/flowbuilder/remove-card/removeCardThunk";
import { hideFlowBuilderEditorAction, setRightContainerWidthAction } from "legacy/store/actions/rightContainerActions";
import { resetItemForm } from "../../../../../store/flowbuilder";

const FlowBuilderRightSidebarContainer: FC = () => {
    const { cardId, nodeId, spec, type }: Form = useAppSelector( getSelectedItemForm );
    const node = useAppSelector(( state ) => nodeId ? getBotFlowNodeById( state, nodeId ) : undefined );
    const { botId, branchName, botInstanceId } = useAppSelector( getAppConfigParams );
    const isProd = useAppSelector( getIsProd );
    const dispatch = useAppDispatch();

    const cardType = useMemo(
        () => {
            if ( !node || !cardId ) return undefined;
            return node.cards.get( cardId )?.type;
        },
        [cardId, node]
    );

    const canCardBeRemoved = useMemo(
        () => !!cardType && [CARDS.Default, CARDS.Function, CARDS.Intent, CARDS.ContentQuickReply].includes( cardType ),
        [cardType],
    );

    const closeSidebar = useCallback(
        () => {
            dispatch( hideFlowBuilderEditorAction());
            dispatch( setRightContainerWidthAction( 450 ));
        },
        [dispatch]
    );

    const onCardRemove = useCallback(
        () => {
            if ( window.confirm( "The card and will be deleted. Confirm?" )) {
                dispatch( removeCard({
                    id: cardId as string,
                    nodeId: nodeId as string,
                    botId,
                    botInstanceId,
                    branchName
                }));
                dispatch( resetItemForm());
                closeSidebar();
            }
        },
        [dispatch, cardId, nodeId, botId, botInstanceId, branchName, closeSidebar]
    );

    const nodeIcon = useCallback(() => {
        switch ( node?.type ) {
            case NODES.Action: return "fas fa-code";
            case NODES.Trigger: return "fas fa-play";
            default: return "fas fa-align-left";
        }
    }, [node?.type]);

    const nodeReference = useMemo(
        () => `${node?.name}__${node?.id}`,
        [node]
    );

    const itemReference = useMemo(
        () => {
            if ( !node || !cardId ) return undefined;
            const card = node?.cards.get( cardId as string );
            if ( !card ) return undefined;
            switch ( card.type ) {
                case CARDS.ContentText:
                case CARDS.ContentQuickReply:
                    return card.acm?.id;
                default:
                    return undefined;
            }
        },
        [cardId, node]
    );

    return (
        <div className={`RightSidebarContainer node-type-${node?.type.toLowerCase()}`}>
            <div className="RightSidebarContainer__Header">
                <div>
                    <i className={nodeIcon()}></i>
                    &nbsp;&nbsp;
                    <span>{node?.name}</span>      
                </div>
                <div>
                    <a role="button" onClick={closeSidebar}><i className="far fa-times"></i></a>
                </div>              
            </div>
            <div className="RightSidebarContainer__Body p-3 w-100 h-100">
                {type && cardId && nodeId && spec === FORMS_SPECS.Panel && (
                    <FormManager
                        id={cardId}
                        onRemove={onCardRemove}
                        nodeId={nodeId}
                        type={type}
                        isProd={isProd}
                    />
                )}

                {canCardBeRemoved && (
                    <div className="text-center m-4">
                        <a className="text-danger" role="button" onClick={onCardRemove}><i className="far fa-trash-alt"></i> Delete the card</a>
                    </div>
                )} 
            </div>
        </div>
    );
};

export default FlowBuilderRightSidebarContainer;

import React from "react";
import propTypes from "legacy/constants/propTypes";
import ContextMenu from "legacy/components/ContextMenu/ContextMenu";

export const ContextMenuContext = React.createContext({
    displayContextMenu: () => {},
    closeContextMenu: () => {},
    isOpen: false,
});


export const withContextMenu = ( displayProps ) => Component => props => (
    <ContextMenuContext.Consumer>
        {store =>
            <Component
                {...props}
                {...store}
                displayContextMenu={props => store.displayContextMenu({...displayProps, ...props}) }
            />
        }
    </ContextMenuContext.Consumer>
);



class ContextMenuProvider extends React.Component {
    state = {
        open: false,
        content: null,
    };

    displayContextMenu = ( props ) => {
        this.setState({
            ...props,
            open: true
        });
    };

    closeContextMenu = () => {
        this.setState({open: false});
    };

    render () {
        const {content, open, ...restState} = this.state;
        const context = {
            displayContextMenu: this.displayContextMenu,
            closeContextMenu: this.closeContextMenu,
            isOpen: open
        };
        return (
            <ContextMenuContext.Provider value={context}>
                {this.props.children}
                <ContextMenu
                    {...restState}
                    open={open}
                    onClose={this.closeContextMenu}
                >
                    {
                        content &&
                        <content.type {...content.props} closeContextMenu={this.closeContextMenu}/>
                    }
                </ContextMenu>
            </ContextMenuContext.Provider>
        );
    }
}
ContextMenuProvider.propTypes = {
    children: propTypes.reactElement
};

export default ContextMenuProvider;

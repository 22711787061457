import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import { get } from "lodash";
import Loader from "../../../../../../legacy/components/Loader/Loader";
import { useFlowBuilder } from "legacy/context/flowBuilder/FlowBuilderContext";
import ReactFlow, { applyEdgeChanges, applyNodeChanges, Background, BackgroundVariant, Connection, Controls, Edge, Node, OnConnectEnd, OnConnectStart, OnConnectStartParams } from "react-flow-renderer";
import { NODES, CARDS, NodeFlow, Position, EDGES, CardFlow, AcmStaticQuickReplyResponseItemResult } from "../../../../../core/entities/flowbuilder";
import BotNode from "../nodes/BotNode/BotNode";
import FlowContextMenu from "../menus/FlowContextMenu/FlowContextMenu";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { FlowNode } from "legacy/constants/propTypes/flowBuilderPropTypes";
import { updateNode } from "clean-archi/core/use-cases/flowbuilder/update-node/updateNodeThunk";
import { selectNode, resetItemForm, disableNodesRerender, disableEdgesRerender, selectFlow } from "../../../../../store/flowbuilder";
import { getSelectedFlow, getShouldRerenderEdges, getShouldRerenderNodes, getAppConfigParams, getSelectedNode, getIsProd } from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";
import { getBotFlowNodes } from "clean-archi/adapters/primary/view-models-generators/bot-elements/botElementsViewModels";
import { hideFlowBuilderEditorAction } from "legacy/store/actions/rightContainerActions";
import { buildConnectionPayload } from "clean-archi/core/use-cases/flowbuilder/connect/buildConnectionPayload";
import { addConnection } from "clean-archi/core/use-cases/flowbuilder/add-connection/addConnectionThunk";
import store from "legacy/store";
import { buildSpecialConnectionId } from "clean-archi/core/use-cases/flowbuilder/connect/buildConnectionPayload";
import { removeConnection } from "clean-archi/core/use-cases/flowbuilder/remove-connection/removeConnectionThunk";
import ActionEdge from "../edges/ActionEdge/ActionEdge";

const getFlowNodes = ( nodes: NodeFlow[]): Node[] => {
    const elements: Node[] = [];
    nodes?.forEach(( node: NodeFlow ) => {
        elements.push({
            id: node.id,
            type: node.type, 
            data: {
                ...node,
            },
            dragHandle: ".node-header",
            position: node.position,
            selectable: false,
        });
    });

    return elements;
};

const getFlowEdges = ( nodes: NodeFlow[]): Edge[] => {

    const edges: Edge[] = [];

    const addEdge = ( source: string, target: string, sourceHandle?: string, targetHandle?: string ) => {
        edges.push({
            id: `e${source + sourceHandle}-${target + targetHandle}`,
            source,
            sourceHandle,
            target,
            targetHandle,
            type: EDGES.Action,
            data: {
                source,
                target,
                sourceHandle,
                targetHandle,
            }
        });
    };

    nodes?.forEach(( node ) => {
        if ( node.connections ) {
            node.connections?.output?.forEach(( connection ) => {
                if ( connection.nodeId ) {
                    addEdge( node.id, connection.nodeId, undefined, connection.id );
                } else {
                    addEdge( node.id, connection.id );
                }
            });
        }

        node.cards.forEach(( card ) => {
            if ( card.connections ) {
                card.connections.output.forEach(( connection ) => {
                    if ( connection.nodeId ) {
                        addEdge( node.id, connection.nodeId, card.id, connection.id );
                    } else {
                        addEdge( node.id, connection.id, card.id );
                    }
                });
            }
            if ( card.type === CARDS.ContentQuickReply ) {
                ( card?.acm?.responseItem as AcmStaticQuickReplyResponseItemResult ).values.forEach( value => {
                    const itemId = buildSpecialConnectionId({ id: value.id, cardId: card.id });
                    if ( value.connections ) {
                        value.connections.output.forEach(( connection ) => {
                            if ( connection.nodeId ) {
                                addEdge( node.id, connection.nodeId, itemId, connection.id );
                            } else {
                                addEdge( node.id, connection.id, itemId );
                            }
                        });
                    }
                });
            }
        });
    });

    return edges;
};

const FlowBuilderFlow = () => {
    const {centerOnNode} = useFlowBuilder();

    const nodeTypes = useMemo(() => ({
        [NODES.BotResponse]: BotNode,
        [NODES.Action]: BotNode,
        [NODES.Trigger]: BotNode,
        [NODES.FlowExit]: BotNode,
        [NODES.FlowEntry]: BotNode,
        [NODES.Condition]: BotNode,
        [NODES.Switch]: BotNode,
    }), []);

    const edgeTypes = useMemo(() => ({
        [EDGES.Action]: ActionEdge,
    }), []);

    const dispatch = useAppDispatch();
    const selectedFlow = useAppSelector( getSelectedFlow );
    const botNodes = useAppSelector( getBotFlowNodes );
    const shouldRerenderNodes = useAppSelector( getShouldRerenderNodes );
    const shouldRerenderEdges = useAppSelector( getShouldRerenderEdges );
    const selectedNodeId = useAppSelector( getSelectedNode );

    const [flowChanged, setFlowChanged] = useState( false );
    const [nodes, setNodes] = useState<Node[] | undefined>( undefined );
    const [edges, setEdges] = useState<Edge[] | undefined>( undefined );
    const [connectParams, setConnectParams] = useState<OnConnectStartParams | undefined>( undefined );
    const flowBuilderRef = useRef<HTMLDivElement>( null );
    const {openContextMenu, closeContextMenu} = useFlowBuilder();
    const { botId, botInstanceId, branchName, language } = useAppSelector( getAppConfigParams );
    const params = useParams();
    const flowId = get( params, "id" );
    const isLoading = false;
    const isProd = useAppSelector( getIsProd );

    useEffect(() => () => {
        dispatch( resetItemForm());
        dispatch( hideFlowBuilderEditorAction());
    }, [dispatch]);

    const resetCardItemForm = useCallback(
        () => {
            dispatch( resetItemForm());
            dispatch( hideFlowBuilderEditorAction());
        },
        [dispatch]
    );

    useEffect(() => {
        if ( !selectedNodeId && botNodes && botNodes?.length > 0 ) {
            dispatch( selectNode( botNodes[0].id ));
        }
    }, [selectedNodeId, botNodes?.length, botNodes, dispatch]);       

    useEffect(() => {
        setFlowChanged(() => true );
        dispatch( selectFlow({flowId: flowId}));
        resetCardItemForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowId, dispatch, botInstanceId, branchName, language, resetCardItemForm]);

    const openContextMenuRelativeToContainer = useCallback(
        ( options: {position: Position, nodeId?: FlowNode["id"], cardId?: CardFlow["id"], connection?: OnConnectStartParams}) => {
            if ( flowBuilderRef.current ) {
                const containerDomRect = flowBuilderRef.current.getBoundingClientRect();
                openContextMenu(
                    {
                        x: options.position.x - containerDomRect.x, 
                        y: options.position.y - containerDomRect.y
                    }, 
                    options.nodeId, 
                    options.cardId,
                    options.connection,
                );
            }
        },
        [openContextMenu]
    );

    useEffect(() => {
        if ( botNodes ) {
            if ( shouldRerenderNodes ) {
                setNodes(() => getFlowNodes( botNodes ));
                dispatch( disableNodesRerender());
            }
            if ( shouldRerenderEdges ) {
                setEdges(() => getFlowEdges( botNodes ));
                dispatch( disableEdgesRerender());
            }

            if ( flowChanged && selectedNodeId ) {
                centerOnNode( selectedNodeId );
                setFlowChanged(() => false );
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlow, selectedNodeId, botNodes, shouldRerenderNodes, shouldRerenderEdges, dispatch, botInstanceId, branchName, language]);

    const onWrapperClick = () => {
        resetCardItemForm();
    };

    const onConnectStart: OnConnectStart = useCallback(( e, params ) => {
        setConnectParams({...connectParams, ...params});
    }, [connectParams]);

    const onConnectStop: OnConnectEnd = useCallback(( e ) => {
        const targetClass = ( e.target as Element ).className;
        if ( targetClass.includes( "react-flow__pane" )) {
            openContextMenuRelativeToContainer({position: {x: e.pageX, y: e.pageY}, connection: connectParams});
        }
    }, [connectParams, openContextMenuRelativeToContainer]);

    const onContextMenu = useCallback(( e: React.MouseEvent ) => {
        e.preventDefault();
        openContextMenuRelativeToContainer({position: {x: e.pageX, y: e.pageY}});
        return false;
    }, [openContextMenuRelativeToContainer]);

    const onNodeContextMenu = useCallback(( e: React.MouseEvent, node: Node ) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch( selectNode( node.data.id ));
        openContextMenuRelativeToContainer({position: {x: e.pageX, y: e.pageY}, nodeId: node.data.id});
        return false;
    }, [dispatch, openContextMenuRelativeToContainer]);

    const onEdgeContextMenu = useCallback(( e: React.MouseEvent ) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);

    const onPaneClick = useCallback(() => {
        closeContextMenu();
    }, [closeContextMenu]);

    const onMoveStart = useCallback(() => {
        closeContextMenu();
    }, [closeContextMenu]);

    const onNodeClick = useCallback(( _e: React.MouseEvent, node: Node ) => dispatch( selectNode( node.data.id )) , [dispatch]);

    const onConnect = useCallback(( connection: Connection ) => {
        const connectionPayload = buildConnectionPayload( connection, store );
        dispatch( addConnection({
            from: connectionPayload.from,
            to: connectionPayload.to,
            botInstanceId
        }));
    }, [botInstanceId, dispatch]);

    const onDelete = useCallback(( idEdge, edges: Edge<any>[] | undefined ) => {
        const data = edges?.find(( e ) => e.id === idEdge );
        
        if ( data ) {
            const connectionPayload = buildConnectionPayload({
                source: data.source as string,
                target: data.target as string,
                sourceHandle: data.sourceHandle as string,
                targetHandle: data.targetHandle as string,
            }, store );        
            dispatch( removeConnection({
                from: connectionPayload.from,
                to: connectionPayload.to,
                botId,
                branchName,
                botInstanceId
            }));
        }  
    }, [dispatch, botId, branchName, botInstanceId, edges]);

    const onNodesChange = useCallback(( changes ) => setNodes(( ns ) => applyNodeChanges( changes, ns as Node<any>[])), []);
    const onEdgesChange = useCallback(( changes ) => {
        setEdges(( es ) => applyEdgeChanges( changes, es as Edge<any>[]));
        if ( changes[0].type === "remove" ) onDelete( changes[0].id, edges );
    }, [setEdges, onDelete, edges]);

    const onNodeDragStop = useCallback(( _e: React.MouseEvent, node: Node ) => {
        dispatch( updateNode({ id: node.data.id, position: node.position }));
    }, [dispatch]);

    return (
        <div className="FlowBuilder__Flow" ref={flowBuilderRef} onClick={onWrapperClick}>
            <Loader loading={isLoading} className={"overlay"} />
            <div className="flow-builder-container">
                <div className="flow-builder">
                    <ReactFlow
                        nodes={ nodes }
                        edges={ edges }
                        onNodesChange={ !isProd ? onNodesChange : undefined }
                        onEdgesChange={ !isProd ? onEdgesChange : undefined }
                        nodeTypes={ nodeTypes }
                        edgeTypes={ edgeTypes }
                        defaultZoom={1}
                        onConnectStart={onConnectStart}
                        onConnectStop={onConnectStop}
                        onContextMenu={onContextMenu}
                        onNodeContextMenu={onNodeContextMenu}
                        onEdgeContextMenu={onEdgeContextMenu}
                        onPaneClick={onPaneClick}
                        onMoveStart={onMoveStart}
                        onNodeClick={onNodeClick}
                        onConnect={onConnect}
                        onNodeDragStop={onNodeDragStop}
                        fitView
                        maxZoom={1}
                    >
                        <Background variant={BackgroundVariant.Dots} gap={10} size={1} color="#DDD" />
                        <Controls />
                    </ReactFlow>
                </div>
            </div>
            <FlowContextMenu />
        </div>
    );
};

export default FlowBuilderFlow;
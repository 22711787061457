import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "../../../../../legacy/store/reducers";
import { BotFlow } from "../../../entities/flowbuilder";
import { FlowbuilderApiError } from "../fetch-bot-flow";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export interface InputCreateBotFlow {
    botId: string;
    branchName: string;
    botInstanceId: string;
}

export const initBotFlow = createAsyncThunk<Partial<BotFlow>, InputCreateBotFlow, { extra: Partial<Dependencies>, rejectValue: FlowbuilderApiError, state: MainReducerState} >(
    "flowbuilder/initBotFlow",
    async ( input: InputCreateBotFlow, { extra, getState, rejectWithValue }) => {
        const { branchName } = getState().app.selectedConfiguration;
        const response = await extra.botFlowGateway?.createBotFlow( input, branchName );
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }
        return response?.data as Partial<BotFlow>;
    }
);
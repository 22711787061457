import { PayloadAction } from "@reduxjs/toolkit";
import { CARDS, FlowBuilderState, IOFlowRef, NODES } from "../../../entities/flowbuilder";
import { selectCardFromNode, selectNodeFromFlow } from "../utils";

export function disconnectReducer ( state: FlowBuilderState, action: PayloadAction<{from: IOFlowRef, to: IOFlowRef}> ) {
    const selectedFlowId = ( state.botFlow.selectedFlowId ) as string;
    
    for ( const [key, value] of Object.entries( action.payload )) {
        const { flows } = state.botFlow;
        if ( flows && flows.size > 0 ) {
            if ( value.type in NODES ) {
                const selectedNode = selectNodeFromFlow({ id: value.id, flowId: selectedFlowId, state });
                if ( selectedNode?.connections ) {
                    if ( key === "from" )
                        selectedNode.connections.output = selectedNode.connections.output.filter(( connection ) => connection.id !== action.payload.to.id );
                    if ( key === "to" )
                        selectedNode.connections.input = selectedNode.connections.input.filter(( connection ) => connection.id !== action.payload.from.id );
                }
            } else if ( value.type in CARDS && value.nodeId ) {
                const selectedNode = selectNodeFromFlow({ id: value.nodeId, flowId: selectedFlowId, state });
                if ( selectedNode ) {
                    const selectedCard = selectCardFromNode({ id: value.id, node: selectedNode });
                    if ( selectedCard?.connections ) {
                        if ( key === "from" )
                            selectedCard.connections.output = selectedCard.connections.output.filter(( connection ) => connection.id !== action.payload.to.id );
                        if ( key === "to" )
                            selectedCard.connections.input = selectedCard.connections.input.filter(( connection ) => connection.id !== action.payload.from.id );
                    }
                }
            } else {
                throw new Error( "Couldn't connect element: Unknow Type" );
            }
        }
    }

    // Request a rerender
    state.botFlow.shouldRerenderEdges = true;
    
}
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useReactFlow, Viewport } from "react-flow-renderer";
import { useFlowBuilder } from "legacy/context/flowBuilder/FlowBuilderContext";
import { NODES, CARDS, Position, AcmStaticQuickReplyResponseItemResult, AcmStaticQuickReplyValueInput, AcmStaticQuickReplyType, CardFlow } from "clean-archi/core/entities/flowbuilder";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { getBotFlowNodes } from "clean-archi/adapters/primary/view-models-generators/bot-elements/botElementsViewModels";
import { getAppConfigParams } from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";

import "./FlowContextMenu.scss";

import { addNode } from "clean-archi/core/use-cases/flowbuilder/add-node/addNodeThunk";
import { removeNode } from "clean-archi/core/use-cases/flowbuilder/remove-node/removeNodeThunk";
import { removeFlowLinkNode } from "clean-archi/core/use-cases/flowbuilder/remove-flow-link-node/removeFlowLinkNodeThunk";
import { removeCard } from "clean-archi/core/use-cases/flowbuilder/remove-card/removeCardThunk";
import { updateCard } from "../../../../../../core/use-cases/flowbuilder/update-card/updateCardThunk";
import { toggleCardLock } from "../../../../../../core/use-cases/flowbuilder/toggle-card-lock/toggleCardLockThunk";
import { useFlowElementLink } from "../../../../../../../legacy/hooks/useFlowElementLink";

const calculateViewportPosition = ( viewport: Viewport, position: Position ): Position => {
    return {
        x: ( position.x - viewport.x ) / viewport.zoom,
        y: ( position.y - viewport.y ) / viewport.zoom,
    };
};

const FlowContextMenu: FC = ({...props}) => {

    const contextMenuRef = useRef<HTMLDivElement>( null );
    const {getViewport} = useReactFlow();
    const {contextMenu, closeContextMenu} = useFlowBuilder();
    const nodes = useAppSelector( getBotFlowNodes );
    const { botId, branchName, botInstanceId, language } = useAppSelector( getAppConfigParams );

    const node = useMemo(
        () => nodes?.find( n => n.id === contextMenu.nodeId ),
        [contextMenu.nodeId, nodes]
    );

    const nodeType = useMemo(
        () => node?.type || undefined,
        [node?.type]
    );

    const card = useMemo(
        () => {
            if ( !node || !contextMenu.cardId ) return undefined;
            return node.cards.get( contextMenu.cardId );
        },
        [contextMenu.cardId, node]
    );

    const cardType = useMemo(
        () => {
            if ( !node || !contextMenu.cardId ) return undefined;
            return node.cards.get( contextMenu.cardId )?.type;
        },
        [contextMenu.cardId, node]
    );

    const dispatch = useAppDispatch();

    const handleClickOutside = useCallback( event => {
        if ( contextMenuRef.current && !contextMenuRef.current.contains( event.target )) {
            closeContextMenu();
        }
    }, []);

    useEffect(() => {
        if ( contextMenu.open ) {
            document.addEventListener( "mousedown", handleClickOutside );
        } else {
            document.removeEventListener( "mousedown", handleClickOutside );
        }

        return () => {
            document.removeEventListener( "mousedown", handleClickOutside );
        };
    }, [contextMenu.open, handleClickOutside]);

    const actionAddNode = useCallback(( type: NODES, defaultCardType?: string ) => {
        closeContextMenu();
        if ( contextMenu.position ) {
            const viewportPosition = calculateViewportPosition( getViewport(), contextMenu.position );
            dispatch( addNode({ position: viewportPosition, type, defaultCardType }));
        }
    }, [closeContextMenu, contextMenu.position, dispatch, getViewport]);

    const addActionNode = useCallback(() => {
        actionAddNode( NODES.Action );
    }, [actionAddNode]);

    const addSwitchNode = useCallback(() => {
        actionAddNode( NODES.Switch );
    }, [actionAddNode]);

    const addBotResponseNode = useCallback(() => {
        actionAddNode( NODES.BotResponse );
        console.log( "TODO - Add a connection on node creation", contextMenu.connection );
    }, [actionAddNode, contextMenu.connection]);
    const addConditionNode = useCallback(() => {
        actionAddNode( NODES.Condition );
    }, [actionAddNode]);
    const addFlowExitNode = useCallback(() => {
        actionAddNode( NODES.FlowExit, CARDS.FlowExitpoint );
    }, [actionAddNode]);
    const addTriggerNode = useCallback(() => {
        actionAddNode( NODES.Trigger );
    }, [actionAddNode]);
    const addTriggerNodeWithPayloadCard = useCallback(() => {
        actionAddNode( NODES.Trigger, CARDS.Payload );
    }, [actionAddNode]);
    const addTriggerNodeWithIntentCard = useCallback(() => {
        actionAddNode( NODES.Trigger, CARDS.Intent );
    }, [actionAddNode]);

    const onNodeRemove = useCallback(
        () => {
            const callback = [NODES.FlowExit, NODES.FlowEntry].includes( nodeType as NODES )
                ? removeFlowLinkNode
                : removeNode;
            dispatch( callback({
                id: contextMenu.nodeId as string,
                botId,
                botInstanceId,
                branchName 
            }));
            closeContextMenu();
        },
        [nodeType, dispatch, contextMenu.nodeId, botId, botInstanceId, branchName, closeContextMenu]
    );

    const onCardRemove = useCallback(
        () => {
            dispatch( removeCard({
                id: contextMenu.cardId as string,
                nodeId: contextMenu.nodeId as string,
                botId,
                botInstanceId,
                branchName 
            }));
            closeContextMenu();
        },
        [botId, botInstanceId, branchName, closeContextMenu, contextMenu.cardId, contextMenu.nodeId, dispatch]
    );

    const onItemRemove = useCallback(
        () => {
            const card = node?.cards.get( contextMenu.cardId as string );
            const length = ( card?.acm?.responseItem as AcmStaticQuickReplyResponseItemResult ).values.length;
            if ( length > 1 ) {
                dispatch( updateCard({
                    id: contextMenu.cardId as string,
                    nodeId: contextMenu.nodeId as string,
                    index: card?.index as number,
                    type: cardType as CARDS,
                    acmData: {
                        id: card?.acm?.id as string,
                        context: card?.acm?.context as string,
                        responseItem: {
                            _id: card?.acm?.responseItem._id as string,
                            _type: card?.acm?.responseItem._type as AcmStaticQuickReplyType,
                            values: ( card?.acm?.responseItem as AcmStaticQuickReplyResponseItemResult ).values.map( v => ({
                                id: v.id,
                                index: v.index,
                                text: v.text,
                                hasChanged: v.id === contextMenu.index,
                                intentData: v.id === contextMenu.index ? 
                                    { kind: "none", value: undefined } :
                                    { kind: "id", value: v.intent?.id as string }
                            })) as AcmStaticQuickReplyValueInput[]
                        }
                    }
                }));
            } else {
                onCardRemove();
            }
            closeContextMenu();
        }, [node?.cards, contextMenu.cardId, contextMenu.nodeId, contextMenu.index, dispatch, cardType, closeContextMenu]
    );

    const canCardBeRemoved = useMemo(
        () => !!cardType && [
            CARDS.Default, 
            CARDS.Function, 
            CARDS.Intent, 
            CARDS.ContentText,
            CARDS.ContentQuickReply,
            CARDS.Tag,
            CARDS.Payload,
            CARDS.Case,
        ].includes( cardType ) && node?.type !== NODES.Trigger && !card?.isDefault,
        [card?.isDefault, cardType, node?.type],
    );

    const copyNodeCodeId = useCallback(() => {
        navigator.clipboard.writeText( `${node?.name }__${ node?.id}` );
        closeContextMenu();
    }, [closeContextMenu, node?.id, node?.name]);

    const copyCardCodeId = useCallback(() => {
        if ( card?.acm ) {
            navigator.clipboard.writeText( `${card.acm.context}` );
        } else if ( card ) {
            navigator.clipboard.writeText( `${card.id}` );
        }
        closeContextMenu();
    }, [card, closeContextMenu]);

    const onToggleCardLock = useCallback(() => {
        dispatch( toggleCardLock({
            id: contextMenu.cardId as string,
            nodeId: contextMenu.nodeId as string,
        }));
        closeContextMenu();
    }, [closeContextMenu, contextMenu.cardId, contextMenu.nodeId, dispatch]);

    const DefaultMenuOptions: Function = useCallback(() => (
        <>
            <Dropdown.Item onClick={addBotResponseNode}>Bot Response</Dropdown.Item>
            <Dropdown.Item onClick={addTriggerNodeWithPayloadCard}>Payload</Dropdown.Item>
            <Dropdown.Item onClick={addTriggerNodeWithIntentCard}>Intent</Dropdown.Item>
            <Dropdown.Item onClick={addFlowExitNode}>Flow Link</Dropdown.Item>
            <Dropdown.Item onClick={addActionNode}>Action</Dropdown.Item>
            <Dropdown.Item onClick={addSwitchNode}>Switch</Dropdown.Item>
            {/* <Dropdown.Item onClick={addConditionNode} disabled>Condition</Dropdown.Item> */}
        </>
    ), [addActionNode, addBotResponseNode, addFlowExitNode, addSwitchNode, addTriggerNodeWithIntentCard, addTriggerNodeWithPayloadCard]);

    const NodeMenuOptions: Function = useCallback(() => (
        <>
            {/* <Dropdown.Item onClick={() => false} disabled>Rename</Dropdown.Item> */}
            <Dropdown.Item onClick={onNodeRemove}><i className="far fa-trash-alt"></i> Delete</Dropdown.Item>
            <Dropdown.Item onClick={copyNodeCodeId}><i className="far fa-code"></i> Copy node ID</Dropdown.Item>
        </>
    ), [copyNodeCodeId, onNodeRemove]);

    const EntityLink = useFlowElementLink({ botId, botInstanceId, branchName, card, language });

    const CardMenuOptions: Function = useCallback(() => {
        if ( contextMenu.index !== undefined ) {
            return <>
                <Dropdown.Item onClick={onItemRemove} disabled={!canCardBeRemoved}><i className="far fa-trash-alt"></i> Delete</Dropdown.Item>
                <Dropdown.Item onClick={onCardRemove} disabled={!canCardBeRemoved}><i className="fa fa-trash"></i> Delete all</Dropdown.Item>
                <Dropdown.Item onClick={copyCardCodeId}><i className="far fa-code"></i> Copy acm context </Dropdown.Item>
                <EntityLink />
                <Dropdown.Item onClick={onToggleCardLock}>
                    <i className="far fa-lock"></i> {contextMenu.isCardLocked ? "Unlock" : "Lock"}
                </Dropdown.Item>
            </>;
        } else {
            return <>
                <Dropdown.Item onClick={onCardRemove} disabled={!canCardBeRemoved}><i className="far fa-trash-alt"></i> Delete</Dropdown.Item>
                <Dropdown.Item onClick={copyCardCodeId}><i className="far fa-code"></i> {( cardType === CARDS.ContentText ) ? "Copy acm context" : "Copy card ID"} </Dropdown.Item>
                { ( cardType === CARDS.ContentText || cardType === CARDS.Intent ) && (
                    <>
                        <EntityLink />
                        <Dropdown.Item onClick={onToggleCardLock}>
                            <i className="far fa-lock"></i> {contextMenu.isCardLocked ? "Unlock" : "Lock"}
                        </Dropdown.Item>
                    </>
                )}
            </>;
        }
    }, [
        canCardBeRemoved,
        cardType,
        contextMenu.index,
        copyCardCodeId,
        onToggleCardLock,
        onCardRemove,
        onItemRemove,
        contextMenu.isCardLocked,
        EntityLink,
    ]);

    const Menu: Function = useCallback(() => {
        let Options = null;

        if ( contextMenu.cardId ) Options = CardMenuOptions;
        else if ( contextMenu.nodeId ) Options = NodeMenuOptions;
        else Options = DefaultMenuOptions;

        return (
            <Dropdown.Menu show>
                <Options />
            </Dropdown.Menu>
        );
    }, [CardMenuOptions, DefaultMenuOptions, NodeMenuOptions, contextMenu.cardId, contextMenu.nodeId]);

    return ( contextMenu.open && contextMenu.position ) ? (
        <div ref={contextMenuRef} className="flow-context-menu" style={{ top: contextMenu.position.y, left: contextMenu.position.x }}>
            <Menu />
        </div>
    ) : <></>;
};

export default FlowContextMenu;

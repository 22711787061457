import { FlowBuilderContextProvider } from "legacy/context/flowBuilder/FlowBuilderContext";
import React from "react";
import { ReactFlowProvider } from "react-flow-renderer";
import FlowBuilder from "./FlowBuilder";

export default () => (
    <ReactFlowProvider>
        <FlowBuilderContextProvider>
            <FlowBuilder/>
        </FlowBuilderContextProvider>
    </ReactFlowProvider>
);

import React, {FC, useCallback} from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { TranslationManager } from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { Text } from "translation-manager-react";
import Button from "legacy/components/Button/Button";
import { Message } from "../../../../core/entities/training/Message";
import { TRAINING_STATUS_IGNORED, TRAINING_STATUS_PENDING, TRAINING_STATUS_TRAINED, TRAINING_STATUS_UNTRAINED } from "../../../../core/entities/training/TrainingData";
import AutocompleteIntent from "./AutocompleteIntent/AutocompleteIntent";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { showConversationAction } from "../../../../../legacy/store/actions/rightContainerActions";
import { getTraining } from "../../view-models-generators/training/trainingViewModels";
import { trainMessage } from "../../../../core/use-cases/training/train-message/trainMessageThunk";
import { checkToggleMessage, displayConversation } from "../../../../store/training";
import { ignoreMessages } from "../../../../core/use-cases/training/ignore-messages/ignoreMessagesThunk";
import { cancelIgnoredMessages } from "../../../../core/use-cases/training/cancel-ignored-messages/cancelIgnoredMessagesThunk";
import { useBasicAction } from "../../../../../legacy/hooks/useBasicAction";
import { ALL_BASIC_ACTIONS } from "../../../../../legacy/constants/hmbConstants";
import { setMessagesToPending } from "../../../../core/use-cases/training/set-messages-to-pending/setMessagesToPendingThunk";

type TrainingRowProps = {
    message: Message;
}

const TrainingRow: FC<TrainingRowProps> = ({ message }) => {
    const canUpdateTraining = useBasicAction( ALL_BASIC_ACTIONS.UPDATE_TRAINING );
    const dispatch = useAppDispatch();
    const { checkedMessages } = useAppSelector( getTraining );

    const {
        _id,
        conversationId,
        date,
        intent,
        training,
        userMessage
    } = message;

    const setFormDefaultValues = useCallback(() => {
        return {
            message: training.status === TRAINING_STATUS_TRAINED
                ? training.trainedMessage
                : userMessage,
            intent: training.status === TRAINING_STATUS_TRAINED
                ? training.assignedIntent?.name
                : undefined
        };
    }, [training.status, training.trainedMessage, training.assignedIntent?.name, userMessage]);

    const isTrainingAvailable = useCallback(() => {
        return training.status === TRAINING_STATUS_UNTRAINED || training.status === TRAINING_STATUS_PENDING; 
    }, [training.status]);

    const {
        register,
        getValues,
        control,
        formState: { errors }
    } = useForm<any>({
        defaultValues: setFormDefaultValues(),
        mode: "all"
    });

    const formIntent = useWatch({
        control,
        name: "intent",
    });

    const formMessage = useWatch({
        control,
        name: "message",
    });

    const train = useCallback(() => {
        dispatch( trainMessage({...getValues(), messageId: _id}));
    }, [_id, getValues, dispatch]);

    const openConversation = useCallback(() => {
        dispatch( displayConversation( conversationId ));
        dispatch( showConversationAction());
    }, [conversationId, dispatch]);

    const setIntentReadOnlyReason = useCallback(() => {
        if ( training.status === TRAINING_STATUS_TRAINED )
            return "The message is already trained.";
        if ( training.status === TRAINING_STATUS_IGNORED )
            return "The message has been ignored.";
        return undefined;        
    }, [training.status]);

    return (
        <tr>
            <td className="row-top-space" style={{width: "40px"}}>
                <Form.Check
                    id={"check-all"}
                    checked={checkedMessages.includes( _id )}
                    onChange={() => dispatch( checkToggleMessage( _id ))}
                    disabled={!canUpdateTraining}
                />
            </td>
            <td className="row-top-space" style={{width: "160px"}}>
                <span>
                    {new Date( date ).toLocaleString()}
                </span>
            </td>
            <td className="row-initial">
                <small className="initial-value">
                    <i className="fas fa-comment-alt-lines"></i>
                    {userMessage}
                </small>
                <FormGroup>
                    <FormControl
                        type="text"
                        placeholder="Message placeholder"
                        disabled={!isTrainingAvailable()}
                        {...register( "message", {
                            required: TranslationManager.getText(
                                textCodes.ERROR_REQUIRED_EMPTY,
                                { option: TranslationManager.textOptions.capitalizeSentence }
                            ),
                        })}
                    />
                </FormGroup>
            </td>
            <td className="row-initial">
                <small className="initial-value">
                    <i className="fas fa-arrow-alt-from-left"></i>
                    {intent?.name}
                </small>
                <FormGroup>
                    <Controller
                        control={control}
                        name="intent"
                        render={({ field: { onChange } }) => (
                            <AutocompleteIntent
                                initialSelectedItem={training.status === TRAINING_STATUS_TRAINED
                                    ? training.assignedIntent
                                    : undefined}
                                placeholder={TranslationManager.getText( textCodes.INTENT_PICK )}
                                onChange={onChange}
                                readOnlyReason={setIntentReadOnlyReason()}
                                disabled={!canUpdateTraining}
                            />
                        )}
                    />
                </FormGroup>
            </td>
            <td className="row-top-space" style={{width: "200px"}}>
                {isTrainingAvailable()
                && <Button
                    disabled={!canUpdateTraining || !formIntent?.id || !formMessage}
                    onClick={train}
                    icon simple bsStyle="success" tooltipText={<Text textCode={textCodes.VALIDATE_TRAINING}
                    />}>
                    <i className="fas fa-check-circle fa-lg"></i>
                </Button>}

                {training.status === TRAINING_STATUS_UNTRAINED
                && <Button
                    disabled={!canUpdateTraining}
                    icon simple tooltipText={<Text textCode={textCodes.IGNORE_TRAINING}/>}
                    onClick={() => dispatch( ignoreMessages( _id ))}
                >
                    <i className="fas fa-times-circle fa-lg"></i>
                </Button>}

                {training.status === TRAINING_STATUS_UNTRAINED
                && <Button
                    disabled={!canUpdateTraining}
                    icon simple tooltipText={<Text textCode={textCodes.SET_PENDING}/>}
                    onClick={() => dispatch( setMessagesToPending( _id ))}
                >
                    <i className="fas fa-user-clock fa-lg"></i>
                </Button>}

                {training.status === TRAINING_STATUS_IGNORED
                && <Button 
                    icon simple tooltipText={<Text textCode={textCodes.CANCEL_IGNORE}/>}
                    onClick={() => dispatch( cancelIgnoredMessages( _id ))}
                >
                    <i className="fas fa-ban fa-lg"></i>
                </Button>}

                <span>&nbsp;|&nbsp;</span>

                <Button icon simple bsStyle="info" tooltipText={<Text textCode={textCodes.OPEN_CONVERSATION}/>} onClick={openConversation}>
                    <i className="fas fa-comment-alt-lines fa-lg"></i>
                </Button>
            </td>
        </tr>
    );
};

export default TrainingRow;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError, RemovedItem } from "../../../entities/flowbuilder";
import { InputRemoveCard } from "clean-archi/core/interfaces/gateways/flowbuilder/CardGateway";

export const removeCard = createAsyncThunk<
    Partial<RemovedItem>,
    InputRemoveCard,
    { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError, fulfilledMeta: {nodeId: string, flowId: string, cardId: string}}
>(
    "flowbuilder/removeCard",
    async ( input: InputRemoveCard, {getState, extra, rejectWithValue, fulfillWithValue}) => {
        const { branchName } = getState().app.selectedConfiguration;
        const response = await extra.cardGateway?.removeCard( input, branchName );
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }
        return fulfillWithValue( response?.data, {
            nodeId: input.nodeId,
            flowId: getState().flowbuilder.botFlow.selectedFlowId as string,
            cardId: input.id
        });
    }
);
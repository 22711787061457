import { PayloadAction } from "@reduxjs/toolkit";
import { BotFlow, FlowBuilderState } from "clean-archi/core/entities/flowbuilder";

export const selectFlowReducer = ( state: FlowBuilderState, action: PayloadAction<{flowId: string, nodeId?: string}> ) => {
    
    if ( state.botFlow.selectedFlowId !== action.payload.flowId ) {

        const botFlowChanges: Partial<BotFlow> = {
            selectedFlowId: action.payload.flowId,
            shouldRerenderNodes: true,
            shouldRerenderEdges: true,
        };

        const nodes = state.botFlow.flows.get( action.payload.flowId )?.nodes;

        if ( nodes && nodes?.size > 0 && !action.payload.nodeId ) {
            const firstNode = nodes.entries().next().value;
            botFlowChanges.selectedNodeId = firstNode[0];
        }

        if ( action.payload.nodeId ) {
            botFlowChanges.selectedNodeId = action.payload.nodeId;
        }

        state.botFlow = {
            ...state.botFlow,
            ...botFlowChanges,
        };
    }
};

import React from "react";
import FrameInfoSkeleton from "../FrameInfoSkeleton";
import { useAppSelector } from "legacy/store/typedHooks";

export interface FrameInfoProps {
    title: string;
    number?: string;
    percentage?: string;
    positiveEvolution: boolean;
    icon: string;
    iconColor: string;
    iconBg: string;
}

function FrameInfo ( info: FrameInfoProps ) {
    const conversationCountLoading = useAppSelector(
        ( state ) => state.stats.conversationCountLoading
    );
    const conversationDurationLoading = useAppSelector(
        ( state ) => state.stats.conversationDurationLoading
    );
    const transfersNumberLoading = useAppSelector(
        ( state ) => state.stats.transfersNumberLoading
    );
    if (
        conversationCountLoading ||
        conversationDurationLoading ||
        transfersNumberLoading
    ) {
        return <FrameInfoSkeleton />;
    }

    var arrowIcon;
    var arrowClass;

    if ( info.positiveEvolution ) {
        arrowClass = "positive-evolution";
        arrowIcon = "las la-arrow-circle-up";
    } else {
        arrowClass = "negative-evolution";
        arrowIcon = "las la-arrow-circle-down";
    }
    return (
        <div className="frame-info ">
            <div className="flex d-flex align-items-center space-between mb-3">
                <span className="chart-title" >{info.title}</span>
                <div
                    style={{ backgroundColor: info.iconBg }}
                    className="frame-info-icon"
                >
                    <i style={{ color: info.iconColor }} className={info.icon}></i>
                </div>
            </div>
            <div className="flex space-between">
                <span className="card-number">{info?.number}</span>
                {info.percentage && <div className="flex align-items-center gap-2">
                    <i className={`${arrowIcon} ${arrowClass} arrow-icon`}></i>
                    <span className={`percentage ${arrowClass}`}>{info.percentage}</span>
                </div>}
            </div>
        </div>
    );
}

export default FrameInfo;

import { hasInput, hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import React, { FC, ReactNode } from "react";
import { Position } from "react-flow-renderer";
import CardHandle from "../../handles/CardHandle/CardHandle";

import "./CardDefault.scss";

interface Props extends CardFlow {
    form?: any;
    index: number;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
}

const CardDefault: FC<Props> = ({id, type, index, form, isEditing, isLastCard, onClick, handle, intent, connections}) => {

    return (
        <div className="bot-card-default-container">
            <div className="bot-card bot-card-default">
                {( hasInput( type )) && (
                    <CardHandle isConnectable={connections.input.length === 0} id={id} type="target" position={Position.Left}/>
                )}
                <div className="bot-card-type">
                    <i className="card-icon fas fa-filter"></i>
                    {handle}
                </div>
                <div className="content" onClick={( e: React.MouseEvent ) => onClick?.( e )}>
                    <div><em>Default</em></div>
                </div>
                {hasOutput( type ) && (
                    <CardHandle isConnectable={connections.output.length === 0} id={id} type="source" position={Position.Right}/>
                )}
            </div>
        </div>
    );
};

export default CardDefault;

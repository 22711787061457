import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Badge} from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import propTypes from "legacy/constants/propTypes";

class BadgeList extends Component {
    scrollbar = null;
    ref = null;


    componentWillUnmount () {
        if ( this.scrollbar )
            this.scrollbar.destroy();
        this.scrollbar = null;
    }

    setRef = ( el ) => {
        this.ref = el;
        if ( this.ref )
            this.scrollbar = new PerfectScrollbar( this.ref, {suppressScrollY: true});
    };

    render () {
        if ( this.scrollbar )
            this.scrollbar.update();

        const containerClassName = classnames(
            "badge-list",
            {
                "inline": this.props.inline
            });
        return (
            <div className={containerClassName} ref={this.setRef}>
                {this.props.list.map(( item, index ) => {
                    if ( typeof item === "string" )
                        return <Badge key={index} className="chip light">{item}</Badge>;
                    if ( typeof item === "object" && item.text )
                        return <Badge key={index} className={item.classNames || "chip light"}>{item.text}</Badge>;
                    return item;
                })}
            </div>
        );
    }
}

BadgeList.propTypes = {
    list: PropTypes.arrayOf( PropTypes.oneOfType([
        PropTypes.string,
        propTypes.reactElement,
        PropTypes.shape({
            text: PropTypes.string,
            classNames: PropTypes.string,
        })
    ])),
    inline: PropTypes.bool,
};

export default BadgeList;

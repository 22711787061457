import { Store } from "redux";
import { AcmStaticQuickReplyResponseItemResult, CardFlow, Flow, FlowBuilderState, NodeFlow } from "../../entities/flowbuilder";
import { TestRootState } from "../../../store/utils/storeForTesting";

export function selectNodeFromFlow ( options: {id: string, flowId: string, state: FlowBuilderState}): NodeFlow | undefined {
    return options.state.botFlow.flows.get( options.flowId )?.nodes.get( options.id );
}

export function selectCardFromNode ( options: {id: string, node: NodeFlow}): CardFlow | undefined {
    return options.node.cards.get( options.id );
}

/**
 * UTILS FOR TESTING
 */
export function getLastCreatedFlow ( store: Store<TestRootState> ): Flow {
    const flows = store.getState().flowbuilder.botFlow.flows;
    return Array.from( flows )[flows.size - 1][1];
}

export function getFirstCreatedFlow ( store: Store<TestRootState> ): Flow {
    const flows = store.getState().flowbuilder.botFlow.flows;
    return Array.from( flows )[0][1];
}

export function getFlowAtIndex ( index: number, store: Store<TestRootState> ): Flow {
    const flows = store.getState().flowbuilder.botFlow.flows;
    return Array.from( flows )[index][1];
}

export function getNodeAtIndexInFlow ( index: number, flowId: string, store: Store<TestRootState> ): NodeFlow | undefined {
    const nodes = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes;
    return nodes ? Array.from( nodes )[index][1] : undefined;
}

export function getLastCreatedCardInNode ( nodeId: NodeFlow["id"], flowId: NodeFlow["id"], store: Store<TestRootState> ) {
    const cards = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes.get( nodeId )?.cards;
    return cards ? Array.from( cards )[cards.size - 1][1] : undefined;
}

export function getCardAtIndexInNode ( index: number, nodeId: NodeFlow["id"], flowId: NodeFlow["id"], store: Store<TestRootState> ) {
    const cards = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes.get( nodeId )?.cards;
    return cards ? Array.from( cards.values())[index] : undefined;
}

export function getReplyAtIndexInQuickReplyCard ( index: number, cardId: CardFlow["id"], nodeId: NodeFlow["id"], flowId: NodeFlow["id"], store: Store<TestRootState> ) {
    const cards = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes.get( nodeId )?.cards;
    if ( !cards ) return;
    const card = cards.get( cardId );
    return card ? ( card.acm?.responseItem as AcmStaticQuickReplyResponseItemResult ).values[index] : undefined;
}

export function updateNodeCardsIndexes ( cards: CardFlow[], fromIndex: number, toIndex: number ) {
    const maxIndex = Math.max( fromIndex, toIndex );
    const minIndex = Math.min( fromIndex, toIndex );

    console.log( fromIndex, toIndex, maxIndex, minIndex );

    [...cards]
        .filter(( card, index ) => index >= minIndex && index <= maxIndex )
        .forEach(( card ) => {
            if ( fromIndex === card.index ) {
                card.index = toIndex;
            } else {
                if ( fromIndex < toIndex ) {
                    card.index--;
                } else {
                    card.index++;
                }
            }
        });

    return cards;
}

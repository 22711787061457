import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "../../../../../legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError } from "../../../entities/flowbuilder";
import { UpdateFlowResult } from "../../../interfaces/gateways/flowbuilder/FlowGateway";

export interface InputEditFlowThunk {
    id: string; // flow id
    name?: string;
    index?: number;
}

export const editFlow = createAsyncThunk<UpdateFlowResult, InputEditFlowThunk, {
    state: MainReducerState,
    extra: Partial<Dependencies>,
    rejectValue: ApiError}>
    (
        "flowbuilder/editFlow",
        async ( input: InputEditFlowThunk, {getState, extra, rejectWithValue}) => {
            const { branchName } = getState().app.selectedConfiguration;
            const botFlowId = getState().flowbuilder.botFlow.id as string;

            const response = await extra.flowGateway?.editFlow({
                ...input,
                botFlowId,
                branchName
            });

            if ( !response?.data || response?.error ) {
                return rejectWithValue({
                    message: response?.message as string,
                    error: response?.error as string,
                });
            }
            return response?.data as UpdateFlowResult;
        }
    );
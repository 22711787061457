import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { CardFlow, ApiError } from "../../../entities/flowbuilder";
import { ResponseApi } from "../fetch-bot-flow";

export interface InputToggleCardLockThunk {
    id: string; // card id
    nodeId: string;
}

export const toggleCardLock = createAsyncThunk<Partial<CardFlow>, InputToggleCardLockThunk,
    { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError,
        fulfilledMeta: {nodeId: string, flowId: string, cardId: string } } >(
            "flowbuilder/toggleCardLock",
            async ( input: InputToggleCardLockThunk, {getState, extra, rejectWithValue, fulfillWithValue}) => {
                const { branchName} = getState().app.selectedConfiguration;
                const { id, nodeId } = input;
                const response: ResponseApi<CardFlow> | undefined = await extra.cardGateway?.toggleCardLock({
                    id, nodeId
                }, branchName );
                const flowId = getState().flowbuilder.botFlow.selectedFlowId as string;

                if ( !response?.data || response?.error ) {
                    return rejectWithValue({
                        message: response?.message as string,
                        error: response?.error as string,
                    });
                }

                return fulfillWithValue( response?.data, { nodeId, flowId, cardId: id });
            }
        );




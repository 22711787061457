import { nanoid, PayloadAction } from "@reduxjs/toolkit";
import { CardFlow, CARDS, Flow, FlowBuilderState, IOFlowRef, NODES } from "../../../entities/flowbuilder";

export const addFlowReducer = ( state: FlowBuilderState, action: PayloadAction<Partial<Flow>> ) => {
    // if ( state.botFlow.flows.size === 0 ) {
    //     /**
    //      * Add initial nodes for 1st flow
    //      */
    //     const { triggerNodeId, payloadCardId, botResponseNodeId, contentCardId } = getInitFlowElementIds();
    //     action.payload.nodes.set( triggerNodeId, getInitFlowTriggerNode({
    //         flowId: action.payload.id,
    //         nodeId: triggerNodeId,
    //         cardId: payloadCardId,
    //         connection: {
    //             flowId: action.payload.id,
    //             id: botResponseNodeId,
    //             type: NODES.BotResponse
    //         }
    //     }));
    //     action.payload.nodes.set( botResponseNodeId, getInitFlowBotResponseNode({
    //         flowId: action.payload.id,
    //         nodeId: botResponseNodeId,
    //         cardId: contentCardId,
    //         connection: {
    //             flowId: action.payload.id,
    //             id: payloadCardId,
    //             type: CARDS.Payload,
    //             nodeId: triggerNodeId
    //         }
    //     }));
    //     state.botFlow.numberOfNodes = 2;
    // }
    const flow = action.payload as Flow;
    state.botFlow.flows.set(
        action.payload.id as string,
        {
            id: flow.id,
            index: flow.index,
            name: flow.name,
            nodes: flow.nodes
        }
    );
    state.botFlow.selectedFlowId = flow.id;
    state.botFlow.flows.set( flow.id, flow );
    state.botFlow.shouldRerenderNodes = true;
    state.botFlow.shouldRerenderEdges = true; 
    state.botFlow.loading = false;
};

export const addStressTestFlowReducer = ( state: FlowBuilderState, action: PayloadAction<Flow> ) => {

    [...Array( 50 )].forEach(( _, i ) => {
        const nodeId = nanoid();
        action.payload.nodes.set( nodeId, getInitFlowStressNode( action.payload.id, nodeId, i ));
    });
    
    state.botFlow.numberOfNodes = action.payload.nodes.size;
    state.botFlow.selectedFlowId = action.payload.id;
    state.botFlow.flows.set( action.payload.id, action.payload );
};

interface InputInitFlowNode {
    flowId: string,
    nodeId: string,
    cardId: string,
    connection: IOFlowRef
}

type InitFlowElements = {
    triggerNodeId: string;
    payloadCardId: string;
    botResponseNodeId: string;
    contentCardId: string;
}

const getInitFlowElementIds = (): InitFlowElements => {
    return {
        triggerNodeId: nanoid(),
        payloadCardId: nanoid(),
        botResponseNodeId: nanoid(),
        contentCardId: nanoid(),
    };
};

const getInitFlowTriggerNode = ( options: InputInitFlowNode ) => {
    const cards = new Map<CardFlow["id"], CardFlow>();
    cards.set( options.cardId,
        {
            id: options.cardId,
            index: 0,
            type: CARDS.Payload,
            connections: {
                input: [],
                output: [options.connection]
            }
        });
    return {
        id: options.nodeId,
        name: "Trigger_0",
        position: {x: 20, y: 60},
        type: NODES.Trigger,
        cards,
        connections: {
            input: [],
            output: []
        }
    };
};

const getInitFlowBotResponseNode = ( options: InputInitFlowNode ) => {
    const cards = new Map<CardFlow["id"], CardFlow>();
    cards.set( options.cardId,
        {
            id: options.cardId,
            index: 0,
            type: CARDS.ContentText,
            connections: {
                input: [],
                output: []
            }
        });
    return {
        id: options.nodeId,
        name: "BotResponse_1",
        position: {x: 360, y: 60},
        type: NODES.BotResponse,
        cards,
        connections: {
            input: [options.connection],
            output: []
        },
        flowId: options.flowId,
    };
};

const getInitFlowStressNode = ( flowId: string, nodeId: string, index: number ) => {

    const cards = new Map<CardFlow["id"], CardFlow>();

    [...Array( 6 )].forEach(( _, i ) => {
        const cardId = nanoid();
        cards.set( cardId,
            {
                id: cardId,
                index: i,
                type: CARDS.ContentText,
                connections: {
                    input: [],
                    output: []
                }
            });
    });
    
    return {
        id: nodeId,
        name: `BotResponse_${index}`,
        position: {x: index * 360, y: 60},
        type: NODES.BotResponse,
        cards,
        connections: {
            input: [],
            output: []
        },
        flowId: flowId,
    };
};
import { hasInput, hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import {
    AcmItem,
    AcmTextResponseItem,
    AcmTextLanguages,
    CardFlow,
    CARDS
} from "clean-archi/core/entities/flowbuilder";
import React, { FC, ReactNode, useMemo } from "react";
import { Position } from "react-flow-renderer";
import CardHandle from "../../handles/CardHandle/CardHandle";
import { useAppSelector } from "legacy/store/typedHooks";
import { getAppConfigParams } from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";
import sanitizeHtml from "sanitize-html";

import "./CardContentText.scss";
import FormManager, { FormManagerProps } from "../../forms/FormManager/FormManager";
import { useToTranslate } from "legacy/hooks/useToTranslate";

interface Props extends CardFlow {
    index: number;
    form: FormManagerProps;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
    acm: AcmItem;
    nodeCardsTypes: CardFlow["type"][];
}

const CardContentText: FC<Props> = ({
    id,
    index,
    type,
    form,
    isEditing,
    isLastCard,
    onClick,
    handle,
    acm,
    connections,
    nodeCardsTypes = [],
}) => {
    const language = useAppSelector( getAppConfigParams ).language as AcmTextLanguages;
    const ToTranslate = useToTranslate();

    const isConnectableViaOutput = useMemo(
        () => {
            const excludingTypes = [CARDS.ContentQuickReply, CARDS.Default, CARDS.Intent];
            return hasOutput( type, isLastCard ) && !nodeCardsTypes.some( c => excludingTypes.includes( c ));
        },
        [isLastCard, nodeCardsTypes, type]
    );
                
    return (
        <div
            className={`bot-card bot-card-content bot-card-${type.toLowerCase()}`}
        >
            {index > 0 && hasInput( type ) && (
                <CardHandle isConnectable id={id} type="target" position={Position.Left} />
            )}
            <div className="bot-card-type">
                <i className="card-icon fas fa-comment-alt-lines"></i>
                {handle}
            </div>
            <div className="content" onClick={( e: React.MouseEvent ) => onClick?.( e )}>
                { form && !form.isProd && isEditing
                    ? <FormManager {...form} />
                    : ( acm?.responseItem as AcmTextResponseItem ).text[language]?.[0]
                        ? <div 
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    ( acm?.responseItem as AcmTextResponseItem ).text[language]?.[0] || "",
                                    { allowedTags: sanitizeHtml.defaults.allowedTags.concat([ "img" ]) }
                                )
                            }}
                        /> 
                        : <ToTranslate/>}
            </div>
            {isConnectableViaOutput && (
                <CardHandle isConnectable={connections.output.length === 0} id={id} type="source" position={Position.Right} />
            )}
        </div>
    );
};

export default CardContentText;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError } from "../../../entities/flowbuilder";
import { Message } from "../../../entities/training/Message";
import { TrainMessageData } from "../../../entities/training/TrainMessageData";

/**
 * Action ignoreMessages can be used in 2 cases:
 * 1. To ignore a single message on a training row (messageId is provided)
 * 2. To ignore checked messages on a page (messageId is not provided, we get ids from trainingState)
 */

export const trainMessage = createAsyncThunk<
  Message,
  Omit<TrainMessageData, "botId" | "branchName" | "language" >, {
  state: MainReducerState,
  extra: Partial<Dependencies>,
  rejectValue: ApiError,
}>(
    "training/trainMessage",
    async ( trainData, { getState, extra, rejectWithValue }) => {
        try {
            const { trainingGateway } = extra;
            const { botId } = getState().app.selectedConfiguration;
            const { branchName, language } = getState().training.filters;
            const trained = await trainingGateway?.trainMessage({
                ...trainData,
                botId,
                branchName,
                language
            });
            if ( !trained ) {
                return rejectWithValue({
                    error: "training/trainMessage",
                    message: "Could not train message"
                });
            }
            return trained;
        } catch ( error: any ) {
            return rejectWithValue({
                error: "training/trainMessage",
                message: error.message
            });
        }
    }
    
);




export const reverseIntentSentences = ( intent: any ) => {
    if ( !intent ) {
        return;
    }
    return {
        ...intent,
        training: intent.training?.map(( t: any ) => ({
            ...t,
            sentences: t.sentences?.reverse()
        }))
    };
};
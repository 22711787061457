import React, {FC, useCallback, useState} from "react";
import {Dropdown} from "react-bootstrap";

interface FlowItemMenuContentProps {
    onDelete?: () => void;
    onRename?: () => void;
    onDuplicate?: () => void;
}

const FlowItemMenuContent: FC<FlowItemMenuContentProps> = ({onDelete, onDuplicate, onRename}) => {

    const [showConfirmDelete, setShowConfirmDelete] = useState( false );

    const onClickDelete = useCallback(( e: React.MouseEvent<HTMLElement> ) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete?.();
    }, [onDelete]);

    const openConfirmDelete = useCallback(( e: React.MouseEvent<HTMLElement> ) => {
        e.preventDefault();
        e.stopPropagation();

        if ( !showConfirmDelete ) {
            setShowConfirmDelete( true );
        }
    }, [showConfirmDelete]);

    const cancelDelete = useCallback(() => {
        setShowConfirmDelete( false );
    }, [setShowConfirmDelete]);

    return (
        <>
            <Dropdown.Item onClick={onRename}><i className="fas fa-pencil-alt"></i> Rename</Dropdown.Item>
            <Dropdown.Item onClick={onDuplicate} disabled><i className="fa fa-clone"></i> Duplicate</Dropdown.Item>
            <Dropdown.Item onClick={openConfirmDelete}>
                {!showConfirmDelete ? (
                    <>
                        <i className="fa fa-trash"></i> Delete
                    </>
                ) : (
                    <div className="FlowsMenu__navLink_menu_confirm">
                        <span>Confirm delete?</span>
                        <span>
                            <button className="btn-cancel" onClick={cancelDelete}><i className="fa fa-times"></i></button>
                            <button className="btn-confirm" onClick={onClickDelete}><i className="fa fa-check"></i></button>
                        </span>
                    </div>
                )}
            </Dropdown.Item>
        </>
    );
};

export default FlowItemMenuContent;

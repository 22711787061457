import React, {FC, useEffect} from "react";
import { Text }             from "translation-manager-react";
import {Form, Modal} from "react-bootstrap";
import Button from "../../../../../../../legacy/components/Button/Button";
import cx from "classnames";
import LoadingModalOverlay from "legacy/components/LoadingModalOverlay/LoadingModalOverlay";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager }         from "translation-manager";
import {get} from "lodash";
import FormInputs from "../../../../../../../legacy/components/form/FormInputs/FormInputs";
import { updateForm } from "legacy/context/form/formActions";

import "./ModalCreateFlow.scss";

import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { getBotFlowId, getFlowsList } from "clean-archi/adapters/primary/view-models-generators/bot-elements/botElementsViewModels";
import { addFlow } from "clean-archi/core/use-cases/flowbuilder/add-flow/addFlowThunk";
import { FormSchema } from "legacy/context/contextTypes";
import { emptyStringRegex } from "legacy/constants/appConstants/regex";
import useForm from "legacy/context/form/useForm";
import useKeyPress from "legacy/hooks/useKeyPress";

interface ModalCreateFlowProps {
    className?: string;
    onHide: () => void;
    onSuccess: () => void;
    isVisible: boolean;
    loading?: boolean;
}

const createFlowFormSchema: FormSchema = {
    name: {
        defaultValue: "",
        validate: [
            {
                validator: function ( value: string ) {
                    return !emptyStringRegex.test( value );
                },
                message:   TranslationManager.getText( textCodes.ERROR_CANT_BE_EMPTY ),
            },
        ],
    },
};

const ModalCreateFlow: FC<ModalCreateFlowProps> = ({
    className,
    onHide,
    onSuccess,
    isVisible,
    loading,
}) => {
    const {formState, formDispatch, submit, reset} = useForm( createFlowFormSchema );
    const value = formState.value;
    const errors = formState.errors;
    const [registerKeyPressEnter] = useKeyPress([[ "Enter" ]]);

    const dispatch = useAppDispatch();
    const botFlowId = useAppSelector( getBotFlowId ) as string;
    const flowsList = useAppSelector( getFlowsList );
    
    const handleOnclick = () => {
        if ( value.name ) {
            console.log( "Dispatching addFlow w/", {
                botFlowId,
                index: flowsList.length,
                name: value.name
            });
            dispatch( addFlow({
                botFlowId,
                index: flowsList.length,
                name: value.name
            }));
        }
        submit();
    };
    
    useEffect(() => {
        if ( formState.success ) {
            onSuccess();
            reset();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.success]);

    return (
        <Modal size={"lg"} show={isVisible} onHide={onHide} className={cx( "ModalImportData", className )}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Text textCode={textCodes.ADD_FLOW} capitalizeSentence/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingModalOverlay show={loading}/>
                <Form>
                    <fieldset>
                        <FormInputs
                            ncols={"col-12"}
                            proprieties={[
                                {
                                    label:       <Text textCode={textCodes.NAME}/>,
                                    type:        "text",
                                    tabIndex:    1,
                                    placeholder: TranslationManager.getText( textCodes.NAME, { option: TranslationManager.textOptions.lowercase }),
                                    value:       get( value, "name" ),  
                                    onChange:    e => updateForm( formDispatch, { valuePath: "name", value: e.target.value }),
                                    errors:      get( errors, "name.messages" ),
                                    ...registerKeyPressEnter( handleOnclick, { preventDefault: true }),
                                },
                            ]}
                        />
                    </fieldset>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={loading} onClick={onHide}>
                    <Text textCode={textCodes.CLOSE}/>
                </Button>
                <Button
                    disabled={loading || !value.name}
                    bsStyle={"primary"}
                    fill
                    onClick={handleOnclick}
                >
                    <Text textCode={textCodes.ADD_FLOW} capitalizeSentence/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default (
    ModalCreateFlow
);
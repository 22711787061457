import React, { useRef, useCallback } from "react";

/**
 * Permet de définir un composant dans un hook useCallback et
 * passe les props via une réference pour éviter les re-init
 * @param {function} ComponentCallback - le composant
 * @param {Object} argProps - les props données à componentCallback
 * @returns {function}
 * @example
 * useComponent((props) => {
 *              // Logique et retour d'un composant à l'intérieur de la fonction
 *     return ( <input value={props.value} /> );
 * }, props) // Passage de props au travers du second argument (les props seront référencées)
 */
export function useComponent ( ComponentCallback, argProps = {}) {
    const argPropsRef   = useRef({});
    argPropsRef.current = argProps;

    const Component     = useCallback( ComponentCallback, [ComponentCallback]);
    return useCallback(( props ) => <Component {...props} {...argPropsRef.current} />, []);
}

import React from "react";
import DefaultView from "legacy/pages/dashboard/ViewDefault";
import CardGeneric from "legacy/components/CardGeneric/CardGeneric";
import textCode from "legacy/constants/translations/textCodes";
import { Text } from "translation-manager-react";
import { compact } from "lodash";
import { ALL_BASIC_ACTIONS } from "legacy/constants/hmbConstants";
import { useBasicAction } from "legacy/hooks/useBasicAction";
import { 
    getReportRoutine,
    updateReportRoutine,
    removeReportRoutine,
    addReportRoutine,
    changeUrlFormValueAction,
    toggleShowUpdateURLReport,
} from "legacy/store/actions/dataStudioReportActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import RemoveButton from "components/Buttons/RemoveButton";
import { useMount } from "legacy/hooks/useEffect";
import { useConfigurationDidChange } from "legacy/hooks/useConfiguration";
import { TranslationManager } from "translation-manager";
import FormInputs from "legacy/components/form/FormInputs/FormInputs";
import Button from "legacy/components/Button/Button";
import UpdateButton from "legacy/components/Buttons/UpdateButton";

/** UrlFormInput */
const UrlFormInput = ({ urlFormValue, changeUrlFormValue, canEditReport }) => {
    return (
        canEditReport && <FormInputs
            ncols={"col-sm-12 col-xs-12"}
            proprieties={[
                {
                    label:       <Text textCode={textCode.ENTER_REPORT_URL}/>,
                    type:        "text",
                    value:       urlFormValue,
                    onChange:    ( e ) => changeUrlFormValue({ urlFormValue: e.target.value }),
                    placeholder: TranslationManager.getText( textCode.URL ),
                },
            ]}
        ></FormInputs>
    );
};

/**
 * Data Studio Card 
 * Defined in same file because more readable
 * @param {object}   params
 * @param {string}   params.botInstanceId
 * @param {string[]} params.urls
 */
const DataStudioCard = ({
    // state
    botInstanceId,
    reports = [],
    fetching,
    originalUrlFormValue,
    urlFormValue,
    showUpdateURLReport,

    // actions
    getReport,
    addReport,
    updateReport,
    deleteReport,
    changeUrlFormValue,
    toggleShowUpdateURLReport
}) => {
    const canSeeReport  = useBasicAction( ALL_BASIC_ACTIONS.SEE_STATISTICS );
    const canEditReport = useBasicAction( ALL_BASIC_ACTIONS.MANAGE_STATISTICS );

    useMount(() => {
        getReport({ botInstanceId });
    });

    useConfigurationDidChange(( newSelectedBot, prevSelectedBot ) => {
        getReport({ botInstaceId: newSelectedBot.instanceId, });
    });

    // const deleteCurrentReport = () => {
    //     if ( reports.length !== 0 ) {
    //         deleteReport({ idList: [reports[0]._id] });
    //     }
    // };

    const updateCurrentReport = () => {
        if ( reports.length === 0 ) {
            addReport();
        } else {
            updateReport();
        }
        toggleShowUpdateURLReport();
    };

    const canceledUpdateURLReport = () => {
        toggleShowUpdateURLReport();
        if ( urlFormValue !== originalUrlFormValue ) {
            changeUrlFormValue({ urlFormValue: originalUrlFormValue});
        }
    };

    return (
        <CardGeneric
            Title={( reports.length === 0 || showUpdateURLReport ) ? ( <Text textCode={textCode.BOT_STATISTICS_REPORT} /> ) : ( <></> )}

            Buttons={
                compact([
                    canEditReport && ( reports.length !== 0 && !showUpdateURLReport ) && (
                        <UpdateButton
                            key={"showUpdateURLReport"}
                            onClick={() => toggleShowUpdateURLReport()}
                        />
                    ),
                    canEditReport && ( reports.length === 0 || showUpdateURLReport ) && (
                        <Button
                            key={"addReport"}
                            className="headerButton"
                            bsStyle="primary" 
                            fill 
                            bsSize="medium"
                            onClick={updateCurrentReport}
                            disabled={fetching}
                        >
                            <Text textCode={reports.length !== 0 ? textCode.UPDATE : textCode.SAVE}/>
                        </Button>
                    ),
                    ( reports.length !== 0 && showUpdateURLReport ) && (
                        <Button
                            key={"canceledUpdateURLReport"}
                            bsStyle="danger"
                            fill
                            onClick={canceledUpdateURLReport}
                        >
                            <Text textCode={textCode.CANCEL}/>
                        </Button>
                    ),
                    // canEditReport && ( reports.length === 0 || showUpdateURLReport === true ) && (
                    //     <RemoveButton
                    //         onClick={deleteCurrentReport}
                    //         disabled={reports.length === 0}
                    //         fill pullRight preventSwal
                    //     />
                    // )
                ])
            }

            Content={
                fetching
                    ? <Text textCode={textCode.FETCHING} capitalizeSentence />
                    : !canSeeReport
                        ? <Text textCode={textCode.NO_AUTH_SEE_REPORT} capitalizeSentence /> 
                        : ( reports.length !== 0 && !showUpdateURLReport ) 
                            ? <>
                                <iframe
                                    title="Google Data Studio Report"
                                    src={reports[0].url}
                                    frameBorder="0"
                                    style={{ 
                                        border: 0,
                                        minHeight: "80vh",
                                        width: "100%"
                                    }}
                                    allowFullScreen
                                ></iframe>
                            </>
                            : <>
                                <UrlFormInput
                                    urlFormValue={urlFormValue}
                                    changeUrlFormValue={changeUrlFormValue}
                                    canEditReport={canEditReport}
                                ></UrlFormInput>
                                <Text textCode={reports.length === 0 ? textCode.NO_REPORT : textCode.ADD_OR_UPDATE_URL} capitalizeSentence />
                            </>
            }
        />
    );
};

const mapStateToProps_DataStudioCard = ( state ) => ({
    botInstanceId: state.app.selectedConfiguration.instanceId,
    reports: state.dataStudio.reports,
    fetching: state.dataStudio.fetching,
    originalUrlFormValue: state.dataStudio.originalUrlFormValue,
    urlFormValue: state.dataStudio.urlFormValue,
    showUpdateURLReport: state.dataStudio.showUpdateURLReport,
});
const mapDispatchToProps_DataStudioCard = dispatch => ({
    getReport: bindActionCreators( getReportRoutine, dispatch ),
    deleteReport: bindActionCreators( removeReportRoutine, dispatch ),
    updateReport: bindActionCreators( updateReportRoutine, dispatch ),
    addReport: bindActionCreators( addReportRoutine, dispatch ),

    changeUrlFormValue: bindActionCreators( changeUrlFormValueAction, dispatch ),
    toggleShowUpdateURLReport: bindActionCreators( toggleShowUpdateURLReport, dispatch ),
});
const ConnectedDataStudioCard = connect( mapStateToProps_DataStudioCard, mapDispatchToProps_DataStudioCard )( DataStudioCard );

export default () => (
    <DefaultView>
        <ConnectedDataStudioCard></ConnectedDataStudioCard>
    </DefaultView>
);
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError } from "../../../entities/flowbuilder";
import { Message } from "../../../entities/training/Message";
import { checkToggleMessage, uncheckAllMessages } from "../../../../store/training";

/**
 * Action setMessagesToPending can be used in 2 cases:
 * 1. To set a single message to pending on a training row (messageId is provided)
 * 2. To set checked messages to pending on a page (messageId is not provided, we get ids from trainingState)
 */

export const setMessagesToPending = createAsyncThunk<
  Message[],
  string | void, {
  state: MainReducerState,
  extra: Partial<Dependencies>,
  rejectValue: ApiError,
}>(
    "training/setMessagesToPending",
    async ( messageId, { dispatch, getState, extra, rejectWithValue }) => {
        try {
            const { trainingGateway } = extra;
            const { checkedMessages } = getState().training;
            const messageIds = messageId ? [messageId] : checkedMessages;
            const pending = await trainingGateway?.setMessagesToPending( messageIds );
            if ( !messageId )
                dispatch( uncheckAllMessages());
            else if ( messageId && checkedMessages.includes( messageId ))
                dispatch( checkToggleMessage( messageId ));
            if ( !pending ) {
                return rejectWithValue({
                    error: "training/setMessagesToPending",
                    message: "Could not set messages to pending"
                });
            }
            return pending;
        } catch ( error: any ) {
            return rejectWithValue({
                error: "training/setMessagesToPending",
                message: error.message
            });
        }
    }
    
);




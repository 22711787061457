import React from "react";

const SkeletonLineChart = () => {
    return (
        <div className="skeleton-chart-container skeleton-frame-info">
            <div className="skeleton-header">
                <div className="skeleton-legend"></div>
                <div className="skeleton-icons"></div>
            </div>
            <div className="skeleton-line-chart mt-5">
                <div className="skeleton-line wavy-line-1" />
                <div className="skeleton-line wavy-line-2" />
                <div className="skeleton-line wavy-line-3" />
            </div>
        </div>
    );
};
export default SkeletonLineChart;
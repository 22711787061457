import { debounce, get } from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import { Badge, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import Loader from "../../../../../legacy/components/Loader/Loader";
import textCodes from "legacy/constants/translations/textCodes.json";
import { Text } from "translation-manager-react";
import { Controller } from "react-hook-form";
import Editor, { EditorProps, OnMount } from "@monaco-editor/react";
import { useAppSelector } from "legacy/store/typedHooks";
import { getAppConfigParams, getIsProd } from "../../view-models-generators/config/configParamsViewModels";
import { FunctionItem } from "clean-archi/core/entities/flowbuilder";
import { HttpFunctionStorelessGateway } from "clean-archi/adapters/secondary/gateways/flowbuilder/function/HttpFunctionStorelessGateway";
import { IUpdateFunctionsInput } from "clean-archi/core/interfaces/gateways/flowbuilder/FunctionStorelessGateway";
import useFunctionForm from "../flowbuilder/forms/FunctionForm/useFunctionForm";

const fetchFunctionGateway = new HttpFunctionStorelessGateway();

const CodeEditor = () => {
    const history = useHistory();
    const params = useParams();
    const functionId = get( params, "id" );
    const appConfigParams = useAppSelector( getAppConfigParams );
    const isProd = useAppSelector( getIsProd );
    const isLoading = false;
    const isSystem = useMemo(() => history.location.pathname.includes( "system/code" ), [history.location.pathname]);
    const [dependencies, setDependencies ] = useState<string[]>([]);
    const [previousValue, setPreviousValue ] = useState<Partial<FunctionItem> | undefined>( undefined );

    const debounceMemo = useMemo(
        () => debounce(( func: Function, value: any ) => func( value ), 2000 ),
        []
    );

    const getFunction = useCallback( async ( id: string ) => {
        const response = await fetchFunctionGateway.fetchFunction({
            botId: appConfigParams.botId,
            branchName: appConfigParams.branchName,
            id,
        });

        return response.data;
    }, [appConfigParams.botId, appConfigParams.branchName]);

    const updateFunction = useCallback( async ( fn: FunctionItem ) => {
        const hasCodeBeenUpdated = previousValue !== undefined
            && ( fn.code !== previousValue.code || fn.description !== previousValue.description );

        if ( !hasCodeBeenUpdated ) return;

        let payload: IUpdateFunctionsInput = {        
            branchName: appConfigParams.branchName,
            ...fn
        };

        if ( isSystem ) {
            payload = {
                ...payload,
                isSystem: true,
            };
        } else {
            payload = {
                ...payload,
                botId: appConfigParams.botId,
            };
        }

        const response = await fetchFunctionGateway.updateFunction( payload, appConfigParams.branchName );
        setPreviousValue( payload );

        return response.data;
    }, [appConfigParams.botId, appConfigParams.branchName, isSystem, previousValue]);

    const onFormChange = useCallback(( datas: any ) => {      
        debounceMemo( updateFunction, {
            id: functionId,
            code: datas.code,
            description: datas.description,
        });       
    }, [debounceMemo, functionId, updateFunction]);

    const {
        control,
        onCodeChange,
        onDescriptionChange,
        register,
        resetForm,
    } = useFunctionForm({ 
        defaultValues: {}, 
        onChange: onFormChange, 
        extra: {
            getAutocompleteSpecialItems: () => {return [];},
            getInitialSelectedItem: () => {return {};},
            getSuggestions: async () => {return [];}
        } 
    });

    useEffect(() => {
        setPreviousValue( undefined );
        getFunction( functionId ).then(( datas ) => {
            setPreviousValue( datas );
            resetForm( datas );
            setDependencies( datas?.dependencies ?? []);
        });
        const { id, code, description } = control._formValues;
        const hasCodeBeenUpdated = previousValue !== undefined && ( code !== previousValue.code || description !== previousValue.description );
        return hasCodeBeenUpdated
            ? () => {
                updateFunction({
                    id,
                    code,
                    description,
                });
            } :
            () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCodeEditorChange: EditorProps["onChange"] = useCallback(
        ( value, event ) => {
            if (
                previousValue !== undefined
                && ( value.code !== previousValue.code || value.description !== previousValue.description )
            ) {
                onCodeChange( value );
            }         
        },
        [onCodeChange, previousValue]
    );

    const codeEditorOptions: EditorProps["options"] = useMemo(
        () => ({
            // theme: 'vs-dark',
            minimap: {
                enabled: false
            }
        }),
        []
    );

    type Params = Parameters<OnMount>;
    type Editor = Params[0];
    type Monaco = Params[1];

    const handleEditorDidMount = ( editor: Editor, monaco: Monaco ) => {
        editor.addCommand( monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyZ, () => {});
        editor.addCommand( monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {});
    }; 

    return (
        <div className="Code__Editor">
            <Loader loading={isLoading} className={"overlay"} />
            <div className="Code__Editor_container h-100">

                <form className="flex flex-column align-stretch h-100">
                    
                    <div className="mb-3">
                        <FormLabel htmlFor="description">
                            <i className="fal fa-comment-alt-lines"></i> <Text textCode={textCodes.DESCRIPTION} uppercase />
                        </FormLabel>
                        <FormControl
                            as="textarea"
                            {...register( "description", { onChange: !isProd ? onDescriptionChange : undefined })}
                        />
                    </div>
                    <div className="mb-3">
                        <FormLabel htmlFor="dependencies">
                            <i className="fal fa-tools"></i> <Text textCode={textCodes.DEPENDENCIES} uppercase />
                        </FormLabel>
                        <div>
                            {dependencies?.map( d => (
                                <Badge
                                    style={{ marginRight: "5px"}}
                                    key={d}
                                    bg="secondary">{d}
                                </Badge>
                            )) }
                        </div>
                    </div>
                    <FormGroup className="Code__Editor_section_container">
                        <FormLabel htmlFor="name">
                            <i className="fal fa-code"></i> <Text textCode={textCodes.CODE} uppercase />
                        </FormLabel>
                        <Controller
                            control={control}
                            name="code"
                            render={({ field: { value }}) => (
                                <Editor
                                    className=""
                                    height="100%"
                                    defaultLanguage="javascript"
                                    onChange={!isProd ? onCodeEditorChange : undefined}
                                    options={codeEditorOptions}
                                    value={value}
                                    onMount={handleEditorDidMount}
                                />
                            )}
                        />
                    </FormGroup>
                </form>

            </div>
        </div>
    );
};

export default CodeEditor;

import { Flow, FlowListEntry, FunctionItem, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import { createSelector } from "reselect";
import { MainReducerState } from "legacy/store/reducers";
import { HighlightedElement } from "../../../../core/entities/flowbuilder/HighlightedElement";

export const getBotFlows: ( state: MainReducerState ) => Flow[] =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.flows,
        ( flows ) => {
            return Array.from( flows.values());
        }
    );
    
export const getBotFlowNodes: ( state: MainReducerState ) => NodeFlow[] | undefined =
    createSelector(
        ( state: MainReducerState ) => {
            const selectedFlowId = state.flowbuilder.botFlow.selectedFlowId as string;
            return state.flowbuilder.botFlow.flows.get( selectedFlowId )?.nodes;
        },
        ( nodes ) => {
            return nodes ? Array.from( nodes.values()) : undefined;
        }
    );

export const getBotFlowNodeById: ( state: MainReducerState, id: string ) => NodeFlow | undefined =
    createSelector(
        ( state: MainReducerState, id: string ) => {
            const selectedFlowId = state.flowbuilder.botFlow.selectedFlowId as string;
            return state.flowbuilder.botFlow.flows.get( selectedFlowId )?.nodes.get( id );
        },
        ( node ) => {
            return node ?? undefined;
        }
    );

export const getBotFlowId: ( state: MainReducerState ) => string | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.id,
        ( id ) => {
            return id;
        }
    );

export const getFlowsList: ( state: MainReducerState ) => FlowListEntry[] =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.flows,
        ( flows ) => {
            return Array.from( flows.values()).map( flow => ({
                id: flow.id, index: flow.index, name: flow.name
            }));
        }
    );

export const getFunctionsList: ( state: MainReducerState ) => FunctionItem[] = 
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.botFlow.functionsList,
        ( functionsList ) => {
            return Array.from( functionsList.values()).map( f => ({
                id: f.id,
                name: f.name,
                isCron: f.isCron
            }));
        }
    );

export const getHighlightedElement: ( state: MainReducerState ) => HighlightedElement | undefined =
    createSelector(
        ( state: MainReducerState ) => state.flowbuilder.highlightedElement,
        ( highlightedElement ) => {
            return highlightedElement;
        }
    );

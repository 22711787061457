import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError, RemovedItem } from "../../../entities/flowbuilder";
import { InputRemoveNode } from "clean-archi/core/interfaces/gateways/flowbuilder/NodeGateway";

export const removeNode = createAsyncThunk<Partial<RemovedItem>, InputRemoveNode, { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError} >(
    "flowbuilder/removeNode",
    async ( input: InputRemoveNode, {extra, rejectWithValue}) => {
        const response = await extra.nodeGateway?.removeNode( input );
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }
        return response?.data;
    }
);
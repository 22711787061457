import React              from "react";
import PropTypes          from "prop-types";
import { get, cloneDeep } from "lodash";

import propTypes from "legacy/constants/propTypes";
import EntitiesContextMenu from "legacy/components/ContextMenu/EntitiesContextMenu";

const CompositeContextMenu = ({
    new: isNew,
    index,
    selectionText,
    entities,
    entity,
    updateEntity,
    closeContextMenu,
    onEntityClick,
}) => {

    function _onEntityClick ( entity ) {
        if ( typeof onEntityClick === "function" )
            onEntityClick( entity );

        const valuePath = isNew ? "newValue" : `compositionsValuesArray[${index}]`;
        const value = cloneDeep( get( entity, valuePath )) || {};
        value.compositionId = entity._id;
        value.id = `@${entity.name}`;
        value.synonyms = [];
        updateEntity({ value, valuePath });
    }

    return (
        <EntitiesContextMenu
            selectionText={selectionText}
            onEntityClick={_onEntityClick}
            entities={entities}
            closeOnEntityClick={true}
            closeContextMenu={closeContextMenu}
            defaultSearchFilter={selectionText}
        />
    );
};

CompositeContextMenu.propTypes = {
    new: PropTypes.bool,
    index: PropTypes.number,

    selectionText: PropTypes.string,
    entities: PropTypes.arrayOf( propTypes.entity ),
    entity: propTypes.editedEntity,
    updateEntity: PropTypes.func,
    onEntityClick: PropTypes.func,

    closeContextMenu: PropTypes.func.isRequired,
};

export default CompositeContextMenu;

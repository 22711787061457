import React, {Component} from "react";
import PropTypes from "prop-types";

import Button    from "legacy/components/Button/Button";
import MenuPopUp from "legacy/components/PopUp/MenuPopUp";
import PopUp, {PopUpWrapper} from "legacy/components/PopUp/PopUp";

class SubMenuButton extends Component {
    state = {
        showMenu: false
    };


    showMenu = ( e ) => {
        this.setState({showMenu: true});
        if (
            this.props.buttonProps
            && "onClick" in this.props.buttonProps
            && typeof this.props.buttonProps.onClick === "function"
        )
            this.props.buttonProps.onClick( e );
    };
    closeMenu = () => {
        this.setState({showMenu: false});
    };

    render () {
        const {buttonProps, popUpProps} = this.props;


        return (
            <div className={"relative"}>
                <PopUpWrapper>
                    <PopUp
                        arrow position={"bottom"} translation={80}
                        {...popUpProps}
                        menu
                        show={this.state.showMenu}
                        onClickOutside={this.closeMenu}
                        body={(
                            <MenuPopUp
                                menu={this.props.menu}
                                onClose={this.closeMenu}
                            />
                        )}
                    />
                </PopUpWrapper>
                <Button
                    icon bsSize="xsmall" simple
                    {...buttonProps}
                    onClick={this.showMenu}
                >
                    <i className="fas fa-ellipsis-v" />
                </Button>
            </div>
        );
    }
}

SubMenuButton.propTypes = {
    menu: MenuPopUp.propTypes.menu,
    buttonProps: PropTypes.shape( Button.propTypes ),
    popUpProps: PropTypes.shape({
        ...PopUp.propTypes,
        show: PropTypes.bool
    }),
};

export default SubMenuButton;

import PropTypes                        from "prop-types";
import React, { Component }             from "react";
import { Text }      from "translation-manager-react";
import textCodes                                   from "legacy/constants/translations/textCodes";

import Dropdown from "react-bootstrap/Dropdown";
import "./messageTemplate.scss";
import { truncate } from "lodash";

class MessageTemplate extends Component {

    constructor ( props ) {
        super( props );
    }

    handleOnSelect = ( message ) => {
        this.props.userInput.innerHTML = message;
    };

    render () {
        return (
            <div
                style={{ position: "absolute" }}
                className={ "PopUp popup-message-template popup-container popup-container-top-80" }
            >
                <div
                    className={ "popup arrow-box arrow-bottom arrow-pos-85" }
                >
                    {this.props.messageTemplateList.length ?
                        ( <Dropdown onSelect={this.handleOnSelect} >
                            {this.props.messageTemplateList.map( msg => (
                                <Dropdown.Item
                                    key={msg._id}
                                    eventKey={msg.message}
                                >{truncate( msg.message, {length: 30})}
                                </Dropdown.Item>
                            ))}
                        </Dropdown> ) :
                        <Text textCode={textCodes.EMPTY_MESSAGE_TEMPLATE_LIST} />
                    }
                </div>
            </div>
        );
    }
}

MessageTemplate.propTypes = {
    loading: PropTypes.bool,
};

MessageTemplate.defaultProps = {};

export default MessageTemplate;

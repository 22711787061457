import { hasInput, hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import React, { FC, ReactNode, useMemo } from "react";
import { Position } from "react-flow-renderer";
import { useAppSelector } from "legacy/store/typedHooks";
import FormManager from "../../forms/FormManager/FormManager";
import CardHandle from "../../handles/CardHandle/CardHandle";
import {getAppConfigParams} from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";

import "./CardIntent.scss";
import { useToTranslate } from "../../../../../../../legacy/hooks/useToTranslate";

interface Props extends CardFlow {
    form?: any;
    index: number;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
}

const CardIntent: FC<Props> = ({id, type, index, form, isEditing, isLastCard, onClick, handle, intent, connections}) => {
    const { language } = useAppSelector( getAppConfigParams );
    const firstTrainingSentence = useMemo(() => {
        const selectedLanguage = intent?.training?.filter( t => t.language === language );
        const firstSentence = selectedLanguage ? selectedLanguage[0]?.sentences[0]?.parts?.map( p => p.text ).join( " " ) : undefined;
        return firstSentence ?? "";
    }, [intent, language]);
    const ToTranslate = useToTranslate();

    return (
        <div className="bot-card-intent-container">
            {!isEditing && (
                <small className="card-headline">{intent?.name}</small>
            )}
            <div className="bot-card bot-card-intent">
                {( hasInput( type )) && (
                    <CardHandle isConnectable={connections.input.length === 0} id={id} type="target" position={Position.Left}/>
                )}
                <div className="bot-card-type">
                    <i className="card-icon fas fa-arrow-alt-from-left"></i>
                    {handle}
                </div>
                <div className="content" onClick={( e: React.MouseEvent ) => onClick?.( e )}>
                    {form && !form.isProd && isEditing ? <FormManager {...form} /> : (
                        <div>
                            <div>{firstTrainingSentence || <ToTranslate/>}</div>
                        </div>
                    )}
                </div>
                {hasOutput( type ) && (
                    <CardHandle isConnectable={connections.output.length === 0} id={id} type="source" position={Position.Right}/>
                )}
            </div>
        </div>
    );
};

export default CardIntent;

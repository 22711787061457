import { AssignedIntent } from "./AssignedIntent";

export const TRAINING_STATUS_UNTRAINED = "untrained";
export const TRAINING_STATUS_TRAINED = "trained";
export const TRAINING_STATUS_IGNORED = "ignored";
export const TRAINING_STATUS_PENDING = "pending";

export interface TrainingData {
    status: typeof TRAINING_STATUS_UNTRAINED
        | typeof TRAINING_STATUS_TRAINED
        | typeof TRAINING_STATUS_IGNORED
        | typeof TRAINING_STATUS_PENDING;
    trainedMessage?: string | null;
    assignedIntent?: AssignedIntent | null;
}
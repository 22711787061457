import { Flow, ResponseApi, FlowListEntry } from "clean-archi/core/entities/flowbuilder";
import { FlowGateway, InputFetchFlowsList, InputUpdateFlow, RemoveFlowResult, UpdateFlowResult } from "clean-archi/core/interfaces/gateways/flowbuilder/FlowGateway";
import { InputAddFlow } from "clean-archi/core/interfaces/gateways/flowbuilder/FlowGateway";
import { FlowApiReturnType, NodeFlowApiReturnType, REGEX_2XX } from "../gateways-entities";
import { transformToFlow, transformToNodeFlow } from "../transform-helpers";
import axiosInstance from "legacy/store/requests/axiosInstance";
import { InputRemoveNode } from "clean-archi/core/interfaces/gateways/flowbuilder/NodeGateway";

export class HttpFlowGateway implements FlowGateway {
    async addFlow ( input: InputAddFlow ): Promise<ResponseApi<Flow>> {
        const response = await axiosInstance.post<ResponseApi<FlowApiReturnType>>( "/api/flowbuilder/flow", {
            botFlowId:  input.botFlowId,
            index: input.index,
            name: input.name,
            botId: input.botId,
            branchName: input.branchName
        }, {
            params: { branchName: input.branchName}
        }).catch( err => err );
        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            return {
                data: this.transformToFlow( response.data.data as FlowApiReturnType ),
            };
        }
        return {
            message: "An error has occured" // TODO: API should return data entry as a JSON object
        };
    }

    async editFlow ( input: InputUpdateFlow ): Promise<ResponseApi<UpdateFlowResult>> {
        const response = await axiosInstance.patch<ResponseApi<FlowApiReturnType>>(
            "/api/flowbuilder/flow",
            input, {
                params: { branchName: input.branchName}
            }
        ).catch( err => err );
        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            const { data } = response.data;
            return data.nodes
                // We have updated flow name
                ? {
                    data: {
                        ...data,
                        nodes: ( data.nodes as NodeFlowApiReturnType[]).map( n => transformToNodeFlow( n ))
                    }
                }
                // We have updated flow index
                : response.data;
        }
        return {
            message: "An error has occured" // TODO: API should return data entry as a JSON object
        };
    }
    
    async removeFlow ( input: InputRemoveNode ): Promise<ResponseApi<RemoveFlowResult>> {
        const response = await axiosInstance.delete<ResponseApi<RemoveFlowResult>>( `/api/flowbuilder/flow/${input.id}`, {
            data: {
                botId: input.botId,
                branchName: input.branchName,
                botInstanceId: input.botInstanceId
            },
            params: { branchName: input.branchName }
        }).catch( err => err );
        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            return {
                data: response.data.data,
            };
        }
        return {
            message: "An error has occured" // TODO: API should return data entry as a JSON object
        };
    }

    async fetchFlowsList ( input: InputFetchFlowsList ): Promise<ResponseApi<FlowListEntry[]>> {
        const response = await axiosInstance.get<ResponseApi<FlowApiReturnType>>( `/api/flowbuilder/flow/list/${input.botId}/${input.botFlowId}/${input.branchName}`, {
            params: {
                branchName: input.branchName
            }
        }).catch( err => err );
        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            return {
                data: response.data.data as Partial<FlowListEntry[]>,
            };
        }
        return {
            message: "An error has occured"
        };
    }

    transformToFlow ( flow: FlowApiReturnType ): Partial<Flow> {
        return transformToFlow( flow );
    }
    
}

import { Connection, ResponseApi } from "clean-archi/core/entities/flowbuilder";
import { ConnectionGateway, InputAddConnection, InputRemoveConnection } from "clean-archi/core/interfaces/gateways/flowbuilder/ConnectionGateway";
import axiosInstance from "legacy/store/requests/axiosInstance";
import { REGEX_2XX } from "../gateways-entities";

export class HttpConnectionGateway implements ConnectionGateway {

    async addConnection ( input: InputAddConnection, branchName: string ): Promise<ResponseApi<Connection>> {
        const response = await axiosInstance.post<ResponseApi<Connection>>( "/api/flowbuilder/connection", input, {
            params: {
                branchName: branchName,
            }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async removeConnection ( input: InputRemoveConnection ): Promise<ResponseApi<Connection>> {
        const response = await axiosInstance.delete<ResponseApi<Connection>>( "/api/flowbuilder/connection", {
            data: input,
            params: {
                branchName: input.branchName
            }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

}

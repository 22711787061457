import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { hideConversationAction, setRightContainerWidthAction } from "legacy/store/actions/rightContainerActions";
import Button from "../../../../../../legacy/components/Button/Button";
import { HttpConversationGateway } from "../../../../secondary/gateways/conversation/HttpConversationGateway";
import { Conversation } from "../../../../../core/entities/bot/Bot";
import { get } from "lodash";
import reactParser from "html-react-parser";
import { htmlRegex, returnRegex } from "../../../../../../legacy/constants/appConstants/regex";
import moment from "moment";
import { useBasicAction } from "../../../../../../legacy/hooks/useBasicAction";
import { ALL_BASIC_ACTIONS } from "../../../../../../legacy/constants/hmbConstants";
import Player from "../../../../../../legacy/components/Player/Player";
import { getTraining } from "clean-archi/adapters/primary/view-models-generators/training/trainingViewModels";
import routes from "../../../../../../legacy/constants/routes";

import "./ConversationSidebarContainer.scss";

const conversationGateway = new HttpConversationGateway();

const ConversationSidebarContainer: FC = () => {
    
    const canListenConversationAudio    = useBasicAction( ALL_BASIC_ACTIONS.LISTEN_CONVERSATION_AUDIO );
    const { conversationId } = useAppSelector( getTraining );
    const [conversation, setConversation] = useState<Conversation | undefined>();
    const messages = useMemo(() => {
        return conversation?.messages;
    }, [conversation]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (conversationId) {
            conversationGateway.getConversation( conversationId ).then(( conversation ) => {
                setConversation( conversation );
            });
        }
    }, [conversationId]);

    const closeSidebar = useCallback(
        () => {
            dispatch( hideConversationAction());
            dispatch( setRightContainerWidthAction( 450 ));
        },
        [dispatch]
    );

    const openFullConversation = useCallback(
        () => {
            window.open( routes.conversationView.buildPath({ id: conversationId }), "_blank");
        },
        []
    );

    const prepareStringToDisplay = useCallback(( str: any ) => {
        let modifiedString = String( str );
        modifiedString = modifiedString.replace( returnRegex, "<br />" );
        modifiedString = modifiedString.replace( htmlRegex, "" );
        return reactParser( modifiedString );
    }, []);

    return (
        <div className={"RightSidebarContainer conversation"}>
            <div className="RightSidebarContainer__Header">
                <div>
                    <span>Conversation</span>      
                </div>
                <div>
                    <Button icon simple bsStyle="white" onClick={openFullConversation}>
                        <i className="fas fa-external-link"></i>
                    </Button>
                    &nbsp;
                    &nbsp;
                    <a role="button" onClick={closeSidebar}><i className="far fa-times"></i></a>
                </div>              
            </div>
            <div className="RightSidebarContainer__Body p-3 w-100 h-100">
                {messages && (
                    <div
                        id={"conversation-container"}
                        className={"conversation-container"}
                    >
                        {canListenConversationAudio && get( conversation, "audioLink" ) && (
                            <Player type={"audio"} url={get( conversation, "audioLink" )}/>
                        )}
                        <div className={"conversation"}>
                            {( messages || []).map(( message, index ) => {
                                return (
                                    <div className={"message"} key={get( message, "_id" ) || index}>
                                        <div className={"message-bubble"}>
                                            {
                                                get( message, "userMessage" ) ? (
                                                    <div
                                                        className={"bubble-wrapper user"}
                                                    >
                                                        <div className={"bubble"}>{prepareStringToDisplay( message.userMessage )}</div>
                                                        {get( message, "updatedAt" ) && (
                                                            <span className={"date"}>
                                                                {moment( message.updatedAt ).format( "L" )} {moment( message.updatedAt ).format( "LTS" )}
                                                            </span>
                                                        )}
                                                    </div>
                                                ) : (
                                                    (( get( message, "payload" ) && !get( message, "payload" ).includes( "_back" ))) && (
                                                        <div
                                                            className={"bubble-wrapper user payload"}
                                                        >
                                                            <div className={"bubble"}>{get( message, "payload" )}</div>
                                                            {get( message, "updatedAt" ) && (
                                                                <span className={"date"}>
                                                                    {moment( message.updatedAt ).format( "L" )} {moment( message.updatedAt ).format( "LTS" )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    )
                                                )
                                            }
                                            {get( message, "acmValue" ) ?
                                                message.acmValue.map(( response: any, acmIndex: any ) => get( response, "text" ) ?
                                                    ( <div className={"bubble-wrapper bot"} key={`acm-value-${acmIndex}`}>
                                                        <div className={"bubble text"}>
                                                            {prepareStringToDisplay( response.text )}
                                                        </div>
                                                    </div>
                                                    ) : null,
                                                ) : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConversationSidebarContainer;

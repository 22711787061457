import { CARDS, CardFlow } from "./Card";
import { ResponseApi } from "./Api";

/* eslint-disable no-unused-vars */

export enum FORMS_SPECS {
    Card = "Card",
    Panel = "Panel",
}

export interface Form {
    cardId?: string;
    content?: CardFlow;
    nodeId?: string;
    spec?: FORMS_SPECS;
    type?: CARDS;
    isEditing?: boolean;
}

export type FetchInput = {
    id: string;
    nodeId: string;
}

export type SubmitInput<T> = T;

export interface FormGateway<T extends { id: any }> {
    fetch( input: SubmitInput<T> ): Promise<ResponseApi<T>>
    submit?( input: SubmitInput<T> ): Promise<ResponseApi<T>>
    validate?( input: SubmitInput<T> ): Promise<ResponseApi<T>>

    /* TODO: When Flowbuilder API is ready, replace by fetch */
    fetchMockedQuickReplies?( input: FetchInput ): Promise<ResponseApi<T>>
}

import { SerializedError } from "@reduxjs/toolkit";
import React, {FC, useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { getErrors } from "../../../view-models-generators/config/configParamsViewModels";

import "./ErrorsNotifications.scss";
import { clearError } from "../../../../../store/flowbuilder";

const ErrorsNotifications: FC = () => {
    const dispatch = useAppDispatch();
    const error = useAppSelector( getErrors );
    const [index, setIndex] = useState( 0 );
    const [errors, setErrors] = useState<( SerializedError & {index: number})[]>([]);

    useEffect(() => {
        if ( error?.message ) {
            setIndex( index + 1 );
            setErrors( prev => [...prev, { ...error, index }]);
            dispatch( clearError );
        }
    }, [error]);

    const dismiss = ( index?: number ) => {
        setErrors([...errors.filter(( error ) => error.index !== index )]);
    };

    return (
        <div className="ErrorsNotifications">
            <ToastContainer>
                {errors.map(( error, index ) => (
                    <Toast key={`error${ index}`} onClose={dismiss.bind( null, error.index )} show={!!error} delay={10000} autohide animation={true}>
                        <Toast.Header>
                            <i className="fas fa-exclamation-triangle"></i><strong className="me-auto">Erreur</strong>
                        </Toast.Header>
                        <Toast.Body>{error.message}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </div>
    );
};

export default ErrorsNotifications;

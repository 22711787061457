import { useEffect, useMemo } from "react";
import { getAppConfigParams } from "../../view-models-generators/config/configParamsViewModels";
import { useAppDispatch, useAppSelector } from "../../../../../legacy/store/typedHooks";
import { getTraining } from "../../view-models-generators/training/trainingViewModels";
import { loadMessages } from "../../../../core/use-cases/training/load-messages/loadMessagesThunk";

export function useLoadMessages () {
    const dispatch = useAppDispatch();
    const { botId } = useAppSelector( getAppConfigParams );
    const { filters, shouldReload } = useAppSelector( getTraining );
    
    const {
        branchName,
        endDate,
        fallback,
        language,
        page,
        perPage,
        startDate,
        status
    } = filters;

    const areFiltersReady = useMemo(() => {
        return botId
        && branchName
        && language
        && page
        && perPage
        && startDate
        && endDate
        && Boolean( status );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        botId,
        branchName,
        endDate,
        fallback,
        language,
        page,
        perPage,
        startDate,
        status
    ]);

    useEffect(() => {
        if (( areFiltersReady && shouldReload )) {
            dispatch( loadMessages());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        botId,
        shouldReload,
    ]);
}
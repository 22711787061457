import React from "react";
import cx     from "classnames";
import Button from "legacy/components/Button/Button";

const AddButton = ({className, ...rest}) =>(
    <Button
        icon simple
        {...rest}
        className={cx( className, "btn-add" )}
    >
        <i className="fal fa-plus"/>
    </Button>
);

AddButton.propTypes = Button.propTypes;
export default AddButton;

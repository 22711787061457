import { PayloadAction } from "@reduxjs/toolkit";
import { CardFlow, FlowBuilderState } from "../../../entities/flowbuilder";
import { selectNodeFromFlow } from "../utils";

export interface UpdateCardFlowAction {
    nodeId: string;
    card: CardFlow;
}

export const updateCardFlowReducer = ( state: FlowBuilderState, action: PayloadAction<UpdateCardFlowAction> ) => {
    const flow = state.botFlow.flows.get( state.botFlow.selectedFlowId as string );
    const node = selectNodeFromFlow({
        id: action.payload.nodeId as string,
        flowId: flow?.id as string,
        state
    });

    if ( node ) {
        node.cards.set( action.payload.card.id as string, { ...action.payload.card });
    }
};

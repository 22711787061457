import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatisticsFilters } from "clean-archi/core/entities/statistics/StatisticsFilters";
import { ConversationsCountApiResponse, StatisticsState } from "clean-archi/core/entities/statistics/StatisticsState";
import { getConversationDuration } from "clean-archi/core/use-cases/statistics/conversation-duration/getConversationDurationThunk";
import { getConversationCount } from "clean-archi/core/use-cases/statistics/conversation-count/getConversationCountThunk";
import dayjs from "dayjs";
import { getTransfersNumber } from "clean-archi/core/use-cases/statistics/transfers-number/getTrasfersNumberThunk";
import { getUniqueUsers } from "../../core/use-cases/statistics/unique-users/getUniqueUsersThunk";
import { getMisunderstanding } from "clean-archi/core/use-cases/statistics/misunderstanding/getMisunderstandingThunk";
import { getMesageDistribution } from "clean-archi/core/use-cases/statistics/message-distribution/getMessageDistributionThunk";

const defaultFilters: Omit<StatisticsFilters, "botId"> = {
    branchName: "prod",
    startDate: dayjs().subtract( 5, "day" ).toISOString(),
    endDate: dayjs().toISOString(),
    botInstanceId: "",
    channelExternalId: "",
    channelId: "",
    channelType: "",
    perDay:false,
    perMonth:false,
    perYear:false,
};

const initialState: StatisticsState = {
    filters: defaultFilters,
    averageConversationsDuration: [],
    conversationDurationLoading: true,
    
    transfersNumber: [],
    transfersNumberLoading: true,

    conversationsCount: [],
    conversationCountLoading: true,

    uniqueUsers: [],
    uniqueUsersLoading: true,

    misunderstanding: [],
    misunderstandingLoading: true,

    messagesDistribution: [],
    messagesDistributionLoading: true,

    weeklyConversations : [],
    weeklyConversationsLoading : false,
  
    totalSatisfaction: null,
};

export const statisticsSlice = createSlice({
    name: "stats",
    initialState,
    reducers: {
        setFilters ( state, action: PayloadAction<Partial<Omit<StatisticsFilters, "botId">>> ) {
            state.filters = {
                ...state.filters,
                ...action.payload,
            };
        },
    },
    extraReducers: ( builder => {
        builder.addCase( getConversationDuration.pending , ( state ) => {
            state.conversationDurationLoading = true;
        });
 
        builder.addCase( getConversationDuration.fulfilled, ( state: StatisticsState, action: PayloadAction<any> ) => {
            state.averageConversationsDuration = action.payload;
            state.conversationDurationLoading = false;
        });
        builder.addCase( getConversationDuration.rejected , ( state ,action ) => {
            state.conversationDurationLoading = false;
        });

        builder.addCase( getConversationCount.pending, ( state, action ) => {
            state.conversationCountLoading = true;
        });
        builder.addCase(
            getConversationCount.fulfilled,( state: StatisticsState, action: PayloadAction<ConversationsCountApiResponse[]> ) => {                
                state.conversationsCount = action.payload; 
                state.conversationCountLoading = false;
            }
        );
        builder.addCase( getConversationCount.rejected, ( state, action ) => {
            state.conversationCountLoading = false;
        });

        builder.addCase( getTransfersNumber.pending, ( state, action ) => {
            state.transfersNumberLoading = true;
        });
        builder.addCase( getTransfersNumber.fulfilled, ( state, action ) => {
            state.transfersNumber = action.payload;
            state.transfersNumberLoading = false;
        });
        builder.addCase( getTransfersNumber.rejected, ( state, action ) => {
            state.uniqueUsersLoading = false;
        });

        builder.addCase( getUniqueUsers.pending, ( state, action ) => {
            state.uniqueUsersLoading = true;
        });
        builder.addCase( getUniqueUsers.fulfilled, ( state, action ) => {
            state.uniqueUsers = action.payload;
            state.uniqueUsersLoading = false;
        });
        builder.addCase( getUniqueUsers.rejected, ( state, action ) => {
            state.uniqueUsersLoading = false;
        });

        builder.addCase( getMisunderstanding.pending, ( state, action ) => {
            state.misunderstandingLoading = true;
        });
        builder.addCase( getMisunderstanding.fulfilled, ( state, action ) => {
            state.misunderstanding = action.payload;
            state.misunderstandingLoading = false;
        });
        builder.addCase( getMisunderstanding.rejected, ( state, action ) => {
            state.misunderstandingLoading = false;
        });

        builder.addCase( getMesageDistribution.pending, ( state, action ) => {
            state.messagesDistributionLoading = true;
        });
        builder.addCase( getMesageDistribution.fulfilled, ( state, action ) => {
            state.messagesDistribution = action.payload;
            state.messagesDistributionLoading = false;
        });
        builder.addCase( getMesageDistribution.rejected, ( state, action ) => {
            state.messagesDistributionLoading = false;
        });
    }),
});

export const { setFilters } = statisticsSlice.actions;

export default statisticsSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { Connection, ApiError } from "clean-archi/core/entities/flowbuilder";
import { InputAddConnection } from "clean-archi/core/interfaces/gateways/flowbuilder/ConnectionGateway";
import { MainReducerState } from "../../../../../legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export const addConnection = createAsyncThunk<Partial<Connection>, InputAddConnection, {
    state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError
} >(
    "flowbuilder/addConnection",
    async ( input: InputAddConnection, {extra, rejectWithValue, getState}) => {
        const { branchName } = getState().app.selectedConfiguration;
        const response = await extra.connectionGateway?.addConnection( input, branchName );
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }
        return response?.data as Connection;
    }
);

import React, { useCallback, useRef, useState } from "react";

function useClickDoubleClick ( clickCallback: Function, doubleClickCallback: Function, delay: number = 500, stopPropagation: boolean = false ) {
    const [disabled, setDisabled] = useState( false );
    const timeoutId: { current: NodeJS.Timeout | undefined } = useRef();

    const disable = useCallback(
        () => {
            setDisabled( true );
            setTimeout(
                () => setDisabled( false ),
                delay
            );
        },
        [delay]
    );

    const onClickDoubleClick = useCallback(
        ( e: React.MouseEvent ) => {
            if ( disabled ) return;

            e.persist();
            if ( stopPropagation ) e.stopPropagation();

            if ( timeoutId.current ) {
                doubleClickCallback( e );
                clearTimeout( timeoutId.current );
                timeoutId.current = undefined;
                disable();
                return;
            }

            timeoutId.current = setTimeout(
                () => {
                    clickCallback( e );
                    timeoutId.current && clearTimeout( timeoutId.current );
                    timeoutId.current = undefined;
                },
                delay
            );
        },
        [delay, disabled, stopPropagation, clickCallback, disable, doubleClickCallback]
    );

    return onClickDoubleClick;
}

export default useClickDoubleClick;

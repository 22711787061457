import React, {FC, useCallback, useRef, useState} from "react";
import { useAppSelector } from "legacy/store/typedHooks";
import { FunctionItem } from "clean-archi/core/entities/flowbuilder";
import { getAppConfigParams, getIsProd } from "../../../../view-models-generators/config/configParamsViewModels";

import FunctionItemMenu from "./FunctionItemMenu";
import { Dropdown } from "react-bootstrap";
import FunctionItemMenuContent from "./FunctionItemMenuContent";
import useKeyPress from "legacy/hooks/useKeyPress";
import { HttpFunctionStorelessGateway } from "clean-archi/adapters/secondary/gateways/flowbuilder/function/HttpFunctionStorelessGateway";
import { IUpdateFunctionsInput } from "clean-archi/core/interfaces/gateways/flowbuilder/FunctionStorelessGateway";

const fetchFunctionGateway = new HttpFunctionStorelessGateway();

interface FunctionMenuItemProps {
    codeFunction: FunctionItem;
    isActive?: boolean;
    isCron?: boolean;
    isSystem?: boolean;
    onOpen: ( id: string ) => void;
    onDelete: ( id: string ) => void;
    onExecute: ( id: string ) => void;
}

const useFocus = (): [any, () => void] => {
    const htmlElRef = useRef<HTMLInputElement>( null );
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    return [ htmlElRef, setFocus ];
};

const FunctionMenuItem: FC<FunctionMenuItemProps> = ({codeFunction, isActive, isCron, isSystem, onOpen, onDelete, onExecute}) => {

    const [contextMenu, setContextMenu] = useState<{x: number; y: number;} | undefined>( undefined );
    const isProd = useAppSelector( getIsProd );
    const hasMenu = ( !isSystem && !isProd ) || isCron;
    const [isRenaming, setIsRenaming] = useState( false );
    const [newName, setNewName] = useState( "" );
    const [registerOnKeyPressEnter] = useKeyPress([[ "Enter" ]]);
    const [renameInputRef, setRenameInputFocus] = useFocus();
    const appConfigParams = useAppSelector( getAppConfigParams );

    const onRename = useCallback(() => {
        setNewName( codeFunction.name ?? "" );
        setIsRenaming( true );
        setTimeout(() => {
            setRenameInputFocus();
        }, 100 );
    }, [codeFunction.name, setRenameInputFocus]);

    const onDuplicate = useCallback(() => {
        console.log( "TODO - Duplicate function" );
    }, []);
    
    const onFunctionContextMenu = useCallback(( e: React.MouseEvent ) => {
        e?.preventDefault();
        e?.stopPropagation();        
        setContextMenu({x: e.pageX - 5, y: e.pageY - 5});
    }, []);

    const hideContextMenu = () => {
        setContextMenu( undefined );
    };

    const onNewNameChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        setNewName( e.target.value );
    };

    const onNewNameBlur = async () => {
        if ( newName !== "" ) {
            
            let payload: IUpdateFunctionsInput = {        
                branchName: appConfigParams.branchName,
                name: newName,
                id: codeFunction.id,
            };
    
            if ( isSystem ) {
                payload = {
                    ...payload,
                    isSystem: true,
                };
            } else {
                payload = {
                    ...payload,
                    botId: appConfigParams.botId,
                };
            }

            await fetchFunctionGateway.updateFunction( payload, appConfigParams.branchName );
            codeFunction.name = newName;
            setNewName( "" );
        }
        
        setIsRenaming( false );
    };

    const onNameClick = ( e: React.MouseEvent ) => {
        if ( e.detail === 2 ) {
            onRename();
        }
    };

    return (
        <div key={codeFunction.id} className={`FunctionsMenu__nav${isActive ? " active" : ""}` }>
            <div 
                className={"FunctionsMenu__navLink"} onClick={() => onOpen?.( codeFunction.id || "" )}
                onContextMenu={( e: React.MouseEvent ) => onFunctionContextMenu?.( e )}
            >
                <span>
                    <i className={isCron ? "fal fa-calendar-times" : "fal fa-file-code"}></i>
                    <span className="FunctionsMenu__navLink-content FunctionsMenu__navLink-content--expanded">
                        {isRenaming ? (
                            <div>
                                <input 
                                    className="rename-input"
                                    placeholder={codeFunction.name} 
                                    value={newName} 
                                    onChange={onNewNameChange} 
                                    onBlur={onNewNameBlur} 
                                    ref={renameInputRef}
                                    {...registerOnKeyPressEnter( onNewNameBlur, { preventDefault: true })}
                                />
                            </div>
                        ) : (
                            <div onClick={onNameClick}>
                                {codeFunction.name}
                            </div>
                        )}
                    </span>
                </span>
                {hasMenu && (
                    <FunctionItemMenu
                        isCron={isCron}
                        onDelete={onDelete.bind( null, codeFunction.id || "" )}
                        onExecute={onExecute.bind( null, codeFunction.id || "" )}
                        onRename={onRename}
                        onDuplicate={onDuplicate}
                    />
                )}
            </div>

            {hasMenu && ( <Dropdown.Menu
                className="FunctionsMenu__navLink_context" 
                show={!!contextMenu} 
                style={{ top: contextMenu?.y, left: contextMenu?.x }} 
                onMouseLeave={hideContextMenu}
            >
                <FunctionItemMenuContent
                    isCron={isCron}
                    onDelete={onDelete.bind( null, codeFunction.id || "" )}
                    onExecute={onExecute.bind( null, codeFunction.id || "" )}
                />
            </Dropdown.Menu> )}
        </div>
    );
};

export default FunctionMenuItem;

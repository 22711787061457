import React, {FC, useContext, useState, useEffect} from "react";
import cx from "classnames";
import Loader from "legacy/components/Loader/Loader";
import {useHistory} from "react-router";
import { Text }    from "translation-manager-react";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import textCodes from "legacy/constants/translations/textCodes.json";
import routes from "legacy/constants/routes";
import ModalCreateFlow from "./ModalCreateFlow/ModalCreateFlow";
import { getIsProd, getSelectedFlow } from "../../../view-models-generators/config/configParamsViewModels";
import FlowsMenu from "../menus/FlowsMenu/FlowsMenu";
import { addStressTestFlow, preselectFlow, selectFlow } from "../../../../../store/flowbuilder";

export const FlowBuilderSidebarContext = React.createContext( false );

export function useFlowBuilderSidebarState () {
    const context = useContext( FlowBuilderSidebarContext );
    if ( context === undefined ) {
        throw new Error( "useFlowBuilderSidebarState must be used within FlowBuilderSidebar" );
    }
    return context;
}

const FlowBuilderSidebar: FC = () => {
    const history = useHistory();
    const [isLoading] = useState( false );
    const [isExpanded, setIsExpanded] = useState( true );
    const [isCreateFlowModalOpen, setIsCreateFlowModalOpen] = useState( false );
    const isProd = useAppSelector( getIsProd );

    // Using RTK slices and types hooks
    const dispatch = useAppDispatch();
    const selectedFlowId = useAppSelector( getSelectedFlow );

    useEffect(() => {
        if ( !selectedFlowId && history.location.pathname.endsWith( "/flowbuilder" )) {
            // If there is no flow the url, select the default flow
            dispatch( preselectFlow());

        } else if ( selectedFlowId ) {
            history.push( routes.flowBuilderFlow.buildPath({id: selectedFlowId}));
            dispatch( selectFlow({flowId: selectedFlowId}));
        }
    }, [selectedFlowId, history, dispatch, history.location.pathname]);

    const changeFlow = ( id: string ) => {
        history.push( routes.flowBuilderFlow.buildPath({id}));
        dispatch( selectFlow({flowId: id}));
    };

    const changeNode = ( id: string ) => {
        // We probably won't display nodes in side bar, only flows
    };

    const openCreateModal = () => {
        setIsCreateFlowModalOpen( true );
    };

    const closeCreateModal = () => {
        setIsCreateFlowModalOpen( false );
    };

    const onCreateSuccess = () => {
        closeCreateModal();
    };

    const stressTest = () => {
        dispatch( addStressTestFlow());
    };

    const expandSidebar = () => setIsExpanded( true );

    const collapseSidebar = () => setIsExpanded( false );

    return (
        <div
            className={cx( "FlowBuilder__Sidebar", { "FlowBuilder__Sidebar--expanded": isExpanded })}
            onMouseEnter={expandSidebar}
            onMouseLeave={collapseSidebar}
        >
            <Loader loading={isLoading} className={"overlay"} />
            <FlowBuilderSidebarContext.Provider value={isExpanded}>
                <FlowsMenu
                    selectedItem={selectedFlowId}
                    onSelectFlow={changeFlow}
                    onSelectNode={changeNode}
                    isSortable={true}
                />
            </FlowBuilderSidebarContext.Provider>
            { !isProd && ( <div className="btn-group">
                <button className="FlowBuilder__Sidebar_addFlow flex-grow-1" onClick={openCreateModal}>
                    <i className="fa fa-plus-circle"></i>
                    <Text
                        className={cx( "FlowBuilder__Sidebar_addFlow_text", { "FlowBuilder__Sidebar_addFlow_text--expanded": true })}
                        textCode={textCodes.ADD_FLOW}
                    />
                </button>
            </div> )}
            <ModalCreateFlow
                onHide={closeCreateModal}
                onSuccess={onCreateSuccess}
                isVisible={isCreateFlowModalOpen}
            />
        </div>
    );
};

export default FlowBuilderSidebar;

import axiosInstance from "../../../../../../legacy/store/requests/axiosInstance";
import { SearchMatch } from "../../../../../core/entities/flowbuilder/SearchMatch";
import { BotFlowStorelessGateway, SearchInBotFlowInput } from "../../../../../core/interfaces/gateways/flowbuilder/BotFlowStorelessGateway";
import { ResponseApi } from "../../../../../core/use-cases/flowbuilder/fetch-bot-flow";
import { REGEX_2XX } from "../gateways-entities";

export class HttpBotFlowStorelessGateway implements BotFlowStorelessGateway {

    async searchInBotFlow ( input: SearchInBotFlowInput ): Promise<ResponseApi<SearchMatch[]>> {
        const response = await axiosInstance.get<ResponseApi<SearchMatch[]>>( "/api/flowbuilder/botflow/search", {
            params: {
                botFlowId: input.botFlowId,
                branchName: input.branchName,
                language: input.language,
                value: input.value
            }
        });
        if ( REGEX_2XX.test( response.status.toString())) {
            return response.data;
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

}
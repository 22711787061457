import { useCallback, useEffect, useMemo, useState } from "react";
import { getAppConfigParams } from "../../view-models-generators/config/configParamsViewModels";
import { useAppSelector } from "../../../../../legacy/store/typedHooks";
import { HttpTrainingGateway } from "../../../secondary/gateways/training/HttpTrainingGateway";
import { debounce } from "lodash";
import { AssignedIntent } from "../../../../core/entities/training/AssignedIntent";
import { getTraining } from "../../view-models-generators/training/trainingViewModels";

const trainingGateway = new HttpTrainingGateway();

export function useIntentSuggestions ( value: string ) {

    const { botId } = useAppSelector( getAppConfigParams );
    const training = useAppSelector( getTraining );
    
    const {
        language,
    } = training.filters;
    
    const [suggestions, setSuggestions] = useState<AssignedIntent[]>([]);
    const [isLoading, setIsLoading] = useState( false );

    const debounceMemo = useMemo(
        () => debounce(( func: Function ) => func(), 200 ),
        []
    );

    const fetchSuggestionsResults = useCallback(() => {
        const isTextReady = value && value.length > 2;
        if ( isTextReady ) {
            setIsLoading( true );
            trainingGateway.getIntentSuggestions({ botId, branchName: "develop", language, value })
                .then(( response ) => {
                    setSuggestions( response ? response as AssignedIntent[] : []);
                })
                .catch(( error ) => {
                    console.error( error );
                })
                .finally(() => {
                    setIsLoading( false );
                });
        }
    }, [botId, language, value]);

    useEffect(() => {
        debounceMemo( fetchSuggestionsResults );
    }, [botId, language, value, fetchSuggestionsResults, debounceMemo]);
  
    return [suggestions, isLoading] as const;
}

import { hasInput, hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import React, { FC, ReactNode } from "react";
import { Position } from "react-flow-renderer";
import FormManager from "../../forms/FormManager/FormManager";
import CardHandle from "../../handles/CardHandle/CardHandle";

import "./CardPayload.scss";

interface Props extends CardFlow {
    form?: any;
    index: number;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
}

const CardPayload: FC<Props> = ({id, type, index, form, isEditing, isLastCard, onClick, handle, payload, connections}) => {
    return (
        <div className="bot-card bot-card-payload">
            {( index > 0 && hasInput( type )) && (
                <CardHandle isConnectable={connections.input.length === 0} id={id} type="target" position={Position.Left}/>
            )}
            <div className="bot-card-type">
                <i className="card-icon fas fa-triangle"></i>
                {handle}
            </div>
            <div className="content" onClick={( e: React.MouseEvent ) => onClick?.( e )}>
                
                {form && !form.isProd && isEditing ? <FormManager {...form} /> : (
                    <div>
                        <div>{payload?.name || <em>Empty</em>}</div>
                    </div>
                )}
            </div>
            {hasOutput( type ) && (
                <CardHandle isConnectable={connections.output.length === 0} id={id} type="source" position={Position.Right}/>
            )}
        </div>
    );
};

export default CardPayload;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "../../../../../legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError } from "../../../entities/flowbuilder";
import { InputRemoveFlow, RemoveFlowResult } from "../../../interfaces/gateways/flowbuilder/FlowGateway";

export const removeFlow = createAsyncThunk<Partial<RemoveFlowResult>, InputRemoveFlow, { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError} >(
    "flowbuilder/removeFlow",
    async ( input: InputRemoveFlow, {getState, extra, rejectWithValue}) => {
        const { botId, branchName, instanceId } = getState().app.selectedConfiguration;
        const response = await extra.flowGateway?.removeFlow({...input, botId, branchName, botInstanceId: instanceId});
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }
        return response?.data as Partial<RemoveFlowResult>;
    }
);
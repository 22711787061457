import { FunctionItem, ResponseApi } from "clean-archi/core/entities/flowbuilder";
import {
    FunctionStorelessGateway,
    ICreateFunctionsInput,
    IExecuteFunctionInput,
    IFetchFunctionInput,
    IFetchFunctionsInput,
    IUpdateFunctionsInput,
} from "clean-archi/core/interfaces/gateways/flowbuilder/FunctionStorelessGateway";
import { REGEX_2XX } from "../gateways-entities";
import axiosInstance from "legacy/store/requests/axiosInstance";

export class HttpFunctionStorelessGateway implements FunctionStorelessGateway {

    async fetchFunction ( input: IFetchFunctionInput ): Promise<ResponseApi<FunctionItem>> {
        const response = await axiosInstance.get<ResponseApi<FunctionItem>>( `/api/flowbuilder/function-editor/${ input.id}`, { params: {...input} });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async fetchFunctions ( input: IFetchFunctionsInput ): Promise<ResponseApi<FunctionItem[]>> {
        const response = await axiosInstance.get<ResponseApi<FunctionItem[]>>( "/api/flowbuilder/function-editor/list", { params: {...input} });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }
    
    async createFunction ( input: ICreateFunctionsInput ): Promise<ResponseApi<FunctionItem>> {
        const response = await axiosInstance.post<ResponseApi<FunctionItem>>( "/api/flowbuilder/function-editor/", {...input}, {
            params: { branchName: input.branchName }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }
    
    async updateFunction ( input: IUpdateFunctionsInput, branchName: string ): Promise<ResponseApi<FunctionItem>> {
        const response = await axiosInstance.put<ResponseApi<FunctionItem>>( "/api/flowbuilder/function-editor/", {...input}, {
            params: { branchName }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }
    
    async deleteFunction ( input: IUpdateFunctionsInput ): Promise<ResponseApi<FunctionItem>> {
        const response = await axiosInstance.delete<ResponseApi<FunctionItem>>( `/api/flowbuilder/function-editor/${ input.id}`, {
            params: { branchName: input.branchName },
            data: {
                ...input
            }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async executeFunction ( input: IExecuteFunctionInput ): Promise<ResponseApi<Record<string, unknown>>> {
        const response = await axiosInstance.post<ResponseApi<Record<string, unknown>>>( "/api/flowbuilder/execute-function/", input, {
            params: { branchName: input.branchName }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import {isEqual} from "lodash";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classnames from "classnames";

import {getActionsToUpdateArray} from "legacy/tools/functions/functions";

class AnimatedButtonList extends Component {
    wrapperRef = null;
    timeoutArray = [];

    constructor ( props ) {
        super( props );
        this.state = {
            list: []
        };
    }


    componentDidMount () {
        document.addEventListener( "mouseup", this.handleClickOutside );
        if ( this.props.show )
            this.updateStateList( this.props.list );
    }

    componentDidUpdate ( prevProps, prevState, snapshot ) {
        if ( !prevProps.show && this.props.show ) {
            this.updateStateList( this.props.list );

        } else if ( prevProps.show && !this.props.show ) {
            this.clearTimeout();
            this.setState({list: []});

        } else if ( prevProps.show && this.props.show ) {
            if ( !isEqual( this.props.list, prevProps.list )) {
                this.updateStateList( this.props.list );
            }
        }

    }

    componentWillUnmount () {
        document.removeEventListener( "mouseup", this.handleClickOutside );
        this.clearTimeout();
    }

    clearTimeout = () => {
        this.timeoutArray.forEach( timeout => {
            clearTimeout( timeout );
        });
    };

    hide = () => {
        this.updateStateList([], this.props.onHide );
    };

    handleClickOutside = ( event ) => {
        // if click outside
        if ( this.wrapperRef && !this.wrapperRef.contains( event.target )) {
            // if animated mode, is show, and onHide is a function
            if ( this.props.show && typeof this.props.onHide === "function" )
                this.hide();
        }
    };

    updateStateList = ( newList, lastHandler = null ) => {
        const prevList = this.state.list;
        const actions = getActionsToUpdateArray( prevList, newList );

        actions.forEach(( action, index ) => {
            const timeout = setTimeout(() => {
                this.setState( oldState => {
                    const list = Array.from( oldState.list );

                    if ( action.type === "add" )
                        list.splice( action.index, 0, action.item );

                    if ( action.type === "remove" )
                        list.splice( action.index, 1 );

                    return {
                        ...oldState,
                        list
                    };
                });

            }, index * this.props.animationDelay );
            this.timeoutArray.push( timeout );

            if ( index + 1 === actions.length && typeof lastHandler === "function" ) {
                this.timeoutArray.push(
                    setTimeout( lastHandler, ( index + 1 ) * this.props.animationDelay + 100 )
                );
            }
        });
    };


    render () {
        const {collapseButton} = this.props;
        const animationClassName = `slideright${this.props.animationDelay - ( this.props.animationDelay % 50 )}`;
        return (
            <div
                ref={node => this.wrapperRef = node}
                className={classnames( "button-list", "animated", this.props.className )}
                style={this.props.style}
            >
                <TransitionGroup>
                    {this.state.list.length > 0 ? (
                        this.state.list.map(( button, index ) => (
                            <CSSTransition
                                key={button.id ? button.id : index}
                                timeout={this.props.animationDelay}
                                classNames={animationClassName}
                            >
                                {button.element}
                            </CSSTransition>
                        ))
                    ) : (
                        <CSSTransition
                            key={-1}
                            timeout={this.props.animationDelay}
                            classNames={animationClassName}
                        >
                            {collapseButton}
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        );
    }
}

AnimatedButtonList.propTypes = {
    show: PropTypes.bool,
    animationDelay: PropTypes.number,
    list: PropTypes.arrayOf( PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        element: PropTypes.element.isRequired
    })),
    collapseButton: PropTypes.element,
    style: PropTypes.object,
    className: PropTypes.string,
    onHide: PropTypes.func,
};

AnimatedButtonList.defaultProps = {
    show: true,
    animationDelay: 150
};

export default AnimatedButtonList;

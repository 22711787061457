import { PayloadAction } from "@reduxjs/toolkit";
import { Flow, FlowBuilderState } from "../../../entities/flowbuilder";
import { RemoveFlowResult } from "../../../interfaces/gateways/flowbuilder/FlowGateway";

export const removeFlowReducer = ( state: FlowBuilderState, action: PayloadAction<Partial<RemoveFlowResult>> ) => {
    
    const flowIdToRemove = action.payload.removedItems?.find( i => i.type === "RemovedFlow" )?.id;
    const flowsToUpdate = action.payload.reorderedFlows;

    if ( flowIdToRemove ) {
        state.botFlow.flows.delete( flowIdToRemove );
    }

    if ( flowsToUpdate?.length ) {
        flowsToUpdate.forEach( flow => {
            state.botFlow.flows.set(
                flow.id as string, {
                    ...state.botFlow.flows.get( flow.id as string ),
                    ...flow,
                } as Flow
            );
        });
    }

    state.botFlow.shouldRerenderNodes = true;
    state.botFlow.shouldRerenderEdges = true; 
    state.botFlow.loading = false;

};
import { PayloadAction } from "@reduxjs/toolkit";
import { FlowBuilderState, RemovedItem } from "../../../entities/flowbuilder";

export function removeItemReducer ( state: FlowBuilderState, action: PayloadAction<Partial<RemovedItem>> ) {
    state.botFlow.flows.forEach( flow => {
        const node = flow.nodes.get( action.payload.nodeId || action.payload.id as string );
        if ( !node ) return;
    
        if ( !action.payload.nodeId ) {
            flow.nodes.delete( node.id );
            return;
        }
    
        const card = node.cards.get( action.payload.id as string );
        if ( !card ) return;
    
        node.cards.delete( card.id );
    });

    state.botFlow.shouldRerenderEdges = true;
    state.botFlow.shouldRerenderNodes = true;
}

import React, { FC } from "react";
import { Handle, HandleProps } from "react-flow-renderer";

import "./CardHandle.scss";

const CardHandle: FC<HandleProps> = ({...props}) => {
    return <Handle {...props} className="card-handle" />;
};

export default CardHandle;

import React, { useMemo } from "react";

type Entry = Array<string>;

interface EventOptions {
    preventDefault?: Boolean;
    alt?: Boolean;
}

type KeyPressHandler = ( callback?: Function, options?: EventOptions ) => {
    onKeyPress( event: React.KeyboardEvent ): void;
};

function useKeyPress ( entries: Array<Entry> ): Array<KeyPressHandler> {
    return useMemo(
        () =>
            entries.map(
                ( observedKeys ) =>
                    ( callback?: Function, options: EventOptions = {}) => ({
                        onKeyPress: ( event: React.KeyboardEvent ) => {
                            if ( observedKeys.includes( event.key ) && ( !options.alt || options.alt === event.altKey )) {
                                if ( options.preventDefault === true ) event.preventDefault();
                                if ( callback ) callback( event );
                            }
                        },
                    })
            ),
        [entries]
    );
}

export default useKeyPress;

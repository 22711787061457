import React from "react";
import { get } from "lodash";
import { useParams } from "react-router";
import CodeEditor from "./CodeEditor";

export const CodeWrapper = () => {

    const params = useParams();
    const functionId = get( params, "id" );

    return (
        <CodeEditor key={functionId} />
    );
};
import { DefaultAction } from "legacy/context/contextTypes";
import { Flow, FlowNode, FlowNodeCard } from "./flowBuilderTypes";
import { Position } from "clean-archi/core/entities/flowbuilder";

export type FlowBuilderActionType = 
    "SET_FLOW_ID" |
    "SET_NODE_ID" | 
    "OPEN_CONTEXT_MENU" | 
    "CLOSE_CONTEXT_MENU" |
    "OPEN_SEARCH" | 
    "CLOSE_SEARCH"
;

export type ContextMenu = {
    open: boolean;
    position?: Position;
    nodeId?: FlowNode["id"];
    cardId?: FlowNodeCard["id"];
    connection?: {
        nodeId: string | null;
        handleId: string | null;
        handleType: string | null;
    };
    index?: string | undefined,
    isCardLocked?: boolean;
}

export type FlowBuilderSearch = {
    open: boolean;
}

export type FlowBuilderAction = DefaultAction<FlowBuilderActionType>;
export type FlowBuilderDispatch = ( action: FlowBuilderAction ) => void
export type FlowBuilderState = {
    currentFlowId: Flow["id"] | undefined,
    currentNodeId: FlowNode["id"] | undefined,
    contextMenu: ContextMenu,
    search: FlowBuilderSearch,
}

export function initFlowBuilderState (): FlowBuilderState {
    return {
        currentFlowId: undefined,
        currentNodeId: undefined,
        contextMenu: {
            open: false,
            connection: undefined,
            index: undefined,
            isCardLocked: false,
        },
        search: {
            open: false,
        }
    };
}

export function flowBuilderReducer ( state: FlowBuilderState, action: FlowBuilderAction ) {

    switch ( action.type ) {
        case "SET_FLOW_ID":
            state.currentFlowId = action.payload;
            break;
        case "SET_NODE_ID":
            state.currentNodeId = action.payload;
            break;
        case "OPEN_CONTEXT_MENU":
            state.contextMenu = {
                open: true,
                position: action.payload.position,
                nodeId: action.payload.nodeId,
                cardId: action.payload.cardId,
                connection: action.payload.connection,
                index: action.payload.index,
                isCardLocked: action.payload.isCardLocked
            };
            break;
        case "CLOSE_CONTEXT_MENU":
            state.contextMenu = {
                open: false,
            };
            break;
        case "OPEN_SEARCH":
            state.search = {
                open: true,
            };
            break;
        case "CLOSE_SEARCH":
            state.search = {
                open: false,
            };
            break;
    }

    return {...state};
}

import React, {FC, useCallback, useEffect} from "react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import SortableFlowItem from "./SortableFlowItem";
import { NodeType } from "legacy/context/flowBuilder/flowBuilderTypes";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { Flow, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import { getBotFlows } from "clean-archi/adapters/primary/view-models-generators/bot-elements/botElementsViewModels";
import { getSelectedFlow } from "../../../../view-models-generators/config/configParamsViewModels";

import "./FlowsMenu.scss";
import { editFlow } from "clean-archi/core/use-cases/flowbuilder/edit-flow/editFlowThunk";
import { enableEdgesRerender, enableNodesRerender, preselectFlow } from "../../../../../../store/flowbuilder";

interface FlowsMenuProps {
    selectedItem?: string;
    onSelectFlow: ( id: string ) => void;
    onSelectNode?: ( id: string ) => void;
    isSortable?: boolean;
    onSort?: ( source: number, destination: number ) => void;
    disabledNodes?: Array<Flow["id"] | NodeFlow["id"]>;
    disabledTypes?: NodeType[];
}

const FlowsMenu: FC<FlowsMenuProps> = ({selectedItem, onSelectFlow, onSelectNode, isSortable, onSort}) => {

    const selectedFlowId = useAppSelector( getSelectedFlow );
    const flows = useAppSelector( getBotFlows );
    const dispatch = useAppDispatch();

    const onOpen = useCallback(( id: string ) => {
        onSelectFlow( id );
    }, [onSelectFlow]);

    const onDragEnd = ( result: any ) => {
        if ( !result.destination ) {
            return;
        }

        dispatch( editFlow({ id: flows[result.source.index].id, index: result.destination.index }));
    };
    
    useEffect(() => {
        dispatch ( enableNodesRerender());
        dispatch ( enableEdgesRerender());

        const hasSelectedFlowId = flows.find(( item ) => item.id === selectedFlowId );

        if ( flows.length > 0 && !hasSelectedFlowId ) {
            dispatch( preselectFlow());
        }
    }, [dispatch, flows, selectedFlowId]);

    return (
        <div className="FlowsMenu">

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {( provided, snapshot ) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {flows
                                .sort(( a, b ) => a.index - b.index )
                                .map(( flow: Flow, index: number ) => (
                                    <Draggable key={flow.id} draggableId={flow.id} index={index}>
                                        {( provided, snapshot ) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <SortableFlowItem
                                                        flow={flow}
                                                        selectedId={selectedItem}
                                                        openedId={selectedFlowId}
                                                        onFlowSelect={onOpen}
                                                        onNodeSelect={onSelectNode}
                                                        isSortable={isSortable}
                                                        handle={<i className="fa fa-grip-vertical drag-handle" {...provided.dragHandleProps}></i>}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    );
};

export default FlowsMenu;

import { IntentItem } from "./Intent";
import { IO } from "./Connection";

export type AcmTextType = "text";
export type AcmStaticQuickReplyType = "static_quick_reply";

export interface AcmText {
    _id: string;
    ar?: string[]
    da?: string[]
    de?: string[]
    en?: string[]
    es?: string[]
    fr?: string[]
    it?: string[]
    pt?: string[]
}

export type AcmTextLanguages = "ar" | "da"| "de"| "en"| "es"| "fr"| "it"| "pt";
export const AcmTextSupportedLanguagesArray: AcmTextLanguages[] = [ "ar", "da", "de", "en", "es", "fr", "it", "pt" ];

export interface AcmTextResponseItem {
    _type: AcmTextType;
    _id: string;
    text: AcmText;
}

export interface AcmStaticQuickReplyValue {
    id: string; // QR id, needed for the flowbuilder to handle connections
    index: number;
    text: AcmText;
    intent?: IntentItem;
    connections?: IO;
}

export interface AcmStaticQuickReplyResponseItemResult {
    _id: string;
    _type: AcmStaticQuickReplyType;
    values: AcmStaticQuickReplyValue[];
}

interface IntentDataNew {
	kind: "name";
	value: string;
}

interface IntentDataExisting {
	kind: "id";
	value: string;
}

interface IntentDataNone {
	kind: "none",
	value: undefined
}

export interface AcmStaticQuickReplyValueInput extends Omit<AcmStaticQuickReplyValue, "intent"> {
    hasChanged: boolean;
    defaultIntent?: IntentItem;
    intentData: IntentDataNew | IntentDataExisting | IntentDataNone;
}

export interface AcmStaticQuickReplyResponseItemInput {
    _id: string;
    _type: AcmStaticQuickReplyType;
    values: AcmStaticQuickReplyValueInput[];
}

export type AcmStaticQuickReplyResponseItem = AcmStaticQuickReplyResponseItemResult | AcmStaticQuickReplyResponseItemInput;

export interface AcmItem {
    id: string;
    context: string;
    responseItem: AcmTextResponseItem | AcmStaticQuickReplyResponseItem;
}

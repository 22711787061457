import React, {
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    Form,
    CardFlow,
    FORMS_SPECS,
    CARDS,
} from "clean-archi/core/entities/flowbuilder";
import useClickDoubleClick from "legacy/hooks/useClickDoubleClick";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import {
    getAppConfigParams,
    getHighlightedElement,
    getIsProd,
    getSelectedItemForm,
} from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";
import {
    hideFlowBuilderEditorAction,
    showFlowBuilderEditorAction,
    setRightContainerWidthAction,
} from "legacy/store/actions/rightContainerActions";
import { useFlowBuilder } from "legacy/context/flowBuilder/FlowBuilderContext";
import { removeCard } from "clean-archi/core/use-cases/flowbuilder/remove-card/removeCardThunk";
import { FormManagerProps } from "../../forms/FormManager/FormManager";
import { initItemForm, resetItemForm } from "../../../../../../store/flowbuilder";

interface Props {
  CardComponent: Function;
  handle?: ReactNode;
  nodeId: string;
  card: CardFlow;
  index: number;
  isLastCard: boolean;
  nodeCardsTypes: CardFlow["type"][];
}

const Card: FC<Props> = ({
    nodeId,
    CardComponent,
    card,
    index,
    isLastCard,
    handle,
    nodeCardsTypes,
}) => {
    // console.log(card);

    const { cardId, spec, isEditing: initialIsEditing }: Form = useAppSelector( getSelectedItemForm );
    const [isEditing, setIsEditing] = useState( initialIsEditing );
    const { openContextMenu, contextMenu } = useFlowBuilder();
    const { botId, botInstanceId, branchName } = useAppSelector( getAppConfigParams );
    const isProd = useAppSelector( getIsProd );
    const dispatch = useAppDispatch();
    const highlightedElement = useAppSelector( getHighlightedElement );
    const isHighlighted = useMemo(() => highlightedElement?.cardId === card.id, [card.id, highlightedElement?.cardId]);

    useEffect(() => {
        setIsEditing(( cardId === card.id && spec === FORMS_SPECS.Card ));
    }, [card.id, cardId, spec]);

    const isSidebarFormOpen = useMemo(
        () => cardId === card.id && spec === FORMS_SPECS.Panel,
        [cardId, card, spec]
    );

    const onCardContextMenu = useCallback(
        ( e: React.MouseEvent ) => {
            e?.preventDefault();
            e?.stopPropagation();
            const containerDomRect = e.currentTarget
                .closest( ".FlowBuilder__Flow" )
                ?.getBoundingClientRect();
            if ( containerDomRect ) {
                openContextMenu(
                    { x: e.pageX - containerDomRect.x, y: e.pageY - containerDomRect.y },
                    nodeId,
                    card.id,
                    undefined,
                    undefined,
                    card.isLocked
                );
            }
        },
        [card.id, nodeId, openContextMenu, card.isLocked]
    );

    const dispatchForm = useCallback(
        ( spec: FORMS_SPECS ) =>
            dispatch(
                initItemForm({
                    cardId: card.id,
                    nodeId: nodeId,
                    spec,
                    type: card.type,
                })
            ),
        [card, dispatch, nodeId]
    );

    const showPanelForm = useCallback(() => {
        dispatchForm( FORMS_SPECS.Panel );
        dispatch( showFlowBuilderEditorAction());
        dispatch(
            setRightContainerWidthAction( card.type === CARDS.Function ? 850 : 450 )
        );
    }, [card.type, dispatch, dispatchForm]);

    const startEditingOnPanelForm = useCallback(() => {
        if ( isEditing ) return;
        showPanelForm();
    }, [isEditing, showPanelForm]);

    const startEditingOnCardForm = useCallback(() => {
        if ( isEditing ) return;
        setIsEditing( true );
        dispatchForm( FORMS_SPECS.Card );
        dispatch( hideFlowBuilderEditorAction());
    }, [dispatch, dispatchForm, isEditing]);

    useEffect(() => {
        if ( cardId === card.id && spec === FORMS_SPECS.Panel ) {
            showPanelForm();
        }
    }, [spec, cardId, card.id, showPanelForm, isEditing]);

    const resetForm = useCallback(() => {
        setIsEditing( false );
        dispatch( resetItemForm());
    }, [dispatch]);

    const onCardRemove = useCallback(() => {
        dispatch(
            removeCard({
                id: card.id,
                nodeId,
                botId,
                botInstanceId,
                branchName,
            })
        );
        resetForm();
    }, [botId, botInstanceId, branchName, card.id, dispatch, nodeId, resetForm]);

    const onClickDoubleClick = useClickDoubleClick(
        startEditingOnPanelForm,
        startEditingOnCardForm,
        300,
        true
    );

    const form: FormManagerProps = useMemo(
        () => ({
            id: card.id,
            isProd,
            nodeId,
            onClose: () => resetForm(),
            onRemove: onCardRemove,
            spec: FORMS_SPECS.Card,
            type: card.type,
        }),
        [card.id, card.type, nodeId, onCardRemove, resetForm, isProd]
    );

    return (
        <div>
            <div
                className={`${isSidebarFormOpen ? "bot-card-form-open" : ""} ${
                    card.isLocked ? "bot-card-is-locked" : ""
                } ${
                    contextMenu.cardId === card.id && contextMenu.index === undefined
                        ? "context-open"
                        : ""
                } ${isHighlighted ? "bot-card-highlighted" : ""}`}
                onContextMenu={( e: React.MouseEvent ) => onCardContextMenu?.( e )}
            >
                <CardComponent
                    {...card}
                    nodeId={nodeId}
                    handle={handle}
                    form={form}
                    index={index}
                    isLastCard={isLastCard}
                    isEditing={isEditing}
                    onClick={onClickDoubleClick}
                    nodeCardsTypes={nodeCardsTypes}
                />
                {card.isLocked && <i className="fa fa-lock bot-card-lock-icon"></i>}
            </div>
        </div>
    );
};

export default Card;

import textCodes     from "legacy/constants/translations/textCodes";
import languageCodes from "legacy/constants/translations/languageCodes";

const createTextCodesPropTypesSchema = isRequired => ( props, propName, componentName ) => {
    if ( props[propName] === null ) {
        if ( isRequired )
            return new Error( `Prop \`${propName}\` supplied to` +
                ` \`${componentName}\`. Is missing.` );
    } else if ( !Object.values( textCodes ).includes( props[propName])) {
        return new Error(
            `Invalid prop \`${propName}\` supplied to` +
            ` \`${componentName}\`. Validation failed.`,
        );
    }
};
export const textCodesSchema         = createTextCodesPropTypesSchema( false );
textCodesSchema.isRequired = createTextCodesPropTypesSchema( true );


const createLangageCodesPropTypesSchema = isRequired => ( props, propName, componentName ) => {
    if ( !props[propName]) {
        if ( isRequired )
            return new Error( `Prop \`${propName}\` supplied to` +
                ` \`${componentName}\`. Is missing.` );
    } else if ( !Object.values( languageCodes ).includes( props[propName])) {
        return new Error(
            `Invalid prop \`${propName}\` supplied to` +
            ` \`${componentName}\`. Validation failed.`,
        );
    }
};
export const languageCodesSchema        = createLangageCodesPropTypesSchema( false );
languageCodesSchema.isRequired = createLangageCodesPropTypesSchema( true );

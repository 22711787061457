// The country code (w/ 2 code) can be found here : https://www.iso.org/obp/ui/#search (ISO 3166-1-alpha-2 code of a country)
const languages = {
    da: { // bad key!
        textCode: "LANGUAGE_DANISH",
        name: "Danish",
        flag: "🇩🇰",
        countryCode: "dk"
    },
    de: {
        textCode: "LANGUAGE_GERMAN",
        name: "German",
        flag: "🇩🇪",
        countryCode: "de"
    },
    en: {
        textCode: "LANGUAGE_ENGLISH",
        name: "English",
        flag: "🇬🇧",
        countryCode: "gb"
    },
    es: {
        textCode: "LANGUAGE_SPANISH",
        name: "Spanish",
        flag: "🇪🇸",
        countryCode: "es"
    },
    fr: {
        textCode: "LANGUAGE_FRENCH",
        name: "French",
        flag: "🇫🇷",
        countryCode: "fr"
    },
    it: {
        textCode: "LANGUAGE_ITALIAN",
        name: "Italian",
        flag: "🇮🇹",
        countryCode: "it",
    },
    pt: {
        textCode: "LANGUAGE_PORTUGUESE",
        name: "Portuguese (European)",
        flag: "🇵🇹",
        countryCode: "pt"
    },
    // gb: {
    //     name: "English (Great Britain)",
    //     flag: "🇬🇧",
    //     countryCode: "gb"
    // },
    // us: {
    //     name: "English (USA)",
    //     flag: "🇺🇸",
    //     countryCode: "us"
    // }
};
export const defaultLanguageCode = "fr";

export default languages;

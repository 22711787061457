import {TranslationManager} from "translation-manager";
import textCodes                            from "legacy/constants/translations/textCodes";
import {specialCharRegex, emptyStringRegex} from "legacy/constants/appConstants/regex";

const profileFormValidator = {
    code: {
        validate: [
            {
                validator: function ( value ) {
                    return !emptyStringRegex.test( value ) && !specialCharRegex.test( value );
                },
                message: TranslationManager.getText( textCodes.CODE_CANT_BE_EMPTY_AND_CAN_NOT_CONTAIN_SPECIAL_CHARACTER )
            }
        ]
    },
    label: {
        validate: [
            {
                validator: function ( value ) {
                    return !emptyStringRegex.test( value );
                },
                message: TranslationManager.getText( textCodes.LABEL_CANT_BE_EMPTY )
            }
        ]
    },
};


export default profileFormValidator;

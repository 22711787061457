import { PayloadAction } from "@reduxjs/toolkit";
import { CardFlow, ConnectionItem, FlowBuilderState, IO, NodeFlow } from "../../../entities/flowbuilder";

export function updateItemConnectionsReducer ( state: FlowBuilderState, action: PayloadAction<Partial<ConnectionItem[]>> ) {
    const flow = state.botFlow.flows.get( state.botFlow.selectedFlowId as string );
    if ( !flow ) return;

    action.payload.forEach( connectionItem => {
        if ( !connectionItem ) return;
        
        let element: NodeFlow | CardFlow | undefined;
        element = flow.nodes.get( connectionItem?.nodeId || connectionItem.id );
        if ( !element ) return;

        if ( connectionItem.nodeId ) {
            element = element.cards.get( connectionItem.id );
        }
        if ( !element ) return;

        element.connections = connectionItem.connections as IO;
    });

    // Request a rerender
    state.botFlow.shouldRerenderEdges = true;
}
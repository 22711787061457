import React, {FC, useContext, useState, useEffect, createContext, useCallback} from "react";
import cx from "classnames";
import Loader from "legacy/components/Loader/Loader";
import {useHistory} from "react-router";
import { Text }    from "translation-manager-react";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import textCodes from "legacy/constants/translations/textCodes.json";
import routes from "legacy/constants/routes";
import { getIsProd, getSelectedFunction } from "../../view-models-generators/config/configParamsViewModels";
import ModalCreateFunction from "./ModalCreateFunction/ModalCreateFunction";
import FunctionsMenu from "../flowbuilder/menus/FunctionsMenu/FunctionsMenu";
import { selectFunction } from "../../../../store/flowbuilder";

const CodeSidebarContext = createContext<{
    rerenderList: boolean; 
    setRerenderList: ( value: boolean ) => void;
        } | undefined>( undefined );

export function CodeSidebarContextProvider ({children}: {children: React.ReactNode}) {
    
    const [rerenderList, setRerenderList] = useState( false );

    const contextValues = {
        rerenderList,
        setRerenderList,
    };

    return (
        <CodeSidebarContext.Provider value={contextValues}>
            {children}
        </CodeSidebarContext.Provider>
    );
}

export function useCodeSiderbar () {
    const context = useContext( CodeSidebarContext );
    if ( context === undefined ) {
        throw new Error( "useCodeSiderbar must be used within a CodeSidebarContextProvider" );
    }
    return context;
}


const CodeSidebar: FC<{isSystem: boolean}> = ({isSystem}) => {
    const history = useHistory();
    const [isLoading] = useState( false );
    const [isExpanded] = useState( true );
    const [isCreateFunctionModalOpen, setIsCreateFunctionModalOpen] = useState( false );

    // Using RTK slices and types hooks
    const dispatch = useAppDispatch();
    const selectedFunctionId = useAppSelector( getSelectedFunction );
    const isProd = useAppSelector( getIsProd );

    const pushCodeEditor = useCallback(( id ) => {
        if ( isSystem ) {
            history.push( routes.systemFunctionsEditor.buildPath({id}));
        } else {
            history.push( routes.codeEditor.buildPath({id}));
        }      
    }, [history, isSystem]);

    useEffect(() => {
        if ( !selectedFunctionId && history.location.pathname.endsWith( "/code" )) {
            // 
        } else if ( selectedFunctionId ) {           
            pushCodeEditor( selectedFunctionId );
        }
    }, [selectedFunctionId, history, dispatch, history.location.pathname, pushCodeEditor]);

    const changeFunction = ( id: string ) => {
        pushCodeEditor( id );
        dispatch( selectFunction( id ));
    };

    const openCreateModal = () => {
        setIsCreateFunctionModalOpen( true );
    };

    const closeCreateModal = () => {
        setIsCreateFunctionModalOpen( false );
    };

    const onCreateSuccess = ( newId: any ) => {
        changeFunction( newId );
        closeCreateModal();
    };

    return (
        <CodeSidebarContextProvider>
            <div
                className={cx( "Code__Sidebar", { "Code__Sidebar--expanded": isExpanded })}
            >
                <Loader loading={isLoading} className={"overlay"} />
                <FunctionsMenu
                    selectedItem={selectedFunctionId}
                    onSelect={changeFunction}
                    isSortable={true}
                    isSystem={isSystem}
                />
                {!isProd && ( <div className="btn-group">
                    <button className="Code__Sidebar_addFunction flex-grow-1" onClick={openCreateModal}>
                        <i className="fa fa-plus-circle"></i>
                        <Text
                            className={cx( "Code__Sidebar_addFunction_text", { "Code__Sidebar_addFunction_text--expanded": isExpanded })}
                            textCode={textCodes.ADD_FUNCTION}
                        />
                    </button>
                </div> )}
                <ModalCreateFunction
                    onHide={closeCreateModal}
                    onSuccess={onCreateSuccess}
                    isVisible={isCreateFunctionModalOpen}
                    isSystem={isSystem}
                />
            </div>
        </CodeSidebarContextProvider>
    );
};

export default CodeSidebar;

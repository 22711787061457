import { hasInput, hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import React, { FC, ReactNode, useCallback } from "react";
import { Position } from "react-flow-renderer";
import FormManager from "../../forms/FormManager/FormManager";
import CardHandle from "../../handles/CardHandle/CardHandle";

import "./CardCase.scss";

interface Props extends CardFlow {
    form?: any;
    index: number;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
}

const CardCase: FC<Props> = ({id, type, index, form, isEditing, isDefault, onClick, handle, case: caseItem, connections}) => {

    const onCardClick = useCallback(( e: React.MouseEvent ) => {
        if ( !isDefault ) {
            onClick?.( e );
        }
    }, [isDefault, onClick]);

    return (
        <div className="bot-card bot-card-case">
            {( index > 0 && hasInput( type )) && (
                <CardHandle isConnectable={connections.input.length === 0} id={id} type="target" position={Position.Left}/>
            )}
            <div className="bot-card-type">
                <i className={`card-icon fas fa-caret${isDefault ? "-square" : ""}-right`}></i>
                {handle}
            </div>
            <div className="content" onClick={onCardClick}>
                
                {form && !form.isProd && isEditing ? <FormManager {...form} /> : (
                    <div>
                        <div>{caseItem || <em>Empty</em>}</div>
                    </div>
                )}
            </div>
            {hasOutput( type ) && (
                <CardHandle isConnectable={connections.output.length === 0} id={id} type="source" position={Position.Right}/>
            )}
        </div>
    );
};

export default CardCase;

import React, { useEffect } from "react";
import FrameInfo from "./FrameInfo";
import { getTransfersNumber } from "clean-archi/core/use-cases/statistics/transfers-number/getTrasfersNumberThunk";
import useFrameInfo from "../hooks/framInfoHook";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import { kpiColors } from "../config/chartsConfig";

const TransfersNumber = () => {
    const { dispatch, frameInfoStoreData, selectedConfiguration, statsDates } =
      useFrameInfo({ frameInfoProp: "TransfersNumber" });

    const infoNumberOfTransfers = {
        title: TranslationManager.getText( textCodes.NUMBER_OF_TRANSFERS ),
        number: frameInfoStoreData ? frameInfoStoreData.toString() : "0",
        // percentage: "4%",
        positiveEvolution: false,
        icon: "las la-cube",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
    };
 
    useEffect(() => {
        dispatch(
            getTransfersNumber({
                startDate: statsDates.startDate,
                endDate: statsDates.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: selectedConfiguration.branchName,
                perDay: true,
            })
        );
    }, [dispatch, selectedConfiguration.branchName, selectedConfiguration.instanceId, statsDates.endDate, statsDates.startDate]);
    
    return <FrameInfo {...infoNumberOfTransfers} />;
};
export default TransfersNumber;

import { useEffect, useState } from "react";
import { CARDS, FORMS_SPECS } from "clean-archi/core/entities/flowbuilder";
import { AppConfiguration, FormContract } from "./form-manager-entities";

const useFormContract = ( configuration: AppConfiguration, type: CARDS, spec: FORMS_SPECS, id?: string ): FormContract | undefined => {
    const [formSpec, setFormContract] = useState<FormContract | undefined>();

    useEffect(() => {
        if ( !type ) {
            setFormContract( undefined );
            return;
        }

        import( `../${type}Form/${type}${spec}FormContract.ts` ).then(( module ) => {
            if ( !module?.default ) {
                setFormContract( undefined );
                return;
            }

            const moduleSpec: FormContract = typeof module?.default === "function"
                ? module?.default( configuration )
                : module?.default;

            setFormContract( moduleSpec );
        });
    }, [configuration, spec, type]);

    return formSpec;
};

export default useFormContract;

import { TrainingFilters } from "../../../../core/entities/training/TrainingFilters";
import { TrainMessageData } from "../../../../core/entities/training/TrainMessageData";
import { TrainingGateway } from "../../../../core/interfaces/gateways/training/TrainingGateway";
import axiosInstance from "../../../../../legacy/store/requests/axiosInstance";
import { IntentSuggestionsFilters } from "../../../../core/entities/training/IntentSuggestionFilters";

export class HttpTrainingGateway implements TrainingGateway {

    async getIntentSuggestions ( filters: IntentSuggestionsFilters ) {
        try {
            const response = await axiosInstance.get(
                "/api/botcore/api/training/intent-suggestions", {
                    params: filters
                });
            return response.data.result;
        } catch ( error: any ) {
            console.log( error );
        }
    }


    async getMessages ( filters: TrainingFilters ) {
        try {
            const response = await axiosInstance.get(
                "/api/botcore/api/training/messages", {
                    params: filters
                });
            return response.data.result;
        } catch ( error: any ) {
            throw new Error( error.response.data );
        }
    }

    async ignoreMessages ( messageIds: string[]) {
        try {
            const response = await axiosInstance.patch(
                "/api/botcore/api/training/ignore",
                { messageIds }
            );
            return response.data.result;
        } catch ( error: any ) {
            throw new Error( error.response.data );
        }
    }

    async trainMessage ( data: TrainMessageData ) {
        try {
            const response = await axiosInstance.patch(
                "/api/botcore/api/training/train",
                data
            );
            return response.data.result;
        } catch ( error: any ) {
            throw new Error( error.response.data );
        }
    }

    async cancelIgnoredMessages ( messageIds: string[]) {
        try {
            const response = await axiosInstance.patch(
                "/api/botcore/api/training/cancel-ignore",
                { messageIds }
            );
            return response.data.result;
        } catch ( error: any ) {
            throw new Error( error.response.data );
        }
    }

    async setMessagesToPending ( messageIds: string[]) {
        try {
            const response = await axiosInstance.patch(
                "/api/botcore/api/training/pending",
                { messageIds }
            );
            return response.data.result;
        } catch ( error: any ) {
            throw new Error( error.response.data );
        }
    }

}
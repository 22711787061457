import { buildConnectionPayload } from "clean-archi/core/use-cases/flowbuilder/connect/buildConnectionPayload";
import React, { FC, useCallback } from "react";
import { EdgeProps, getBezierPath, getEdgeCenter } from "react-flow-renderer";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { removeConnection } from "clean-archi/core/use-cases/flowbuilder/remove-connection/removeConnectionThunk";
import { getAppConfigParams, getIsProd } from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";
import store from "legacy/store";

import "./ActionEdge.scss";

const foreignObjectSize = 40;

interface Props extends Omit<EdgeProps, "data"> {
    data?: {    
        source: string;
        target: string;
        sourceHandle?: string;
        targetHandle?: string;
    },
}

const ActionEdge: FC<Props> = ({ 
    id,
    data,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}) => {
    const dispatch = useAppDispatch();
    const { botId, branchName, botInstanceId } = useAppSelector( getAppConfigParams );
    const isProd = useAppSelector( getIsProd );

    const onDelete = useCallback(( e ) => {
        e.stopPropagation();
        const connectionPayload = buildConnectionPayload({
            source: data?.source as string,
            target: data?.target as string,
            sourceHandle: data?.sourceHandle as string,
            targetHandle: data?.targetHandle as string,
        }, store );
        dispatch( removeConnection({
            from: connectionPayload.from,
            to: connectionPayload.to,
            botId,
            branchName,
            botInstanceId
        }));
    }, [data, dispatch, botId, branchName, botInstanceId]);
    
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    return (
        <g className="action-edge">
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="btn-delete-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                {!isProd && ( <button className="btn-delete" onClick={onDelete} title="Supprimer">
                    ×
                </button> )}
            </foreignObject>
        </g>
    );
};

export default ActionEdge;

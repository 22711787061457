import axios from "legacy/store/requests/axiosInstance";
import { DefaultAction, DefaultDispatch } from "./contextTypes";

export const defaultApiRequestDispatcher = <ActionType, Payload>( 
    method: "GET"|"POST"|"PUT", 
    url: string, 
    payload: Payload, 
    actionBaseName: ActionType, 
    dispatch: DefaultDispatch<DefaultAction>,
    dataFilter?: ( data: any ) => void,
) => {
    dispatch({type: `${actionBaseName }_TRIGGER`, payload});
    axios.request({url, method, params: payload})
        .then( res => {
            let data = res.data.result;
            if ( dataFilter ) data = dataFilter( data );
            dispatch({
                type: `${actionBaseName }_SUCCESS`,
                payload: data,
            });
        })
        .catch( error => {
            const result = error;
            dispatch({
                type: `${actionBaseName }_FAILURE`,
                payload: {
                    error: true,
                    message: result,
                }
            });
        });
};

export const defaultApiRequestReducer = <ActionType, State>( actionBaseName: ActionType, state: State, action: DefaultAction ) => {
    switch ( action.type ) {
        case `${actionBaseName}_TRIGGER`:
            return {
                ...state,
                loading: true,
            };
        case `${actionBaseName}_SUCCESS`:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case `${actionBaseName}_FAILURE`:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        default:
            return state;
    }
};
import { CARDS, NODES, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import { addCard } from "clean-archi/core/use-cases/flowbuilder/add-card/addCardThunk";
import React, { FC } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useAppDispatch } from "legacy/store/typedHooks";

import "./AddActionCardMenu.scss";

interface props {
    nodeId: NodeFlow["id"];
    nodeType: NodeFlow["type"];
    index?: number;
}

const AddActionCardMenu: FC<props> = ({nodeId, nodeType, index, ...props}) => {

    const dispatch = useAppDispatch();

    const actionAddCard = ( type: CARDS ) => {
        dispatch( addCard({ nodeId, type, index }));
    };

    const addContentTextCard = () => {
        actionAddCard( CARDS.ContentText );
    };

    const DefaultMenuOptions: Function = () => (
        <DropdownButton
            className="btn-add-card"
            as={ButtonGroup}
            title={<i className="fa fa-plus"></i>}
        >
            <Dropdown.Item onClick={addContentTextCard}>Add a Text</Dropdown.Item>
        </DropdownButton>
    );

    switch ( nodeType ) {
        default: return DefaultMenuOptions();
    }
};

export default AddActionCardMenu;

import { createRoutine } from "redux-saga-routines";
import { createAction }  from "@reduxjs/toolkit";



// export const removeTagAggregationsRoutine     = createRoutine( "TAGAGGREG/removeTagAggregations" );
export const updateTagAggregationRoutine            = createRoutine( "TAGGAGGREG/updateTagAggregation" );
export const removeTagAggregationsRoutine           = createRoutine( "TAGAGGREG/removeTagAggregation" );


/** ******** TAG AGGREGATIONS LIST *************/
export const fetchTagAggregationListRoutine         = createRoutine( "TAGAGGREG/fetchTagAggregationList" );
export const setTagAggregationsListFilterAction     = createAction( "TAGAGGREG/setTagAggregationsListFilter" );
export const setTagAggregationsListCheckedIdsAction = createAction( "TAGAGGREG/setTagAggregationsListCheckedIds" );

/** ******** TAG AGGREGATIONS FORM ************ */

export const mountTagAggregationsFormRoutine     = createRoutine( "TAGAGGREG/mountTagAggregationsForm" );
export const unMountTagAggregationsFormAction    = createAction( "TAGAGGREG/unMountTagAggregationsForm" );
export const saveTagAggregationsFormRoutine      = createRoutine( "TAGAGGREG/saveTagAggregationsForm" );
export const updateTagAggregationsFormAction     = createAction( "TAGAGGREG/updateTagAggregationsForm" );
export const setTagAggregationsFormErrorsAction  = createAction( "TAGAGGREG/setTagAggregationsFormErrorsAction" );
export const setTagAggregationsFormAction        = createAction( "TAGAGGREG/setTagAggregationsFormAction" );
export const setInitialTagAggregationsFormAction = createAction( "TAGAGGREG/setInitialTagAggregationsFormAction" );

/** ******** BROADCAST ACTIONS *************/
export const broadcastDeleteTagAggregationsAction        = createAction( "TAGAGGREG/broadcastDeleteTagAggregations" );
export const broadcastCreateTagAggregationsAction        = createAction( "TAGAGGREG/broadcastCreateTagAggregations" );
export const broadcastUpdateTagAggregationsAction        = createAction( "TAGAGGREG/broadcastUpdateTagAggregations" );
export const broadcastSendTagAggregationsStoryStepAction = createAction( "TAGAGGREG/broadcastSendTagAggregationsStoryStep" ); // {result, index, tagAggregationsId}

import { BotFlowGateway } from "clean-archi/core/interfaces/gateways/flowbuilder/BotFlowGateway";
import { InputFetchBotFlow, ResponseApi } from "clean-archi/core/use-cases/flowbuilder/fetch-bot-flow/fetchBotFlowThunk";
import { BotFlow, CardFlow, CARDS, Flow, NodeFlow, NODES } from "../../../../../core/entities/flowbuilder";
import { BotFlowApiReturnType, REGEX_2XX } from "../gateways-entities";
import axiosInstance from "legacy/store/requests/axiosInstance";
import { isEmpty } from "lodash";
import { InputCreateBotFlow } from "clean-archi/core/use-cases/flowbuilder/init-bot-flow";
import { transformToSystemFnCard } from "../transform-helpers";

export class HttpBotFlowGateway implements BotFlowGateway {

    async fetchBotFlow ( input: InputFetchBotFlow ): Promise<ResponseApi<BotFlow>> {
        const response = await axiosInstance.get<ResponseApi<BotFlowApiReturnType>>( "/api/flowbuilder/botflow", {
            params: {
                botId: input.botId,
                branchName: input.branchName,
            }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? {} : this.transformToBotFlow( data as BotFlowApiReturnType ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };

    }

    async createBotFlow ( input: InputCreateBotFlow, branchName: string ): Promise<ResponseApi<BotFlow>> {
        const response = await axiosInstance.post<ResponseApi<BotFlowApiReturnType>>( "/api/flowbuilder/botflow", {
            botId: input.botId,
            branchName: input.branchName,
            botInstanceId: input.botInstanceId,
        }, {
            params: {
                branchName: branchName,
            }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: this.transformToBotFlow( data as BotFlowApiReturnType ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    transformToBotFlow ( data: BotFlowApiReturnType ): Partial<BotFlow> {
        let numberOfNodes = 0;
        const flows = new Map<Flow["id"], Flow>();
        for ( const flow of data.flows ) {
            const nodes = new Map<NodeFlow["id"], NodeFlow>();
            for ( const node of flow.nodes ) {
                numberOfNodes++;
                const cards = new Map<CardFlow["id"], CardFlow>();
                for ( const card of node.cards ) {
                    cards.set( card.id, card.type === CARDS.Tag ? transformToSystemFnCard( card ) : card );
                }
                nodes.set( node.id, {
                    id: node.id,
                    name: node.name,
                    flowId: flow.id,
                    position: node.position,
                    type: node.type as NODES,
                    connections: node.connections,
                    cards: cards
                });
            }
            flows.set( flow.id, {
                id: flow.id,
                index: flow.index,
                name: flow.name,
                nodes: nodes
            });
        }

        return {
            id: data.id,
            botId: data.botId,
            branchName: data.branchName,
            flows: flows,
            numberOfNodes,
        };
    }

}


import { createAsyncThunk } from "@reduxjs/toolkit";
import { NodeFlow, ApiError, IO, Position, NODES } from "clean-archi/core/entities/flowbuilder";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export interface InputAddNodeThunk {
  position: Position;
  type: NODES;
  connections?: IO;
  defaultCardType?: string;
}

export const addNode = createAsyncThunk<
    NodeFlow, InputAddNodeThunk, {
        state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError
    } >(
        "flowbuilder/addNode",
        async ( input: InputAddNodeThunk, {getState, extra, rejectWithValue}) => {
            const { botId, branchName, instanceId: botInstanceId } = getState().app.selectedConfiguration;
            const { selectedFlowId, numberOfNodes} = getState().flowbuilder.botFlow;
            const name = `${input.type}_${numberOfNodes}`;
            const response = await extra.nodeGateway?.addNode(
                {
                    ...input,
                    flowId: selectedFlowId as string,
                    name,
                    botId,
                    branchName,
                    botInstanceId
                });
            if ( !response?.data || response?.error ) {
                return rejectWithValue({
                    message: response?.message as string,
                    error: response?.error as string,
                });
            }
            return response?.data as NodeFlow;
        }
    );

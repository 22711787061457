import { hasInput, hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import React, { FC, ReactNode } from "react";
import { Position } from "react-flow-renderer";
import CardHandle from "../../handles/CardHandle/CardHandle";

import "./CardOutput.scss";

interface Props extends CardFlow {
    form?: any;
    index: number;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
}

const CardOutput: FC<Props> = ({id, type, index, form, isEditing, isLastCard, onClick, handle, tag, connections}) => {
    return (
        <div className="bot-card bot-card-output">
            {( index > 0 && hasInput( type )) && (
                <CardHandle isConnectable={connections.input.length === 0} id={id} type="target" position={Position.Left}/>
            )}
            
            {hasOutput( type, isLastCard ) && (
                <CardHandle isConnectable={connections.output.length === 0} id={id} type="source" position={Position.Right}/>
            )}
        </div>
    );
};

export default CardOutput;

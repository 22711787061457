import PropTypes      from "prop-types";
import { formErrors, translationsText } from "legacy/constants/propTypes/appPropTypes";

export const chartLineValue = PropTypes.arrayOf( PropTypes.shape({
    id:          PropTypes.string,
    visible:     PropTypes.bool,
    description: PropTypes.string,
    color:       PropTypes.string,
    data:        PropTypes.arrayOf( PropTypes.shape({
        x: PropTypes.string,
        y: PropTypes.number,
    })),
}));


export const objectChart = {
    title:       PropTypes.string,
    description: PropTypes.string,
    visible:     PropTypes.bool,
    xAxis:       PropTypes.shape({
        legend: translationsText,
    }),
    yAxis:       PropTypes.shape({
        legend: translationsText,
    }),
    values:      chartLineValue,
};
export const chart       = PropTypes.shape( objectChart );

/** ***** HEADERS PROPTYPES *******/
export const headers = PropTypes.shape({
    isSystem:      PropTypes.bool,
    botInstanceId: PropTypes.string,
    _id:           PropTypes.string,
    values:        PropTypes.arrayOf( PropTypes.shape({
        label:       PropTypes.string,
        key:         PropTypes.string.isRequired,
        visible:     PropTypes.bool,
        description: PropTypes.string,
        category:    PropTypes.string,
    })),
    createdAt:     PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf( Date ),
    ]),
    updatedAt:     PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf( Date ),
    ]),
});

export const editedHeadersNewRow = PropTypes.shape({
    label:       PropTypes.string,
    key:         PropTypes.string,
    visible:     PropTypes.bool,
    description: PropTypes.string,
    category:    PropTypes.string,
});

export const editedHeaders = PropTypes.shape({
    headers,
    newRow: editedHeadersNewRow,
});

export const editedHeadersErrors = PropTypes.shape({
    values: PropTypes.object,
    newRow: formErrors,
});

export const tagAggregation = PropTypes.shape({
    _id: PropTypes.string,
    botInstanceId: PropTypes.number,
    name: PropTypes.string,
    codename: PropTypes.string,
    comment: PropTypes.string, 
    aggregationFormulaType: PropTypes.string,
    aggregationFormula: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
});
import React, { FC } from "react";
import cx from "classnames";

import "./InfoTooltip.scss";

interface InfoTooltipProps {
    className?: string;
    text: string;
    icon?: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
    className = "",
    text,
    icon = "question-circle",
}) => {
    

    return (
        <div className={cx( "info-tooltip", { [className]: className })}>
            <div className="info-tooltip__icon">
                <i className={`fas fa-${icon}`}></i>
            </div>
            <div className="info-tooltip__popover">{text}</div>
        </div>
    );
};

export default InfoTooltip;

import { NODES } from "./Node";
import { CARDS } from "./Card";

/* eslint-disable no-unused-vars */
export enum FLOW_SPECIAL_ELEMENT {
    ContentQuickReplySingleReply = "ContentQuickReplySingleReply"
}

export type FLOW_ELEMENT_TYPE = NODES | CARDS | FLOW_SPECIAL_ELEMENT;

export interface IO {
    input: IOFlowRef[];
    output: IOFlowRef[];
}

export interface IOFlowRef {
    id: string;
    type: FLOW_ELEMENT_TYPE;
    flowId?: string;
    nodeId?: string; // If connection to/from CARD
    cardId?: string;
}

export interface ConnectionItem extends Omit<IOFlowRef, "flowId"> {
    connections?: IO;
}

export interface Connection {
    from: ConnectionItem;
    to: ConnectionItem;
}

export enum EDGES {
    Action = "Action",
}

export interface SpecialConnectionId {
    id: string;
    cardId: string;
}

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DefaultView from "legacy/pages/dashboard/ViewDefault";
import CardTagAggregationsList from "legacy/pages/stats/CardTagAggregationsList.jsx";
import TableTagAggregations from "legacy/pages/stats/TableTagAggregations";
import {
    setTagAggregationsListFilterAction, 
    fetchTagAggregationListRoutine, 
    removeTagAggregationsRoutine,
    setTagAggregationsListCheckedIdsAction
} from "legacy/store/actions/tagAggregationsActions.js";


/**
 * TableTagAggregations
 */
const mapStateToProps_TableTagAggregations    = ( state ) => ({
    filter:                    state.listTagAggregations.filter,
    tagAggregations:           state.listTagAggregations.displayList,
    tagAggregationsCheckedIds: state.listTagAggregations.checkedIds,
});
const mapDispatchToProps_TableTagAggregations = dispatch => ({
    getTagAggregationsList:       bindActionCreators( fetchTagAggregationListRoutine, dispatch ),
    removeTagAggregations:        bindActionCreators( removeTagAggregationsRoutine, dispatch ),
    setTagAggregationsCheckedIds: bindActionCreators( setTagAggregationsListCheckedIdsAction, dispatch ),
});
const ConnectedTableTagAggregations = connect( mapStateToProps_TableTagAggregations, mapDispatchToProps_TableTagAggregations )( TableTagAggregations );


/**
 * CardTagAggregationsList
 */
const mapStateToProps_CardTagAggregationsList = ( state ) => ({
    botInstanceId:             state.app.selectedConfiguration.instanceId,
    filter:                    state.listTagAggregations.filter,
    tagAggregationsCheckedIds: state.listTagAggregations.checkedIds,
    status:                    state.listTagAggregations.listStatus,
    TagAggregationTable:       ConnectedTableTagAggregations,
});

const mapDispatchToProps_CardCardTagAggregationsList = dispatch => ({
    setFilter:               bindActionCreators( setTagAggregationsListFilterAction, dispatch ),
    getTagAggregationsList:  bindActionCreators( fetchTagAggregationListRoutine, dispatch ),
    removeTagAggregations:   bindActionCreators( removeTagAggregationsRoutine, dispatch ),
    // startStory:              bindActionCreators( startIntegrationTestStoryRoutine, dispatch ),
});
const ConnectedCardTagAggregationsList = connect( mapStateToProps_CardTagAggregationsList, mapDispatchToProps_CardCardTagAggregationsList )( CardTagAggregationsList );

export default () => (
    <DefaultView>
        <ConnectedCardTagAggregationsList></ConnectedCardTagAggregationsList>
    </DefaultView>
);
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError } from "../../../entities/flowbuilder";
import { Message } from "../../../entities/training/Message";

export const loadMessages = createAsyncThunk<
  {messages: Message[], nbOfPages: number},
  void, {
  state: MainReducerState,
  extra: Partial<Dependencies>,
  rejectValue: ApiError,
}>(
    "training/loadMessages",
    async ( _, { getState, extra, rejectWithValue }) => {
        try {
            const { trainingGateway } = extra;
            const { botId } = getState().app.selectedConfiguration;
            const { filters } = getState().training;
            const messages = await trainingGateway?.getMessages({
                ...filters,
                botId,
            });
            if ( !messages ) {
                return rejectWithValue({
                    error: "training/loadMessages",
                    message: "Could not load messages"
                });
            }

            return messages;
        } catch ( error: any ) {
            return rejectWithValue({
                error: "training/loadMessages",
                message: error.message
            });
        }
    }, {
        condition: ( _, { getState }) => {
            const { loading } = getState().training;
            return !loading;
        }
    }

    
);




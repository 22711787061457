import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const ButtonList = ({list, className, style, ...rest}) => (
    <div
        className={classnames( "button-list", className )}
        style={style}
        {...rest}
    >
        {list}
    </div>
);


ButtonList.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    list: PropTypes.arrayOf( PropTypes.element.isRequired ),
};

export default ButtonList;

import React, {FC} from "react";
import {ButtonGroup, DropdownButton} from "react-bootstrap";
import FunctionItemMenuContent from "./FunctionItemMenuContent";

interface FunctionItemMenuProps {
    onDelete?: () => void;
    onRename?: () => void;
    onDuplicate?: () => void;
    isCron?: boolean;
    onExecute: () => void;
}

const FunctionItemMenu: FC<FunctionItemMenuProps> = ({isCron, onDelete, onExecute, onDuplicate, onRename}) => {

    const preventSelect = ( e: React.MouseEvent<HTMLElement> ) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <DropdownButton
            className="FunctionsMenu__navLink_menu"
            as={ButtonGroup}
            title={<i className="fas fa-ellipsis-h"></i>}
            onClick={preventSelect}
        >
            <FunctionItemMenuContent
                isCron={isCron}
                onRename={onRename}
                onDelete={onDelete}
                onExecute={onExecute}
            />
        </DropdownButton>
    );
};

export default FunctionItemMenu;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { FunctionItem } from "../../../entities/flowbuilder";
import { ApiError } from "clean-archi/core/entities/flowbuilder";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export interface InputFetchFunctionsListThunk {
    isSystem?: boolean,
}

export const fetchFunctionsList = createAsyncThunk<FunctionItem[], InputFetchFunctionsListThunk, { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError} >(
    "flowbuilder/fetchFunctionsList",
    async ({isSystem}, {getState, extra, rejectWithValue}) => {
        const { botId, branchName } = getState().app.selectedConfiguration;
        const payload = isSystem ? {
            branchName: branchName,
            isSystem: isSystem,
        } : {
            botId: botId,
            branchName: branchName,
        };
        const response = await extra.functionGateway?.fetchFunctions( payload );

        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as unknown as string,
            });
        }
        return response?.data as FunctionItem[];
    }
);
import React, { useEffect } from "react";
import FrameInfo from "./FrameInfo";
import { getConversationCount } from "clean-archi/core/use-cases/statistics/conversation-count/getConversationCountThunk";
import useFrameInfo from "../hooks/framInfoHook";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";
import { kpiColors } from "../config/chartsConfig";

const TotalInteractions = () => {
    const { dispatch, frameInfoStoreData, selectedConfiguration, statsDates } =
      useFrameInfo({frameInfoProp: "TotalInteractions"});

    useEffect(() => {
        dispatch(
            getConversationCount({
                startDate: statsDates.startDate,
                endDate: statsDates.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: selectedConfiguration.branchName,
            })
        );
    }, [
        dispatch,
        selectedConfiguration.instanceId,
        selectedConfiguration.branchName,
        statsDates.startDate,
        statsDates.endDate,
    ]);

    // TODO: replace the percentage and the positiveEvolution with real data from api
    const infoTotalInteraction = {
        title: TranslationManager.getText( textCodes.TOTAL_INTERACTIONS ),
        number: frameInfoStoreData ? frameInfoStoreData.toString() : "0",
        // percentage: "8.5%",
        positiveEvolution: true,
        icon: "las la-user-tag",
        iconColor: kpiColors.iconColor,
        iconBg: kpiColors.iconBg,
    };

    return (
        <FrameInfo {...infoTotalInteraction} />
    );
};

export default TotalInteractions;

import { useMemo, useState } from "react";
import { isNil }             from "lodash";

/**
 * @module useInput
 * @category Hooks
 */


/**
 * Objet permettant d'injecter en une seul variable les props value et onChange d'un component ( <input {...bind} /> )
 * @typedef {Object} BindInput
 * @property {Function} onChange - La fonction modifiant value (utilise event.target.current)
 * @property {*} value - value
 */


/**
 * Custom Hook simplifiant l'utilisation d'un input
 * @author Eden Cadagiani
 * @param {*} [initialValue=""] - la valeur initial
 * @param {Function} [transformater=null] - Une fonction récupérant la nouvelle valeur, et retournant une version modifié. Le changement est bloqué si retourne false ou throw une erreur. Peux être async.
 * @return [value, setValue, bind] - Retourne un tableau ayant en 1er argument la valeur de l'input, la fonction setValue, et l'objet bind permettant d'injecter les props value et onChange
 */
export function useInput ( initialValue = "", transformater = null ) {
    const [value, setValue] = useState( initialValue );
    const bind = useMemo(() => ({
        value,
        onChange: async event => {
            let value = event.target.value;
            try {
                if ( typeof transformater === "function" )
                    value = await transformater( event.target.value );
            } catch ( err ) {
                value = false;
            }
            if ( value !== false ) setValue( value );
        }
    }), [transformater, value]);
    return [
        value,
        setValue,
        bind
    ];
}

/**
 * Custom Hook simplifiant l'utilisation d'un input contrôlé,
 * il permet dans un component de proposer des props permettant de modifier et set la valeur d'un champs
 * mais de quand même pouvoir faire fonctionner ce champs si les props ne sont pas utilisé
 * @author Eden Cadagiani
 * @param {*} [initialValue=""] - La valeur initial
 * @param {*} [controlledValue=null] - La valeur contrôlé, ou non, par les props
 * @param {Function} [controlledOnchange=null] - La fonction permettant de contrôlé, ou non, par les props
 * @return [value, setValue, bind] - Retourne un tableau ayant en 1er argument la valeur de l'input, la fonction setValue, et l'objet bind permettant d'injecter les props value et onChange
 * @example
 * const MyControllableComponent = ({value: controlledValue, onChange: controlledOnChange, ...rest}) => {
 *      const [ value, setValue, bindInput ] = useInputControlled( controlledValue, controlledValue, controlledOnChange );
 *
 *      return (
 *          <input {...bindInput} {...rest} />
 *      )
 * };
 */
export function useInputControlled ( initialValue = "", controlledValue = null, controlledOnchange = null ) {
    const [value, setValue] = useState( initialValue );

    const setValueControlled = v => {
        if ( typeof controlledOnchange === "function" )
            controlledOnchange( v );
        setValue( v );
    };

    const bind = {
        value: !isNil( controlledValue ) ? controlledValue : value,
        onChange: event => {
            if ( typeof controlledOnchange === "function" )
                controlledOnchange( event.target.value );
            setValue( event.target.value );
        }
    };
    return [
        bind.value,
        setValueControlled,
        bind
    ];
}


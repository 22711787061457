import { createAsyncThunk } from "@reduxjs/toolkit";
import { Connection, ApiError } from "clean-archi/core/entities/flowbuilder";
import { InputRemoveConnection } from "clean-archi/core/interfaces/gateways/flowbuilder/ConnectionGateway";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export const removeConnection = createAsyncThunk<Partial<Connection>, InputRemoveConnection, { extra: Partial<Dependencies>, rejectValue: ApiError} >(
    "flowbuilder/removeConnection",
    async ( input: InputRemoveConnection, {extra, rejectWithValue}) => {
        const response = await extra.connectionGateway?.removeConnection( input );
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }
        return response?.data as Connection;
    }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { FlowListEntry, ApiError } from "clean-archi/core/entities/flowbuilder";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";

export interface InputFetchFlowsListThunk {
    botFlowId?: string;
}

export const fetchFlowsList = createAsyncThunk<FlowListEntry[], InputFetchFlowsListThunk, { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError} >(
    "flowbuilder/fetchFlowsList",
    async ( input, {getState, extra, rejectWithValue}) => {
        const { botId, branchName } = getState().app.selectedConfiguration;
        const botFlowId = input.botFlowId || getState().flowbuilder.botFlow.id as string;
        const response = await extra.flowGateway?.fetchFlowsList({ botFlowId, botId, branchName });
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as unknown as string,
            });
        }
        return response?.data as FlowListEntry[];
    }
);

import { PayloadAction } from "@reduxjs/toolkit";
import { Form, FlowBuilderState } from "clean-archi/core/entities/flowbuilder";

export const initItemFormReducer = ( state: FlowBuilderState, action: PayloadAction<Form> ) => {
    state.itemForm.cardId = action.payload.cardId;
    state.itemForm.content = action.payload.content;
    state.itemForm.nodeId = action.payload.nodeId;
    state.itemForm.spec = action.payload.spec;
    state.itemForm.type = action.payload.type;
};

import React, {useState, useEffect} from "react";
import {find, get} from "lodash";
import moment from "moment";

import propTypes from "legacy/constants/propTypes";
import Card from "legacy/components/Card/Card";
import ChartLine from "legacy/components/ChartLine/ChartLine";
import ChartPie from "legacy/components/ChartPie/ChartPie";
// import SelectInlineMulti from "components/form/SelectInlineMulti/SelectInlineMulti";

function getVisibleLines ( values = []) {
    return values.filter( line => line.visible && line.data.length > 0 );
}
function getSelectLineOptions ( values = []) {
    return getVisibleLines( values ).map( line => ({ value: line.id, label: line.id }));
}


const CardBotChart = ({title, description, values, xAxis, yAxis, id, visible, loading, ...rest}) => {
    const visibleLines = getVisibleLines( values );

    const selectLineOptions = getSelectLineOptions( values );
    const [selectLineValue, setSelectLineValue] = useState( selectLineOptions );

    // useEffect(() => {
    //     setSelectLineValue( getSelectLineOptions( values ));
    // }, [values]);

    function onSelectLineChange ( value ) {
        setSelectLineValue( value );
    }


    const filteredLines = visibleLines.filter( line => !!find( selectLineValue, {value: line.id}));

    if ( !visible || !visibleLines.length > 0 )
        return null;

    let chart = "line";
    if ( !filteredLines.find( line => line.data.length > 1 ))
        chart = "pie";
    if ( !filteredLines.find( line => line.data.length > 1 || ( line.data.length === 1 && !!line.data.find( value => value.y > 0 ))))
        chart = "zero";

    return (
        <Card
            loading={loading}
            className={"CardBotChart"}
            title={title}
            description={description}
            contentClassName={"CardBotChart__content"}
            content={(
                <>
                    {/* {(
                        <SelectInlineMulti
                            className={"CardBotChart__selectLine"}
                            options={selectLineOptions}
                            value={selectLineValue}
                            onChange={onSelectLineChange}
                            isClearable={true}
                        />
                    )} */}
                    <div className={"CardBotChart__chartContainer"} >
                        {{
                            "zero": <div className={"CardBotChart__zeroChart"}>0</div>,
                            "pie": (
                                <ChartPie
                                    data={filteredLines.map( line => ({
                                        id: line.id,
                                        label: line.id,
                                        color: line.color,
                                        value: get( line.data, "[0].y" )
                                    }))}
                                    legends={[]}
                                />
                            ),
                            "line": (
                                <ChartLine
                                    data={filteredLines}
                                    axisLeft={{
                                        ...yAxis,
                                    }}
                                    axisBottom={{
                                        ...xAxis,
                                        format: date => moment( date ).format( "DD/MM/YY" ),
                                        legendOffset: 48,
                                        tickRotation: -35
                                    }}
                                    xFormat={date => moment( date ).format( "DD/MM/YY" )}
                                    xScale={{
                                        type: "time",
                                        format: "%d/%m/%Y",
                                        precision: "day",
                                    }}
                                    margin={{bottom: 60, top: 20}}
                                    animate={false}
                                    enablePointLabel={false}
                                    enableSlices={"x"}
                                    legends={[]}
                                />
                            )
                        }[chart]}
                    </div>
                </>
            )}
        />
    );
};

CardBotChart.propTypes = {
    ...propTypes.objectChart
};

export default CardBotChart;

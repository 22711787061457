import { PayloadAction } from "@reduxjs/toolkit";
import { FlowBuilderState, NodeFlow } from "../../../entities/flowbuilder";

export const updateNodeReducer = ( state: FlowBuilderState, action: PayloadAction<Partial<NodeFlow>> ) => {
    if ( !state.botFlow.flows || state.botFlow.flows.size === 0 ) return;

    const selectedFlowId = ( action.payload.flowId ?? state.botFlow.selectedFlowId ) as string;
    const currentNode = state.botFlow.flows.get( selectedFlowId )?.nodes.get( action.payload.id as string );

    if ( currentNode && action.payload.position ) {
        currentNode.position = action.payload.position;
    }
};

import { InputUpdateNode, NodeGateway, InputAddNode, InputRemoveNode } from "clean-archi/core/interfaces/gateways/flowbuilder/NodeGateway";
import axiosInstance from "legacy/store/requests/axiosInstance";
import { NodeFlowApiReturnType, REGEX_2XX } from "../gateways-entities";
import { NodeFlow, RemovedItem } from "clean-archi/core/entities/flowbuilder";
import { ResponseApi } from "clean-archi/core/use-cases/flowbuilder/fetch-bot-flow";
import { transformToNodeFlow } from "../transform-helpers";

export class HttpNodeGateway implements NodeGateway {
    
    async addNode ( input: InputAddNode ): Promise<ResponseApi<NodeFlow>> {
        const response = await axiosInstance.post<ResponseApi<NodeFlowApiReturnType>>( "/api/flowbuilder/node", input, {
            params: {
                branchName: input.branchName,
            }
        });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: this.transformToNodeFlow( data as NodeFlowApiReturnType ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateNode ( input: InputUpdateNode, branchName: string ): Promise<ResponseApi<NodeFlow>> {
        const response = await axiosInstance
            .patch<ResponseApi<NodeFlowApiReturnType>>( `/api/flowbuilder/node/${input.id}`, input, {
                params: { branchName }
            })
            .catch( err => err );

        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: this.transformToNodeFlow( response.data.data as NodeFlowApiReturnType ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async removeNode ( input: InputRemoveNode ): Promise<ResponseApi<RemovedItem>> {
        const response = await axiosInstance
            .delete<ResponseApi<RemovedItem>>( `/api/flowbuilder/node/${input.id}`, {
                data: {
                    botId: input.botId,
                    branchName: input.branchName,
                    botInstanceId: input.botInstanceId
                },
                params: { branchName: input.branchName }
            })
            .catch( err => err );

        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            return response.data;
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async removeFlowLinkNode ( input: InputRemoveNode ): Promise<ResponseApi<RemovedItem[]>> {
        const response = await axiosInstance
            .delete<ResponseApi<RemovedItem[]>>( `/api/flowbuilder/node/flowlink/${input.id}`, {
                data: {
                    botId: input.botId,
                    branchName: input.branchName,
                    botInstanceId: input.botInstanceId
                },
                params: { branchName: input.branchName }
            })
            .catch( err => err );

        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            return response.data;
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    transformToNodeFlow ( data: NodeFlowApiReturnType ): Partial<NodeFlow> {
        return transformToNodeFlow( data );
    }

}

import { FlowBuilderState } from "../../../entities/flowbuilder";

export const preselectFlowReducer = ( state: FlowBuilderState ) => {
    const flowsArray = Array.from( state.botFlow.flows );
    const matches = global.window.location.pathname.match( /^\/.*\/\w+\/flowbuilder\/(.*)\?*$/ );

    if ( matches?.length ) {
        const [, routeSelectedFlowId] = matches;
        state.botFlow.selectedFlowId =
            !state.botFlow.flows.size || state.botFlow.flows.has( routeSelectedFlowId )
                ? routeSelectedFlowId
                : flowsArray[0][1].id;
        return;
    }

    const storeSelectedFlowId = state.botFlow.selectedFlowId;
    if ( storeSelectedFlowId ) {
        if ( !state.botFlow.flows.has( storeSelectedFlowId )) {
            state.botFlow.selectedFlowId = flowsArray[0][1].id;
        }
        return;
    }

    state.botFlow.selectedFlowId = ( flowsArray.length > 0 ) ? flowsArray[0][1]?.id : undefined;

    if ( state.botFlow.selectedFlowId ) {
        const nodes = state.botFlow.flows.get( state.botFlow.selectedFlowId )?.nodes;

        if ( nodes && nodes?.size > 0 ) {
            const firstNode = nodes.entries().next().value;
            state.botFlow.selectedNodeId = firstNode[0];
        }
    }
};

import axiosInstance from "../../../../../legacy/store/requests/axiosInstance";
import { ConversationGateway } from "../../../../core/interfaces/gateways/conversation/ConversationGateway";

export class HttpConversationGateway implements ConversationGateway {

    async getConversation ( id: string ) {
        try {
            const response = await axiosInstance.get(
                `/api/botcore/api/conversation/${id}`);
            return response.data.result;
        } catch ( error ) {
            console.error( error as string );
            throw new Error( error as string );
        }
    }

}
import React from "react";
import PropTypes from "prop-types";
import {filter, get} from "lodash";
import cx from "classnames";
import { ResponsiveLine } from "@nivo/line";
import {useMouseClientPos} from "legacy/hooks/useDom";

import "./chartLine.scss";

const ChartLine = ({
    className,
    data,
    axisBottom,
    axisRight,
    axisTop,
    axisLeft,
    margin,
    xScale, yScale,
    animate = true,
    sliceToolTipMaxWidth = 300,
    ...rest
}) => {
    const mousePosRef = useMouseClientPos();
    const visibleData = filter( data, ( line ) => line.visible && line.data.length > 0 );
    return (
        <div className={cx( "ChartLine", className )}>
            <ResponsiveLine
                data={visibleData}
                margin={{ top: 50, right: 50, bottom: 50, left: 50, ...margin }}
                xScale={{ type: "point", stacked: false, min: "auto", max: "auto", ...xScale }}
                yScale={{ type: "linear", stacked: false, min: "auto", max: "auto", ...yScale }}
                axisTop={axisTop}
                axisRight={axisRight}
                axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: 36,
                    legendPosition: "middle",
                    // legend: "",
                    ...axisBottom
                }}
                axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: "middle",
                    // legend: "",
                    ...axisLeft
                }}
                colors={{ scheme: "dark2" }}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                enablePointLabel={true}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                    {
                        anchor: "top-left",
                        direction: "row",
                        justify: false,
                        translateX: 0,
                        translateY: -40,
                        itemsSpacing: 10,
                        itemDirection: "left-to-right",
                        itemWidth: 130,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: "circle",
                        symbolBorderColor: "rgba(0, 0, 0, .5)",
                        effects: [
                            {
                                on: "hover",
                                style: {
                                    itemBackground: "rgba(0, 0, 0, .03)",
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                animate={animate}
                sliceTooltip={({ slice }) => {
                    const tooMuchRight = mousePosRef.current.x + sliceToolTipMaxWidth > document.body.clientWidth;
                    return (
                        <div
                            className={"ChartLine__sliceToolTip"}
                            style={{
                                transform: tooMuchRight ? "translateX(calc(-100% + -30px))" : "none"
                            }}
                        >
                            <div className={"text-center"}>{get( slice, "points[0].data.xFormatted" )}</div>
                            <table>
                                <tbody>
                                    {slice.points.map( point => (
                                        <tr
                                            key={point.id}
                                            style={{
                                                color: point.serieColor,
                                                padding: "5px 0",
                                            }}
                                        >
                                            <td style={{ paddingRight: 10 }}>
                                                <strong> {point.serieId} </strong>
                                            </td>
                                            <td>{point.data.yFormatted}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                }}
                {...rest}
            />
        </div>
    );
};

ChartLine.propTypes = {
    sliceToolTipMaxWidth: PropTypes.number,
    ...ResponsiveLine.propTypes,
};

export default ChartLine;

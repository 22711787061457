import { FormSchema } from "legacy/context/contextTypes";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { markFieldsAsTouched, resetForm, updateErrors, success } from "./formActions";
import { formReducer, initFormState } from "./formReducer";

export default function useForm ( formSchema: FormSchema ) {
    const initialState = useMemo(() => {
        return initFormState( formSchema );
    }, [formSchema]);
    
    const [formState, formDispatch] = useReducer( formReducer, initialState );

    const checkErrors = useCallback(() => {
        updateErrors( formDispatch, formState, formSchema );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDispatch, formSchema, formState.value]);

    useEffect(() => {       
        checkErrors();
    }, [formState.value, formState.touchedFields, checkErrors]);

    const isValid = () => {
        const totalErrors = !formState.errors ? 0 : Object.entries( formState.errors ).reduce(( total, [key, field]: Array<string | any> ) => total + (( !field.valid ) ? 1 : 0 ), 0 );
        return totalErrors === 0;
    };

    const submit = () => { 
        markFieldsAsTouched( formDispatch );
        if ( isValid()) {
            success( formDispatch );
        }
    };

    const reset = () => {
        resetForm( formDispatch, initFormState( formSchema ));
    };

    return {
        formState, 
        formDispatch,
        submit,
        reset,
    };
}

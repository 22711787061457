import { createAsyncThunk } from "@reduxjs/toolkit";
import { StatisticsFilters } from "clean-archi/core/entities/statistics/StatisticsFilters";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { ApiError } from "../../../entities/flowbuilder";


export const getConversationCount = createAsyncThunk<
  any,
  StatisticsFilters,
  {
    state: MainReducerState;
    extra: Partial<Dependencies>;
    rejectValue: ApiError;
  }
>(
    "stats/getConversationCount",
    async ( filters, { getState, extra, rejectWithValue }) => {
              
        try {
            const { statisticGateway } = extra;
            const { botId } = getState().app.selectedConfiguration; 
            const result = await statisticGateway?.getConversationsCountRequest(
                {
                    ...filters,
                    botId,
                }
            );
            return result;
        } catch ( error: any ) {
            return rejectWithValue({
                error: "stats/getConversationCount",
                message: error.message,
            });
        }
    }
);

import React from "react";
import cx     from "classnames";
import ReactCountryFlag from "react-country-flag";

import Button           from "legacy/components/Button/Button";
import languagesConst   from "legacy/constants/hmbConstants/languagesConst";

import "./LanguageButton.scss";

const LanguageButton = ({language, className, ...rest}) =>(
    <Button
        icon
        bsSize={"xsmall"}
        {...rest}
        className={cx( className, "btn-light", "btn-language" )}
    >
        <ReactCountryFlag countryCode={languagesConst[language].countryCode} svg/>
    </Button>
);

LanguageButton.propTypes = Button.propTypes;
export default LanguageButton;

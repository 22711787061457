import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SearchMatch } from "../../../../../../core/entities/flowbuilder/SearchMatch";
import { HttpBotFlowStorelessGateway } from "../../../../../secondary/gateways/flowbuilder/botflow/HttpBotFlowStorelessGateway";
import { useAppSelector } from "../../../../../../../legacy/store/typedHooks";
import { getAppConfigParams } from "../../../../view-models-generators/config/configParamsViewModels";
import { getBotFlowId } from "../../../../view-models-generators/bot-elements/botElementsViewModels";

const fetchBotFlowStorelessGateway = new HttpBotFlowStorelessGateway();

export const useFlowbuilderSearch = ( value: string ) => {
    const { branchName, language} = useAppSelector( getAppConfigParams );
    const botFlowId = useAppSelector( getBotFlowId ) as string;
    
    const [searchResults, setSearchResults] = useState<SearchMatch[] | null>( null );
    const [isLoading, setIsLoading] = useState( false );

    const debounceMemo = useMemo(
        () => debounce(( func: Function ) => func(), 200 ),
        []
    );

    const fetchSearchResults = useCallback(() => {
        const isTextReady = value && value.length > 2;
        if ( isTextReady ) {
            setIsLoading( true );
            fetchBotFlowStorelessGateway.searchInBotFlow({ botFlowId, branchName, language, value })
                .then(( response ) => {
                    setSearchResults( response ? response.data as SearchMatch[] : []);
                })
                .catch(( error ) => {
                    console.error( error );
                })
                .finally(() => {
                    setIsLoading( false );
                });
        }
    }, [botFlowId, branchName, language, value]);

    useEffect(() => {
        debounceMemo( fetchSearchResults );
    }, [botFlowId, branchName, language, value, fetchSearchResults, debounceMemo]);
  
    return [searchResults, isLoading] as const;
};

import { ResponseApi } from "clean-archi/core/use-cases/flowbuilder/fetch-bot-flow/fetchBotFlowThunk";
import { isEmpty } from "lodash";
import { CardFlow, ExitpointCardFlow, Flow, NodeFlow, RemovedItem } from "../../../../../core/entities/flowbuilder";
import { CardFlowApiReturnType, NodeFlowApiReturnType, REGEX_2XX } from "../gateways-entities";
import axiosInstance from "legacy/store/requests/axiosInstance";
import { CardGateway, InputAddCard, InputCaseCard, InputMoveCard, InputRemoveCard, InputUpdateCard, InputUpdateCardWithFlowExitpoint, InputUpdateCardWithNewFunction, InputUpdateCardWithNewIntent, InputUpdateCardWithSelectedFunction, InputUpdateCardWithSelectedIntent, InputUpdateCardWithSystemFunction, InputUpdateCardWithUpdatedFunction, InputUpdateCardWithUpdatedIntent } from "clean-archi/core/interfaces/gateways/flowbuilder/CardGateway";
import { transformDataToCardFlow, transformDataToRemovedItem, transformToFlow, transformToNodeFlow, transformToSystemFnCard } from "../transform-helpers";

export class HttpCardGateway implements CardGateway {
    async addCard (
        input: InputAddCard,
        branchName: string
    ): Promise<ResponseApi<CardFlow | CardFlow[]>> {
        const response = await axiosInstance.post<
      ResponseApi<CardFlowApiReturnType | CardFlowApiReturnType[]>
    >( "/api/flowbuilder/card", input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: transformDataToCardFlow( data ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async moveCard (
        input: InputMoveCard,
        branchName: string
    ): Promise<ResponseApi<NodeFlow>> {
        const response = await axiosInstance.patch<
      ResponseApi<NodeFlowApiReturnType>
    >( "/api/flowbuilder/card/index", {...input, branchName}, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: transformToNodeFlow( data as NodeFlowApiReturnType ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async addCardWithAcm (
        input: InputAddCard,
        branchName: string
    ): Promise<ResponseApi<CardFlow | CardFlow[]>> {
        const response = await axiosInstance.post<
      ResponseApi<CardFlowApiReturnType | CardFlowApiReturnType[]>
    >( "/api/flowbuilder/card/acm", input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: transformDataToCardFlow( data ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async toggleCardLock ( input: { id: string; nodeId: string; }, branchName: string ): Promise<ResponseApi<CardFlow>> {
        const { id, nodeId } = input;
        const response = await axiosInstance.patch<ResponseApi<CardFlowApiReturnType>>( `/api/flowbuilder/card/lock/${id}`, {
            nodeId,
            branchName
        }, {
            params: {
                branchName: branchName,
            },
        });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data )
                    ? undefined
                    : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithAcm (
        input: InputUpdateCard,
        branchName: string
    ): Promise<ResponseApi<CardFlow | ( CardFlow | RemovedItem )[]>> {
        const response = await axiosInstance.patch<
      ResponseApi<CardFlowApiReturnType>
    >( `/api/flowbuilder/card/acm/${input.id}`, input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: transformDataToCardFlow( data ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithPayload (
        input: InputUpdateCard,
        branchName: string
    ): Promise<ResponseApi<CardFlow>> {
        const response = await axiosInstance.patch<
      ResponseApi<CardFlowApiReturnType>
    >( `/api/flowbuilder/card/payload/${input.id}`, input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithCase (
        input: InputCaseCard,
        branchName: string
    ): Promise<ResponseApi<CardFlow>> {
        const response = await axiosInstance.patch<
      ResponseApi<CardFlowApiReturnType>
    >( `/api/flowbuilder/card/case/${input.id}`, input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithSelectedIntent (
        input: InputUpdateCardWithSelectedIntent,
        branchName: string
    ): Promise<ResponseApi<CardFlow>> {
        const response = await axiosInstance.put<
      ResponseApi<CardFlowApiReturnType>
    >( "/api/flowbuilder/intent/select", input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithNewIntent (
        input: InputUpdateCardWithNewIntent,
        branchName: string
    ): Promise<ResponseApi<CardFlow>> {
        const { id, ...rest } = input;
        const response = await axiosInstance.post<
      ResponseApi<CardFlowApiReturnType>
    >( `/api/flowbuilder/intent/${id}`, rest, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithUpdatedIntent (
        input: InputUpdateCardWithUpdatedIntent,
        branchName: string
    ): Promise<ResponseApi<CardFlow[]>> {
        const response = await axiosInstance.patch<
      ResponseApi<CardFlowApiReturnType[]>
    >( "/api/flowbuilder/intent", input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithFlowExitpoint (
        input: InputUpdateCardWithFlowExitpoint,
        branchName: string
    ): Promise<ResponseApi<ExitpointCardFlow>> {
        const response = await axiosInstance.patch<
      ResponseApi<CardFlowApiReturnType>
    >( `/api/flowbuilder/card/flowlink/${input.id}`, input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data )
                    ? undefined
                    : this.transformFlowEntryElements( data as CardFlowApiReturnType ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithNewFunction (
        input: InputUpdateCardWithNewFunction,
        branchName: string
    ): Promise<ResponseApi<CardFlow>> {
        const { id, ...rest } = input;
        const response = await axiosInstance.post<
      ResponseApi<CardFlowApiReturnType>
    >( `/api/flowbuilder/function/${id}`, rest, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithSelectedFunction (
        input: InputUpdateCardWithSelectedFunction,
        branchName: string
    ): Promise<ResponseApi<CardFlow>> {
        const response = await axiosInstance.put<
      ResponseApi<CardFlowApiReturnType>
    >( "/api/flowbuilder/function/select", input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithUpdatedFunction (
        input: InputUpdateCardWithUpdatedFunction,
        branchName: string
    ): Promise<ResponseApi<CardFlow[]>> {
        const response = await axiosInstance.patch<
      ResponseApi<CardFlowApiReturnType[]>
    >( "/api/flowbuilder/function", input, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data: isEmpty( data ) ? undefined : data,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async updateCardWithSystemFunction (
        input: InputUpdateCardWithSystemFunction,
        branchName: string
    ): Promise<ResponseApi<CardFlow>> {
        const { id, ...rest } = input;
        const response = await axiosInstance.patch<
      ResponseApi<CardFlowApiReturnType>
    >( `/api/flowbuilder/function/system/${id}`, rest, {
        params: {
            branchName: branchName,
        },
    });
        const { data } = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data /** @todo transform system fn data to tag data */,
                data: isEmpty( data )
                    ? undefined
                    : transformToSystemFnCard( data as CardFlowApiReturnType ),
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async removeCard (
        input: InputRemoveCard,
        branchName: string
    ): Promise<ResponseApi<RemovedItem>> {
        const response = await axiosInstance
            .delete<ResponseApi<RemovedItem>>( `/api/flowbuilder/card/${input.id}`, {
                data: {
                    botId: input.botId,
                    branchName: input.branchName,
                    botInstanceId: input.botInstanceId,
                    nodeId: input.nodeId,
                },
                params: {
                    branchName: branchName,
                },
            })
            .catch(( err ) => err );
        const { data } = response.data;
        if (
            !( response instanceof Error ) &&
      REGEX_2XX.test( response.status.toString())
        ) {
            return {
                ...response.data,
                data : transformDataToRemovedItem( data )
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    transformFlowEntryElements (
        data: CardFlowApiReturnType
    ): Partial<ExitpointCardFlow> {
        return {
            ...data,
            linkedEntryFlow: transformToFlow( data.linkedEntryFlow ) as Required<Flow>,
            linkedEntryNode: transformToNodeFlow(
                data.linkedEntryNode
            ) as Required<NodeFlow>,
        };
    }
}

import React, {FC, useContext, useEffect, useMemo} from "react";
import {Route, Switch} from "react-router-dom";
import routes from "legacy/constants/routes";
import FlowBuilderSidebar from "./FlowBuilderSiderbar";
import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { AppContext } from "legacy/context/AppContext";
import { getAppConfigParams } from "../../../view-models-generators/config/configParamsViewModels";
import { fetchBotFlow } from "clean-archi/core/use-cases/flowbuilder/fetch-bot-flow/fetchBotFlowThunk";
import { useMount } from "legacy/hooks/useEffect";

import "./FlowBuilder.scss";
import FlowBuilderSearch from "./Search/FlowBuilderSearch";

const FlowBuilder: FC = () => {
    const { setSidebarMini } = useContext( AppContext );
    const flowBuilderRoutes = useMemo(() => Object.values( routes || {}).filter( pathItem => pathItem.inFlowBuilderSwitch ), []);

    const dispatch = useAppDispatch();
    const { branchName, botId, language } = useAppSelector( getAppConfigParams );

    useEffect(() => {
        dispatch( fetchBotFlow({botId, branchName}));
    }, [botId, branchName, dispatch, language]);

    useMount(() => {
        setSidebarMini( true );
    });

    return (
        <div className="FlowBuilder">
            <FlowBuilderSidebar />
            <Switch>
                {flowBuilderRoutes.map(( pathItem, index ) => (
                    <Route
                        key={pathItem.id}
                        exact={pathItem.exact}
                        path={pathItem.buildPath()}
                        component={pathItem.component}
                    />
                ))}
            </Switch>
            <FlowBuilderSearch />
        </div>
    );
};

export default FlowBuilder;

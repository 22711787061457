import React, { FC, ReactNode, useCallback, useContext, useMemo } from "react";
import { Text } from "translation-manager-react";
import { hasInput } from "legacy/context/flowBuilder/flowBuilderConfig";
import {
    AcmItem,
    AcmStaticQuickReplyResponseItemResult,
    AcmTextLanguages,
    CardFlow,
} from "clean-archi/core/entities/flowbuilder";
import textCodes from "legacy/constants/translations/textCodes.json";
import { Position } from "react-flow-renderer";
import CardHandle from "../../handles/CardHandle/CardHandle";
import { useAppSelector } from "legacy/store/typedHooks";
import { getAppConfigParams } from "clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";
import { buildSpecialConnectionId } from "clean-archi/core/use-cases/flowbuilder/connect/buildConnectionPayload";
import { useFlowBuilder } from "legacy/context/flowBuilder/FlowBuilderContext";

import "./CardContentQuickReply.scss";
import FormManager, { FormManagerProps } from "../../forms/FormManager/FormManager";
import { useToTranslate } from "legacy/hooks/useToTranslate";

export const SideIconContext: React.Context<React.ReactElement> = React.createContext( <i /> );

export function useSideIcon () {
    return useContext( SideIconContext );
}

interface Props extends CardFlow {
    index: number;
    form: FormManagerProps;
    onClick?: Function;
    handle?: ReactNode;
    acm: AcmItem;
    nodeId: string;
}

const CardContentQuickReply: FC<Props> = ({
    id,
    nodeId,
    type,
    index,
    isLocked,
    form,
    isEditing,
    onClick,
    handle,
    acm,
}) => {
    const {openContextMenu, contextMenu} = useFlowBuilder();
    const ToTranslate = useToTranslate();
    
    const onCardContextMenu = useCallback(( e: React.MouseEvent, index: string ) => {
        e?.preventDefault();
        e?.stopPropagation();        
        const containerDomRect = e.currentTarget.closest( ".FlowBuilder__Flow" )?.getBoundingClientRect();
        if ( containerDomRect ) {
            openContextMenu(
                { x: e.pageX - containerDomRect.x, y: e.pageY - containerDomRect.y },
                nodeId,
                id,
                undefined,
                index,
                isLocked
            );
        }

    }, [id, nodeId, openContextMenu, isLocked]);
    
    const sideIconValue = useMemo(
        () => <i className="card-icon fas fa-bullseye-pointer"></i>,
        []
    );

    const mainClassName = `bot-card-${type.toLowerCase()}`;

    const language = useAppSelector( getAppConfigParams )
        .language as AcmTextLanguages;

    const content = useMemo(() => {
        const responseItem: AcmStaticQuickReplyResponseItemResult | undefined = ( acm?.responseItem as AcmStaticQuickReplyResponseItemResult );
        return responseItem?.values?.length ? (
            responseItem?.values.map(( value, idx ) => (
                <div key={`reply-${idx}`} className={`${mainClassName}__reply ${( contextMenu.cardId === id && contextMenu.index === value.id ) ? "context-open" : ""}`} onContextMenu={( e: React.MouseEvent ) => onCardContextMenu?.( e, value.id )}>
                    <div className={`${mainClassName}__reply-icon-wrapper`}>
                        {sideIconValue}
                    </div>
                    <div className={`${mainClassName}__reply-body`}>
                        <small className="card-headline">{value?.intent?.name || <em>no selected intent</em>}</small>
                        <div key={idx} className={`${mainClassName}__reply-content`}>
                            {value.text[language]?.[0] || <ToTranslate />}
                            <CardHandle
                                id={buildSpecialConnectionId({ id: value.id, cardId: id })}
                                type="source"
                                position={Position.Right}
                            />
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div className="py-2 px-3 mt-2 bg-danger rounded text-white">
                <p className="mb-1 text">
                    <Text
                        className="mb-1 text"
                        textCode={textCodes.INVALID_QUICK_REPLIES_EMPTY}
                    />
                </p>
            </div>
        );
    }, [acm?.responseItem, contextMenu.cardId, contextMenu.index, id, language, mainClassName, onCardContextMenu, sideIconValue, ToTranslate]);

    return (
        <div
            className={`bot-card bot-card-content ${mainClassName} ${isEditing ? "is-editing" : ""}`}
        >
            {hasInput( type ) && (
                <CardHandle id={id} type="target" position={Position.Left} />
            )}
            <div className="bot-card-type">
                {handle}
            </div>
            <div className={`${mainClassName}__content`} onClick={( e: React.MouseEvent ) => onClick?.( e )}>
                <SideIconContext.Provider value={sideIconValue}>
                    {form && !form.isProd && isEditing ? <FormManager {...form} /> : content}
                </SideIconContext.Provider>
            </div>
        </div>
    );
};

export default CardContentQuickReply;

import { PayloadAction } from "@reduxjs/toolkit";
import { Flow, FlowBuilderState } from "../../../entities/flowbuilder";
import { UpdateFlowResult } from "../../../interfaces/gateways/flowbuilder/FlowGateway";

export const editFlowReducer = ( state: FlowBuilderState, action: PayloadAction<UpdateFlowResult> ) => {
    const { flow, flows, nodes } = action.payload;

    if ( flow?.id ) {
        state.botFlow.flows.set(
            flow.id as string, {
                ...state.botFlow.flows.get( flow.id as string ),
                ...flow,
            } as Flow
        );
    }

    if ( flows ) {
        flows.forEach( flow => {
            state.botFlow.flows.set(
                flow.id as string, {
                    ...state.botFlow.flows.get( flow.id as string ),
                    ...flow,
                } as Flow
            );
        });
    }

    if ( nodes ) {
        nodes.forEach( node => {
            state.botFlow.flows.forEach( flow => {
                if ( flow.nodes.has( node.id )) {
                    flow.nodes.set( node.id, node );
                }
            });
        });
    }

};

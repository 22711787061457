import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { AcmItem, CardFlow, CARDS, ExitpointCardFlow, FORMS_SPECS, ApiError, FlowExitpointInput, FunctionItem, IntentItem, PayloadItem, RemovedItem, TagItem } from "../../../entities/flowbuilder";
import { InputCaseCard, InputUpdateCard } from "../../../interfaces/gateways/flowbuilder/CardGateway";
import { ResponseApi } from "../fetch-bot-flow";
import { initItemForm, switchFormSpec } from "../../../../store/flowbuilder/flowbuilderSlice";

export type InputUpdateIntentCardAction = "create" | "update" | "select";
export type InputUpdateFunctionCardAction = "create" | "update" | "select";

export interface InputUpdateCardThunk {
    id: string; // card id
    nodeId: string;
    index: number;
    type: CARDS;
    acmData?: AcmItem;
    payload?: PayloadItem;
    intentData?: IntentItem & { action: InputUpdateIntentCardAction };
    flowExitpointData?: FlowExitpointInput["flowExitpointData"];
    functionData?: FunctionItem & { action: InputUpdateFunctionCardAction };
    tag?: TagItem;
    case?: string;
}

export const updateCard = createAsyncThunk<Partial<CardFlow | CardFlow[] | ExitpointCardFlow>, InputUpdateCardThunk,
    { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError,
        fulfilledMeta: {nodeId: string, flowId: string, cardId: string, removedItems: RemovedItem[]}} >(
            "flowbuilder/updateCard",
            async ( input: InputUpdateCardThunk, {getState, extra, dispatch, rejectWithValue, fulfillWithValue}) => {
                let response: ResponseApi<CardFlow | CardFlow[] | ExitpointCardFlow | ( CardFlow | RemovedItem )[]> | undefined = undefined;
                let removedItems: RemovedItem[] = [];
                const { botId, branchName, language, instanceId: botInstanceId } = getState().app.selectedConfiguration;
                const botFlowId = getState().flowbuilder.botFlow.id as string;
                const flowId = getState().flowbuilder.botFlow.selectedFlowId as string;
                switch ( input.type ) {
                    case CARDS.ContentText:
                        response = await extra.cardGateway?.updateCardWithAcm({
                            ...input,
                            acmData: {
                                ...input.acmData,
                                botId,
                                branchName,
                                botInstanceId,
                            }
                        } as InputUpdateCard, branchName );
                        break;
                    case CARDS.ContentQuickReply:
                        response = await extra.cardGateway?.updateCardWithAcm({
                            ...input,
                            acmData: {
                                ...input.acmData,
                                botId,
                                branchName,
                                botInstanceId,
                            }
                        } as InputUpdateCard, branchName );
                        
                        if ( response?.data ) {
                            removedItems = ( response?.data as ( CardFlow | RemovedItem )[]).filter( i => i.type === "RemovedItem" ) as RemovedItem[];
                        }
                        break;
                    case CARDS.Payload:
                        response = await extra.cardGateway?.updateCardWithPayload({
                            ...input,
                            payload: {
                                ...input.payload,
                            }
                        } as InputUpdateCard, branchName );
                        break;
                    case CARDS.Case:
                        response = await extra.cardGateway?.updateCardWithCase({
                            ...input,
                            botId,
                            branchName,
                            botInstanceId,
                            case: input.case,
                        } as InputCaseCard, branchName );
                        break;
                    case CARDS.Intent:
                        if ( input.intentData?.action === "select" ) {
                            response = await extra.cardGateway?.updateCardWithSelectedIntent({
                                id: input.id, // card id
                                nodeId: input.nodeId,
                                intentId: input.intentData.id as string,
                                botId,
                                branchName
                            }, branchName );
                        } else if ( input.intentData?.action === "create" ) {
                            response = await extra.cardGateway?.updateCardWithNewIntent({
                                id: input.id, // card id
                                nodeId: input.nodeId,
                                intentData: {
                                    name: input.intentData.name,
                                    training: input.intentData.training,
                                    botId,
                                    branchName
                                }
                            }, branchName );
                        } else if ( input.intentData?.action === "update" ) {
                            response = await extra.cardGateway?.updateCardWithUpdatedIntent({
                                intentData: {
                                    id: input.intentData.id as string,
                                    name: input.intentData.name,
                                    training: input.intentData.training,
                                    botId,
                                    branchName
                                }
                            }, branchName );
                        }
                        break;
                    case CARDS.FlowExitpoint: {
                        const flowExitpointData = input.flowExitpointData as FlowExitpointInput["flowExitpointData"] & { language: string };
                        response = await extra.cardGateway?.updateCardWithFlowExitpoint({
                            id: input.id,
                            botFlowId,
                            botInstanceId,
                            botId,
                            branchName,
                            language,
                            nodeId: input.nodeId,
                            flowExitpointData: {
                                kind: flowExitpointData?.kind,
                                value: flowExitpointData?.value as string
                            }
                        }, branchName );
                        break;
                    }
                    case CARDS.Function:
                        if ( input.functionData?.action === "create" ) {
                            response = await extra.cardGateway?.updateCardWithNewFunction({
                                id: input.id,
                                nodeId: input.nodeId,
                                functionData: {
                                    botId: botId,
                                    branchName: branchName,
                                    code: input.functionData.code as string,
                                    description: input.functionData.description as string,
                                    name: input.functionData.name as string
                                }
                            }, branchName );
                            dispatch( switchFormSpec( FORMS_SPECS.Panel ));
                            dispatch(
                                initItemForm({
                                    cardId: input.id,
                                    nodeId: input.nodeId,
                                    spec: FORMS_SPECS.Panel,
                                    type: CARDS.Function,
                                })
                            );
                        } else if ( input.functionData?.action === "select" ) {
                            response = await extra.cardGateway?.updateCardWithSelectedFunction({
                                id: input.id,
                                nodeId: input.nodeId,
                                functionId: input.functionData.id as string
                            }, branchName );
                        } else if ( input.functionData?.action === "update" ) {
                            response = await extra.cardGateway?.updateCardWithUpdatedFunction({
                                functionData: {
                                    botId: botId,
                                    branchName: branchName,
                                    code: input.functionData.code as string,
                                    description: input.functionData.description as string,
                                    id: input.functionData.id as string,
                                    name: input.functionData.name as string
                                }
                            }, branchName );
                        }
                        break;
                    case CARDS.Tag:{
                        if ( input.tag && input.tag.name ) {
                            response = await extra.cardGateway?.updateCardWithSystemFunction({
                                id: input.id,
                                nodeId: input.nodeId,
                                functionData: {
                                    botId,
                                    branchName,
                                    id: input.tag?.functionId,
                                    systemFnData: {
                                        systemFnName: "addTag",
                                        systemFnParams: input.tag.name
                                    }
                                }
                            }, branchName );
                        }
                        break;
                    }
                    default:
                        throw new Error( "Card type not supported yet" );
                }

                if ( !response?.data || response?.error ) {
                    return rejectWithValue({
                        message: response?.message as string,
                        error: response?.error as string,
                    });
                }
                return fulfillWithValue(
                    Array.isArray( response?.data ) ? response?.data.filter( i => i?.type !== "RemovedItem" ) as CardFlow[] : response?.data,
                    {nodeId: input.nodeId, flowId, cardId: input.id, removedItems});
            }
        );




import { PayloadAction } from "@reduxjs/toolkit";
import { FlowBuilderState, NodeFlow } from "../../../entities/flowbuilder";
import { selectNodeFromFlow } from "../utils";

export const moveCardReducer = ( state: FlowBuilderState, action: PayloadAction<NodeFlow> ) => {
    const selectedFlow = state.botFlow.selectedFlowId;
    if ( selectedFlow ) {
        const node = selectNodeFromFlow({id: action.payload.id, flowId: selectedFlow, state});

        action.payload.cards.forEach(( card ) => {
            node?.cards.set( card.id, card );
        });
    }
};
import React from "react";
import cx     from "classnames";
import Button from "legacy/components/Button/Button";

const CloneButton = ({className, ...rest}) =>(
    <Button
        {...rest}
        bsStyle="info" simple icon
        className={cx( className, "btn-update" )}
    >
        <i className="far fa-clone" />
    </Button>
);

CloneButton.propTypes = Button.propTypes;
export default CloneButton;

import { useRef, useLayoutEffect, useCallback } from "react";

/**
 * @category Hooks
 * @module useStaticCallback
 */


/**
 * Permet d'avoir un callback qui ne se re-init pas à chaque render (comme useCallback) mais sans avoir à passer des entrées.
 * WARNING: preferer autant que possible le classique useCallback de react
 * @param {function} fn - la fonction a memoize
 * @author Eden Cadagiani
 * @return {function}
 */
export function useStaticCallback ( fn ) {
    let ref = useRef(() => {
    });
    useLayoutEffect(() => {
        ref.current = fn;
    });
    // eslint-disable-next-line
    return useCallback(( ...args ) => ( 0, ref.current )( ...args ), []); // "( 0, ref.current )" call ref.current function, but without actual context
}

import React, {FC, useCallback, useRef, useState} from "react";
import cx from "classnames";
import { Flow } from "clean-archi/core/entities/flowbuilder";
import FlowItemMenu from "./FlowItemMenu";
import { useAppDispatch } from "../../../../../../../legacy/store/typedHooks";
import { removeFlow } from "clean-archi/core/use-cases/flowbuilder/remove-flow/removeFlowThunk";
import { editFlow } from "clean-archi/core/use-cases/flowbuilder/edit-flow/editFlowThunk";
import useKeyPress from "legacy/hooks/useKeyPress";
import { useFlowBuilderSidebarState } from "../../flows/FlowBuilderSiderbar";

interface SortableFlowItemProps {
    flow: Flow;
    selectedId?: string;
    openedId?: string;
    onFlowSelect?: ( id: string ) => void;
    onNodeSelect?: ( id: string ) => void;
    handle: any;
    isSortable?: boolean;
}

const useFocus = (): [any, () => void] => {
    const htmlElRef = useRef<HTMLInputElement>( null );
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    return [ htmlElRef, setFocus ];
};

const SortableFlowItem: FC<SortableFlowItemProps> = ({flow, selectedId, openedId, onFlowSelect, onNodeSelect, handle, isSortable}) => {
    const hasChildActive = flow.nodes.has( selectedId as string );
    const isActive = flow.id === selectedId || hasChildActive;
    const isFlowBuilderSidebarExpanded = useFlowBuilderSidebarState();
    const dispatch = useAppDispatch();
    const [isRenaming, setIsRenaming] = useState( false );
    const [newName, setNewName] = useState( "" );
    const [registerOnKeyPressEnter] = useKeyPress([[ "Enter" ]]);
    const [renameInputRef, setRenameInputFocus] = useFocus();

    const onDelete = useCallback(() => {
        console.log( "TODO - Delete flow" );
        dispatch( removeFlow({ id: flow.id }));
    }, [dispatch, flow.id]);

    const onRename = useCallback(() => {
        setNewName( flow.name );
        setIsRenaming( true );
        setTimeout(() => {
            setRenameInputFocus();
        }, 100 );
    }, [flow.name, setRenameInputFocus]);

    const onDuplicate = useCallback(() => {
        console.log( "TODO - Duplicate flow" );
    }, []);
    
    const onNewNameChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        setNewName( e.target.value );
    };

    const onNewNameBlur = () => {
        if ( newName !== "" ) {
            dispatch( editFlow({ id: flow.id, name: newName }));
            setNewName( "" );
        }
        
        setIsRenaming( false );
    };

    const onNameClick = ( e: React.MouseEvent ) => {
        if ( e.detail === 2 ) {
            onRename();
        }
    };

    return (
        <div key={flow.id} className={`FlowsMenu__nav${isActive ? " active" : ""}` }>
            <div className={"FlowsMenu__navLink"} onClick={() => onFlowSelect?.( flow.id )}>
                <span className="FlowsMenu__navLink_left">
                    {isSortable && (
                        <span className={cx( "FlowsMenu__navLink-content", { "FlowsMenu__navLink-content--expanded": isFlowBuilderSidebarExpanded })}>
                            {handle}
                        </span>
                    )}
                    <i className="fa fa-sitemap"></i>
                    <span className={cx( "FlowsMenu__navLink-content", { "FlowsMenu__navLink-content--expanded": isFlowBuilderSidebarExpanded })}>
                        {isRenaming ? (
                            <div>
                                <input 
                                    className="rename-input"
                                    placeholder={flow.name} 
                                    value={newName} 
                                    onChange={onNewNameChange} 
                                    onBlur={onNewNameBlur} 
                                    ref={renameInputRef}
                                    {...registerOnKeyPressEnter( onNewNameBlur, { preventDefault: true })}
                                />
                            </div>
                        ) : (
                            <div onClick={onNameClick}>
                                {flow.name}
                            </div>
                        )}
                    </span>
                </span>
                <span 
                    className={cx( "FlowsMenu__navLink_right", { "FlowsMenu__navLink_right--expanded": isFlowBuilderSidebarExpanded })}
                >
                    <FlowItemMenu onDelete={onDelete} onRename={onRename} onDuplicate={onDuplicate} />
                </span>
            </div>
        </div>
    );
};

export default SortableFlowItem;

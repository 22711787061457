import { FormSchema } from "legacy/context/contextTypes";
import { verify } from "legacy/tools/validator";
import { FormDispatch, FormState } from "./formReducer";

export async function updateErrors ( dispatch: FormDispatch, state: FormState, formSchema: FormSchema ) {
    const errors = await verify( state.value, formSchema );
    Object.entries( errors ).forEach(([key, field]) => {
        errors[key].touched = state.touchedFields && state.touchedFields[key];
        if ( !errors[key].touched ) errors[key].messages = []; // hide messages while input is not touched
    });
    dispatch({type: "UPDATE_ERRORS", payload: errors});
}

export function markFieldsAsTouched ( dispatch: FormDispatch ) {
    dispatch({type: "MARK_FIELDS_AS_TOUCHED"});
}

export function updateForm ( dispatch: FormDispatch, payload: any ) {
    dispatch({type: "UPDATE_VALUE", payload});
}

export function submitForm ( dispatch: FormDispatch ) {
    dispatch({type: "SUBMIT"});
}

export function resetForm ( dispatch: FormDispatch, payload: FormState ) {
    dispatch({type: "RESET", payload});
}

export function success ( dispatch: FormDispatch ) {
    dispatch({type: "SUCCESS"});
}

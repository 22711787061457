import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CardFlow, CARDS } from "../../clean-archi/core/entities/flowbuilder";

interface HookInput {
  botId: string;
  botInstanceId: string;
  branchName: string;
  card?: CardFlow;
  language: string;
}

export const useFlowElementLink = ({
    botId, botInstanceId, branchName, card, language
}: HookInput ) => useMemo(() => {
    if ( !card ) return () => null;
    const cardType = card?.type as CARDS;
    let link: string;
    let title: string;
    switch ( cardType ) {
        case CARDS.Intent:
            link = `/${botId}/${branchName}/nlu/intents/update/${card?.intent?.id}?language=${language}`;
            title = "Link to intent";
            break;
        case CARDS.ContentText:
        case CARDS.ContentQuickReply:
            link = `/${botId}/${botInstanceId}/${branchName}/acm/responses/update/${card?.acm?.id}?language=${language}`;
            title = "Link to Acm";
            break;
    }
  
    return () =>
        <Dropdown.Item>
            <i className="far fa-link"></i>
            <Link to={link}>{title}</Link>
        </Dropdown.Item>;
}, [botId, botInstanceId, branchName, card?.id, language]);
    
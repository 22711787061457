import React, { FC } from "react";
import Conversations from "./graphs/Conversations";
import Misunderstanding from "./graphs/Misunderstanding";
import TransfersNumber from "./graphs/TransfersNumber";
import TotalInteractions from "./graphs/TotalInteractions";
import ConversationDuration from "./graphs/ConversationDuration";
import MessageDistributionGraph from "./graphs/MessageDistributionGraph";
import StatisticsFilters from "./statistics-filters/StatisticsFilters";
import "./Statistics.scss";
import UniqueUsers from "./graphs/UniqueUsers";
import Stack from "react-bootstrap/Stack";
import WeeklyConversations from "./graphs/WeeklyConversations";

const Statistics: FC = () => {
    return (
        <div className="main-content">
            <StatisticsFilters />
            <div className="mt-3">
                <Stack direction="horizontal" gap={3}>
                    <div className="w-100">
                        <Stack direction="vertical" gap={3}>
                            <Stack direction="horizontal" gap={3}>
                                <div className="w-50 dashboard-item">
                                    <TotalInteractions />
                                </div>

                                <div className="w-50 dashboard-item">
                                    <ConversationDuration />
                                </div>
                                <div className="w-50 dashboard-item">
                                    <TransfersNumber />
                                </div>
                            </Stack>
                        </Stack>
                    </div>
                </Stack>
            </div> 
 
            <div className="dashboard-item w-100 mt-3">
                <Conversations />
            </div>
            <div className="mt-3">
                <Stack direction="horizontal" gap={3}>
                    <div className="w-100 dashboard-item-graph">
                        <MessageDistributionGraph />
                    </div>
                    <div className="w-100 dashboard-item-graph">
                        <UniqueUsers />
                    </div>
                </Stack>
            </div>
            <div className="w-100 dashboard-item  mt-3">
                <Misunderstanding />
            </div>
            {/* TODO: add weekly conversations after api developping
                    <div className="w-100 dashboard-item  mt-3">
                        <WeeklyConversations />
                    </div>*/}
        </div>

    );
};

export default Statistics;
import React from "react";

const FrameInfoSkeleton = () => {
    return (
        <div className="skeleton-frame-info">
            <div className="skeleton-number"></div>
            <div className="skeleton-percentage"></div>
        </div>
    );
};

export default FrameInfoSkeleton;

import { createSelector } from "reselect";
import { MainReducerState } from "legacy/store/reducers";
import { TrainingState } from "../../../../core/entities/training/TrainingState";

export const getTraining: ( state: MainReducerState ) => TrainingState =
    createSelector(
        ( state: MainReducerState ) => state.training,
        ( training ) => {
            return training;
        }
    );

import { PayloadAction } from "@reduxjs/toolkit";
import { FlowBuilderState, NodeFlow } from "../../../entities/flowbuilder";

/**
 * @TODO Display a warning notification if no flow created OR don't display node to be created
 */

export function addNodeReducer ( state: FlowBuilderState, action: PayloadAction<NodeFlow> ) {
    if ( state.botFlow.flows && state.botFlow.flows.size > 0 ) {
        // Find the selected flow
        const flow = state.botFlow.flows.get( state.botFlow.selectedFlowId as string );
        if ( flow ) {
            flow.nodes.set( action.payload.id as string, action.payload );
            state.botFlow.selectedNodeId = action.payload.id;
            state.botFlow.numberOfNodes++;
            // Request a rerender
            state.botFlow.shouldRerenderNodes = true;
            state.botFlow.shouldRerenderEdges = true;
        }
    }
}

// function getDefaultCards ( type?: NODES ) {
//     const cards = new Map<CardFlow["id"], CardFlow>();

//     switch ( type ) {
//         case NODES.Trigger: {
//             const cardId = nanoid();
//             cards.set( cardId, {
//                 id: cardId,
//                 index: 0,
//                 type: CARDS.Payload,
//                 connections: {
//                     input: [],
//                     output: []
//                 }
//             });
//             break;
//         }
//         case NODES.BotResponse: {
//             const cardId = nanoid();
//             cards.set( cardId, {
//                 id: cardId,
//                 index: 0,
//                 type: CARDS.ContentText,
//                 connections: {
//                     input: [],
//                     output: []
//                 }
//             });
//             break;
//         }
//     }

//     return cards;
// }
import React, {Component} from "react";
import PropTypes     from "prop-types";
import AccordionList from "legacy/components/AccordionList/AccordionList";

class CheckboxListContainer extends Component {
    render () {
        if ( !this.props.options )
            return null;

        if ( this.props.categorized )
            return (
                <AccordionList
                    list={this.props.options.map(({name, options}) => ({
                        title: name, content: options
                    }))}
                    className={this.props.className}
                    style={this.props.style}
                    panelStyle={this.props.panelStyle}
                    headingStyle={this.props.headingStyle}
                    titleStyle={this.props.titleStyle}
                    bodyStyle={{...this.props.bodyStyle, columnCount: this.props.column}}
                />
            );
        else
            return (
                <div
                    className={this.props.className}
                    style={{...this.props.style, ...this.panelGroupStyle, columnCount: this.props.column}}
                >
                    {this.props.options.map( item => item )}
                </div>
            );
    }
}

CheckboxListContainer.propTypes = {
    column: PropTypes.number,
    categorized: PropTypes.bool,
    options: PropTypes.oneOfType([
        PropTypes.arrayOf( PropTypes.element ),
        PropTypes.arrayOf( PropTypes.shape({
            name: PropTypes.string,
            options: PropTypes.arrayOf( PropTypes.element )
        }))
    ]),
    panelStyle: PropTypes.object,
    headingStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    bodyStyle: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
};
CheckboxListContainer.defaultProps = {
    column: 1
};

export default CheckboxListContainer;

import { PayloadAction } from "@reduxjs/toolkit";
import { CardFlow, FlowBuilderState } from "../../../entities/flowbuilder";
import { selectNodeFromFlow } from "../utils";

export function updateCardReducer ( state: FlowBuilderState, action: PayloadAction<Partial<CardFlow | CardFlow[]>, string, {nodeId: string, flowId: string, cardId: string}> ) {
    if ( state.botFlow.flows && state.botFlow.flows.size > 0 ) {
        const selectedFlowId = action.meta.flowId ?? state.botFlow.selectedFlowId;
        const selectedNodeId = action.meta.nodeId ?? state.botFlow.selectedNodeId;
        
        if ( Array.isArray( action.payload ) && action.payload.length ) {
            const cardIds = action.payload.map( card => card?.id );
            state.botFlow.flows.forEach( flow => {
                flow.nodes.forEach( node => {
                    node.cards.forEach( card => {
                        if ( cardIds.includes( card.id )) {
                            const cardWithIntent = ( action.payload as CardFlow[]).find( item => item.id === card.id );
                            node.cards.set( card.id, cardWithIntent as CardFlow );
                        }
                    });
                });
            });
        } else {
            const node = selectNodeFromFlow({id: selectedNodeId as string, flowId: selectedFlowId as string, state});
            if ( node ) {
                const { id } = action.payload as CardFlow;
                node.cards.set( id, {
                    ...action.payload as CardFlow,
                });
            }
        }
    }
    state.botFlow.loading = false;
}
import PropTypes from "prop-types";


export const branchName = PropTypes.oneOf(["develop", "prod", "global"]);

export const environment = PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    branchName: branchName,

    // for bot form
    serviceUrl: PropTypes.string,
    nluProjectId: PropTypes.string,
    chatbaseAPI: PropTypes.string,
});

export const instanceChannel = PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    externalId: PropTypes.string,
    environment: PropTypes.string,
    config: PropTypes.object,
    intents: PropTypes.array,
    sessionDuration: PropTypes.number,
    invocationName: PropTypes.string,
    subdomain: PropTypes.string,
    notes: PropTypes.string,
});

export const instance = PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    channels: PropTypes.arrayOf( instanceChannel )
});

export const bot = PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    instances: PropTypes.arrayOf( instance ),
    environments: PropTypes.arrayOf( environment ),
    languages: PropTypes.arrayOf( PropTypes.string ),

    description: PropTypes.string,
});

export const botsList = PropTypes.arrayOf( bot );

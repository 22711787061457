import { useEffect } from "react";
import { typeOf } from "legacy/tools/functions/functions";

export const useDocumentTitle = ( breadCrumbArray: any[] | null ) => {

    useEffect(() => {
        document.title = generateTitle( breadCrumbArray );
    }, [breadCrumbArray]);
    
    function refactoRouteName ( name: string ) {
        const n = name.split( "_" );
        name = "";
        for ( const i in n ) {
            name = `${name + n[i].toUpperCase().charAt( 0 ) + n[i].toLowerCase().slice( 1 )} ` ;
        }
        
        return name;
    }

    const generateTitle = ( breadcrumbArray: any[] | null ): string => {
        switch ( breadCrumbArray?.length ) {
            case 2:
                if ( breadCrumbArray?.[1].id === "function" ) {
                    if ( breadCrumbArray?.[1].name ) {
                        return `Hellomybot - ${refactoRouteName( breadcrumbArray?.[0]?.name?.props.textCode )} - ${refactoRouteName( breadcrumbArray?.[1]?.name )}`
                    }
                    return `Hellomybot  - ${refactoRouteName( breadcrumbArray?.[0]?.name?.props.textCode )}`;
                } 
                return `Hellomybot - ${refactoRouteName( breadcrumbArray?.[1]?.name?.props.textCode )}`;
            case 3:
                if ( breadcrumbArray?.[2]?.name ) { 
                    return `Hellomybot - ${refactoRouteName( breadcrumbArray?.[1]?.name?.props.textCode )} - ${refactoRouteName( typeOf(breadcrumbArray?.[2]?.name, "string") ? breadcrumbArray?.[2]?.name : breadcrumbArray?.[2]?.name?.props.textCode)}`;
                } 
                return `Hellomybot - ${refactoRouteName( breadcrumbArray?.[1]?.name?.props.textCode )}`;
            default:
                return "Hellomybot";
        }
    };

};
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
// import { TranslationManager }                                  from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { addSuccessNotificationAction } from "legacy/store/actions/notificationsActions";
import { TranslationManager } from "translation-manager";

import { defaultRequestSaga } from "legacy/store/sagas/defaultSagas";
// import { getSelectedLanguage } from "tools/functions/functionsHMB";
// import { addSuccessNotificationAction } from "actions/notificationsActions";
// import * as textCodes from "legacy/constants/translations/textCodes.json";
import {
    fetchTagAggregationListRoutine, removeTagAggregationsRoutine,
    // removeTagAggregationsRoutine,
    // setTagAggregationsListFilterAction,
    // setTagAggregationsListCheckedIdsAction  
    // updateTagAggregationRoutine
} from "legacy/store/actions/tagAggregationsActions";
import { getStatTagAggregationsRequest, deleteTagAggregationRequest } from "legacy/store/requests/stats/tagAggregationRequests";



export const fetchTagAggregationsListWorker = defaultRequestSaga( fetchTagAggregationListRoutine, function* ({ filterBy, botInstanceId }) {
    // const [selectedConfiguration] = yield select( state => [state.app.selectedConfiguration]);
    // if ( !botInstanceId )
    //     botInstanceId = selectedConfiguration.instanceId;
    const res = yield call( getStatTagAggregationsRequest, { botInstanceId, filterBy });
    return { tagAggregationsList: res.data.result };


    // check if the response is an empty object
    // if ( Object.keys( res ).length === 0 && res.constructor === Object )
    //     yield put({ type: fetchTagAggregationListRoutine.FAILURE, payload: res.data });
    // else
    //     yield put({ type: fetchTagAggregationListRoutine.SUCCESS, payload: res.data });

    // return { tagAggregationsList: res.data };
    // return { displayList: res.data };
    // return { integrationTestList: "" };
});

// export const setTagAggregationWorker = defaultRequestSaga();

export const deleteTagAggregationsWorker = defaultRequestSaga( removeTagAggregationsRoutine, function* ({ tagAggregations }) {
    const res = yield call( deleteTagAggregationRequest, tagAggregations );
    return res.data.result;
}, {
    onSuccess: function* ({ result, initial }) {
        yield put( addSuccessNotificationAction({ message: TranslationManager.getText( textCodes.DELETED ) }));
        const [selectedConfiguration] = yield select( state => [state.app.selectedConfiguration]);
        const botInstanceId = selectedConfiguration.instanceId;
        yield put( fetchTagAggregationListRoutine({ botInstanceId }));
    },
});

// export const updateTagAggregationWorker = defaultRequestSaga();

/*
 *********** WATCHER ***********
 */
function* watchFetchTagAggregationsList () {
    yield takeLatest( fetchTagAggregationListRoutine.TRIGGER, fetchTagAggregationsListWorker );
}

// function* watchSetTagAggregation () {
//     yield takeLatest( setTagAggregationsListFilterAction.TRIGGER, setTagAggregationWorker );
// }

function* watchDeleteTagAggregations () {
    yield takeLatest( removeTagAggregationsRoutine.TRIGGER, deleteTagAggregationsWorker );
}

// function* watchUpdateTagAggregation () {
//     yield takeLatest( updateTagAggregationRoutine.TRIGGER, updateTagAggregationWorker );
// }

/*
 *********** EXPORT ***********
 */
export default function* root () {
    yield all([
        fork( watchFetchTagAggregationsList ),
        // fork( watchSetTagAggregation ),
        fork( watchDeleteTagAggregations ),
        // fork( watchUpdateTagAggregation ),
    ]);
}

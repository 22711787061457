import React, {FC} from "react";
import { Text }             from "translation-manager-react";
import {Form, Modal} from "react-bootstrap";
import Button from "../../../../../../legacy/components/Button/Button";
import cx from "classnames";
import LoadingModalOverlay from "legacy/components/LoadingModalOverlay/LoadingModalOverlay";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager }         from "translation-manager";
import {get} from "lodash";
import FormInputs from "../../../../../../legacy/components/form/FormInputs/FormInputs";
import { updateForm } from "legacy/context/form/formActions";

import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
import { FormSchema } from "legacy/context/contextTypes";
import { emptyStringRegex } from "legacy/constants/appConstants/regex";
import useForm from "legacy/context/form/useForm";
import useKeyPress from "legacy/hooks/useKeyPress";
import { HttpFunctionStorelessGateway } from "clean-archi/adapters/secondary/gateways/flowbuilder/function/HttpFunctionStorelessGateway";
import { getAppConfigParams } from "../../../view-models-generators/config/configParamsViewModels";
import { useCodeSiderbar } from "../CodeSidebar";
import { ICreateFunctionsInput } from "clean-archi/core/interfaces/gateways/flowbuilder/FunctionStorelessGateway";

import "./ModalCreateFunction.scss";
import { selectFunction } from "../../../../../store/flowbuilder";

const fetchFunctionGateway = new HttpFunctionStorelessGateway();

interface ModalCreateFunctionProps {
    className?: string;
    onHide: () => void;
    onSuccess: ( id: any ) => void;
    isVisible: boolean;
    loading?: boolean;
    isSystem?: boolean;
}

const createFlowFormSchema: FormSchema = {
    name: {
        defaultValue: "",
        validate: [
            {
                validator: function ( value: string ) {
                    return !emptyStringRegex.test( value );
                },
                message:   TranslationManager.getText( textCodes.ERROR_CANT_BE_EMPTY ),
            },
        ],
    },
    isCron: {
        defaultValue: false,
    }
};

const ModalCreateFunction: FC<ModalCreateFunctionProps> = ({
    className,
    onHide,
    onSuccess,
    isVisible,
    loading,
    isSystem,
}) => {
    const {setRerenderList} = useCodeSiderbar();
    const {formState, formDispatch, submit, reset} = useForm( createFlowFormSchema );
    const value = formState.value;
    const errors = formState.errors;
    const [registerKeyPressEnter] = useKeyPress([[ "Enter" ]]);
    const appConfigParams = useAppSelector( getAppConfigParams );
    const dispatch = useAppDispatch();

    const handleOnclick = () => {
        if ( value.name ) {

            let payload: ICreateFunctionsInput = {
                branchName: appConfigParams.branchName,
                name: value.name,
                code: "",
                description: "",
            };

            if ( isSystem ) {
                payload = {
                    ...payload,
                    isSystem: true,
                };
            } else {
                payload = {
                    ...payload,
                    botId: appConfigParams.botId,
                    isCron: value.isCron,
                };
            }

            fetchFunctionGateway.createFunction( payload ).then(( e ) => {
                if ( e?.data?.id ) {
                    
                    dispatch( selectFunction( e.data.id ));
                    setRerenderList( true );
                    reset();
                    onHide();
                }
            });
        }
        submit();
    };

    return (
        <Modal size={"lg"} show={isVisible} onHide={onHide} className={cx( "ModalImportData", className )}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Text textCode={textCodes.ADD_FUNCTION} capitalizeSentence/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingModalOverlay show={loading}/>
                <Form>
                    <fieldset>
                        {isSystem
                            ? <FormInputs
                                ncols={"col-12"}
                                proprieties={[
                                    {
                                        label:       <Text textCode={textCodes.NAME}/>,
                                        type:        "text",
                                        autoFocus:   true,
                                        tabIndex:    1,
                                        placeholder: TranslationManager.getText( textCodes.NAME, { option: TranslationManager.textOptions.lowercase }),
                                        value:       get( value, "name" ),  
                                        onChange:    e => updateForm( formDispatch, { valuePath: "name", value: e.target.value }),
                                        errors:      get( errors, "name.messages" ),
                                        ...registerKeyPressEnter( handleOnclick, { preventDefault: true }),
                                    },
                                ]}
                            />
                            : <FormInputs
                                ncols={"col-12"}
                                proprieties={[
                                    {
                                        label:       <Text textCode={textCodes.NAME}/>,
                                        type:        "text",
                                        autoFocus:   true,
                                        tabIndex:    1,
                                        placeholder: TranslationManager.getText( textCodes.NAME, { option: TranslationManager.textOptions.lowercase }),
                                        value:       get( value, "name" ),  
                                        onChange:    e => updateForm( formDispatch, { valuePath: "name", value: e.target.value }),
                                        errors:      get( errors, "name.messages" ),
                                        ...registerKeyPressEnter( handleOnclick, { preventDefault: true }),
                                    },
                                    {
                                        label:       <Text textCode={textCodes.IS_CRON_TASK}/>,
                                        type:        "checkbox",
                                        tabIndex:    2,
                                        value:       get( value, "isCron" ),
                                        onChange:    e => updateForm( formDispatch, { valuePath: "isCron", value: e.target.checked }),
                                    },
                                ]}
                            />
                        }
                    </fieldset>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={loading} onClick={onHide}>
                    <Text textCode={textCodes.CLOSE}/>
                </Button>
                <Button
                    disabled={loading || !value.name}
                    bsStyle={"primary"}
                    fill
                    onClick={handleOnclick}
                >
                    <Text textCode={textCodes.ADD_FUNCTION} capitalizeSentence/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default (
    ModalCreateFunction
);
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainReducerState } from "../../../../../legacy/store/reducers";
import { Dependencies } from "../../../../store/utils/storeForTesting";
import { Flow, ApiError } from "../../../entities/flowbuilder";

export interface InputAddFlowThunk {
    botFlowId: string;
    index: number;
    name: string;
}

export const addFlow = createAsyncThunk<Partial<Flow>, InputAddFlowThunk, { state: MainReducerState, extra: Partial<Dependencies>, rejectValue: ApiError} >(
    "flowbuilder/addFlow",
    async ( input: InputAddFlowThunk, {getState, extra, rejectWithValue}) => {
        const { botId, branchName } = getState().app.selectedConfiguration;
        const response = await extra.flowGateway?.addFlow({...input, botId, branchName});
        if ( !response?.data || response?.error ) {
            return rejectWithValue({
                message: response?.message as string,
                error: response?.error as string,
            });
        }
        return response?.data as Partial<Flow>;
    }
);
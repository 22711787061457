import { Connection } from "react-flow-renderer";
import { Store } from "redux";
import { MainReducerState } from "legacy/store/reducers";
import {
    CARDS,
    FLOW_ELEMENT_TYPE,
    FLOW_SPECIAL_ELEMENT,
    AcmStaticQuickReplyValue,
    AcmStaticQuickReplyResponseItemResult,
    ConnectionItem,
    CardFlow,
    NodeFlow,
    SpecialConnectionId
} from "clean-archi/core/entities/flowbuilder";

export function buildSpecialConnectionId ({ id, cardId }: SpecialConnectionId ): string {
    return JSON.stringify({ id, cardId });
}

function parseSpecialConnectionId ( idString: string ): SpecialConnectionId | undefined {
    try {
        const { id, cardId }: SpecialConnectionId = JSON.parse( idString );
        return { id, cardId };
    } catch ( e ) {
        return undefined;
    }
}

export const buildConnectionPayload = (
    { source, sourceHandle, target, targetHandle }: Connection, store: Store<MainReducerState> 
): {from: ConnectionItem, to: ConnectionItem} => {
    if ( !sourceHandle ) {
        throw new Error();
    }
    const flowId = store.getState().flowbuilder.botFlow.selectedFlowId as string;
    let sourceCard: CardFlow | undefined;
    let sourceItem: AcmStaticQuickReplyValue | undefined;
    let sourceItemType: FLOW_ELEMENT_TYPE | undefined;
    let targetElement: NodeFlow | CardFlow | undefined;
    
    const nodeCards = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes.get( source as string )?.cards;
    sourceCard = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes.get( source as string )?.cards.get( sourceHandle );

    if ( !sourceCard ) {
        if ( nodeCards?.has( sourceHandle )) {
            sourceCard = nodeCards.get( sourceHandle );
        } else {
            const connectionId = parseSpecialConnectionId( sourceHandle );
            if ( !connectionId || !nodeCards?.has( connectionId.cardId )) throw new Error();

            sourceCard = nodeCards.get( connectionId.cardId );
            switch ( sourceCard?.type ) {
                case CARDS.ContentQuickReply: {
                    if ( !sourceCard.acm ) throw new Error();
                    const responseItem = sourceCard.acm.responseItem as AcmStaticQuickReplyResponseItemResult;
                    const reply = responseItem.values.find( value => value.id === connectionId.id );
                    if ( !reply ) throw new Error();
                    sourceItem = reply;
                    sourceItemType = FLOW_SPECIAL_ELEMENT.ContentQuickReplySingleReply;
                    break;
                }
                default:
                    throw new Error();
            }
        }
    }

    if ( !targetHandle ) {
        targetElement = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes.get( target as string );
    } else {
        targetElement = store.getState().flowbuilder.botFlow.flows.get( flowId )?.nodes.get( target as string )?.cards.get( targetHandle );
    }
    
    return {
        from: {
            ...( sourceItem
                ? {
                    id: sourceItem.id,
                    cardId: sourceCard?.id as string,
                    type: sourceItemType as FLOW_SPECIAL_ELEMENT
                }
                : {
                    id: sourceCard?.id as string,
                    type: sourceCard?.type as CARDS,
                }
            ),
            nodeId: source as string
        },
        to: {
            id: targetElement?.id as string,
            type: targetElement?.type as FLOW_ELEMENT_TYPE,
            nodeId: targetHandle ? target as string : undefined
        }
    };
};
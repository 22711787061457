import React, { FC } from "react";


import Statistics from "./statistics/Statistics";
const Dashbaord: FC = () => {

    return (
        <>
            <Statistics />
        </>
    );
};

export default Dashbaord;
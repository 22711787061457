import React, {FC, useCallback, useMemo} from "react";
import textCodes from "legacy/constants/translations/textCodes.json";
import { Text } from "translation-manager-react";
import { Form, Table } from "react-bootstrap";
import TrainingRow from "./TrainingRow";
import { FormCheckInputProps } from "react-bootstrap/esm/FormCheckInput";
import Select from "react-select";
import DateTime from "react-datetime";
import { getBotLanguages, getIsProd } from "../../view-models-generators/config/configParamsViewModels";
import Button from "../../../../../legacy/components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../../../../legacy/store/typedHooks";
import { useLoadMessages } from "./useLoadMessages";
import { TRAINING_STATUS_IGNORED, TRAINING_STATUS_PENDING, TRAINING_STATUS_TRAINED, TRAINING_STATUS_UNTRAINED } from "../../../../core/entities/training/TrainingData";
import { capitalize } from "lodash";
import { useInitLanguageFilter } from "./useInitLanguageFilter";
import moment from "moment";
import { getTraining } from "../../view-models-generators/training/trainingViewModels";
import { checkAllMessages, refreshMessages, resetFilters, setFilters, uncheckAllMessages } from "../../../../store/training";
import { ignoreMessages } from "../../../../core/use-cases/training/ignore-messages/ignoreMessagesThunk";
import SyncButton from "../../../../../legacy/components/Buttons/SyncButton";
import { TranslationManager }         from "translation-manager";

import "./Training.scss";
import { cancelIgnoredMessages } from "../../../../core/use-cases/training/cancel-ignored-messages/cancelIgnoredMessagesThunk";
import { useBasicAction } from "../../../../../legacy/hooks/useBasicAction";
import { ALL_BASIC_ACTIONS } from "../../../../../legacy/constants/hmbConstants";
import { TrainingPagination } from "./TrainingPagination";
import { Redirect } from "react-router";
import routes from "../../../../../legacy/constants/routes";
import { setMessagesToPending } from "../../../../core/use-cases/training/set-messages-to-pending/setMessagesToPendingThunk";
import Loader from "../../../../../legacy/components/Loader/Loader";

const Training: FC = () => {
    const canUpdateTraining = useBasicAction( ALL_BASIC_ACTIONS.UPDATE_TRAINING );
    const dispatch = useAppDispatch();
    const training = useAppSelector( getTraining );
    const isProd = useAppSelector( getIsProd );

    useInitLanguageFilter();
    useLoadMessages();

    const botLanguages = useAppSelector( getBotLanguages );
    const botLanguagesOptions = useMemo(() => botLanguages?.map(( language ) => ({label: capitalize( language ), value: language})), [botLanguages]);

    const onCheckAllChange: FormCheckInputProps["onChange"] = useCallback(({ target: { checked } }) => {
        if ( checked ) {
            dispatch( checkAllMessages( training.messages.map( message => message._id )));
        } else {
            dispatch( uncheckAllMessages());
        }
    }, [dispatch, training.messages]);

    return !isProd
        ? (
            <div className="main-content">
                <div className="card">
                    <div className="card-wrapper">
                        <div className="header">
                            <div className="d-flex flex-row justify-content-between">
                                <h4 className="title">
                                    <Text textCode={textCodes.TRAINING} option={Text.options.capitalize}/>
                                
                                    <SyncButton
                                        onClick={() => dispatch( refreshMessages())}
                                        tooltipText={TranslationManager.getText( textCodes.REFRESH_LIST )}
                                        tooltipProps={{ delay: [600, 0] }}
                                    />
                                </h4>
                                <Button 
                                    icon simple tooltipText={<Text textCode={textCodes.RESET_FILTERS}/>}
                                    onClick={() => dispatch( resetFilters())}
                                    className="btn-fa-times"
                                >
                                    <i className="fal fa-filter"></i>
                                    <i className="fal fa-times"></i>
                                </Button>
                            </div>
                        </div>
                        <div className="content">
                            <div className="table-page">
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="filters-group d-flex flex-row align-items-center">
                                        <Select
                                            isClearable={false}
                                            options={[
                                                {label: capitalize( TRAINING_STATUS_IGNORED ), value: TRAINING_STATUS_IGNORED},
                                                {label: capitalize( TRAINING_STATUS_TRAINED ), value: TRAINING_STATUS_TRAINED},
                                                {label: capitalize( TRAINING_STATUS_UNTRAINED ), value: TRAINING_STATUS_UNTRAINED},
                                                {label: capitalize( TRAINING_STATUS_PENDING ), value: TRAINING_STATUS_PENDING},
                                            ]} 
                                            placeholder={"Message status"}
                                            onChange={( option ) => dispatch( setFilters({
                                                status: option?.value,
                                                page: "1"
                                            }))}
                                            value={{label: capitalize( training.filters.status ), value: training.filters.status}}
                                        />
                                    &nbsp;
                                        <Select 
                                            isClearable={false}
                                            options={[{label: "All Messages", value: "all_messages"}, {label: "Fallback", value: "fallback"}]}
                                            placeholder={<Text textCode={textCodes.MESSAGE_OR_FALLBACK}/>} 
                                            onChange={( option ) => dispatch( setFilters({
                                                fallback: option?.value === "fallback",
                                                page: "1"
                                            }))}
                                            value={{
                                                label: training.filters.fallback ? "Fallback" : "All Messages",
                                                value: training.filters.fallback ? "fallback" : "all_messages"
                                            }}
                                        />
                                    &nbsp;
                                    |
                                    &nbsp;
                                        <div className="d-flex flex-row dates-range align-items-center">
                                            <i className="fa fa-calendar-alt"></i>
                                        &nbsp;
                                            <DateTime
                                                inputProps={{className: "form-control", placeholder: "From date"}}
                                                onChange={( date ) => dispatch( setFilters({
                                                    startDate: moment( date ).toISOString(),
                                                    page: "1"
                                                }))}
                                                value={new Date( training.filters.startDate )}
                                            />
                                        &nbsp;
                                            <DateTime
                                                inputProps={{className: "form-control", placeholder: "To date"}}
                                                onChange={( date ) => dispatch( setFilters({
                                                    endDate: moment( date ).toISOString(),
                                                    page: "1"
                                                }))}
                                                value={new Date( training.filters.endDate )}
                                            />
                                        </div>
                                    </div>
                                    <div className="filters-group d-flex flex-row align-items-center">
                                        <Select
                                            isClearable={false}
                                            options={botLanguagesOptions} 
                                            placeholder={"Language"}
                                            onChange={( option ) => dispatch( setFilters({
                                                language: option?.value,
                                                page: "1"
                                            }))}
                                            value={{label: capitalize( training.filters.language ), value: training.filters.language}}
                                        /> 
                                    &nbsp;
                                        <Select
                                            isClearable={false}
                                            options={[
                                                {label: "Develop", value: "develop"},
                                                {label: "Prod", value: "prod"},
                                            ]} 
                                            placeholder={"Branch Name"}
                                            onChange={( option ) => dispatch( setFilters({
                                                branchName: option?.value,
                                                page: "1"
                                            }))}
                                            value={{label: capitalize( training.filters.branchName ), value: training.filters.branchName}}
                                        />                    
                                    </div>
                                </div>

                                <div className="HMBTable__container">

                                    {training.loading && (
                                        <div className={"HMBTable__loadingOverlay"}>
                                            <Loader/>
                                        </div>
                                    )}

                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Form.Check 
                                                        value="checkAll"
                                                        type="checkbox"
                                                        id={"check-all"}
                                                        onChange={onCheckAllChange}
                                                        disabled={!canUpdateTraining}
                                                    />
                                                </th>
                                                <th>
                                                Date
                                                </th>
                                                <th>Message</th>
                                                <th>Intent</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {training.messages.map( message =>
                                                <TrainingRow
                                                    key={message._id}
                                                    message={message}
                                                />
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="filters-group">
                                        {training.checkedMessages?.length > 0 && (
                                            <Select 
                                                isClearable={true}
                                                options={
                                                    training.filters.status === TRAINING_STATUS_UNTRAINED
                                                        ? [{label: "Ignore messages", value: "ignore"}, {label: "Set messages pending", value: "pending"} ]
                                                        : [{label: "Cancel ignore messages", value: "cancel_ignore"}]
                                                } 
                                                placeholder={<Text textCode={textCodes.GROUP_ACTION}/>}
                                                isDisabled={!canUpdateTraining}
                                                onChange={( option ) => {
                                                    switch ( option?.value ) {
                                                        case "ignore":
                                                            dispatch( ignoreMessages());
                                                            break;
                                                        case "cancel_ignore":
                                                            dispatch( cancelIgnoredMessages());
                                                            break;
                                                        case "pending":
                                                            dispatch( setMessagesToPending());
                                                            break;
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        {training.nbOfPages > 1
                                    && <TrainingPagination
                                        currentPage={parseInt( training.filters.page )}
                                        nbOfPages={training.nbOfPages}
                                        nbOfPagesToDisplay={5}
                                        onClick={( page ) => dispatch( setFilters({ page: page.toString()})) }
                                    />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : <Redirect to={routes.home.buildPath()}/>;
};

export default Training;
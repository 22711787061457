import React, { FC, ReactNode } from "react";
import { Position } from "react-flow-renderer";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import { hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import FormManager from "../../forms/FormManager/FormManager";
import CardHandle from "../../handles/CardHandle/CardHandle";
import routes from "legacy/constants/routes";
import { useHistory } from "react-router";
import { useAppDispatch } from "legacy/store/typedHooks";
import { useFlowBuilder } from "legacy/context/flowBuilder/FlowBuilderContext";

import "./CardFlowEntrypoint.scss";
import { selectFlow } from "../../../../../../store/flowbuilder";

interface Props extends CardFlow {
    form?: any;
    index: number;
    isLastCard: boolean;
    onClick?: Function;
    handle?: ReactNode;
}

const CardFlowEntrypoint: FC<Props> = ({id, type, form, isEditing, handle, flowEntrypoint, connections}) => {
    
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {centerOnNode} = useFlowBuilder();
    
    const redirectToFlow = ( e: React.MouseEvent<HTMLButtonElement> ) => {
        if ( flowEntrypoint?.flowId ) {
            e.preventDefault();
            e.stopPropagation();
            history.push( `${routes.flowBuilderFlow.buildPath({id: flowEntrypoint.flowId}) }` );
            dispatch( selectFlow({flowId: flowEntrypoint.flowId, nodeId: flowEntrypoint.nodeId}));
            if ( flowEntrypoint.nodeId ) {
                centerOnNode( flowEntrypoint.nodeId, 500 );
            }
        }
    };

    return (
        <div className="bot-card-flowentrypoint-container">
            <div className="bot-card bot-card-flowentrypoint">
                <div className="bot-card-type">
                    <i className="card-icon fas fa-arrow-alt-from-left"></i>
                    {handle}
                </div>
                <div className="content">
                    {form && !form.isProd && isEditing ? <FormManager {...form} /> : (
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="card-flowentry-text">
                                {flowEntrypoint && (
                                    <div>
                                        <p className="mb-0">
                                            <small className="card-headline">{flowEntrypoint.flowName}</small>
                                        </p>
                                        {flowEntrypoint.nodeName || ""}
                                    </div>
                                ) || <em>No flow connected</em>}
                            </div>
                            <div>
                                <button className="btn btn-sm btn-light" onClick={redirectToFlow}>
                                    <i className="fas fa-sign-out fa-rotate-180"></i>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {hasOutput( type ) && (
                <CardHandle isConnectable={connections.output.length === 0} id={id} type="source" position={Position.Right}/>
            )}
        </div>
    );
};

export default CardFlowEntrypoint;

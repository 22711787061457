import { PayloadAction } from "@reduxjs/toolkit";
import { CardFlow, FlowBuilderState } from "../../../entities/flowbuilder";
import { selectNodeFromFlow } from "../utils";

export function addCardReducer ( state: FlowBuilderState, action: PayloadAction<Partial<CardFlow | CardFlow[]>, string,
    {nodeId: string, flowId: string, cardId: string } > ) {
    if ( state.botFlow.flows && state.botFlow.flows.size > 0 ) {
        const selectedFlowId = action.meta.flowId ?? state.botFlow.selectedFlowId;
        const selectedNodeId = action.meta.nodeId ?? state.botFlow.selectedNodeId;
        const node = selectNodeFromFlow({id: selectedNodeId as string, flowId: selectedFlowId as string, state});

        if ( node ) {
            if ( Array.isArray( action.payload ) && action.payload.length ) {
                action.payload.forEach( card => {
                    node.cards.set( card?.id as string, card as CardFlow );
                });
            } else {
                node.cards.set(( action.payload as CardFlow ).id,
                    {
                        ...action.payload,
                    } as CardFlow 
                );
            }
        }
    }
    state.botFlow.loading = false;
}
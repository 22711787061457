import { CardFlow, CARDS, FunctionSuggestion, IntentSuggestion, ResponseApi } from "clean-archi/core/entities/flowbuilder";
import {
    IFetchCardInput,
    IFetchIntentSuggestionInput,
    CardStorelessGateway,
    IFetchIntentFunctionInput
} from "clean-archi/core/interfaces/gateways/flowbuilder/CardStorelessGateway";
import { FlowApiReturnType, REGEX_2XX } from "../gateways-entities";
import axiosInstance from "legacy/store/requests/axiosInstance";
import { transformToSystemFnCard } from "../transform-helpers";

export class HttpCardStorelessGateway implements CardStorelessGateway {
    async fetch ( input: IFetchCardInput, branchName: string ): Promise<ResponseApi<CardFlow>> {
        const response = await axiosInstance
            .get<ResponseApi<FlowApiReturnType>>(
                `/api/flowbuilder/card/${input.botId}/${input.branchName}/${input.language}/${input.nodeId}/${input.id}`, {
                    params: {
                        branchName: branchName,
                    }
                })
            .catch( err => err );

        if ( !( response instanceof Error ) && REGEX_2XX.test( response.status.toString())) {
            const {data: card} = response.data;
            let finalData: CardFlow;
            switch ( card.type ) {
                case CARDS.Tag:
                    finalData = transformToSystemFnCard( card );
                    break;
                default:
                    finalData = card;
            }
            return {
                ...response.data,
                data: finalData,
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }

    async fetchIntentSuggestions ( input: IFetchIntentSuggestionInput ): Promise<ResponseApi<IntentSuggestion[]>> {
        try {
            const response = await axiosInstance.get<ResponseApi<IntentSuggestion[]>>( "/api/flowbuilder/intent/suggestions", {
                params: {
                    language: input.language,
                    value: input.value,
                    botId: input.botId,
                    branchName: input.branchName,
                    nodeId: input.nodeId
                }
            });
            const {data} = response.data;
            if ( REGEX_2XX.test( response.status.toString())) {
                return {
                    ...response.data,
                    data: data as IntentSuggestion[],
                };
            }
            return {
                ...response.data,
                data: undefined,
            };
        } catch ( error ) {
            console.error( error as string );
            throw new Error( error as string );
        }
    }

    async fetchFunctionSuggestions ( input: IFetchIntentFunctionInput ): Promise<ResponseApi<FunctionSuggestion[]>> {
        const response = await axiosInstance.get<ResponseApi<FunctionSuggestion[]>>( "/api/flowbuilder/function/suggestions", { params: input });
        const {data} = response.data;
        if ( REGEX_2XX.test( response.status.toString())) {
            return {
                ...response.data,
                data
            };
        }
        return {
            ...response.data,
            data: undefined,
        };
    }
    
}

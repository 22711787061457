import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { getMesageDistribution } from "clean-archi/core/use-cases/statistics/message-distribution/getMessageDistributionThunk";
import ChartSkeleton from "../ChartSkeleton";
import { MessageDistributionApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
import useStatsData from "../hooks/graphsHook";
import { getMessageDistributionData, getMessageDistributionOptions } from "../config/chartsConfig";
import textCodes from "legacy/constants/translations/textCodes.json";
import { TranslationManager } from "translation-manager";

const MesageDistributionGraph = () => {
    const { storeData, loading, statsDates, selectedConfiguration, dispatch } =
        useStatsData({
            dataSelector: ( state ) => state.stats.messagesDistribution,
            loadingSelector: ( state ) => state.stats.messagesDistributionLoading,
        });

    useEffect(() => {
        dispatch(
            getMesageDistribution({
                startDate: statsDates.startDate,
                endDate: statsDates.endDate,
                botInstanceId: selectedConfiguration.instanceId,
                branchName: selectedConfiguration.branchName,
            })
        );
    }, [
        dispatch,
        selectedConfiguration.instanceId,
        selectedConfiguration.branchName,
        statsDates.startDate,
        statsDates.endDate,
    ]);

    const dataPie = getMessageDistributionData(
        storeData as MessageDistributionApiResponse[]
    );
    const labels = dataPie.map(( data ) => data.item );
    const options = getMessageDistributionOptions( labels );
    const series = dataPie.map(( item ) => item.value );

    if ( loading ) return <ChartSkeleton />;

    return (
        <div className="p-3">
            <span className="chart-title">
                {TranslationManager.getText( textCodes.MESSAGE_DISTRIBUTION )}
            </span>
            <Chart options={options} series={series} type="pie" height={200} />
        </div>
    );
};

export default MesageDistributionGraph;
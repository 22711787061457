import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Loader from "legacy/components/Loader/Loader";
import { useFlowBuilder } from "legacy/context/flowBuilder/FlowBuilderContext";
import { useKeyPress } from "react-flow-renderer";
import { useFlowbuilderSearch } from "./useFlowbuilderSearch";
import { SearchMatch } from "clean-archi/core/entities/flowbuilder/SearchMatch";
import { CARDS, NODES } from "clean-archi/core/entities/flowbuilder";
import { useHistory } from "react-router";
import { useAppDispatch } from "legacy/store/typedHooks";
import routes from "legacy/constants/routes";

import "./FlowBuilderSearch.scss";
import { highlightElement, selectFlow } from "../../../../../../store/flowbuilder";

const FlowBuilderSearch = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {centerOnNode, flowBuilderState, openSearch, closeSearch} = useFlowBuilder();
    const isOpen = useMemo(() => flowBuilderState.search.open, [flowBuilderState]);    
    const searchInputRef = useRef<HTMLInputElement>( null );
    const [searchText, setSearchText] = useState( "" );
    const cmdAndFPressed = useKeyPress(["Control+f", "Meta+f"]);
    const escapePressed = useKeyPress(["Escape"]);

    const [ searchResults, isLoading ] = useFlowbuilderSearch( searchText );

    const unHighlightElement = useCallback(() => {
        dispatch( highlightElement( undefined ));
    }, [dispatch]);

    useEffect(() => {
        if ( cmdAndFPressed ) {
            openSearch();
        }

        if ( escapePressed && isOpen ) {
            closeSearch();
            unHighlightElement();
        }
    }, [cmdAndFPressed, openSearch, escapePressed, isOpen, closeSearch, unHighlightElement]);

    const onSearchTextChange = ( e: React.FormEvent<HTMLInputElement> ) => {
        setSearchText( e.currentTarget.value );
    };

    const onSearchClick = useCallback(() => {
        if ( isOpen ) {
            closeSearch();
            unHighlightElement();
        } else {
            openSearch();
        }
    }, [closeSearch, isOpen, openSearch, unHighlightElement]);

    useEffect(() => {
        if ( isOpen ) {
            setTimeout(() => {
                if ( searchInputRef.current ) {
                    searchInputRef.current.focus();
                }
            }, 300 );
        } else {
            if ( searchInputRef.current ) {
                searchInputRef.current.blur();
            }
        }
    }, [isOpen]);

    const clear = useCallback(() => {
        setSearchText( "" );
        if ( searchInputRef.current ) {
            searchInputRef.current.focus();
        }
        unHighlightElement();
    }, [unHighlightElement]);

    const onResultSelect = useCallback(( searchMatch: SearchMatch ) => {
        history.push( routes.flowBuilderFlow.buildPath({id: searchMatch.flowId}));
        dispatch( selectFlow({flowId: searchMatch.flowId, nodeId: searchMatch.nodeId ?? undefined}));
        if ( searchMatch.nodeId ) {
            centerOnNode( searchMatch.nodeId, 500 );
        }

        if ( searchMatch.type !== "Flow" ) {
            dispatch( highlightElement({
                flowId: searchMatch.flowId,
                nodeId: searchMatch.nodeId,
                cardId: searchMatch.cardId,
                type: searchMatch.type,
            }));
        }
    }, [centerOnNode, dispatch, history]);
    const renderResultMeta = useCallback(( type: CARDS | NODES | "Flow" ) => {
        switch ( type ) {
            case "Flow": return {icon: "fal fa-project-diagram", color: "#009688"};
            case NODES.Action: return {icon: "fas fa-window-maximize", color: "#0075cf"};
            case NODES.BotResponse: return {icon: "fas fa-window-maximize", color: "#009688"};
            case NODES.Condition: return {icon: "fas fa-window-maximize", color: "#0075cf"};
            case NODES.FlowEntry: return {icon: "fas fa-window-maximize", color: "#ce6a57"};
            case NODES.FlowExit: return {icon: "fas fa-window-maximize", color: "#ce6a57"};
            case NODES.Switch: return {icon: "fas fa-window-maximize", color: "#0075cf"};
            case NODES.Trigger: return {icon: "fas fa-window-maximize", color: "#ddc700"};
            case CARDS.ContentText: return {icon: "fas fa-comment-alt-lines", color: "#009688"};
            case CARDS.ContentQuickReply: return {icon: "fas fa-bullseye-pointer", color: "#009688"};
            case CARDS.FlowEntrypoint:
            case CARDS.FlowExitpoint: return {icon: "fas fa-arrow-alt-from-left", color: "#ce6a57"};
            case CARDS.Function: return {icon: "fa fa-code", color: "#0075cf"};
            case CARDS.Intent: return {icon: "fas fa-arrow-alt-from-left", color: "#ddc700"};
            case CARDS.Payload: return {icon: "fas fa-triangle", color: "#ddc700"};
            case CARDS.Tag: return {icon: "fas fa-tag", color: "#0075cf"};
            default: return {icon: "fas fa-search", color: "#888888"};
        }
    }, []);

    const renderResult = useCallback(( result: SearchMatch ) => {
        const resultMeta = renderResultMeta( result.type );
        const resultText = result.match.replaceAll( searchText, `<strong>${ searchText }</strong>` );

        return <>
            <div className="icon" style={{color: resultMeta.color}}>
                <i className={resultMeta.icon}></i>
            </div>
            <div className="result-text">
                {result.type !== "Flow" && (
                    <div className="flow-name">
                        <i className="fa fa-sitemap"></i> {result.flowName}
                    </div>
                )}
                <div className="text" dangerouslySetInnerHTML={{__html: resultText}}></div>
                {result.metadata?.intentName && (
                    <div className="meta">
                        <i className="fas fa-arrow-alt-from-left"></i> {result.metadata.intentName}
                    </div>
                )}
            </div>
            <span className="total">
                {result.numberOfMatches && result.numberOfMatches > 0 && (
                    <span className="badge" style={{backgroundColor: resultMeta.color}}>{result.numberOfMatches}</span>
                )}
            </span>
        </>;
    }, [renderResultMeta, searchText]);

    return (
        <div className={`flowbuilder-search ${isOpen ? "open" : "close"}`}>
            <div className="search-container">
                <div className="input-container">
                    <input placeholder="Search..." value={searchText} onChange={onSearchTextChange} ref={searchInputRef} type="text" name="search-autocomplete" />
                    {searchText.length > 0 && (
                        <button className="btn-clear" onClick={clear}>
                            <i className="fa fa-times"></i>
                        </button>
                    )}
                </div>
                <button className="btn-search" onClick={onSearchClick} title={!isOpen ? "Open search" : "Close search"}>
                    {isOpen ? ( <i className="fa fa-chevron-circle-right"></i> ) : ( <i className="fa fa-search"></i> )}
                </button>
            </div>
            <Loader loading={isLoading} className={"overlay"} />
            {searchText.length > 2 && (
                <div className="search-result">
                    <ul className="search-result-list">
                        {searchResults?.map(( result, index ) => (
                            <li key={`${result.flowId}_${result.type}_${index}`} onClick={onResultSelect.bind( null, result )}>
                                {renderResult( result )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FlowBuilderSearch;
import { TranslationManager } from "translation-manager";
import textCodes from "legacy/constants/translations/textCodes.json";
import { CARDS, NODES, CardFlow, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import { addCard } from "clean-archi/core/use-cases/flowbuilder/add-card/addCardThunk";
import { canAddCardOfTypeTo } from "legacy/context/flowBuilder/flowBuilderConfig";
import React, { FC, useMemo } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useAppDispatch } from "legacy/store/typedHooks";
import InfoTooltip from "legacy/components/InfoTooltip/InfoTooltip";

import "./AddEventCardMenu.scss";

interface props {
    nodeId: NodeFlow["id"];
    nodeType: NodeFlow["type"];
    index?: number;
    cardsTypes: CardFlow["type"][];
    isLastCardConnected?: boolean;
}

interface DisabledStatus {
    disabled: boolean;
    reason: string;
}

const AddEventCardMenu: FC<props> = ({nodeId, nodeType, index, cardsTypes, isLastCardConnected, ...props}) => {

    const dispatch = useAppDispatch();

    const actionAddCard = ( type: CARDS ) => {
        dispatch( addCard({ nodeId, type, index }));
    };
    const addDefaultCard = () => {
        actionAddCard( CARDS.Default );
    };
    const addContentQuickReplyCard = () => {
        actionAddCard( CARDS.ContentQuickReply );
    };
    const addFunctionCard = () => {
        actionAddCard( CARDS.Function );
    };
    const addIntentCard = () => {
        actionAddCard( CARDS.Intent );
    };
    const addPayloadCard = () => {
        actionAddCard( CARDS.Payload );
    };
    const canAddQuickReplyCard: DisabledStatus = useMemo(
        () => {
            const alreadyExist = !canAddCardOfTypeTo( CARDS.ContentQuickReply, nodeType, cardsTypes );

            return {
                disabled: alreadyExist || !!isLastCardConnected,
                reason: alreadyExist ? "Quick replies are already added" : "The last content card is already connected",
            };
        },
        [cardsTypes, isLastCardConnected, nodeType]
    );

    const canAddDefaultCard: DisabledStatus = useMemo(
        () => {
            const hasDefaultCard = cardsTypes.some( c => c === CARDS.Default );
            return {
                disabled: hasDefaultCard,
                reason: TranslationManager.getText( textCodes.DEFAULT_CARD_REACHED_LIMIT )
            };
        },
        [cardsTypes]
    );

    const DefaultMenuOptions: Function = () => (
        <DropdownButton
            className="btn-add-card"
            as={ButtonGroup}
            title={<i className="fa fa-plus"></i>}
        >
            <Dropdown.Item onClick={addContentQuickReplyCard} disabled={canAddQuickReplyCard.disabled}>
                Add Quick Replies
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={addEventCard} disabled>Event</Dropdown.Item> */}
            <Dropdown.Item onClick={addFunctionCard} disabled>Function</Dropdown.Item>
            <Dropdown.Item onClick={addIntentCard} disabled>Intent</Dropdown.Item>
            <Dropdown.Item onClick={addPayloadCard} disabled>Payload</Dropdown.Item>
        </DropdownButton>
    );

    const BotResponseMenuOptions: Function = () => (
        <DropdownButton
            className="btn-add-card"
            as={ButtonGroup}
            title={<i className="fa fa-plus"></i>}
        >
            <Dropdown.Item onClick={addContentQuickReplyCard} disabled={canAddQuickReplyCard.disabled}>
                <span>Add Quick Replies</span>
                {canAddQuickReplyCard.disabled && <InfoTooltip text={canAddQuickReplyCard.reason} />}
            </Dropdown.Item>
            <Dropdown.Item onClick={addIntentCard}>Intent</Dropdown.Item>
            <Dropdown.Item onClick={addDefaultCard} disabled={canAddDefaultCard.disabled}>
                <span>Default</span>
                {canAddDefaultCard.disabled && <InfoTooltip text={canAddDefaultCard.reason} />}
            </Dropdown.Item>
            <Dropdown.Item onClick={addPayloadCard}>Payload</Dropdown.Item>
            {/* <Dropdown.Item onClick={addEventCard} disabled>Event</Dropdown.Item> */}
        </DropdownButton>
    );

    const TriggerMenuOptions: Function = () => (
        <DropdownButton
            className="btn-add-card"
            as={ButtonGroup}
            title={<i className="fa fa-plus"></i>}
        >
            <Dropdown.Item onClick={addIntentCard} disabled>Intent</Dropdown.Item>
            <Dropdown.Item onClick={addPayloadCard}>Payload</Dropdown.Item>
        </DropdownButton>
    );

    switch ( nodeType ) {
        case ( NODES.Trigger ): return TriggerMenuOptions();
        case ( NODES.BotResponse ): return BotResponseMenuOptions();
        default: return DefaultMenuOptions();
    }
};

export default AddEventCardMenu;

import React from "react";
import { useMemo } from "react";
import { getAppConfigParams } from "../../clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";
import { useAppSelector } from "../store/typedHooks";


export const useToTranslate = () => {

    const { language } = useAppSelector( getAppConfigParams );

    const flag = useMemo(() => {
        switch ( language ) {
            case "fr":
                return "🇫🇷";
            case "en":
                return "🇬🇧";
            case "es":
                return "🇪🇸";
            case "de":
                return "🇩🇪";
            case "it":
                return "🇮🇹";
            case "pt":
                return "🇵🇹";
            case "da":
                return "🇩🇰";
            default:
                return "🏴‍☠️";
        }
    }, [language]);

    return () => <em>To Complete or Translate {flag}</em>;
};

import { PayloadAction } from "@reduxjs/toolkit";
import { ExitpointCardFlow, FlowBuilderState, NodeFlow } from "../../../entities/flowbuilder";

export const addFlowEntryElementsReducer = ( state: FlowBuilderState, action: PayloadAction<Partial<ExitpointCardFlow>> ) => {
    const { linkedEntryFlow, linkedEntryNode } = action.payload;

    if ( linkedEntryFlow && !state.botFlow.flows.has( linkedEntryFlow.id )) {
        state.botFlow.flows.set(
            linkedEntryFlow.id as string,
            {
                id: linkedEntryFlow.id,
                index: linkedEntryFlow.index,
                name: linkedEntryFlow.name,
                nodes: new Map<NodeFlow["id"], NodeFlow>()
            }
        );
    }

    const flow = state.botFlow.flows.get( linkedEntryFlow?.id as string );
    if ( flow ) {
        flow.nodes.set( linkedEntryNode?.id as string, linkedEntryNode as NodeFlow );
        state.botFlow.numberOfNodes++;
    }

    state.botFlow.shouldRerenderNodes = true;
};

import { useAppSelector, useAppDispatch } from "legacy/store/typedHooks";
import { MisunderstandingApiResponse, MessageDistributionApiResponse, UniqueUsersApiResponse } from "clean-archi/core/entities/statistics/StatisticsState";
 
type ApiResponse =
  | MisunderstandingApiResponse
  | MessageDistributionApiResponse
  | UniqueUsersApiResponse;

interface UseStatsDataProps {
  dataSelector: ( state: any ) => ApiResponse[];
  loadingSelector: ( state: any ) => boolean;
}

const useStatsData = ({ dataSelector, loadingSelector }: UseStatsDataProps ) => {
    const statsDates = useAppSelector(( state ) => state.stats.filters );
    const selectedConfiguration = useAppSelector(
        ( state ) => state.app.selectedConfiguration
    );
    const loading: boolean = useAppSelector( loadingSelector );
    const storeData = useAppSelector( dataSelector );
    const dispatch = useAppDispatch();

    return { storeData, loading, dispatch, statsDates, selectedConfiguration };
};

export default useStatsData;

import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import Card from "../../cards/Card/Card";
import CardIntent from "../../cards/CardIntent/CardIntent";
import CardPayload from "../../cards/CardPayload/CardPayload";
import CardContentText from "../../cards/CardContentText/CardContentText";
import CardContentQuickReply from "../../cards/CardContentQuickReply/CardContentQuickReply";
import CardFlowExitpoint from "../../cards/CardFlowExitpoint/CardFlowExitpoint";
import CardFlowEntrypoint from "../../cards/CardFlowEntrypoint/CardFlowEntrypoint";
import CardFunction from "../../cards/CardFunction/CardFunction";
import { canAddCardTo, isDraggable } from "legacy/context/flowBuilder/flowBuilderConfig";
import { CARDS,  CardFlow, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import CardDemo from "../../cards/CardDemo/CardDemo";
import CardDefault from "../../cards/CardDefault/CardDefault";
import CardTag from "../../cards/CardTag/CardTag";
import CardOutput from "../../cards/CardOutput/CardOutput";
import CardCase from "../../cards/CardCase/CardCase";

export interface MoveCardProps {
    id: string;
    fromIndex: number;
    toIndex: number;
}

interface Props {
    id: string;
    cards: CardFlow[];
    node: NodeFlow;
    onMoveCard: ( moveProps: MoveCardProps ) => void;
    menu: ReactNode;
    nodeCardsTypes: CardFlow["type"][];
}

interface DragCard {
    id: string;
    index: number;
    groupId: string;
}

const BotNodeCards: FC<Props> = ({ id, node, cards, onMoveCard, nodeCardsTypes }) => {
    const canAddCard = useMemo(() => canAddCardTo( node ), [node]);
    const [isDragging, setIsDragging] = useState<DragCard | undefined>();
    const [dragOverIndex, setDragOverIndex] = useState<number | undefined>();
    const groupId = useMemo(() => {
        return `${id }-${ node.id}`;
    }, [id, node.id]);
    
    const onHandleMouseDown = useCallback(( e: any ) => {
        e.currentTarget.closest( ".bot-card-inner" ).setAttribute( "draggable", "true" );
    }, []);

    const renderHandle = useCallback(( card: CardFlow, nodeType: NodeFlow["type"], index: number ) => {
        return ( isDraggable( card.type, nodeType ) && cards.length > 1 ) ? <i 
            className="fa fa-grip-lines drag-handle" 
            onMouseDown={onHandleMouseDown} 
            data-id={card.id}
            data-index={index}
            data-group={groupId}></i> : <></>;
    }, [cards.length, groupId, onHandleMouseDown]);

    const renderCard = useCallback(( card: CardFlow, nodeType: NodeFlow["type"], index: number, isLastCard: boolean ) => {

        const cardProps = {
            key: card.id,
            card,
            index,
            isLastCard,
            nodeId: node?.id as string,
            handle: renderHandle( card, nodeType, index ),
            nodeCardsTypes: nodeCardsTypes,
        };

        switch ( card.type ) {
            case CARDS.ContentText: return <Card {...cardProps} CardComponent={CardContentText} />;
            case CARDS.ContentQuickReply: return <Card {...cardProps} CardComponent={CardContentQuickReply} />;
            case CARDS.Default: return <Card {...cardProps} CardComponent={CardDefault} />;
            case CARDS.Intent: return <Card {...cardProps} CardComponent={CardIntent} />;
            case CARDS.Payload: return <Card {...cardProps} CardComponent={CardPayload} />;
            case CARDS.FlowExitpoint: return <Card {...cardProps} CardComponent={CardFlowExitpoint} />;
            case CARDS.FlowEntrypoint: return <Card {...cardProps} CardComponent={CardFlowEntrypoint} />;
            case CARDS.Function: return <Card {...cardProps} CardComponent={CardFunction} />;
            case CARDS.Tag: return <Card {...cardProps} CardComponent={CardTag} />;
            case CARDS.Output: return <Card {...cardProps} CardComponent={CardOutput} />;
            case CARDS.Case: return <Card {...cardProps} CardComponent={CardCase} />;
            default: return <Card {...cardProps} CardComponent={CardDemo} />;
        }
    }, [node?.id, nodeCardsTypes, renderHandle]);

    const allowDrop = ( e: any ) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    const onDrop = useCallback(( e: any ) => {
        if ( isDragging?.groupId === e.currentTarget.dataset.group && dragOverIndex !== undefined && isDragging?.index !== undefined ) {
            e.preventDefault();
            const newIndex = dragOverIndex > isDragging?.index ? dragOverIndex - 1 : dragOverIndex;
            onMoveCard({id: isDragging.id, fromIndex: isDragging.index, toIndex: newIndex });
        }
    }, [dragOverIndex, isDragging?.groupId, isDragging?.id, isDragging?.index, onMoveCard]);

    const onDragEnter = useCallback(( e: any ) => {
        if ( isDragging?.groupId === e.currentTarget.dataset.group ) {
            setDragOverIndex( parseInt( e.currentTarget.dataset.index ));
        }
    }, [isDragging]);

    const onDragLeave = useCallback(( e: any ) => {
        if ( parseInt( e.currentTarget.dataset.index ) === dragOverIndex ) {
            setDragOverIndex( undefined );
        }
    }, [dragOverIndex]);

    const onDragStart = useCallback(( e: any ) => {
        const dragging = {
            id: e.currentTarget.dataset.id,
            index: parseInt( e.currentTarget.dataset.index ),
            groupId: e.currentTarget.dataset.group,
        };

        setTimeout(() => {
            setIsDragging( dragging );
        }, 100 );
    }, []);

    const onDragEnd = useCallback(( e: any ) => {
        setIsDragging( undefined );
        setDragOverIndex( undefined );
    }, []);

    return (
        <div className={`${isDragging ? "is-dragging" : ""}`}>

            <div
                className={`card-dropzone ${dragOverIndex == ( cards.length > 0 ? cards[0].index : 0 ) ? "dropping" : ""}`}
                onDragOver={allowDrop} 
                onDrop={onDrop} 
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                data-id={0}
                data-group={groupId}
                data-index={cards.length > 0 ? cards[0].index : 0}
            ></div>
            
            {cards.map(( card, index: number ) => (
                <div 
                    key={card.id}
                    className={`bot-card-container ${isDragging?.id === card.id ? "dragging" : ""}`}
                >
                    {canAddCard && ( 
                        <div className="add-card-separator">
                            {/* {menu} */}
                        </div>
                    )}

                    <div 
                        className={"bot-card-inner"}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        draggable={isDragging?.id === card.id}
                        data-id={card.id}
                        data-index={card.index}
                        data-group={groupId}
                    >
                        {renderCard( card, node.type, card.index, cards.length - 1 === index )}
                    </div>

                    <div
                        className={`card-dropzone ${dragOverIndex == ( card.index + 1 ) ? "dropping" : ""}`}
                        onDragOver={allowDrop} 
                        onDrop={onDrop} 
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        data-id={card.id}
                        data-group={groupId}
                        data-index={card.index + 1}
                    ></div>
                </div>
            ))}
        </div>
    );
};

export default BotNodeCards;

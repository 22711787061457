export const positions = ["topLeft","topRight","bottomLeft","bottomRight"];
export const animateCss = [
    {value: "bounce", label: "bounce"},
    {value: "flash", label: "flash"},
    {value: "pulse", label: "pulse"},
    {value: "rubberBand", label: "rubberBand"},
    {value: "shake", label: "shake"},
    {value: "swing", label: "swing"},
    {value: "tada", label: "tada"},
    {value: "wobble", label: "wobble"},
    {value: "jello", label: "jello"},
    {value: "heartBeat", label: "heartBeat"},
    {value: "bounceIn", label: "bounceIn"},
    {value: "bounceInDown", label: "bounceInDown"},
    {value: "bounceInLeft", label: "bounceInLeft"},
    {value: "bounceInRight", label: "bounceInRight"},
    {value: "bounceInUp", label: "bounceInUp"},
    {value: "bounceOut", label: "bounceOut"},
    {value: "bounceOutDown", label: "bounceOutDown"},
    {value: "bounceOutLeft", label: "bounceOutLeft"},
    {value: "bounceOutRight", label: "bounceOutRight"},
    {value: "bounceOutUp", label: "bounceOutUp"},
    {value: "fadeIn", label: "fadeIn"},
    {value: "fadeInDown", label: "fadeInDown"},
    {value: "fadeInDownBig", label: "fadeInDownBig"},
    {value: "fadeInLeft", label: "fadeInLeft"},
    {value: "fadeInLeftBig", label: "fadeInLeftBig"},
    {value: "fadeInRight", label: "fadeInRight"},
    {value: "fadeInRightBig", label: "fadeInRightBig"},
    {value: "fadeInUp", label: "fadeInUp"},
    {value: "fadeInUpBig", label: "fadeInUpBig"},
    {value: "fadeOut", label: "fadeOut"},
    {value: "fadeOutDown", label: "fadeOutDown"},
    {value: "fadeOutDownBig", label: "fadeOutDownBig"},
    {value: "fadeOutLeft", label: "fadeOutLeft"},
    {value: "fadeOutLeftBig", label: "fadeOutLeftBig"},
    {value: "fadeOutRight", label: "fadeOutRight"},
    {value: "fadeOutRightBig", label: "fadeOutRightBig"},
    {value: "fadeOutUp", label: "fadeOutUp"},
    {value: "fadeOutUpBig", label: "fadeOutUpBig"},
    {value: "flip", label: "flip"},
    {value: "flipInX", label: "flipInX"},
    {value: "flipInY", label: "flipInY"},
    {value: "flipOutX", label: "flipOutX"},
    {value: "flipOutY", label: "flipOutY"},
    {value: "lightSpeedIn", label: "lightSpeedIn"},
    {value: "lightSpeedOut", label: "lightSpeedOut"},
    {value: "rotateIn", label: "rotateIn"},
    {value: "rotateInDownLeft", label: "rotateInDownLeft"},
    {value: "rotateInDownRight", label: "rotateInDownRight"},
    {value: "rotateInUpLeft", label: "rotateInUpLeft"},
    {value: "rotateInUpRight", label: "rotateInUpRight"},
    {value: "rotateOut", label: "rotateOut"},
    {value: "rotateOutDownLeft", label: "rotateOutDownLeft"},
    {value: "rotateOutDownRight", label: "rotateOutDownRight"},
    {value: "rotateOutUpLeft", label: "rotateOutUpLeft"},
    {value: "rotateOutUpRight", label: "rotateOutUpRight"},
    {value: "slideInUp", label: "slideInUp"},
    {value: "slideInDown", label: "slideInDown"},
    {value: "slideInLeft", label: "slideInLeft"},
    {value: "slideInRight", label: "slideInRight"},
    {value: "slideOutUp", label: "slideOutUp"},
    {value: "slideOutDown", label: "slideOutDown"},
    {value: "slideOutLeft", label: "slideOutLeft"},
    {value: "slideOutRight", label: "slideOutRight"},
    {value: "zoomIn", label: "zoomIn"},
    {value: "zoomInDown", label: "zoomInDown"},
    {value: "zoomInLeft", label: "zoomInLeft"},
    {value: "zoomInRight", label: "zoomInRight"},
    {value: "zoomInUp", label: "zoomInUp"},
    {value: "zoomOut", label: "zoomOut"},
    {value: "zoomOutDown", label: "zoomOutDown"},
    {value: "zoomOutLeft", label: "zoomOutLeft"},
    {value: "zoomOutRight", label: "zoomOutRight"},
    {value: "zoomOutUp", label: "zoomOutUp"},
    {value: "hinge", label: "hinge"},
    {value: "jackInTheBox", label: "jackInTheBox"},
    {value: "rollIn", label: "rollIn"},
    {value: "rollOut", label: "rollOut"},
];


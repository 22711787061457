import React from "react";
import PropTypes from "prop-types";
import Button    from "legacy/components/Button/Button";

const CustomLink = ({
    to,
    href,
    target,
    ...rest
}) => {
    const props = rest;

    const dest = to || href;
    if ( target ) {
        props.componentType = "a";
        props.href = dest;
        props.target = target;
    } else {
        props.componentType = "Link";
        props.to = dest;
    }

    return (
        <Button {...props} />
    );
};

CustomLink.propTypes = {
    ...Button.propTypes,
    to: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
};

export default CustomLink;

import React, { FC } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { CARDS, NODES, NodeFlow } from "clean-archi/core/entities/flowbuilder";
import { addCard } from "clean-archi/core/use-cases/flowbuilder/add-card/addCardThunk";
import { useAppDispatch } from "legacy/store/typedHooks";

interface props {
    nodeId: NodeFlow["id"];
    nodeType: NodeFlow["type"];
    index?: number;
}

const AddFunctionCardMenu: FC<props> = ({nodeId, nodeType, index}) => {

    const dispatch = useAppDispatch();

    const actionAddCard = ( type: CARDS ) => {
        dispatch( addCard({ nodeId, type, index }));
    };

    const addFunctionCard = () => {
        actionAddCard( CARDS.Function );
    };

    const addTagCard = () => {
        actionAddCard( CARDS.Tag );
    };

    const DefaultMenuOptions: Function = () => (
        <DropdownButton
            className="btn-add-card"
            as={ButtonGroup}
            title={<i className="fa fa-plus"></i>}
        >
        </DropdownButton>
    );

    const BotResponseMenuOptions: Function = () => (
        <DropdownButton
            className="btn-add-card"
            as={ButtonGroup}
            title={<i className="fa fa-plus"></i>}
        >
            <Dropdown.Item onClick={addFunctionCard}>Function</Dropdown.Item>
            <Dropdown.Item onClick={addTagCard}>Tag</Dropdown.Item>
        </DropdownButton>
    );

    switch ( nodeType ) {
        case NODES.BotResponse: 
        case NODES.Action: return BotResponseMenuOptions();
        default: return DefaultMenuOptions();
    }
};

export default AddFunctionCardMenu;

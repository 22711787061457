import React, {createContext, useCallback, useContext, useReducer} from "react";
import { ContextMenu, FlowBuilderDispatch, flowBuilderReducer, FlowBuilderState, initFlowBuilderState } from "./flowBuilderReducer";
import { FlowNode, FlowNodeCard } from "./flowBuilderTypes";
import { Position } from "clean-archi/core/entities/flowbuilder";
import { useReactFlow } from "react-flow-renderer";
import { useAppSelector } from "../../store/typedHooks";
import { getIsProd } from "../../../clean-archi/adapters/primary/view-models-generators/config/configParamsViewModels";

type FlowBuilderProviderProps = {children: React.ReactNode}

interface ConnectionHandle {
    nodeId: string | null;
    handleId: string | null;
    handleType: string | null;
}

const FlowBuilderContext = createContext<{
    centerOnNode: ( nodeId: string, duration?: number ) => void;
    flowBuilderState: FlowBuilderState; 
    flowBuilderDispatch: FlowBuilderDispatch;
    openContextMenu: (
        position: Position, 
        nodeId?: FlowNode["id"], 
        cardId?: FlowNodeCard["id"], 
        connection?: ConnectionHandle,
        index?: string,
        isCardLocked?: boolean
    ) => void;
    closeContextMenu: () => void;
    openSearch: () => void;
    closeSearch: () => void;
    contextMenu: ContextMenu;
        } | undefined>( undefined );

export function FlowBuilderContextProvider ({children}: FlowBuilderProviderProps ) {
    const reactFlowInstance = useReactFlow();
    const [flowBuilderState, flowBuilderDispatch] = useReducer( flowBuilderReducer, initFlowBuilderState());
    const isProd = useAppSelector( getIsProd );


    // -----------------------------------------------------------
    // FlowBuilder actions
    
    const openContextMenu = useCallback((
        position: Position,
        nodeId?: FlowNode["id"],
        cardId?: FlowNodeCard["id"],
        connection?: ConnectionHandle,
        index?: string,
        isCardLocked?: boolean
    ) => {
        if ( !isProd ) flowBuilderDispatch({
            type: "OPEN_CONTEXT_MENU",
            payload: {
                position,
                nodeId,
                cardId,
                connection,
                index,
                isCardLocked,
            }});
    }, [flowBuilderDispatch, isProd]);
    
    const closeContextMenu = useCallback(() => {
        flowBuilderDispatch({type: "CLOSE_CONTEXT_MENU"});
    }, [flowBuilderDispatch]);
    
    const openSearch = useCallback(() => {
        flowBuilderDispatch({type: "OPEN_SEARCH"});
    }, [flowBuilderDispatch]);
    
    const closeSearch = useCallback(() => {
        flowBuilderDispatch({type: "CLOSE_SEARCH"});
    }, [flowBuilderDispatch]);

    const centerOnNode = useCallback(
        ( nodeId, duration = 0 ) => {
            if ( nodeId ) {
                const node = reactFlowInstance.getNode( nodeId );
                if ( node ) {
                    reactFlowInstance.setCenter( node.position.x + ( node.width ? node.width / 2 : 125 ) , node.position.y + ( node.height ? node.height / 2 : 75 ), {
                        zoom: 1,
                        duration,
                    });
                } else {
                    reactFlowInstance.setCenter( 0, 0, {
                        zoom: 1,
                        duration,
                    });
                }
            }
        },
        [reactFlowInstance]
    );

    // -----------------------------------------------------------
    // Define context values

    const contextValues = {
        centerOnNode,
        flowBuilderState, flowBuilderDispatch,
        openContextMenu,
        closeContextMenu,
        contextMenu: flowBuilderState.contextMenu,
        openSearch,
        closeSearch,
    };

    return (
        <FlowBuilderContext.Provider value={contextValues}>
            {children}
        </FlowBuilderContext.Provider>
    );
}

// Custom useContext hook
export function useFlowBuilder () {
    const context = useContext( FlowBuilderContext );
    if ( context === undefined ) {
        throw new Error( "useFlowBuilder must be used within a FlowBuilderProvider" );
    }
    return context;
}

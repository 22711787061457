import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

interface PaginationProps {
  currentPage: number;
  nbOfPagesToDisplay: number;
  nbOfPages: number;
  onClick: ( page: number ) => void;
}

export const TrainingPagination = ({
    currentPage,
    nbOfPagesToDisplay,
    nbOfPages,
    onClick,
}: PaginationProps ) => {

    const [ pages, setPages ] = useState<number[]>([]);

    useEffect(() => {
        const ps = [];
        const start = Math.max( 1, currentPage - Math.floor( nbOfPagesToDisplay / 2 ));
        const end = Math.min( nbOfPages, currentPage + Math.floor( nbOfPagesToDisplay / 2 )) > nbOfPagesToDisplay
            ? Math.min( nbOfPages, currentPage + Math.floor( nbOfPagesToDisplay / 2 ))
            : nbOfPagesToDisplay;
        const endFinal = Math.min( nbOfPages, end );
        for ( let i = start; i <= endFinal; i++ ) {
            ps.push( i );
        }
        setPages( ps );
    }, [ currentPage, nbOfPagesToDisplay, nbOfPages ]);

    return (
        <Pagination>
            {currentPage !== 1 && < Pagination.First onClick={() => onClick( 1 )}/>}
            {pages.map( page => (
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => onClick( page )}
                >
                    {page}
                </Pagination.Item>
            ))}
            {currentPage !== nbOfPages && <Pagination.Last onClick={() => onClick( nbOfPages )}/>}
        </Pagination>
    );
};

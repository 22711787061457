import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, Control, UseFormRegister, UseFormReset } from "react-hook-form";
import { cloneDeep } from "lodash";
import { FunctionItem, FunctionSuggestion } from "clean-archi/core/entities/flowbuilder";
import { InputUpdateFunctionCardAction } from "clean-archi/core/use-cases/flowbuilder/update-card/updateCardThunk";
import { FormEntries, IFunctionFormsSharedContractExtraContract } from "./FunctionFormsSharedContract";
import { AutocompleteInputProps } from "../lib/AutocompleteInput/AutocompleteInput";

export interface FunctionFormProps {
    defaultValues: FormEntries;
    onChange: Function;
    extra: IFunctionFormsSharedContractExtraContract;
    onBlur?: Function;
}

interface FunctionForm {
    autocompleteItemToId: ( item: FunctionSuggestion ) => string;
    autocompleteItemToString: ( item: FunctionSuggestion | null | undefined ) => string;
    autocompleteSpecialItems: AutocompleteInputProps<FunctionSuggestion>["specialItems"];
    control: Control<FormEntries, any>;
    initialSelectedItem: FunctionSuggestion;
    items: FunctionSuggestion[];
    onOptionSelect: ({ selectedItem }: any ) => void;
    onDescriptionChange: ( e: React.ChangeEvent<HTMLInputElement> ) => void;
    onCodeChange: ( value: string ) => void;
    register: UseFormRegister<FormEntries>;
    updateItems: ({ inputValue }: any ) => void;
    resetForm: ( values?: Partial<FunctionItem> ) => void;
}

export function useFunctionForm ({ defaultValues, onChange, extra, onBlur }: FunctionFormProps ): FunctionForm {

    const [formState, setFormState] = useState<FormEntries>( defaultValues );
    const {
        control,
        register,
        setValue,
        reset,
    } = useForm<any>({ defaultValues, mode: "all" });
    const [items, setItems] = useState<FunctionSuggestion[]>([]);

    useEffect(
        () => {
            function resetFormState () {
                setFormState( prevFormState => ({
                    ...prevFormState,
                    id: defaultValues.id
                }));
            }
            resetFormState();
        },
        [defaultValues.id]
    );

    const autocompleteItemToId = ( item: FunctionSuggestion ) => item.id as string;

    const autocompleteItemToString = ( item: FunctionSuggestion | null | undefined ) => item?.name || "";

    const autocompleteSpecialItems: AutocompleteInputProps<FunctionSuggestion>["specialItems"] = useMemo(
        () => extra.getAutocompleteSpecialItems( formState, items ),
        [extra, formState, items]
    );

    const initialSelectedItem = useMemo(
        () => extra.getInitialSelectedItem( defaultValues ),
        [defaultValues, extra]
    );

    const updateItems = useCallback(
        ({ inputValue }) => {
            if ( !inputValue ) {
                setItems([]);
                return;
            }
            extra.getSuggestions( inputValue )
                .then(( data: FunctionSuggestion[]) => {
                    const suggestions = Array.isArray( data ) ? data : [];
                    setItems( suggestions );
                });
        },
        [extra]
    );

    const onOptionSelect = useCallback(
        ({ selectedItem }) => {

            if ( !selectedItem ) {
                onBlur?.();
                return;
            }

            const value = selectedItem as FunctionSuggestion;
            const hasNoId = !value.id;
            const isSameFunction = !hasNoId && value.id === formState.id;

            const nextFormState = {
                ...formState,
                action:
                    ( isSameFunction && "update" as InputUpdateFunctionCardAction ) ||
                    ( hasNoId && "create" as InputUpdateFunctionCardAction ) ||
                    "select" as InputUpdateFunctionCardAction,
                id: value.id,
                name: value.name,
                description: value.description,
                code: value.code
            };

            setFormState( nextFormState );
            setValue( "id", value.id );
            setValue( "name", value.name );
            setValue( "description", value.description );
            setValue( "code", value.code );

            if ( nextFormState.action && value.name ) {
                onChange( nextFormState, nextFormState.action === "update" );
            }

            onBlur?.();
        },
        [formState, onChange, setValue, onBlur]
    );

    const onDescriptionChange = useCallback(
        ( e: React.ChangeEvent<HTMLInputElement> ) => {
            const description = e.target.value;
            const hasNoId = !formState.id;

            const nextFormState = cloneDeep( formState );
            nextFormState.action = ( hasNoId ? "create" : "update" ) as InputUpdateFunctionCardAction;
            nextFormState.description = description;
            setFormState( nextFormState );

            onChange( nextFormState );
        },
        [formState, onChange]
    );

    const onCodeChange = useCallback(
        ( value: string ) => {
            const code = value;
            const hasNoId = !formState.id;

            const nextFormState = cloneDeep( formState );
            nextFormState.action = ( hasNoId ? "create" : "update" ) as InputUpdateFunctionCardAction;
            nextFormState.code = code;
            setFormState( nextFormState );
            setValue( "code", code );

            onChange( nextFormState );
        },
        [formState, onChange, setValue]
    );

    const resetForm = useCallback(( values?: Partial<FunctionItem> ) => {
        let nextFormState = cloneDeep( formState );
        nextFormState = {...nextFormState, ...values};
        nextFormState.action = ( !values?.id ? "create" : "update" ) as InputUpdateFunctionCardAction;
        setFormState( nextFormState );
        reset( values );
    }, [formState, reset]);

    return {
        autocompleteItemToId,
        autocompleteItemToString,
        autocompleteSpecialItems,
        control,
        initialSelectedItem,
        items,
        onOptionSelect,
        onCodeChange,
        onDescriptionChange,
        register,
        updateItems,
        resetForm,
    };
}

export default useFunctionForm;

import { Flow } from "./Flow";
import { FlowExitpointItem, FlowEntrypointItem } from "./FlowLink";
import { FunctionItem } from "./Function";
import { IO } from "./Connection";
import { NodeFlow } from "./Node";
import { PayloadItem } from "./Payload";
import { IntentItem } from "./Intent";
import { AcmItem } from "./Acm";
import { TagItem } from "./Tag";

/* eslint-disable no-unused-vars */

export enum CARD_STYLE {
    Bordered = "Bordered",
    Borderless = "Borderless",
}

export enum CARDS {
    Event = "Event",
    Function = "Function",
    Intent = "Intent",
    FlowExitpoint = "FlowExitpoint",
	FlowEntrypoint = "FlowEntrypoint",
    Payload = "Payload",
    ContentText = "ContentText",
    ContentQuickReply = "ContentQuickReply",
    Tag = "Tag",
    Output = "Output",
    Default = "Default",
    Case = "Case",
}

export type CardFlow = {
    id: string;
    index: number;
    type: CARDS;
    connections: IO;
    acm?: AcmItem;
    payload?: PayloadItem;
    case?: string;
    intent?: IntentItem;
    tag?: TagItem;
    flowExitpoint?: FlowExitpointItem;
    flowEntrypoint?: FlowEntrypointItem;
    function?: FunctionItem;
    isEditing?: boolean;
    isLocked?: boolean;
    isDefault?: boolean;
}

export type ExitpointCardFlow = CardFlow & {
    linkedEntryFlow: Flow;
    linkedEntryNode: NodeFlow;
}

import React, {FC} from "react";
import {ButtonGroup, DropdownButton} from "react-bootstrap";
import { useAppSelector } from "../../../../../../../legacy/store/typedHooks";
import { getIsProd } from "../../../../view-models-generators/config/configParamsViewModels";
import FlowItemMenuContent from "./FlowItemMenuContent";

interface FlowItemMenuProps {
    onDelete?: () => void;
    onRename?: () => void;
    onDuplicate?: () => void;
}

const FlowItemMenu: FC<FlowItemMenuProps> = ({onDelete, onDuplicate, onRename}) => {

    const isProd = useAppSelector( getIsProd );

    const preventSelect = ( e: React.MouseEvent<HTMLElement> ) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return ( !isProd ? (
        <DropdownButton
            className="FlowsMenu__navLink_menu"
            as={ButtonGroup}
            title={<i className="fas fa-ellipsis-h"></i>}
            onClick={preventSelect}
        >
            <FlowItemMenuContent onDelete={onDelete} onRename={onRename} />
        </DropdownButton>
    ) : null );
};

export default FlowItemMenu;

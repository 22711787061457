import React, {FC, useContext, useMemo} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import routes from "legacy/constants/routes";
import { AppContext } from "legacy/context/AppContext";
import { useMount } from "legacy/hooks/useEffect";

import "./Code.scss";
import CodeSidebar from "./CodeSidebar";

const Code: FC = () => {
    const history = useHistory();
    const { setSidebarMini } = useContext( AppContext );
    const codeRoutes = useMemo(() => Object.values( routes || {}).filter( pathItem => pathItem.inCodeSwitch ), []);

    const isSystem = useMemo(() => history.location.pathname.includes( "system/code" ), [history.location.pathname]);

    useMount(() => {
        setSidebarMini( true );
    });

    return (
        <div className="Code">
            <CodeSidebar isSystem={isSystem} />
            <Switch>
                {codeRoutes.map(( pathItem, index ) => (
                    <Route
                        key={pathItem.id}
                        exact={pathItem.exact}
                        path={pathItem.buildPath()}
                        component={pathItem.component}
                    />
                ))}
            </Switch>
        </div>
    );
};

export default Code;

import { hasInput, hasOutput } from "legacy/context/flowBuilder/flowBuilderConfig";
import { CardFlow } from "clean-archi/core/entities/flowbuilder";
import React, { FC, ReactNode } from "react";
import { Position } from "react-flow-renderer";
import CardHandle from "../../handles/CardHandle/CardHandle";

import "./CardDemo.scss";

interface Props extends CardFlow {
    form?: any;
    index: number;
    onClick?: Function;
    handle?: ReactNode;
}

const CardDemo: FC<Props> = ({id, type, index, onClick, handle}) => {
    return (
        <div className="bot-card bot-card-demo">
            {( index > 0 && hasInput( type )) && (
                <CardHandle id={id} type="target" position={Position.Left}/>
            )}
            {handle}
            <div className="content" onClick={( e: React.MouseEvent ) => onClick?.( e )}>
                DEMO - <strong>{type}</strong>
            </div>
            {hasOutput( type ) && (
                <CardHandle id={id} type="source" position={Position.Right}/>
            )}
        </div>
    );
};

export default CardDemo;

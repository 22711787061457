import { useAppDispatch, useAppSelector } from "legacy/store/typedHooks";
const useFrameInfo = ({ frameInfoProp }: { frameInfoProp: string }) => {
    const dispatch = useAppDispatch();
    const selectedConfiguration = useAppSelector(
        ( state ) => state.app.selectedConfiguration
    );
    const statsDates = useAppSelector(( state ) => state.stats.filters );
    
    const conversationDurationData = useAppSelector(
        ( state ) => state.stats.averageConversationsDuration[0]
    );
    const totalInteractionsData = useAppSelector(
        ( state ) => state.stats.conversationsCount
    );
    const transfersNumberData = useAppSelector(
        ( state ) => state.stats.transfersNumber
    );

    let frameInfoStoreData;

    switch ( frameInfoProp ) {
        case "ConversationDuration":
            frameInfoStoreData =
              conversationDurationData?.averageConversationsDuration;
            break;
        case "TotalInteractions": {
            let sum = 0;
            if (Array.isArray(totalInteractionsData)) {
                for ( let value of totalInteractionsData ) {
                    sum += value.conversationsCount;
                }
                frameInfoStoreData = sum;
            }
            break;
        }
        case "TransfersNumber":{
            let sum = 0;
            for ( let value of transfersNumberData ) {
                sum += value.transfersCount;
            }
            frameInfoStoreData = sum;
            break;
        }
        default:
            frameInfoStoreData = 0;
            break;
    }  

    return {
        dispatch,
        frameInfoStoreData,
        selectedConfiguration,
        statsDates,
    };
};

export default useFrameInfo;

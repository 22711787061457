import React  from "react";
import cx     from "classnames";
import Button from "legacy/components/Button/Button";

import "legacy/components/Buttons/UpdateButton.scss";

const icon = <i className="fal fa-pencil UpdateButton__icon"/>;

const UpdateButton = ({ openInNewIcon = false, className, ...rest }) => (
    <Button
        {...rest}
        bsStyle="warning"
        className={cx( className, "btn-update", "UpdateButton__button" )}
        icon
        simple
    >
        {!openInNewIcon && <i className="fal fa-pencil UpdateButton__icon"/>}
        {openInNewIcon && <i className="fal fa-external-link-alt"/>}
    </Button>
);

UpdateButton.propTypes = Button.propTypes;
UpdateButton.icon = icon;
export default UpdateButton;

import {
    CARDS,
    NODES,
    IO,
    Position,
    AcmItem,
    PayloadItem,
    IntentItem,
    FlowExitpointItem,
    FunctionItem,
    SystemFnMetadata
} from "clean-archi/core/entities/flowbuilder";

export const REGEX_2XX = /^2[0-9][0-9]$/;

export interface BotFlowApiReturnType {
    id: string;
    botId: string;
    branchName: string;
    flows: FlowApiReturnType[];
}

export interface FlowApiReturnType {
    id: string;
    index: number;
    botFlowId: string;
    name: string;
    nodes: NodeFlowApiReturnType[];
}

export interface NodeFlowApiReturnType {
    id: string;
    name: string;
    flowId: string;
    position: Position;
    type: NODES;
    connections: IO;
    cards: CardFlowApiReturnType[];
}

export interface CardFlowApiReturnType {
    id: string;
    type: CARDS;
    index: number;
    connections: IO;
    acm?: AcmItem;
    payload?: PayloadItem;
    intent?: IntentItem;
    flowExitpoint?: FlowExitpointItem;
    linkedEntryFlow: FlowApiReturnType;
    linkedEntryNode: NodeFlowApiReturnType;
    function?: FunctionItem;
    systemFnMetadata?: SystemFnMetadata;
}

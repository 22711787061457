import React, {FC, useCallback, useState} from "react";
import {Dropdown} from "react-bootstrap";
import { useAppSelector } from "../../../../../../../legacy/store/typedHooks";
import { getIsProd } from "../../../../view-models-generators/config/configParamsViewModels";

interface FunctionItemMenuContentProps {
    onDelete?: () => void;
    onRename?: () => void;
    onDuplicate?: () => void;
    onExecute?: () => void;
    isCron?: boolean;
}

const FunctionItemMenuContent: FC<FunctionItemMenuContentProps> = ({isCron, onDelete, onDuplicate, onExecute, onRename}) => {

    const [showConfirmDelete, setShowConfirmDelete] = useState( false );
    const isProd = useAppSelector( getIsProd );

    const onClickDelete = useCallback(( e: React.MouseEvent<HTMLElement> ) => {
        e.preventDefault();
        e.stopPropagation();
        onDelete?.();
    }, [onDelete]);

    const openConfirmDelete = useCallback(( e: React.MouseEvent<HTMLElement> ) => {
        e.preventDefault();
        e.stopPropagation();

        if ( !showConfirmDelete ) {
            setShowConfirmDelete( true );
        }
    }, [showConfirmDelete]);

    const cancelDelete = useCallback(() => {
        setShowConfirmDelete( false );
    }, [setShowConfirmDelete]);

    return (
        <>
            {isCron && (
                <Dropdown.Item onClick={onExecute}>
                    <i className="fa fa-play"></i> Execute
                </Dropdown.Item>
            )}
            {
                !isProd && (
                    <>
                        <Dropdown.Item onClick={onRename}><i className="fas fa-pencil-alt"></i> Rename</Dropdown.Item>
                        <Dropdown.Item onClick={onDuplicate} disabled><i className="fa fa-clone"></i> Duplicate</Dropdown.Item>
                        <Dropdown.Item onClick={openConfirmDelete}>
                            {!showConfirmDelete ? (
                                <>
                                    <i className="fa fa-trash"></i> Delete
                                </>
                            ) : (
                                <div className="FunctionsMenu__navLink_menu_confirm">
                                    <span>Confirm delete?</span>
                                    <span>
                                        <button className="btn-cancel" onClick={cancelDelete}><i className="fa fa-times"></i></button>
                                        <button className="btn-confirm" onClick={onClickDelete}><i className="fa fa-check"></i></button>
                                    </span>
                                </div>
                            )}
                        </Dropdown.Item>
                    </>
                )
            }
        </>
    );
};

export default FunctionItemMenuContent;
